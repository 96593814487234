import { Component, OnInit } from '@angular/core';
import { carriers } from '../../../../config/metadata-config';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ProspectService } from '../../../services/prospect.service';
import { Router } from '@angular/router';
import { PolicyService } from 'src/app/services/policy.service';

export function validShipperNumber(status?: any): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const condition = true;
    return condition ? { notValid: status } : null;
  };
}

@Component({
  selector: 'upsc-shipper',
  templateUrl: './shipper.component.html',
  styleUrls: ['./shipper.component.scss']
})

export class ShipperComponent implements OnInit {
  showCarriers: boolean = false;
  carriers: string[] = [];
  filteredCarriers: string[] = [];
  selectedCarriers: string[] = [];
  shipmentForm: FormGroup;
  searchControl = new FormControl();
  upsSelected: boolean = false;
  otherSelected: boolean = false;
  prospectPortalData;
  isImageVisible: boolean = false;
  pageError: boolean = false;
  shipperNumbers: string[] = [];
  


  shipperForm: FormGroup;
  allShipperFormControls;
  newShipperNumbers = [];
  numberOfAddedShippers: any = 1; // Count starts at 1 (when page loads, we create 1 shipper number form control for them)
  validateLoading: boolean = false;
  validateError: boolean = false;
  atLeastOneError: boolean = false;
  atLeastOneShipperNumberEntered: boolean = false;

  constructor(private fb: FormBuilder, private prospectService: ProspectService, private router: Router, private policyService: PolicyService) {
    const savedData = sessionStorage.getItem('prospectPortalData');
    if (savedData) {
      this.prospectPortalData = JSON.parse(savedData);
      this.selectedCarriers = this.prospectPortalData.selectedCarriers ? this.prospectPortalData.selectedCarriers : [];
      this.shipperNumbers = this.prospectPortalData.shipperNumbers;
    } else {
      this.pageError = true;
    }

    this.prospectPortalData = JSON.parse(sessionStorage.getItem('prospectPortalData'));
    this.shipmentForm = this.fb.group({
      selectedCarriers: [this.prospectPortalData?.selectedCarriers ?? [], Validators.required],
      // selectedCarriers: this.fb.array([]),
      shipperNumbers: this.fb.array(this.initializeShipperNumbers()),
      numberOfShipments: [this.prospectPortalData?.numberOfShipments, Validators.required],
      valueOfShipments: [this.prospectPortalData?.valueOfShipments, Validators.required]
    });

    // For any new UPS shipper numbers added
    this.shipperForm = this.fb.group({
      1: ['', { updateOn: 'blur', validators: [validShipperNumber] }]
    });

    this.allShipperFormControls = Object.keys(this.shipperForm.controls);
  }

  ngOnInit(): void {
    this.carriers = Object.keys(carriers);
    this.filteredCarriers = this.carriers;
    this.searchControl.valueChanges.subscribe(value => {
      this.filterCarriers(value);
    });

    this.shipperForm.patchValue({
      '1': this.prospectPortalData?.shipperNumbers?.[0] ?? ''
    });
  }

  initializeShipperNumbers(): any[] {
    const shipperNumbers = this.prospectPortalData?.shipperNumbers ?? [];
    return shipperNumbers.map((number: string) => this.fb.control(number));
  }

  get shipperNumbersArray(): FormArray {
    return this.shipperForm.get('shipperNumbers') as FormArray;
  }

  toggleShipperNumberValidation(): void {
    this.shipperNumbersArray.controls.forEach(control => {
      if (this.upsSelected) {
        control.setValidators(Validators.required);
      } else {
        control.clearValidators();
      }
      control.updateValueAndValidity();
    });
  }

  onUpsSelectedChange(selected: boolean): void {
    this.upsSelected = selected;
    this.toggleShipperNumberValidation();
  }


  filterCarriers(value: string): void {
    if (!value) {
      this.filteredCarriers = this.carriers;
    } else {
      this.filteredCarriers = this.carriers.filter(carrier =>
        carrier.toLowerCase().startsWith(value.toLowerCase())
      );
    }
  }

  onSearchBarClick(): void {
    this.showCarriers = true;
  }

  onSelectCarrier(event: any): void {
    const carrier = event.option.value;
    if (!this.selectedCarriers?.includes(carrier) && this.selectedCarriers?.length < 3) {
      this.selectedCarriers.push(carrier);
      this.shipmentForm.controls.selectedCarriers.setValue(this.selectedCarriers);
    }


    this.upsSelected = false;
    this.otherSelected = false; // If they make any edits to their carriers, hide any other input fields until they click 'I'm done adding shippers' button again
    this.searchControl.reset();
  }

  clearSelectedCarrier(carrier: string): void {
    this.selectedCarriers = this.selectedCarriers.filter(c => c !== carrier);

    this.upsSelected = false;
    this.otherSelected = false; // If they make any edits to their carriers, hide any other input fields until they click 'I'm done adding shippers' button again
  }

  get shipmentNumbers(): FormArray {
    return this.shipmentForm.get('shipperNumbers') as FormArray;
  }

  addShipmentNumber(): void {
    this.shipperNumbers.push(this.fb.control('shipperNumber').value);
  }

  removeShipmentNumber(index: number): void {
    const shipperNumbers = this.shipmentForm.get('shipperNumbers') as FormArray;
    if (shipperNumbers.length > 1) {
      shipperNumbers.removeAt(index);
    }
  }

  doneAdding(): void {
    this.updateCarrierSelection();
  }

  updateCarrierSelection(): void {
    this.upsSelected = this.selectedCarriers.includes('UPS');
    this.otherSelected = this.selectedCarriers.some(carrier => carrier !== 'UPS');
  }

  onSubmit(formData: any, event: Event) {
    event.preventDefault();
  }

  isFormValid(): boolean {
    if (this.upsSelected) {
      return this.shipmentForm.get('valueOfShipments')?.valid && this.atLeastOneShipperNumberEntered;
    }
    return this.shipmentForm.valid;
  }

  onContinue(): void {
    if (!this.upsSelected && this.shipmentForm.invalid) {
      return;
    }

    this.validateLoading = true;
    this.validateError = false;
    this.atLeastOneError = false;
    if (this.upsSelected) {
      this.validateShippers();
    } else { // Skip, go to save prospect portal session directly
      this.saveProspectPortalSession();
    }
  }

  saveProspectPortalSession() {
    const formData = this.shipmentForm.value;
    const obj = {
      selectedCarriers: this.selectedCarriers,
      numberOfShipments: formData.numberOfShipments,
      valueOfShipments: formData.valueOfShipments,
      // shipperNumbers: this.shipmentNumbers.value,
      shipperNumbers: this.retrieveAddedShipperNumbers()
    };

    const prospectPortalData = JSON.parse(sessionStorage.getItem('prospectPortalData')) || {};
    const data = { ...prospectPortalData, ...obj };

    this.prospectService.saveProspectPortalSession({
      opportunityID: this.prospectPortalData.opportunityID,
      emailAddress: this.prospectPortalData.customerEmail,
      data: JSON.stringify(data)
    }).subscribe({
      next: (response) => {
        console.log(response);
        sessionStorage.setItem('prospectPortalData', JSON.stringify(data));
        this.router.navigate(['prospect-portal/products']);
        this.validateLoading = false;
      },
      error: (err) => {
        console.log('Error saving session');
        this.validateError = true;
        this.validateLoading = false;
      }
    });
  }

  toggleImage() {
    this.isImageVisible = !this.isImageVisible;
  }

  ngAfterViewInit() {
    this.shipperForm.valueChanges.subscribe(
      val => {
        let enteredValues = [];
        for (const field in this.shipperForm.controls) {
          if (!this.shipperForm?.controls[field]?.value) {
            this.shipperForm.controls[field].setErrors(null);
            this.shipperForm.controls[field].clearValidators();
          } else {
            enteredValues.push(this.shipperForm?.controls[field]?.value);
          }
        }

        if (enteredValues?.length > 0) {
          this.atLeastOneShipperNumberEntered = true; // User is not allowed to continue unless they have at least one shipper number entered
        } else {
          this.atLeastOneShipperNumberEntered = false;
        }
      }
    );
  }

  addShipperFormControl() {
    this.shipperForm.addControl(this.numberOfAddedShippers + 1, new FormControl('', { updateOn: 'blur' }), { emitEvent: false });
    this.numberOfAddedShippers += 1;
    this.allShipperFormControls = Object.keys(this.shipperForm.controls);
  }

  removeShipperFormControl(formControlName) {
    this.shipperForm.removeControl(formControlName);
    this.allShipperFormControls = Object.keys(this.shipperForm.controls);
  }

  retrieveAddedShipperNumbers() {
    this.newShipperNumbers = []; // Reset array
    for (const field in this.shipperForm.controls) {
      if (this.shipperForm?.controls[field]?.value) {
        this.newShipperNumbers.push(this.shipperForm?.controls[field]?.value);
      }
    }

    return this.newShipperNumbers;
  }

  validateShippers() {
    // Get an array of all shipper numbers the user input
    let inputtedShipperNumberArray = [];
    for (const field in this.shipperForm.controls) {
      if (this.shipperForm?.controls[field]?.value) {
        inputtedShipperNumberArray.push({
          formControlValue: field,
          shipperNumber: this.shipperForm?.controls[field]?.value
        });
      } else {
        this.shipperForm.controls[field].setErrors(null);
        this.shipperForm.controls[field].clearValidators();
      }
    }

    // Format the array above into a new array format for the validation API
    if (inputtedShipperNumberArray?.length > 0) {
      let payloadShipper = [];
      for (let i = 0; i < inputtedShipperNumberArray?.length; i++) {
        payloadShipper.push({
          shipperNumber: inputtedShipperNumberArray[i].shipperNumber,
          carrierType: 'UPS',
          startDate: new Date(),
          country: 'US'
        });
      }

      this.policyService.validateMultipleShipperNumbers(payloadShipper).subscribe(
        data => {
          // this.validateLoading = false;
          if (data?.data) {
            // For any invalid shipper numbers, we will add an error to the form control
            for (let i = 0; i < data?.data?.length; i++) {
              if (data?.data[i]?.validIndicator != 'Yes') {
                this.shipperForm.controls[inputtedShipperNumberArray[i].formControlValue].setErrors({ notValid: true });
                this.shipperForm.controls[inputtedShipperNumberArray[i].formControlValue].addValidators(validShipperNumber(true));
              } else {
                this.shipperForm.controls[inputtedShipperNumberArray[i].formControlValue].setErrors(null);
                this.shipperForm.controls[inputtedShipperNumberArray[i].formControlValue].clearValidators();
              }
            }

            if (this.shipperForm.valid) {
              this.retrieveAddedShipperNumbers();
              this.saveProspectPortalSession();
            } else {
              this.validateLoading = false;
            }
          } else {
            // If no data is returned, we have to stop them and display an error.
            this.validateError = true;
          }
        }, error => {
          this.validateLoading = false;
          this.validateError = true;
        }
      );
    } else {
      this.validateLoading = false;
      this.atLeastOneError = true;
    }
  }
}
