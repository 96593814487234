<div *ngIf="!pageLoading && !systemError" class="layout-wrapper">
  <div class="wrapper">
    <div class="title">Review & Submit</div>
    <div class="green-bar"></div>
    <div class="description">Please verify that the following information is correct.</div>
  
    <div class="subtitle">Contact Information<mat-icon (click)="edit('contact')">edit</mat-icon></div>
    <div><span class="bold">email: </span><span class="text">{{ prospectPortalData.customerEmail }}</span></div>
    <div><span class="bold">phone: </span><span class="text">{{ prospectPortalData.customerPhone }}</span></div>
    
    <div class="subtitle">Business Tax ID<mat-icon (click)="edit('tax')">edit</mat-icon></div>
    <div class="text">{{ prospectPortalData.taxID }}</div>
  
    <div class="subtitle">Products<mat-icon (click)="edit('product')">edit</mat-icon></div>
    <div class="text" *ngFor="let product of selectedProducts">{{ product.value }}</div>
  
    <div *ngIf="hasUPS">
      <div class="subtitle">UPS Shipper Number(s)<mat-icon (click)="edit('shipper')">edit</mat-icon></div>
      <div class="text" *ngFor="let shipper of prospectPortalData.shipperNumbers" class="text">{{ shipper }}</div>
    </div>

    <div class="subtitle">Transportation Carrier<mat-icon (click)="edit('carrier')">edit</mat-icon></div>
    <div class="text" *ngFor="let carrier of prospectPortalData.selectedCarriers">{{ carrier }}</div>
  
    <div class="subtitle">Shipment Details<mat-icon (click)="edit('details')">edit</mat-icon></div>
    <div class="text">Weekly Shipments: {{ prospectPortalData.numberOfShipments }}</div>
    <div class="text">Average Value: {{ prospectPortalData.valueOfShipments | currency:'USD' }}</div>
  
    <div class="bottom-nav">
      <button class="upsc-white-button" (click)="back()">
        <mat-icon>chevron_left</mat-icon>Back
      </button>
      <button class="upsc-button" *ngIf="!submitting" (click)="submit()">
        Submit<mat-icon>chevron_right</mat-icon>
      </button>
      <button class="upsc-button" [disabled]="true" *ngIf="submitting">
        Submit
        <div class="spinner-border" role="status">
          <span class="sr-only">{{ 'claims.edit.loading' | translate }}</span>
        </div>
      </button>
    </div>
    <div class="system-error" *ngIf="submissionError">
      Something went wrong. Please try again later.
    </div>
  </div>
</div>

<div class="system-error" *ngIf="systemError">
  Something went wrong. Please try again later.
</div>

<div class="spinner d-flex justify-content-center" *ngIf="pageLoading">
  <div class="spinner-border">
      <span class="sr-only">{{ 'guestVerification.form.loading' | translate }}</span>
  </div>
</div>