<div class="recent-invoices" *ngIf="dashboardRecentInvoices">
  <div class="loading" *ngIf="loading">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <div class="mobile">
    <div class="mobile-section">
      <div class="mobile-table" *ngFor="let invoice of invoiceListNoPlanned.slice(0,5)">
        <div class="invoice-item dashboard">
          <div class="item-header">
            <div class="invoice-number mr-auto">
                <span class="roboto-medium d-flex">{{invoice.invoiceNumber}}</span>
            </div>
            <div class="bubble d-flex align-items-center">
              <div class="status-box"
                [ngClass]="{'blue': invoice.status=='Planned', 'orange': invoice.status=='Billed', 'blood-orange': invoice.status=='Due' && invoice.dueAmount>0 || invoice.status==='Pending', 'pink': invoice.paymentTechStatus=='Payment Drafted' || invoice.paymentTechStatus=='Payment Being Drafted' || invoice.paymentTechStatus=='Payment Approved' || invoice.paymentTechStatus=='Payment Declined' || invoice.paymentTechStatus=='Payment Transaction Error' || invoice.paymentTechStatus=='Payment Reversed' || invoice.status==='Payment-Requested' || invoice.status==='Declined', 'turquoise': invoice.status=='paid' || invoice.paymentTechStatus=='Payment Received' || invoice.status=='Due' && invoice.dueAmount==0 || invoice.status==='Approved', 'green': invoice.payTo }">
                <div *ngIf="invoice.paymentTechStatus">{{ invoice.paymentTechStatus }}</div>
                <div *ngIf="invoice.status==='Planned'">{{ 'billing.status.upcoming' | translate }}</div>
                <div
                  *ngIf="(invoice.status==='Billed') && (invoice.paytweakLink ==null) && (isUsaUser || isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser)">
                  {{ 'billing.status.billed' | translate }}</div>
                <div
                  *ngIf="(invoice.status==='Billed') && (invoice.paytweakLink !==null) && (isUkUser || isGermanyUser || isFranceUser || isItalyUser)">
                  <a href="{{ invoice.paytweakLink }}" target="_blank">{{ 'billing.status.billed' | translate }}</a>
                </div>
                <div
                  *ngIf="(invoice.status==='Due' || invoice.status==='Pending') && (invoice.dueAmount>0) && (invoice.paytweakLink ==null) && (isUsaUser || isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser)">
                  {{ 'billing.status.due' | translate }}</div>
                <div
                  *ngIf="(invoice.status==='Due') && (invoice.dueAmount>0) && (invoice.paytweakLink !==null) && (isUkUser || isGermanyUser || isFranceUser || isItalyUser)">
                  <a href="{{ invoice.paytweakLink }}" target="_blank">{{ 'billing.status.due' | translate }}</a>
                </div>
                <div *ngIf="invoice.status==='paid' || invoice.status==='Due' && invoice.dueAmount==0 || invoice.status==='Approved'">{{
                  'billing.status.paid' | translate }}</div>
                <div *ngIf="invoice.payTo">{{ 'billing.status.refund' | translate }}</div>
                <div *ngIf="invoice.status==='Created'">{{ 'billing.status.pending' | translate }}</div>
                <div *ngIf="invoice.status==='Requested' || invoice.status==='Payment-Requested'">{{ 'billing.status.pending' | translate }}</div>
                <div *ngIf="invoice.status==='Fixed'">{{ 'billing.status.processing' | translate }}</div>
                <div *ngIf="invoice.status==='Drafted'">{{ 'billing.status.processing' | translate }}</div>
                <div *ngIf="invoice.status==='Closed'">{{ 'billing.status.processed' | translate }}</div>
                <div *ngIf="invoice.status==='Declined'">{{ 'billing.status.declined' | translate }}</div>
              </div>
              <mat-icon class="billing-status-icon"
                *ngIf="invoice.paymentTechStatus==='Payment Drafted' || invoice.paymentTechStatus==='Payment Being Drafted'"
                ngbTooltip="{{ 'billing.status.tooltips.drafted' | translate }}"
                tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="invoice.paymentTechStatus==='Payment Reversed'"
                ngbTooltip="{{ 'billing.status.tooltips.reversed' | translate }}"
                tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="invoice.payTo"
                ngbTooltip="{{ 'billing.status.tooltips.refunded' | translate }}"
                tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Created' || invoice.status==='Requested'"
                ngbTooltip="{{ 'billing.status.tooltips.pending' | translate }}"
                tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Billed'"
                ngbTooltip="{{ 'billing.status.tooltips.billed' | translate }}"
                tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Planned'"
                ngbTooltip="{{ 'billing.status.tooltips.planned' | translate }}"
                tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Due' && invoice.dueAmount>0"
                ngbTooltip="{{ 'billing.status.tooltips.due' | translate }}"
                tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon"
                *ngIf="invoice.status==='paid' || invoice.status==='Due' && invoice.dueAmount==0"
                ngbTooltip="{{ 'billing.status.tooltips.paid' | translate }}"
                tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Closed'"
                ngbTooltip="{{ 'billing.status.tooltips.processed' | translate }}"
                tooltipClass="ngb-tooltip-class">info</mat-icon>
            </div>
          </div>
          <div class="item-row">
            <div class="item-text left">{{ 'billing.summary.bill' | translate }}:</div>
            <div class="right" *ngIf="invoice.invoiceBillDate && isUsaUser">{{ invoice.invoiceBillDate | date: 'MM/dd/yy' }}</div>
            <div class="right" *ngIf="invoice.invoiceBillDate && (isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser)">
              {{ invoice.invoiceBillDate | date: 'dd/MM/yy' }}
            </div>
          </div>
          <div class="item-row">
            <div class="item-text left">{{ 'billing.summary.dueDate' | translate }}:</div>
            <div class="right" *ngIf="invoice.dueDate && isUsaUser">{{ invoice.dueDate | date: 'MM/dd/yy' }}</div>
            <div class="right" *ngIf="invoice.dueDate && (isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser)">
              {{ invoice.dueDate | date: 'dd/MM/yy' }}
            </div>
          </div>
          <div class="item-row">
            <div class="item-text left">{{ 'billing.summary.amount' | translate }}:</div>
            <div class="right" *ngIf="invoice.amount && invoice.amount>=0 && (invoice.amountCur=='USD')">{{ invoice.amount | currency:'USD' }}</div>
            <div class="right" *ngIf="invoice.amount && invoice.amount>=0 && (invoice.amountCur=='CAD')">{{ invoice.amount | currency:'CAD' + ' ':'code' }}</div>
            <div class="right" *ngIf="invoice.amount && invoice.amount>=0 && (invoice.amountCur=='GBP')">{{ invoice.amount | currency:'GBP' }}</div>
            <div class="right" *ngIf="invoice.amount && invoice.amount>=0 && (invoice.amountCur=='EUR')">{{ invoice.amount | currency:'EUR' }}</div>
            <div class="right" *ngIf="invoice.amount && invoice.amount<0 && (invoice.amountCur=='USD')">-{{ invoice.amount * -1 | currency:'USD' }}</div>
            <div class="right" *ngIf="invoice.amount && invoice.amount<0 && (invoice.amountCur=='CAD')">-{{ invoice.amount * -1 | currency:'CAD':'code' }}</div>
            <div class="right" *ngIf="invoice.amount && invoice.amount<0 && (invoice.amountCur=='GBP')">-{{ invoice.amount * -1 | currency:'GBP' }}</div>
            <div class="right" *ngIf="invoice.amount && invoice.amount<0 && (invoice.amountCur=='EUR')">-{{ invoice.amount * -1 | currency:'EUR' }}</div>
          </div>
          <div class="item-row">
            <div class="item-text left">{{ 'billing.summary.payable' | translate }}:</div>
            <div class="right" *ngIf="invoice.dueAmount && (invoice.dueAmountCur=='USD')">{{ invoice.dueAmount | currency:'USD' }}</div>
            <div class="right" *ngIf="invoice.dueAmount && (invoice.dueAmountCur=='CAD')">{{ invoice.dueAmount | currency:'CAD' + ' ':'code' }}</div>
            <div class="right" *ngIf="invoice.dueAmount && (invoice.dueAmountCur=='GBP')">{{ invoice.dueAmount | currency:'GBP' }}</div>
            <div class="right" *ngIf="invoice.dueAmount && (invoice.dueAmountCur=='EUR')">{{ invoice.dueAmount | currency:'EUR' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="notification-box">
      <upsc-notification-box [isWarning]="true" *ngIf="invoiceListNoPlanned.length == 0 && !loading">
        <div class="box-format">
          <mat-icon>warning</mat-icon>
          <div class="text">{{ 'billing.summary.notFound' | translate }}</div>
        </div>
      </upsc-notification-box>
    </div>
  </div>
  <div class="desktop">
    <div class="table-summary">
      <div class="overflow-format">
        <table class="upsc-table-isop">
          <thead>
            <tr>
              <th scope="col">{{ 'billing.summary.number' | translate }}</th>
              <th scope="col">{{ 'billing.summary.bill' | translate }}</th>
              <th scope="col">{{ 'billing.summary.dueDate' | translate }}</th>
              <th scope="col">{{ 'billing.summary.amount' | translate }}</th>
              <th scope="col">{{ 'billing.summary.payable' | translate }}</th>
              <th scope="col">{{ 'billing.summary.status' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let invoice of invoiceListNoPlanned.slice(0,5)">
              <td class="green" *ngIf="invoice.invoiceNumber">
                <span class="no" >{{ invoice.invoiceNumber }}</span>
              </td>
              <td *ngIf="!invoice.invoiceNumber">-</td>
              <td *ngIf="invoice.invoiceBillDate && isUsaUser">{{ invoice.invoiceBillDate | date: 'MM/dd/yy' }}</td>
              <td
                *ngIf="invoice.invoiceBillDate && (isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser)">
                {{ invoice.invoiceBillDate | date: 'dd/MM/yy' }}</td>
              <td *ngIf="!invoice.invoiceBillDate">-</td>
              <td *ngIf="invoice.dueDate && isUsaUser">{{ invoice.dueDate | date: 'MM/dd/yy' }}</td>
              <td *ngIf="invoice.dueDate && (isUkUser || isGermanyUser || isCanadaUser || isFranceUser || isItalyUser)">{{ invoice.dueDate | date: 'dd/MM/yy' }}</td>
              <td *ngIf="!invoice.dueDate">-</td>
              <td *ngIf="invoice.amount && invoice.amount<0 && (invoice.amountCur=='USD')">-{{ invoice.amount * -1 | currency:'USD' }}</td>
              <td *ngIf="invoice.amount && invoice.amount<0 && (invoice.amountCur=='CAD')">-{{ invoice.amount * -1 | currency:'CAD' + ' ':'code' }}</td>
              <td *ngIf="invoice.amount && invoice.amount<0 && (invoice.amountCur=='GBP')">-{{ invoice.amount * -1 | currency:'GBP' }}</td>
              <td *ngIf="invoice.amount && invoice.amount<0 && (invoice.amountCur=='EUR')">-{{ invoice.amount * -1 | currency:'EUR' }}</td>
              <td *ngIf="invoice.amount && invoice.amount>=0 && (invoice.amountCur=='USD')">{{ invoice.amount | currency:'USD' }}</td>
              <td *ngIf="invoice.amount && invoice.amount>=0 && (invoice.amountCur=='CAD')">{{ invoice.amount | currency:'CAD' + ' ':'code' }}</td>
              <td *ngIf="invoice.amount && invoice.amount>=0 && (invoice.amountCur=='GBP')">{{ invoice.amount | currency:'GBP' }}</td>
              <td *ngIf="invoice.amount && invoice.amount>=0 && (invoice.amountCur=='EUR')">{{ invoice.amount | currency:'EUR' }}</td>
              <td *ngIf="!invoice.amount">-</td>
              <td *ngIf="invoice.dueAmount && (invoice.dueAmountCur=='USD')">{{ invoice.dueAmount | currency:'USD' }}</td>
              <td *ngIf="invoice.dueAmount && (invoice.dueAmountCur=='CAD')">{{ invoice.dueAmount | currency:'CAD' + ' ':'code' }}</td>
              <td *ngIf="invoice.dueAmount && (invoice.dueAmountCur=='GBP')">{{ invoice.dueAmount | currency:'GBP' }}</td>
              <td *ngIf="invoice.dueAmount && (invoice.dueAmountCur=='EUR')">{{ invoice.dueAmount | currency:'EUR' }}</td>
              <td *ngIf="!invoice.dueAmount">-</td>
              <td>
                <div class="d-flex align-items-center">
                  <div class="status-box"
                    [ngClass]="{'blue': invoice.status=='Planned', 'orange': invoice.status=='Billed', 'blood-orange': invoice.status=='Due' && invoice.dueAmount>0, 'pink': invoice.paymentTechStatus=='Payment Drafted' || invoice.paymentTechStatus=='Payment Being Drafted' || invoice.paymentTechStatus=='Payment Approved' || invoice.paymentTechStatus=='Payment Declined' || invoice.paymentTechStatus=='Payment Transaction Error' || invoice.paymentTechStatus=='Payment Reversed', 'turquoise': invoice.status=='paid' || invoice.paymentTechStatus=='Payment Received' || invoice.status=='Due' && invoice.dueAmount==0, 'green': invoice.payTo }">
                    <div *ngIf="invoice.paymentTechStatus">{{ invoice.paymentTechStatus }}</div>
                    <div *ngIf="invoice.status==='Planned'">{{ 'billing.status.upcoming' | translate }}</div>
                    <div
                      *ngIf="(invoice.status==='Billed') && (isUsaUser || isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser) && (invoice.paytweakLink ==null)">
                      {{ 'billing.status.billed' | translate }}</div>

                    <div
                      *ngIf="(invoice.status==='Billed') && (invoice.paytweakLink !==null) && (isUkUser || isGermanyUser || isFranceUser || isItalyUser)">
                      <a href="{{ invoice.paytweakLink }}" target="_blank">{{ 'billing.status.billed' | translate }}</a>
                    </div>

                    <div
                      *ngIf="(invoice.status==='Due') && (invoice.dueAmount>0) && (isUsaUser || isCanadaUser || isUkUser || isGermanyUser || isFranceUser || isItalyUser) && (invoice.paytweakLink==null)">
                      {{ 'billing.status.due' | translate }}</div>

                    <div
                      *ngIf="(invoice.status==='Due') && (invoice.dueAmount>0) && (invoice.paytweakLink !==null) && (isUkUser || isGermanyUser || isFranceUser || isItalyUser)">
                      <a href="{{ invoice.paytweakLink }}" target="_blank">{{ 'billing.status.due' | translate }} </a>
                    </div>

                    <div *ngIf="invoice.status==='paid' || invoice.status==='Due' && invoice.dueAmount==0">{{
                      'billing.status.paid' | translate }}</div>
                    <div *ngIf="invoice.payTo">{{ 'billing.status.refund' | translate }}</div>
                    <div *ngIf="invoice.status==='Created'">{{ 'billing.status.pending' | translate }}</div>
                    <div *ngIf="invoice.status==='Requested'">{{ 'billing.status.pending' | translate }}</div>
                    <div *ngIf="invoice.status==='Fixed'">{{ 'billing.status.processing' | translate }}</div>
                    <div *ngIf="invoice.status==='Drafted'">{{ 'billing.status.processing' | translate }}</div>
                    <div *ngIf="invoice.status==='Closed'">{{ 'billing.status.processed' | translate }}</div>
                  </div>
                  <mat-icon class="billing-status-icon"
                    *ngIf="invoice.paymentTechStatus==='Payment Drafted' || invoice.paymentTechStatus==='Payment Being Drafted'"
                    ngbTooltip="{{ 'billing.status.tooltips.drafted' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="invoice.paymentTechStatus==='Payment Reversed'"
                    ngbTooltip="{{ 'billing.status.tooltips.reversed' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="invoice.payTo"
                    ngbTooltip="{{ 'billing.status.tooltips.refunded' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Created' || invoice.status==='Requested'"
                    ngbTooltip="{{ 'billing.status.tooltips.pending' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Billed'"
                    ngbTooltip="{{ 'billing.status.tooltips.billed' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Planned'"
                    ngbTooltip="{{ 'billing.status.tooltips.planned' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Due' && invoice.dueAmount>0"
                    ngbTooltip="{{ 'billing.status.tooltips.due' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon"
                    *ngIf="invoice.status==='paid' || invoice.status==='Due' && invoice.dueAmount==0"
                    ngbTooltip="{{ 'billing.status.tooltips.paid' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Closed'"
                    ngbTooltip="{{ 'billing.status.tooltips.processed' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                </div>
              </td>
            </tr>
            <tr *ngIf="invoiceListNoPlanned.length == 0 && !loading">
              <td colspan="6" class="NF">{{ 'billing.summary.notFound' | translate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="upcoming-invoices" *ngIf="upcomingInvoices">
  <div class="mobile">
    <div class="mobile-section">
      <div class="mobile-table" *ngFor="let invoice of invoiceListPlannedOnly">
        <div class="invoice-item">
          <div class="item-header">
            <div class="invoice-number mr-auto">
                <span class="roboto-medium d-flex">{{invoice.invoiceNumber}}</span>
            </div>
            <div class="bubble d-flex align-items-center">
              <div class="status-box"
                [ngClass]="{'blue': invoice.status=='Planned', 'orange': invoice.status=='Billed', 'blood-orange': invoice.status=='Due', 'pink': invoice.status=='Created' || invoice.status=='Requested' || invoice.status=='Fixed' || invoice.status=='Drafted' || invoice.status=='Closed', 'turquoise': invoice.status=='Paid', 'green': invoice.status=='Refunded'}">
                <div *ngIf="invoice.status==='Planned'">{{ 'billing.status.upcoming' | translate }}</div>
                <div *ngIf="invoice.status==='Billed'">{{ 'billing.status.billed' | translate }}</div>
                <div *ngIf="invoice.status==='Due'">{{ 'billing.status.due' | translate }}</div>
                <div *ngIf="invoice.status==='paid'">{{ 'billing.status.paid' | translate }}</div>
                <div *ngIf="invoice.status==='Refunded'">{{ 'billing.status.refund' | translate }}</div>
                <div *ngIf="invoice.status==='Created'">{{ 'billing.status.pending' | translate }}</div>
                <div *ngIf="invoice.status==='Requested'">{{ 'billing.status.pending' | translate }}</div>
                <div *ngIf="invoice.status==='Fixed'">{{ 'billing.status.processing' | translate }}</div>
                <div *ngIf="invoice.status==='Drafted'">{{ 'billing.status.processing' | translate }}</div>
                <div *ngIf="invoice.status==='Closed'">{{ 'billing.status.processed' | translate }}</div>
              </div>
              <mat-icon class="billing-status-icon"
              *ngIf="invoice.paymentTechStatus==='Payment Drafted' || invoice.paymentTechStatus==='Payment Being Drafted'"
              container="body" ngbTooltip="{{ 'billing.status.tooltips.drafted' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="invoice.paymentTechStatus==='Payment Reversed'"
                container="body" ngbTooltip="{{ 'billing.status.tooltips.reversed' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="invoice.payTo" container="body"
                ngbTooltip="{{ 'billing.status.tooltips.refunded' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Created' || invoice.status==='Requested'"
                container="body" ngbTooltip="{{ 'billing.status.tooltips.pending' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Billed'" container="body"
                ngbTooltip="{{ 'billing.status.tooltips.billed' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Planned'" container="body"
                ngbTooltip="{{ 'billing.status.tooltips.planned' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Due' && invoice.dueAmount>0"
                container="body" ngbTooltip="{{ 'billing.status.tooltips.due' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon"
                *ngIf="invoice.status==='paid' || invoice.status==='Due' && invoice.dueAmount==0" container="body"
                ngbTooltip="{{ 'billing.status.tooltips.paid' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Closed'" container="body"
                ngbTooltip="{{ 'billing.status.tooltips.processed' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
            </div>
          </div>
          <div class="item-row">
            <div class="item-text left">{{ 'billing.summary.bill' | translate }}:</div>
            <div class="right" *ngIf="isUsaUser">{{ invoice.invoiceBillDate | date: 'MM/dd/yy' }}</div>
            <div class="right" *ngIf="isUkUser || isCanadaUser || isGermanyUser || isFranceUser || isItalyUser">
              {{ invoice.invoiceBillDate | date: 'dd/MM/yy' }}
            </div>
          </div>
          <div class="item-row">
            <div class="item-text left">{{ 'billing.summary.expected' | translate }}:</div>
            <div class="right" *ngIf="invoice.dueAmountCur=='USD'">{{ invoice.dueAmount | currency:'USD' }}</div>
            <div class="right" *ngIf="invoice.dueAmountCur=='CAD'">{{ invoice.dueAmount | currency:'CAD' + ' ':'code' }}</div>
            <div class="right" *ngIf="invoice.dueAmountCur=='GBP'">{{ invoice.dueAmount | currency:'GBP' }}</div>
            <div class="right" *ngIf="invoice.dueAmountCur=='EUR'">{{ invoice.dueAmount | currency:'EUR' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="notification-box">
      <upsc-notification-box [isWarning]="true" *ngIf="invoiceListPlannedOnly.length == 0">
        <div class="box-format">
          <mat-icon>warning</mat-icon>
          <div class="text">{{ 'billing.summary.notFound' | translate }}</div>
        </div>
      </upsc-notification-box>
    </div>
  </div>
  <div class="desktop">
    <div class="table-summary">
      <div class="overflow-format">
        <table class="upsc-table-isop">
          <thead>
            <tr>
              <th scope="col">{{ 'billing.summary.number' | translate }}</th>
              <th scope="col">{{ 'billing.summary.bill' | translate }}</th>
              <th scope="col">{{ 'billing.summary.expected' | translate }}</th>
              <th scope="col">{{ 'billing.summary.status' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="invoiceListPlannedOnly.length<=0">
              <td colspan="4" class="NF">{{ 'billing.summary.none' | translate }}</td>
            </tr>
            <tr *ngFor="let invoice of invoiceListPlannedOnly">
              <td>{{ invoice.invoiceNumber }}</td>
              <td *ngIf="isUsaUser">{{ invoice.invoiceBillDate | date:'MM/dd/yy' }}</td>
              <td *ngIf="isUkUser || isCanadaUser || isGermanyUser || isFranceUser || isItalyUser">{{ invoice.invoiceBillDate | date:'dd/MM/yy' }}</td>
              <td *ngIf="invoice.dueAmountCur=='USD'">{{ invoice.dueAmount | currency:'USD' }}</td>
              <td *ngIf="invoice.dueAmountCur=='CAD'">{{ invoice.dueAmount | currency:'CAD' + ' ':'code' }}</td>
              <td *ngIf="invoice.dueAmountCur=='GBP'">{{ invoice.dueAmount | currency:'GBP' }}</td>
              <td *ngIf="invoice.dueAmountCur=='EUR'">{{ invoice.dueAmount | currency:'EUR' }}</td>
              <td>
                <div class="d-flex align-items-center">
                  <div class="status-box"
                    [ngClass]="{'blue': invoice.status=='Planned', 'orange': invoice.status=='Billed', 'blood-orange': invoice.status=='Due', 'pink': invoice.status=='Created' || invoice.status=='Requested' || invoice.status=='Fixed' || invoice.status=='Drafted' || invoice.status=='Closed', 'turquoise': invoice.status=='paid', 'green': invoice.status=='Refunded'}">
                    <div *ngIf="invoice.status==='Planned'">{{ 'billing.status.upcoming' | translate }}</div>
                    <div *ngIf="invoice.status==='Billed'">{{ 'billing.status.billed' | translate }}</div>
                    <div *ngIf="invoice.status==='Due'">{{ 'billing.status.due' | translate }}</div>
                    <div *ngIf="invoice.status==='paid'">{{ 'billing.status.paid' | translate }}</div>
                    <div *ngIf="invoice.status==='Refunded'">{{ 'billing.status.refund' | translate }}</div>
                    <div *ngIf="invoice.status==='Created'">{{ 'billing.status.pending' | translate }}</div>
                    <div *ngIf="invoice.status==='Requested'">{{ 'billing.status.pending' | translate }}</div>
                    <div *ngIf="invoice.status==='Fixed'">{{ 'billing.status.processing' | translate }}</div>
                    <div *ngIf="invoice.status==='Drafted'">{{ 'billing.status.processing' | translate }}</div>
                    <div *ngIf="invoice.status==='Closed'">{{ 'billing.status.processed' | translate }}</div>
                  </div>
                  <mat-icon class="billing-status-icon"
                    *ngIf="invoice.paymentTechStatus==='Payment Drafted' || invoice.paymentTechStatus==='Payment Being Drafted'"
                    ngbTooltip="{{ 'billing.status.tooltips.drafted' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="invoice.paymentTechStatus==='Payment Reversed'"
                    ngbTooltip="{{ 'billing.status.tooltips.reversed' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="invoice.payTo"
                    ngbTooltip="{{ 'billing.status.tooltips.refunded' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Created' || invoice.status==='Requested'"
                    ngbTooltip="{{ 'billing.status.tooltips.pending' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Billed'"
                    ngbTooltip="{{ 'billing.status.tooltips.billed' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Planned'"
                    ngbTooltip="{{ 'billing.status.tooltips.planned' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Due' && invoice.dueAmount>0"
                    ngbTooltip="{{ 'billing.status.tooltips.due' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon"
                    *ngIf="invoice.status==='paid' || invoice.status==='Due' && invoice.dueAmount==0"
                    ngbTooltip="{{ 'billing.status.tooltips.paid' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="invoice.status==='Closed'"
                    ngbTooltip="{{ 'billing.status.tooltips.processed' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<div class="payment-history" *ngIf="paymentHistory">
  <div class="mobile">
    <div class="mobile-header">
      <div class="title">{{ 'billing.navigation.history' | translate }}</div>
      <div *ngIf="isUsaUser && invoiceListNoPlanned.length != 0" class="ml-auto">
        <button class="upsc-white-button" (click)="openExport()" [disabled]="selected.length == 0">
          Download Selected Invoices<mat-icon class="icon-format">save_alt</mat-icon>
        </button>
      </div>
    </div>
    <div class="mobile-select-all" *ngIf="isUsaUser && invoiceListNoPlanned.length != 0">
      <div>
        <input type="checkbox" class="upsc-green-background-checkbox size-22"
          (change)="onChangeAll($event.target.checked)" [checked]="checkedAll()">
      </div>
      <div class="text">Select All</div>
    </div>
    <div class="mobile-section">
      <div class="mobile-table" *ngFor="let payment of invoiceListNoPlanned">
        <div class="invoice-item">
          <div class="item-header">
            <div class="invoice-number mr-auto">
                <span class="roboto-medium d-flex">
                  <input type="checkbox" class="upsc-green-background-checkbox size-22 mr-2" (change)="onChange($event.target.checked, payment)"
                  [checked]="checked(payment)">
                  {{ payment.invoiceNumber }}
                </span>
            </div>
            <div class="bubble d-flex align-items-center">
              <div class="status-box"
                [ngClass]="{'blue': payment.status=='Planned', 'orange': payment.status=='Billed', 'blood-orange': payment.status=='Due', 'pink': payment.paymentTechStatus=='Payment Drafted' || payment.paymentTechStatus=='Payment Being Drafted' || payment.paymentTechStatus=='Payment Approved' || payment.paymentTechStatus=='Payment Declined' || payment.paymentTechStatus=='Payment Transaction Error' || payment.paymentTechStatus=='Payment Reversed' || payment.status=='Reversed', 'turquoise': payment.status=='paid' || payment.paymentTechStatus=='Payment Received', 'green': payment.payTo, 'black': payment.paymentMethod=='Experience Refund'}">
                <div *ngIf="payment.paymentTechStatus">{{ payment.paymentTechStatus }}</div>
                <div *ngIf="payment.status==='Planned'">{{ 'billing.status.upcoming' | translate }}</div>
                <div *ngIf="payment.status==='Billed'">{{ 'billing.status.billed' | translate }}</div>
                <div *ngIf="payment.status==='Due'">{{ 'billing.status.due' | translate }}</div>
                <div *ngIf="payment.status==='paid'">{{ 'billing.status.paid' | translate }}</div>
                <div *ngIf="payment.payTo">{{ 'billing.status.refund' | translate }}</div>
                <div *ngIf="payment.status==='Created'">{{ 'billing.status.pending' | translate }}</div>
                <div *ngIf="payment.status==='Requested'">{{ 'billing.status.pending' | translate }}</div>
                <div *ngIf="payment.status==='Fixed'">{{ 'billing.status.processing' | translate }}</div>
                <div *ngIf="payment.status==='Drafted'">{{ 'billing.status.processing' | translate }}</div>
                <div *ngIf="payment.status==='Closed'">{{ 'billing.status.processed' | translate }}</div>
                <div *ngIf="payment.status==='Reversed'">{{ 'billing.status.reversed' | translate }}</div>
                <div *ngIf="payment.paymentMethod==='Experience Refund'">-</div>
              </div>
              <mat-icon class="billing-status-icon"
                *ngIf="payment.paymentTechStatus==='Payment Drafted' || payment.paymentTechStatus==='Payment Being Drafted'"
                container="body" ngbTooltip="{{ 'billing.status.tooltips.drafted' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon"
                *ngIf="payment.paymentTechStatus==='Payment Reversed' || payment.status==='Reversed'" container="body"
                ngbTooltip="{{ 'billing.status.tooltips.reversed' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="payment.payTo" container="body"
                ngbTooltip="{{ 'billing.status.tooltips.refunded' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="payment.status==='Created' || payment.status==='Requested'"
                container="body" ngbTooltip="{{ 'billing.status.tooltips.pending' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="payment.status==='Billed'" container="body"
                ngbTooltip="{{ 'billing.status.tooltips.billed' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="payment.status==='Planned'" container="body"
                ngbTooltip="{{ 'billing.status.tooltips.planned' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="payment.status==='Due' && payment.dueAmount>0"
                container="body" ngbTooltip="{{ 'billing.status.tooltips.due' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon"
                *ngIf="payment.status==='paid' || payment.status==='Due' && payment.dueAmount==0" container="body"
                ngbTooltip="{{ 'billing.status.tooltips.paid' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
              <mat-icon class="billing-status-icon" *ngIf="payment.status==='Closed'" container="body"
                ngbTooltip="{{ 'billing.status.tooltips.processed' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
            </div>
          </div>
          <div class="item-row">
            <div class="item-text left">{{ 'billing.summary.date' | translate }}:</div>
            <div class="right" *ngIf="payment.date && isUsaUser">{{ payment.date | date: 'MM/dd/yy' }}</div>
            <div class="right" *ngIf="!payment.date && payment.paidDate && isUsaUser">{{ payment.paidDate | date: 'MM/dd/yy' }}</div>
            <div class="right" *ngIf="payment.date && (isUkUser || isCanadaUser || isGermanyUser || isFranceUser || isItalyUser)">{{ payment.date | date: 'dd/MM/yy' }}</div>
            <div class="right" *ngIf="!payment.date && payment.paidDate && (isUkUser || isCanadaUser || isGermanyUser || isFranceUser || isItalyUser)">{{ payment.paidDate | date: 'dd/MM/yy' }}</div>
            <div class="right" *ngIf="payment.draftDate && isUsaUser">{{ payment.draftDate | date: 'MM/dd/yy' }}</div>
            <div class="right" *ngIf="payment.draftDate && (isUkUser || isCanadaUser || isGermanyUser || isFranceUser || isItalyUser)">{{ payment.draftDate | date: 'dd/MM/yy' }}</div>
          </div>
          <div class="item-row">
            <div class="item-text left">{{ 'billing.summary.amt' | translate }}:</div>
            <div class="right"
              *ngIf="payment.amount && !payment.distributedAmount && (payment.amountCurrency=='USD' || payment.amountCur=='USD')">
              {{ payment.amount | currency:'USD' }}</div>
            <div class="right"
              *ngIf="payment.amount && !payment.distributedAmount && (payment.amountCurrency=='CAD' || payment.amountCur=='CAD')">
              {{ payment.amount | currency:'CAD' + ' ':'code' }}</div>
            <div class="right"
              *ngIf="payment.amount && !payment.distributedAmount && (payment.amountCurrency=='GBP' || payment.amountCur=='GBP')">
              {{ payment.amount | currency:'GBP' }}</div>
            <div class="right"
              *ngIf="payment.amount && !payment.distributedAmount && (payment.amountCurrency=='EUR' || payment.amountCur=='EUR')">
              {{ payment.amount | currency:'EUR' }}</div>
            <div class="right"
              *ngIf="payment.distributedAmount && !payment.amount && (payment.distributedAmountCur=='USD')">{{
              payment.distributedAmount | currency:'USD' }}</div>
            <div class="right"
              *ngIf="payment.distributedAmount && !payment.amount && (payment.distributedAmountCur=='CAD')">{{
              payment.distributedAmount | currency:'CAD' + ' ':'code' }}</div>
            <div class="right"
              *ngIf="payment.distributedAmount && !payment.amount && (payment.distributedAmountCur=='GBP')">{{
              payment.distributedAmount | currency:'GBP' }}</div>
            <div class="right"
              *ngIf="payment.distributedAmount && !payment.amount && (payment.distributedAmountCur=='EUR')">{{
              payment.distributedAmount | currency:'EUR' }}</div>
            <div class="right"
              *ngIf="payment.amount && payment.distributedAmount && (payment.amountCurrency=='USD' || payment.amountCur=='USD')">
              {{ payment.amount | currency:'USD' }}</div>
            <div class="right"
              *ngIf="payment.amount && payment.distributedAmount && (payment.amountCurrency=='CAD' || payment.amountCur=='CAD')">
              {{ payment.amount | currency:'CAD' + ' ':'code' }}</div>
            <div class="right"
              *ngIf="payment.amount && payment.distributedAmount && (payment.amountCurrency=='GBP' || payment.amountCur=='GBP')">
              {{ payment.amount | currency:'GBP' }}</div>
            <div class="right"
              *ngIf="payment.amount && payment.distributedAmount && (payment.amountCurrency=='EUR' || payment.amountCur=='EUR')">
              {{ payment.amount | currency:'EUR' }}</div>
          </div>
          <div class="item-row">
            <div class="item-text left">{{ 'billing.summary.method' | translate }}:</div>
            <div class="right" *ngIf="payment.paymentMethod && payment.paymentMethod != 'UPS Wallet'">{{ payment.paymentMethod }}</div>
            <div class="right" *ngIf="payment.paymentMethod && payment.paymentMethod == 'UPS Wallet'">
              <a class="digital-wallet-link mobile" *ngIf="canAccessDigitalWallet" (click)="goToWallet()"><span>UPS<sup>®</sup> Wallet</span><span><mat-icon>launch</mat-icon></span></a>
              <div *ngIf="!canAccessDigitalWallet">UPS<sup>®</sup> Wallet</div>
            </div>
          </div>
          <div class="item-row">
            <div class="item-text left">{{ 'billing.summary.check' | translate }}:</div>
            <div class="right" *ngIf="payment.refNum">{{ payment.refNum }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="notification-box">
      <upsc-notification-box [isWarning]="true" *ngIf="invoiceListNoPlanned.length == 0">
        <div class="box-format">
          <mat-icon>warning</mat-icon>
          <div class="text">{{ 'billing.summary.notFound' | translate }}</div>
        </div>
      </upsc-notification-box>
    </div>
  </div>
  <div class="desktop">
    <div class="payment-title">
      <div class="invoices-title">{{ 'billing.navigation.history' | translate }}</div>
      <button class="upsc-white-button" *ngIf="isUsaUser && invoiceListNoPlanned.length != 0" (click)="openExport()" [disabled]="selected.length == 0">
        Download Selected Invoices<mat-icon class="icon-format">save_alt</mat-icon>
      </button>
    </div>
    <div class="table-summary">
      <div class="overflow-format">
        <table class="upsc-table-isop">
          <thead>
            <tr>
              <th *ngIf="isUsaUser && invoiceListNoPlanned.length != 0" class="download-column">
                <input type="checkbox" class="upsc-green-background-checkbox size-22"
                (change)="onChangeAll($event.target.checked)" [checked]="checkedAll()">
              </th>
              <th scope="col" *ngIf="isUsaUser || isCanadaUser">{{ 'billing.summary.invoice' | translate }}</th>
              <th scope="col">{{ 'billing.summary.date' | translate }}</th>
              <th scope="col">{{ 'billing.summary.amt' | translate }}</th>
              <th scope="col">{{ 'billing.summary.method' | translate }}</th>
              <th scope="col">{{ 'billing.summary.check' | translate }}</th>
              <th scope="col">{{ 'billing.summary.status' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let payment of invoiceListNoPlanned">
              <td *ngIf="isUsaUser && invoiceListNoPlanned.length != 0" class="download-column">
                <input type="checkbox" class="upsc-green-background-checkbox size-22" (change)="onChange($event.target.checked, payment)" [checked]="checked(payment)">
              </td>
              <td *ngIf="isUsaUser || isCanadaUser">{{ payment.invoiceNumber }}</td>
              <td *ngIf="payment.date && isUsaUser">{{ payment.date | date: 'MM/dd/yy' }}</td>
              <td *ngIf="!payment.date && payment.paidDate && isUsaUser">{{ payment.paidDate | date: 'MM/dd/yy' }}</td>
              <td *ngIf="payment.date && (isUkUser || isCanadaUser || isGermanyUser || isFranceUser || isItalyUser)">{{
                payment.date | date: 'dd/MM/yy' }}</td>
              <td
                *ngIf="!payment.date && payment.paidDate && (isUkUser || isCanadaUser || isGermanyUser || isFranceUser || isItalyUser)">
                {{ payment.paidDate | date: 'dd/MM/yy' }}</td>
              <td *ngIf="payment.draftDate && isUsaUser">{{ payment.draftDate | date: 'MM/dd/yy' }}</td>
              <td *ngIf="payment.draftDate && (isUkUser || isCanadaUser || isGermanyUser || isFranceUser || isItalyUser)">{{
                payment.draftDate | date: 'dd/MM/yy' }}</td>
              <td *ngIf="!payment.date && !payment.draftDate && !payment.paidDate">-</td>
              <td
                *ngIf="payment.amount && !payment.distributedAmount && (payment.amountCurrency=='USD' || payment.amountCur=='USD')">
                {{ payment.amount | currency:'USD' }}</td>
              <td
                *ngIf="payment.amount && !payment.distributedAmount && (payment.amountCurrency=='CAD' || payment.amountCur=='CAD')">
                {{ payment.amount | currency:'CAD' + ' ':'code' }}</td>
              <td
                *ngIf="payment.amount && !payment.distributedAmount && (payment.amountCurrency=='GBP' || payment.amountCur=='GBP')">
                {{ payment.amount | currency:'GBP' }}</td>
              <td
                *ngIf="payment.amount && !payment.distributedAmount && (payment.amountCurrency=='EUR' || payment.amountCur=='EUR')">
                {{ payment.amount | currency:'EUR' }}</td>
              <td *ngIf="payment.distributedAmount && !payment.amount  && (payment.distributedAmountCur=='USD')">{{ payment.distributedAmount | currency:'USD' }}</td>
              <td *ngIf="payment.distributedAmount && !payment.amount  && (payment.distributedAmountCur=='CAD')">{{ payment.distributedAmount | currency:'CAD' + ' ':'code' }}</td>
              <td *ngIf="payment.distributedAmount && !payment.amount  && (payment.distributedAmountCur=='GBP')">{{ payment.distributedAmount | currency:'GBP' }}</td>
              <td *ngIf="payment.distributedAmount && !payment.amount && (payment.distributedAmountCur=='EUR')">{{ payment.distributedAmount | currency:'EUR' }}</td>
              <td
                *ngIf="payment.amount && payment.distributedAmount && (payment.amountCurrency=='USD' || payment.amountCur=='USD')">
                {{ payment.amount | currency:'USD' }}</td>
              <td
                *ngIf="payment.amount && payment.distributedAmount && (payment.amountCurrency=='CAD' || payment.amountCur=='CAD')">
                {{ payment.amount | currency:'CAD' + ' ':'code' }}</td>
              <td
                *ngIf="payment.amount && payment.distributedAmount && (payment.amountCurrency=='GBP' || payment.amountCur=='GBP')">
                {{ payment.amount | currency:'GBP' }}</td>
              <td
                *ngIf="payment.amount && payment.distributedAmount && (payment.amountCurrency=='EUR' || payment.amountCur=='EUR')">
                {{ payment.amount | currency:'EUR' }}</td>
              <td *ngIf="(payment.paymentMethod) && (payment.paymentMethod != 'UPS Wallet')">{{ payment.paymentMethod }}</td>
              <td *ngIf="(payment.paymentMethod) && (payment.paymentMethod == 'UPS Wallet')">
                <a class="digital-wallet-link" *ngIf="canAccessDigitalWallet" (click)="goToWallet()"><span>UPS<sup>®</sup> Wallet</span><span><mat-icon>launch</mat-icon></span></a>
                <div *ngIf="!canAccessDigitalWallet">UPS<sup>®</sup> Wallet</div>
              </td>
              <td *ngIf="(!payment.paymentMethod)">-</td>
              <td *ngIf="(payment.refNum)">{{ payment.refNum }}</td>
              <td *ngIf="(!payment.refNum)">-</td>
              <td>
                <div class="d-flex align-items-center">
                  <div class="status-box"
                    [ngClass]="{'blue': payment.status=='Planned', 'orange': payment.status=='Billed', 'blood-orange': payment.status=='Due', 'pink': payment.paymentTechStatus=='Payment Drafted' || payment.paymentTechStatus=='Payment Being Drafted' || payment.paymentTechStatus=='Payment Approved' || payment.paymentTechStatus=='Payment Declined' || payment.paymentTechStatus=='Payment Transaction Error' || payment.paymentTechStatus=='Payment Reversed' || payment.status=='Reversed', 'turquoise': payment.status=='paid' || payment.paymentTechStatus=='Payment Received', 'green': payment.payTo, 'black': payment.paymentMethod=='Experience Refund'}">
                    <div *ngIf="payment.paymentTechStatus">{{ payment.paymentTechStatus }}</div>
                    <div *ngIf="payment.status==='Planned'">{{ 'billing.status.upcoming' | translate }}</div>
                    <div *ngIf="payment.status==='Billed'">{{ 'billing.status.billed' | translate }}</div>
                    <div *ngIf="payment.status==='Due'">{{ 'billing.status.due' | translate }}</div>
                    <div *ngIf="payment.status==='paid'">{{ 'billing.status.paid' | translate }}</div>
                    <div *ngIf="payment.payTo">{{ 'billing.status.refund' | translate }}</div>
                    <div *ngIf="payment.status==='Created'">{{ 'billing.status.pending' | translate }}</div>
                    <div *ngIf="payment.status==='Requested'">{{ 'billing.status.pending' | translate }}</div>
                    <div *ngIf="payment.status==='Fixed'">{{ 'billing.status.processing' | translate }}</div>
                    <div *ngIf="payment.status==='Drafted'">{{ 'billing.status.processing' | translate }}</div>
                    <div *ngIf="payment.status==='Closed'">{{ 'billing.status.processed' | translate }}</div>
                    <div *ngIf="payment.status==='Reversed'">{{ 'billing.status.reversed' | translate }}</div>
                    <div *ngIf="payment.paymentMethod==='Experience Refund'">-</div>
                  </div>
                  <mat-icon class="billing-status-icon"
                    *ngIf="payment.paymentTechStatus==='Payment Drafted' || payment.paymentTechStatus==='Payment Being Drafted'"
                    ngbTooltip="{{ 'billing.status.tooltips.drafted' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon"
                    *ngIf="payment.paymentTechStatus==='Payment Reversed' || payment.status==='Reversed'"
                    ngbTooltip="{{ 'billing.status.tooltips.reversed' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="payment.payTo"
                    ngbTooltip="{{ 'billing.status.tooltips.refunded' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="payment.status==='Created' || payment.status==='Requested'"
                    ngbTooltip="{{ 'billing.status.tooltips.pending' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="payment.status==='Billed'"
                    ngbTooltip="{{ 'billing.status.tooltips.billed' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="payment.status==='Planned'"
                    ngbTooltip="{{ 'billing.status.tooltips.planned' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="payment.status==='Due' && payment.dueAmount>0"
                    ngbTooltip="{{ 'billing.status.tooltips.due' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon"
                    *ngIf="payment.status==='paid' || payment.status==='Due' && payment.dueAmount==0"
                    ngbTooltip="{{ 'billing.status.tooltips.paid' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                  <mat-icon class="billing-status-icon" *ngIf="payment.status==='Closed'"
                    ngbTooltip="{{ 'billing.status.tooltips.processed' | translate }}" tooltipClass="ngb-tooltip-class">info</mat-icon>
                </div>
              </td>
            </tr>
            <tr *ngIf="invoiceListNoPlanned.length == 0">
              <td colspan="7" class="NF">{{ 'billing.summary.notFound' | translate }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="error" *ngIf="walletError">{{ 'common.systemError' | translate }}</div>
</div>
