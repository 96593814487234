
export const        PHONE_PATTERN = /^(\+0?1\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const CANADAPHONE_PATTERN = /^(\+0?1\s)?\(?\d{3}\)?[\s--]?\d{3}[\s--]?\d{4}$/;
export const EMAIL_PATTERN = /^(?!\.)[\w\-.]*[\w\-]@(?!-)[a-zA-Z0-9\-]*[a-zA-Z0-9](\.(?!-)[a-zA-Z0-9\-]*[a-zA-Z0-9])*\.[a-zA-Z0-9]{2,}$/;
export const ADDRESS_1_PATTERN = /^(?!\s)[a-zA-Z0-9\s#.,-/]{1,50}$/;
export const ADDRESS_2_PATTERN = /^(?!\s)[a-zA-Z0-9\s#.,-/]{0,50}$/;
export const CITY_PATTERN = /^(?!\s)[a-zA-Z0-9\s.,-/]{1,30}$/;
export const ZIP_PATTERN = /^\d{5}(-{1}\d{4})?$/;
export const TUPSS_FLOW_ZIP_PATTERN = /(^\d{5}(?:-{1}\d{4})?$)|(^\d{5}(\-)$)/;

export const POLICY_PATTERN = /^[a-zA-Z0-9][a-zA-Z0-9\-.]{4,}[a-zA-Z0-9]$/;

export const PAYEE_NAME_PATTERN = /^(?!\s)[\w\s\-+&()/,`#.:;*]{1,30}$/;
export const PAYEE_ACCOUNT_PATTERN = /^(?!\s)[a-zA-Z0-9#.-]*$/;
export const ALPHA_NUMERIC_AND_SPACES_PATTERN = /^[a-zA-Z0-9 ]*$/;