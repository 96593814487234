<div class="upsc-custom-update">
  <form [formGroup]="ChangePasswordForm">

    <upsc-notification-box class="success-box mb-4" *ngIf="resetSucceeded" [isSuccessInfo]="true">
      <div class="icon"><mat-icon>check_circle_outline</mat-icon></div>
      <div class="text">
        {{ 'update.success' | translate }}
      </div>
    </upsc-notification-box>

    <upsc-notification-box class="error-box mb-4" *ngIf="showErrorMessages || passwordNotMatch || worngPassword"
      [isDangerInfo]="true">
      <div class="icon"><mat-icon>cancel_outline</mat-icon></div>
      <div class="errors">
        <div
          *ngIf="ChangePasswordForm.get('currentPassword').errors?.required  && ChangePasswordForm.controls.currentPassword.touched">
          &nbsp;&nbsp;{{ 'update.currentpasswordRequired' | translate }}
        </div>
        <div
          *ngIf="ChangePasswordForm.get('currentPassword').errors?.pattern  && ChangePasswordForm.controls.currentPassword.touched">
          &nbsp;&nbsp;{{ 'update.invalid' | translate }}
        </div>
        <div *ngIf="worngPassword">
          &nbsp;&nbsp;{{ 'update.errorReturned' | translate }}
        </div>
        <div
          *ngIf="ChangePasswordForm.get('newPassword').errors?.required && ChangePasswordForm.controls.newPassword.touched">
          &nbsp;&nbsp;{{ 'update.newpasswordRequired' | translate }}
        </div>
        <div
          *ngIf="ChangePasswordForm.get('newPassword').errors?.pattern && ChangePasswordForm.controls.newPassword.touched">
          &nbsp;&nbsp;{{ 'update.invalid' | translate }}
        </div>
        <div
          *ngIf="ChangePasswordForm.get('confirmPassword').errors?.required && ChangePasswordForm.controls.confirmPassword.touched">
          &nbsp;&nbsp;{{ 'update.confirmpasswordRequired' | translate }}
        </div>
        <div
          *ngIf="ChangePasswordForm.get('confirmPassword').errors?.pattern && ChangePasswordForm.controls.confirmPassword.touched">
          &nbsp;&nbsp;{{ 'update.invalid' | translate }}
        </div>
        <div *ngIf="passwordNotMatch && ChangePasswordForm.controls.confirmPassword.touched">
          &nbsp;&nbsp;{{ 'update.passwordsmustmatch' | translate }}
        </div>
      </div>
    </upsc-notification-box>

    <div class="main">
      <div class="content">

       

        <!--  <div class="controls">
      <label class="userIDLabel"> {{ 'update.userid' | translate }}: </label>
      <span class="userid" type="text">{{userid}}</span>
     
    </div> -->
        <div class="field-containers">
          <div class="field-section">
            <div class="title"> {{ 'resetPassword.title' | translate }}</div>
            <div class="password-wrapper" [ngClass]="isCanadaUser ? 'addBorder' : ''">
              <mat-form-field class="custom-width">
                <mat-label>User Id</mat-label>
                <input formControlName="userId" readonly type="text" matInput>
              </mat-form-field>
              <mat-form-field class="custom-width">
                <input matInput formControlName="currentPassword"
                  placeholder="{{ 'update.currentpassword' | translate }}" [type]="hide ? 'password' : 'text'" required>
                <mat-icon matSuffix class="icon" (click)="hide = !hide">{{hide ? 'visibility_off' :
                  'visibility'}}</mat-icon>
              </mat-form-field>
              <mat-error *ngIf="ChangePasswordForm.get('currentPassword').errors || worngPassword">
                <div class="error"
                  *ngIf="ChangePasswordForm.controls.currentPassword.hasError('required') && ChangePasswordForm.controls.currentPassword.touched">
                  *&nbsp;{{ 'update.currentpasswordRequired' | translate }}
                </div>
                <div class="error"
                  *ngIf="ChangePasswordForm.controls.currentPassword.hasError('pattern') && ChangePasswordForm.controls.currentPassword.touched">
                  {{ 'update.invalid' | translate }}
                </div>
                <div class="error" *ngIf="worngPassword">
                  {{ 'update.errorReturned' | translate }}
                </div>
              </mat-error>
            </div>
            <ng-template #resetPasswordControl>
              <strong>{{ 'resetPassword.form.tooltip.title' | translate }}</strong>
              <div>{{ 'resetPassword.form.tooltip.bullet1' | translate }}</div>
              <div>{{ 'resetPassword.form.tooltip.bullet2' | translate }}</div>
              <div>{{ 'resetPassword.form.tooltip.bullet3' | translate }}</div>
              <div>{{ 'resetPassword.form.tooltip.bullet4' | translate }}</div>
              <div>{{ 'resetPassword.form.tooltip.bullet5' | translate }}</div>
              <div>{{ 'resetPassword.form.tooltip.bullet6' | translate }}</div>
              <div>{{ 'resetPassword.form.tooltip.bullet7' | translate }}</div>
            </ng-template>
            <div class="password-wrapper" [ngClass]="isCanadaUser ? 'addBorder' : ''">
              <mat-form-field class="custom-width" [ngbTooltip]="resetPasswordControl" tooltipClass="ngb-tooltip-class">
                <input matInput formControlName="newPassword" placeholder="{{ 'update.newpassword' | translate }}"
                  [type]="hide2 ? 'password' : 'text'" required>
                <mat-icon matSuffix class="icon" (click)="hide2 = !hide2">{{hide2 ? 'visibility_off' :
                  'visibility'}}</mat-icon>
              </mat-form-field>
              <mat-error *ngIf="ChangePasswordForm.get('newPassword').errors">
                <div class="error"
                  *ngIf="ChangePasswordForm.get('newPassword').errors['required'] && ChangePasswordForm.controls['newPassword'].touched">
                  *&nbsp;{{ 'update.newpasswordRequired' | translate }}
                </div>
                <div class="error"
                  *ngIf="ChangePasswordForm.get('newPassword').errors['pattern'] && ChangePasswordForm.controls['newPassword'].touched">
                  {{ 'update.invalid' | translate }}
                </div>
              </mat-error>
            </div>
            <div class="password-wrapper" [ngClass]="isCanadaUser ? 'addBorder' : ''">
              <mat-form-field class="custom-width">
                <input matInput formControlName="confirmPassword"
                  placeholder="{{ 'update.confirmpassword' | translate }}" [type]="hide3 ? 'password' : 'text'"
                  required>
                <mat-icon matSuffix class="icon" (click)="hide3 = !hide3">{{hide3 ? 'visibility_off' :
                  'visibility'}}</mat-icon>
              </mat-form-field>
              <mat-error *ngIf="ChangePasswordForm.get('confirmPassword').errors || passwordNotMatch">
                <div class="error"
                  *ngIf="ChangePasswordForm.get('confirmPassword').errors?.required && ChangePasswordForm.controls['confirmPassword'].touched">
                  *&nbsp;{{ 'update.confirmpasswordRequired' | translate }}
                </div>
                <div class="error"
                  *ngIf="ChangePasswordForm.get('confirmPassword').errors?.pattern && ChangePasswordForm.controls['confirmPassword'].touched">
                  {{ 'update.invalid' | translate }}
                </div>
                <div class="error" *ngIf="passwordNotMatch && ChangePasswordForm.controls['confirmPassword'].touched">
                  {{ 'update.passwordsmustmatch' | translate }}
                </div>
              </mat-error>
            </div>
          </div>
          <div class="tip-section">
            <div class="title">{{ 'resetPassword.form.tooltip.title' | translate }}</div>
            <div>{{ 'resetPassword.form.tooltip.bullet1' | translate }}</div>
            <div>{{ 'resetPassword.form.tooltip.bullet2' | translate }}</div>
            <div *ngIf="!isCanadaUser">{{ 'resetPassword.form.tooltip.bullet3' | translate }}</div>
            <div>{{ 'resetPassword.form.tooltip.bullet4' | translate }}</div>
            <div>{{ 'resetPassword.form.tooltip.bullet5' | translate }}</div>
            <div>{{ 'resetPassword.form.tooltip.bullet6' | translate }}</div>
            <div>{{ 'resetPassword.form.tooltip.bullet7' | translate }}</div>
          </div>
        </div>
        <!--  <div class="button-align d-flex align-items-center">
      <button class="upsc-button" type="submit" (click)="ChangePassword();">
        <div class="button-content-wrapper"><div class="text-only">{{ 'update.update' | translate }}</div></div>
      </button>
      <a (click)="cancel();" class="cancel">{{ 'update.cancel' | translate }}</a>
    </div> -->

        <div class="btn-containers">
          <button class="upsc-white-button" (click)="cancel()">
            <mat-icon>chevron_left</mat-icon> {{ 'update.cancel' | translate }}
          </button>

          <button class="upsc-button" type="submit" (click)="ChangePassword();">
            {{ 'update.update' | translate }}<mat-icon>chevron_right</mat-icon>
          </button>
        </div>

      </div>
    </div>
  </form>
</div>