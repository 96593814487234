import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ExportService } from '../../../../services/export.service';
import { ISummary } from '../../../../shared/models/claim.interface';
import { ClaimService } from '../../../../services/claim.service';
import { LoginService } from 'src/app/services/login.service';
import { IPolicies } from '../../../../shared/models/policies.interface';
import { DatePipe, TitleCasePipe, DecimalPipe } from '@angular/common';
import { PhoneNumberPipe } from 'src/app/shared/pipes/phone-number.pipe';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';
import { Subscription } from 'rxjs';

const FILENAME = "claim_submission_summary";

@Component({
  selector: 'upsc-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent {

  excelSelected = true;
  pdfSelected = false;
  policy: IPolicies = {};
  summaryRequest: ISummary = {};
  claimSummaryJson: any[] = [];
  loading = false;
  exportSubscription: Subscription;

  constructor(
    public dialogRef:
     MatDialogRef<ExportComponent>,
   private exportService: ExportService,
    private claimService: ClaimService,
    private loginService: LoginService,
    private datePipe: DatePipe,
    private phonePipe: PhoneNumberPipe,
    private titleCasePipe: TitleCasePipe,
    private decimalPipe: DecimalPipe,
    private userService: UserService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) private tableObj //Holds the table properties, will determine what data columns to show
  ) { }

  closeDialog() {
    if (this.exportSubscription) {
      this.exportSubscription.unsubscribe();
    }
    this.dialogRef.close();
  }

  //Called when download button is pressed. 
  //Executes download of selected document type
  download() {
    //First, display the loading page.
    this.loading = true;
    //Load the data by calling API.
    let filter = JSON.parse(localStorage.getItem('params')); //Based on the search criteria it will downloading the data and if there is no search inputs? Downloading default data.
    this.claimService.getAllClaimIds(filter).subscribe(
      data => {
        let allClaimIds = data.claimIds;
        //We want to limit the claim exporting to the most recent 5000 claims
        if (allClaimIds.length > 1000) {
          allClaimIds = allClaimIds.slice(0, 999);
        }

        this.getClaims(allClaimIds);
      },
      error => { }
    );
  }

  getClaims(allClaimIds: string[]) {
    let claimIds = [];
    this.policy = this.loginService.getPolicyDetails();
    if (allClaimIds.length > 100) {
      claimIds = allClaimIds.splice(0, 100);
    }
    else if (allClaimIds.length > 0) {
      claimIds = allClaimIds.splice(0, allClaimIds.length)
    }

    if (claimIds.length > 0) {
      this.exportSubscription = this.claimService.getClaimByIds(this.policy.policyNumber, claimIds).subscribe(
        data => {
          let arraydata = data.items
          for (var i = 0; i < arraydata.length; i++) {
            try {
              if (arraydata[i]['claimStatus']['closedOutCome'] === 'mistake' || arraydata[i]['claimStatus']['closedOutCome'] === 'duplicate') {
                //Don't do anything with the specific closed claims
              } else {
                let sliceTrack = arraydata[i]['shippingDetails']['trackingNumber'].slice(0, 2);
                var accountNum;
                if (sliceTrack.toUpperCase() == '1Z') {
                  accountNum = arraydata[i]['shippingDetails']['trackingNumber'].slice(2, 8).toUpperCase();
                } else { accountNum = null; }
                console.log("download data PN:",this.policy.policyNumber);
                //Due to dynamic table layouts, the data will be displayed differently in the exported files,
                //But for the sake of easiness, we don't care about that now, and load all data that may be used.
                this.claimSummaryJson.push({
                  'PolicyNumber': this.policy.policyNumber,
                  'ShipperName': arraydata[i]['shipperName'],
                  'ShipperNumber': arraydata[i]['shipperNumber'],
                  'ClaimNumber': arraydata[i]['claimNumber'],
                  'ShipDate': arraydata[i]['shippingDetails']['shipDate'] ? this.datePipe.transform(new Date(arraydata[i]['shippingDetails']['shipDate']), this.userService.isUsaUser() ? 'MM/dd/yy' : 'dd/MM/yy', 'UTC') : "",
                  'CheckAchNumber': arraydata[i]['checkNumber'].length == 0 ? null : arraydata[i]['checkNumber'][arraydata[i]['checkNumber'].length - 1],
                  'CheckAchIssueDate': arraydata[i]['checkIssueDate'].length == 0 ? null : this.datePipe.transform(arraydata[i]['checkIssueDate'][arraydata[i]['checkIssueDate'].length - 1], this.userService.isUsaUser() ? 'MM/dd/yy' : 'dd/MM/yy', 'UTC'),
                  'FileDate': arraydata[i]['fileDate'] ? this.datePipe.transform(new Date(arraydata[i]['fileDate']), this.userService.isUsaUser() ? 'MM/dd/yy' : 'dd/MM/yy', 'UTC') : "",
                  'FiledClaimAmount': arraydata[i]['claimAmount_Ext'] ? arraydata[i]['claimAmount_Ext'].slice(0, -3) + " " + arraydata[i]['claimAmount_Ext'].split(" ").pop().toUpperCase() : '',
                  'FiledClaimAmountCurrency': arraydata[i]['claimAmount_Ext'] ? arraydata[i]['claimAmount_Ext'].split(" ").pop().toUpperCase() : '',
                  'TrackingNumberBOL': arraydata[i]['shippingDetails']['trackingNumber'].toUpperCase(),
                  'Shipper': arraydata[i]['shippingDetails']['senderDetails'] ? arraydata[i]['shippingDetails']['senderDetails']['companyName'] : "",
                  'Recipient': arraydata[i]['shippingDetails']['receiverDetails'] ? arraydata[i]['shippingDetails']['receiverDetails']['companyName'] : "",
                  'Reference': arraydata[i]['shippingDetails']['referenceNumber'],
                  'ReasonforClaim': arraydata[i]['lossCause'] == 'loss_Ext' ? this.translateService.instant('claimsDetail.loss') : arraydata[i]['lossCause'] == 'damage_Ext' ? this.translateService.instant('claimsDetail.damage') : arraydata[i]['lossCause'] == 'fallout_Ext' ? this.translateService.instant('claimsDetail.missingContents') : arraydata[i]['lossCause'] == 'delay' ? this.translateService.instant('claimsDetail.lateDelivery') : arraydata[i]['lossCause'] == 'abandonment' ? this.translateService.instant('claimsDetail.abandonment') : '',
                  'ClaimStatus': arraydata[i]['customerDisplayStatus'] == 'Claim Received' ? this.translateService.instant('metadata.claimStatusMapping.claim_received') :
                                 arraydata[i]['customerDisplayStatus'] && arraydata[i]['customerDisplayStatus'].includes('Closed') ? this.translateService.instant('metadata.claimStatusMapping.incomplete_documentation_Ext') :
                                 arraydata[i]['customerDisplayStatus'] == 'Closed' ? this.translateService.instant('metadata.claimStatusMapping.closed') :
                                 arraydata[i]['customerDisplayStatus'] == 'Paid' ? this.translateService.instant('metadata.claimStatusMapping.paid') :
                                 arraydata[i]['customerDisplayStatus'] == 'Withdrawn' ? this.translateService.instant('metadata.claimStatusMapping.withdrawn_Ext') :
                                 arraydata[i]['customerDisplayStatus'] == 'Submitted for Payment' ? this.translateService.instant('metadata.claimStatusMapping.submitted_for_payment') :
                                 arraydata[i]['customerDisplayStatus'] == 'Incomplete Submission' ? this.translateService.instant('metadata.claimStatusMapping.incomplete_submission') :
                                 arraydata[i]['customerDisplayStatus'] == 'Investigation Pending' ? this.translateService.instant('metadata.claimStatusMapping.investigation_Pending') :
                                 arraydata[i]['customerDisplayStatus'] == 'Denied' ? this.translateService.instant('metadata.claimStatusMapping.denied_Ext') :
                                 arraydata[i]['customerDisplayStatus'] == 'Documents Pending' ? this.translateService.instant('metadata.claimStatusMapping.incomplete_documentation_Ext') : this.titleCasePipe.transform(arraydata[i]['customerDisplayStatus']),
                  'PaymentAmount': (Number(arraydata[i]['totalPayments']['amount']) === 0) ? "0.00"+" " + arraydata[i]['totalPayments']['currency'].toUpperCase() : this.decimalPipe.transform(arraydata[i]['totalPayments']['amount'], "1.2-2") + " " + arraydata[i]['totalPayments']['currency'].toUpperCase(),
                  'PaymentAmountCurrency': arraydata[i]['totalPayments']['currency'] ? arraydata[i]['totalPayments']['currency'].toUpperCase() : '',
                  'UPSAccount': accountNum,
                  'Description': arraydata[i]['shippingDetails']['merchandiseDescription'],
                  'SenderCity': arraydata[i]['shippingDetails']['senderDetails'] ? arraydata[i]['shippingDetails']['senderDetails']['city'] : "",
                  'SenderState': arraydata[i]['shippingDetails']['senderDetails'] && arraydata[i]['shippingDetails']['senderDetails']['state'] == 'Not Applicable' ? this.translateService.instant('metadata.claimStatusMapping.not_applicable') : arraydata[i]['shippingDetails']['senderDetails'] ? arraydata[i]['shippingDetails']['senderDetails']['state'] : "",
                  'ReceiverCity': arraydata[i]['shippingDetails']['receiverDetails'] ? arraydata[i]['shippingDetails']['receiverDetails']['city'] : "",
                  'ReceiverState': arraydata[i]['shippingDetails']['receiverDetails'] && arraydata[i]['shippingDetails']['receiverDetails']['state'] == 'Not Applicable' ? this.translateService.instant('metadata.claimStatusMapping.not_applicable') : arraydata[i]['shippingDetails']['receiverDetails'] ? arraydata[i]['shippingDetails']['receiverDetails']['state'] : "",
                  'Contact': arraydata[i]['reporter']['displayName'] ? arraydata[i]['reporter']['displayName'] : "",
                  'ContactEmail': arraydata[i]['reporter']['emailAddress1'] ? arraydata[i]['reporter']['emailAddress1'] : "",
                  'ContactPhone': arraydata[i]['reporter']['workNumber'] ? this.phonePipe.transform(arraydata[i]['reporter']['workNumber']) : "",
                  'Adjuster': arraydata[i]['adjuster'] ? arraydata[i]['adjuster']['displayName'] : "",
                  'AdjusterEmail': arraydata[i]['adjuster'] ? arraydata[i]['adjuster']['email'] : "",
                  'AdjusterPhone': arraydata[i]['adjuster'] ? this.phonePipe.transform(arraydata[i]['adjuster']['phoneNumber']) : "",
                  'DeclaredValue': arraydata[i]['declaredValue'] ? arraydata[i]['declaredValue'] : "",
                  'LossDate': arraydata[i]['lossDate'] ? arraydata[i]['lossDate'] : "",
                  'Payee': arraydata[i]['payeeId'] ? arraydata[i]['payeeId'] : "",
                  'SubmissionNumber': arraydata[i]['submissionNumber'] ? arraydata[i]['submissionNumber'] : "",
                  "ClaimSource": arraydata[i]['claimSource'] ? (arraydata[i]['claimSource'].toLowerCase().includes('tfcp') ? 'Franchise' : (arraydata[i]['claimSource'].toLowerCase().includes('tccp') ? 'Customer' : "")) : ""
                });
              }
            } catch (e) {
              //claim object was null or undefined
            }
          }
          this.getClaims(allClaimIds);
          //once data has been loaded into claimSummaryJson, we can call the export functions
        },
        err => {}
      );
    } else {
      if (this.excelSelected) {
        this.exportService.exportToExcel(this.claimSummaryJson, FILENAME, this.tableObj);
        this.loading = false;
        this.dialogRef.close();
      } else if (this.pdfSelected) {
        this.exportService.exportToPDF(this.claimSummaryJson, FILENAME, this.tableObj);
        this.loading = false;
        this.dialogRef.close();
      } else {
        //If an error happened, the loading screen will stay open
      }
    }
  }

}
