//This service is for handling all claim related data.
//Track 3. iShip. My choice. Regular claims.
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CssThemesService } from 'src/app/services/css-themes.service';
import { IClaim, emptyClaim } from 'src/app/shared/models/claim.interface';
import { environment } from '../../environments/environment';
import { ApiEndpoints } from 'src/config/api-endpoints';
import { IChannelPartnerShipmentDetails, IShippingDetailsAutofill, emptyChannelPartnerShipmentDetails, emptyShippingDetails, emptyShippingDetailsWithErrorResponse, hardCodedChannelPartnerShipmentDetails, hardCodedTrack3ShipmentDetails } from 'src/app/shared/models/shippingDetails.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClaimService } from 'src/app/services/claim.service';
import { UserService } from 'src/app/services/user.service';
import { TupssService } from '../pages/claims-custom/tupss/services/tupss.service';
import { IShipInterface } from '../pages/claims-custom/tupss/interfaces/claim-data.interface';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  flowType: 'tupss' | 'my-choice' | 'customized-claims-portal' | 'ajg' | 'inxpress' | 'upshealthcare' | 'freightcenterinc';
  currentUrl;
  commonClaimsInterface$ = new BehaviorSubject<IClaim>(null);
  _commonClaimsInterface: IClaim;
  shipmentData;
  track3ShipmentDetails$ = new BehaviorSubject<IShippingDetailsAutofill>(null);
  _track3ShipmentDetails: IShippingDetailsAutofill;
  channelPartnerShipmentDetails$ = new BehaviorSubject<IChannelPartnerShipmentDetails>(null);
  _channelPartnerShipmentDetails: IChannelPartnerShipmentDetails;
  iShipDetails$ = new BehaviorSubject<IShipInterface>(null);
  _iShipDetails: IShipInterface;

  constructor(
    private router: Router,
    private themeService: CssThemesService,
    private http: HttpClient,
    private claimService: ClaimService,
    private userService: UserService,
    private tupssService: TupssService
  ) {
    this.setCommonClaimsInterface(JSON.parse(sessionStorage.getItem('claimModel')));
    this.setTrack3ShipmentDetails(JSON.parse(sessionStorage.getItem('track3')));
    this.setChannelPartnerShipmentDetails(JSON.parse(sessionStorage.getItem('channelPartner')));
  }

  //Set service variable & BehaviorSubject
  setTrack3ShipmentDetails(shipmentDetails: IShippingDetailsAutofill) {
    this._track3ShipmentDetails = shipmentDetails;
    this.track3ShipmentDetails$.next(shipmentDetails);
    sessionStorage.setItem('track3', JSON.stringify(shipmentDetails));
  }

  deleteTrack3ShipmentDetails() {
    this.setTrack3ShipmentDetails(null);
  }

  //Get BehaviorSubject
  getTrack3ShipmentDetails() {
    return this.track3ShipmentDetails$;
  }

  setChannelPartnerShipmentDetails(shipmentDetails: IChannelPartnerShipmentDetails) {
    this._channelPartnerShipmentDetails = shipmentDetails;
    this.channelPartnerShipmentDetails$.next(shipmentDetails);
    sessionStorage.setItem('channelPartner', JSON.stringify(shipmentDetails));
  }

  getChannelPartnerShipmentDetails() {
    return this.channelPartnerShipmentDetails$;
  }

  deleteChannelPartnerShipmentDetails() {
    this.setChannelPartnerShipmentDetails(null);
  }

  //Set service variable & BehaviorSubject
  setCommonClaimsInterface(claimData: IClaim) {
    this._commonClaimsInterface = claimData;
    this.commonClaimsInterface$.next(claimData);
    sessionStorage.setItem('claimModel', JSON.stringify(claimData));
  }

  //Get BehaviorSubject
  getCommonClaimsInterface() {
    return this.commonClaimsInterface$;
  }

  //Set service variable & BehaviorSubject
  setIShipDetails(iShipData: IShipInterface) {
    this._iShipDetails = iShipData;
    this.iShipDetails$.next(iShipData);
  }

  //Get BehaviorSubject
  getIShipDetails() {
    return this.iShipDetails$;
  }

  // check if current flow is for channel partner portals
  checkForCcp() {
    let returnValue = false;
    if (this.currentUrl.includes('unishippers') || this.currentUrl.includes('ajg') || this.currentUrl.includes('inxpress') || this.currentUrl.includes('upshealthcare') || this.currentUrl.includes('freightcenterinc')) {
      returnValue = true;
    } else {
      returnValue = false;
    }
    return returnValue;
  }

  //get call back url for ccp DPAT redirection
  getCallBackURL(urlBase) {
    let callBackURL = '';
    if (this.currentUrl.includes('my-choice')) {
      callBackURL = `${urlBase}ups-my-choice/detail`;
    } else if (this.currentUrl.includes('customized-claims-portal')) {
      callBackURL = `${urlBase}unishippers/detail`;
    } else if (this.currentUrl.includes('ajg')) {
      callBackURL = `${urlBase}ajg/detail`;
    } else if (this.currentUrl.includes('inxpress')) {
      callBackURL = `${urlBase}inxpress/detail`;
    } else if (this.currentUrl.includes('upshealthcare')) {
      callBackURL = `${urlBase}upshealthcare/detail`;
    } else if (this.currentUrl.includes('freightcenterinc')) {
      callBackURL = `${urlBase}freightcenterinc/detail`;
    }
    else {
      callBackURL = `${urlBase}tupss/detail`;
    }
    return callBackURL;
  }

  //get flow type for dpat redirection
  getTupssFlow() {
    let tupssFlow = '';
    if (this.currentUrl.includes('my-choice')) {
      tupssFlow = 'mychoice';
    } else if (this.currentUrl.includes('customized-claims-portal')) {
      tupssFlow = 'unishippers';
    } else if (this.currentUrl.includes('ajg')) {
      tupssFlow = 'ajg';
    } else if (this.currentUrl.includes('inxpress')) {
      tupssFlow = 'inxpress';
    } else if (this.currentUrl.includes('upshealthcare')) {
      tupssFlow = 'upshealthcare';
    } else if (this.currentUrl.includes('freightcenterinc')) {
      tupssFlow = 'freightcenterinc';
    }
    else {
      tupssFlow = 'tccp';
    }
    return tupssFlow;
  }

  determineWhichClaimFlow(): string {
    this.currentUrl = this.router.url;
    if (this.currentUrl.includes('my-choice')) {
      this.flowType = 'my-choice';
      this.userService.updateUserInfo({ type: 'my-choice' });
      this.themeService.loadMyChoiceStyle();
    } else if (this.currentUrl.includes('unishippers')) {
      this.flowType = 'customized-claims-portal';
      this.userService.updateUserInfo({ type: 'customized-claims-portal' });
      this.themeService.loadCustomizedClaimsStyle(this.flowType);
    } else if (this.currentUrl.includes('ajg')) {
      this.flowType = 'ajg';
      this.userService.updateUserInfo({ type: 'customized-claims-portal' });
      this.themeService.loadCustomizedClaimsStyle(this.flowType);
    } else if (this.currentUrl.includes('inxpress')) {
      this.flowType = 'inxpress';
      this.userService.updateUserInfo({ type: 'customized-claims-portal' });
      this.themeService.loadCustomizedClaimsStyle(this.flowType);
    } else if (this.currentUrl.includes('upshealthcare')) {
      this.flowType = 'upshealthcare';
      this.userService.updateUserInfo({ type: 'customized-claims-portal' });
      this.themeService.loadCustomizedClaimsStyle(this.flowType);
    } else if (this.currentUrl.includes('freightcenterinc')) {
      this.flowType = 'freightcenterinc';
      this.userService.updateUserInfo({ type: 'customized-claims-portal' });
      this.themeService.loadCustomizedClaimsStyle(this.flowType);
    }
    else if (this.currentUrl.includes('guest')) {
      this.flowType = 'ajg';  //Guest is using AJG because both should have no style adjustments
      this.userService.updateUserInfo({ type: 'customized-claims-portal' });
      this.themeService.loadCustomizedClaimsStyle(this.flowType);
    } else {
      this.flowType = 'tupss';
      this.themeService.loadStyle();
    }
    return this.flowType;
  }

  //Input tracking number.
  //Responds by getting shipment details and storing in track3ShipmentDetails object inside this service.
  //This can be used by any claim that needs to have Track 3 shipment info.
  //Right now it is used in 1) Regular claims, 2) Tupss claims, 3) My choice claims
  gatherTrack3ShipmentDetails(trackingNumber: string) {
    if (trackingNumber.toLowerCase().indexOf('1z') != 0) {
      //Load an empty shipment details object. A non 1Z tracking number will never get any information from this API, so don't even bother calling it.
      this.setTrack3ShipmentDetails(emptyShippingDetails);
      return;
    }
    let endpoint = `${environment.baseUrl.api}${ApiEndpoints.getShipmentDetailsAutofill}${trackingNumber}`;
    if (this.flowType == 'my-choice' && environment.baseUrl.urlType != 'prod') {
      //Use this custom my-choice URL when in lower environments. Track 3 normally points to Prod in all env, My Choice uses regular lower env data when in lower environments.
      endpoint = `${environment.baseUrl.api}${ApiEndpoints.getShipmentDetailsAutofill}${trackingNumber}/my-choice`;
    }
    this.http.get<any>(endpoint).subscribe(
      response => {
        if (response) {
          //Record all of the track 3 shipment details. These will be used later to populate parts of the claim requests.
          let formattedTrack3ShipmentDetails = {
            shipperConsigneeAddress: response.shipperConsigneeAddress ? response.shipperConsigneeAddress : null,  //Array of addresses
            shipDate: response.shipDate ? this.createDateObjBasedOnString(response.shipDate) : '',
            shipperNumber: response.shipperNumber ? response.shipperNumber : '',
            companyName: response.companyName ? response.companyName : '',
            attentionName: response.attentionName ? response.attentionName : '',
            serviceCode: response.serviceCode ? response.serviceCode : null,
            referenceFieldsValue: response.value ? response.value : '',
            deliveryDetails: response.deliveryDetails ? response.deliveryDetails : null,
            declaredValue: response.declaredValue ? response.declaredValue : '',
            shipmentPackage: response.shipmentPackage ? response.shipmentPackage : null,
            shipmentType: response.shipmentType ? response.shipmentType : null
          };
          this.setTrack3ShipmentDetails(formattedTrack3ShipmentDetails);
        } else {
          //Load an empty shipment details object. No data was returned.
          this.setTrack3ShipmentDetails(emptyShippingDetailsWithErrorResponse);
          return;
        }
      }, error => {
        //Load an empty shipment details object. No data was returned OR the API failed.
        this.setTrack3ShipmentDetails(emptyShippingDetailsWithErrorResponse);
        return;
      }
    );
  }

  gatherChannelPartnerShipmentDetails(trackingNumber: string, channelPartnerName: string): Observable<any> {
    let endpoint = `${environment.baseUrl.api}${ApiEndpoints.DATABRICKS_SHIPMENT_DETAILS}`;
    let request = {
      trackingNum: trackingNumber,
      isInXpress: channelPartnerName == 'inxpress' ? true : false
    }
    return this.http.post<any>(endpoint, request).pipe(map(
      response => {
        if (channelPartnerName == 'inxpress' && response?.data?.liability == false) { //if liability = false for inxpress users (don't allow user to continue)
          return { error: "liability is false" };
        } else if (channelPartnerName == 'inxpress' && response?.code != 200) { //for inxpress users, if shipmentdetails fails, don't allow them to continue at ALL
          return { error: "inxpress system error" };
        }
        else if (channelPartnerName === 'upshealthcare' && response?.code === 200) { 
          if (Number(response.data.insuredValue) < 101) {
            return { error: "upshealthcare insured amount error" };
          } else {
            this.setChannelPartnerShipmentDetails(response.data);
            return;
          }
        } else {
          this.setChannelPartnerShipmentDetails(response.data);
          return;
        }
      },
      error => {
        if (channelPartnerName == 'inxpress') { //for inxpress users, if shipmentdetails fails, don't allow them to continue at ALL
          return { error: "inxpress system error" };
        } else {
          this.setChannelPartnerShipmentDetails(emptyChannelPartnerShipmentDetails);
          return { error: 'internal error' };
        }
      }
    ));
  }

  //Input track 3 details.
  //Responds by populating the claims interface with details from the shipping details. basically "Autofill" parts of the claim.
  //At this point it is the first time claim interface is being touched in the flow.
  //Start with an empty claim and fill in as needed.
  //Can be used by any claim that already called gatherTrack3ShipmentDetails().
  populateClaimInterfaceUsingTrack3Details(shipmentDetails: IShippingDetailsAutofill) {
    let shipperInfo = shipmentDetails.shipperConsigneeAddress.find(x => x.description?.toLowerCase() == 'shipper address');
    let consigneeInfo = shipmentDetails.shipperConsigneeAddress.find(x => x.description?.toLowerCase() == 'shipto address');
    if (this.flowType == 'tupss' || this.flowType == 'my-choice') {
      this.simpleSetup();
    }

    let claimData = {
      shippingDetailsDTO: {
        shipperNumber: shipmentDetails.shipperNumber ? shipmentDetails.shipperNumber : '',
        shipperCompanyName: shipmentDetails.companyName ? shipmentDetails.companyName : '',
        consigneeAttentionName: shipmentDetails.attentionName ? shipmentDetails.attentionName : '',
        serviceCode: shipmentDetails.serviceCode.code ? shipmentDetails.serviceCode.code : '',
        serviceDescription: shipmentDetails.serviceCode.description ? shipmentDetails.serviceCode.description : '',
        referenceFieldsValue: shipmentDetails.referenceFieldsValue ? shipmentDetails.referenceFieldsValue : '',
        deliveryDetailsCode: shipmentDetails.deliveryDetails.code ? shipmentDetails.deliveryDetails.code : '',
        deliveryDetailsDescription: shipmentDetails.deliveryDetails.description ? shipmentDetails.deliveryDetails.description : '',
        deliveryDetailsDate: shipmentDetails.deliveryDetails.date ? this.createDateObjBasedOnString(shipmentDetails.deliveryDetails.date) : '',
        deliveryDetailsTime: shipmentDetails.deliveryDetails.time ? `${shipmentDetails.deliveryDetails.time.substring(0, 2)}:${shipmentDetails.deliveryDetails.time.substring(2, 4)}:${shipmentDetails.deliveryDetails.time.substring(4, 6)}` : '',
        declaredValueCurrencyCode: shipmentDetails.declaredValue.currencyCode ? this.formatCurrencyFromTrack3(shipmentDetails.declaredValue.currencyCode) : '',
        declaredValueMonetaryValue: shipmentDetails.declaredValue.monetaryValue ? shipmentDetails.declaredValue.monetaryValue : '',
        currentStatusDescription: shipmentDetails.shipmentPackage.currentStatusDescription ? shipmentDetails.shipmentPackage.currentStatusDescription : '',
        serviceOptionsDescription: shipmentDetails.shipmentPackage.serviceOptionTypeDescription ? shipmentDetails.shipmentPackage.serviceOptionTypeDescription : '',
        modeOfTransportationCode: shipmentDetails.shipmentType.code ? shipmentDetails.shipmentType.code : '',
        modeOfTransportationDescription: shipmentDetails.shipmentType.description ? shipmentDetails.shipmentType.description : '',
        shipDate: shipmentDetails.shipDate ? shipmentDetails.shipDate : '',
        shipperAddressLine: shipperInfo?.addressLine ? shipperInfo.addressLine : '',
        shipperCity: shipperInfo?.city ? shipperInfo.city : '',
        shipperStateProvinceCode: shipperInfo?.stateProvinceCode ? shipperInfo.stateProvinceCode : '',
        shipperPostalCode: shipperInfo?.postalCode ? shipperInfo.postalCode : '',
        shipperCountryCode: shipperInfo?.countryCode ? shipperInfo.countryCode : '',
        shipperAddressCode: shipperInfo?.code ? shipperInfo.code : '',
        consigneeAddressCode: consigneeInfo?.code ? consigneeInfo.code : '',
        consigneeAddressLine: consigneeInfo?.addressLine ? consigneeInfo.addressLine : '',
        consigneeCity: consigneeInfo?.city ? consigneeInfo.city : '',
        consigneeStateProvinceCode: consigneeInfo?.stateProvinceCode ? consigneeInfo.stateProvinceCode : '',
        consigneePostalCode: consigneeInfo?.postalCode ? consigneeInfo.postalCode : '',
        consigneeCountryCode: consigneeInfo?.countryCode ? consigneeInfo.countryCode : '',
        senderDetails: {
          companyName: shipperInfo?.companyName ? shipperInfo.companyName : '',
          city: shipperInfo?.city ? shipperInfo.city : '',
          state: shipperInfo?.stateProvinceCode ? shipperInfo.stateProvinceCode : '',
          country: shipperInfo?.countryCode ? shipperInfo.countryCode : '',
          zipCode: shipperInfo?.postalCode ? shipperInfo.postalCode : '',
          address1: shipperInfo?.addressLine ? shipperInfo.addressLine : ''
        },
        receiverDetails: {
          companyName: consigneeInfo?.companyName ? consigneeInfo.companyName : '',
          city: consigneeInfo?.city ? consigneeInfo.city : '',
          state: consigneeInfo?.stateProvinceCode ? consigneeInfo.stateProvinceCode : '',
          country: consigneeInfo?.countryCode ? consigneeInfo.countryCode : '',
          zipCode: consigneeInfo?.postalCode ? consigneeInfo.postalCode : '',
          address1: consigneeInfo?.addressLine ? consigneeInfo.addressLine : ''
        }
      },
      deliveryDate: shipmentDetails.shipmentPackage.deliveryDate ? new Date(`${shipmentDetails.shipmentPackage.deliveryDate.substring(0, 4)}-${shipmentDetails.shipmentPackage.deliveryDate.substring(4, 6)}-${shipmentDetails.shipmentPackage.deliveryDate.substring(6, 8)}T00:00:00`).toISOString().replace('.000Z', 'Z') : '',
      deliveryScanDate: shipmentDetails.shipmentPackage.deliveryDate ? new Date(`${shipmentDetails.shipmentPackage.deliveryDate.substring(0, 4)}-${shipmentDetails.shipmentPackage.deliveryDate.substring(4, 6)}-${shipmentDetails.shipmentPackage.deliveryDate.substring(6, 8)}T00:00:00`).toISOString().replace('.000Z', 'Z') : '',
      packageStatus: shipmentDetails.shipmentPackage.currentStatusDescription ? shipmentDetails.shipmentPackage.currentStatusDescription.substring(0, 34) : '', //GW Max length on this field is 35 chars
      originScan: this.findOriginScan(shipmentDetails, "origin"),
      trackingNumber_Ext: shipmentDetails.shipmentPackage.shipmentIdentificationNumber ? shipmentDetails.shipmentPackage.shipmentIdentificationNumber : '',
      transportationCarrierId_Ext: this.flowType == 'my-choice' ? 'UPS' : '',
      lossDate: shipmentDetails.shipDate ? shipmentDetails.shipDate : ''
    }

    // console.log("should have track 3 data and initally entered claim data from the start form group")
    let claim = _.merge(this._commonClaimsInterface, claimData);
    if (this.flowType == 'my-choice') {
      claim.claimSource = 'My Choice Customer Portal UI';
    }
    this.setCommonClaimsInterface(claim);
  }

  populateClaimInterfaceUsingChannelPartnerInfo(shipmentDetails: IChannelPartnerShipmentDetails) {
    this.simpleSetup();

    let claimData = {
      shippingDetailsDTO: {
        shipperCompanyName: shipmentDetails.shipperName,
        shipDate: shipmentDetails.shipDate,
        shipperAddressLine: shipmentDetails.originAddress,
        shipperCity: shipmentDetails.originCity,
        shipperStateProvinceCode: shipmentDetails.originState,
        shipperPostalCode: shipmentDetails.originPostalCode,
        shipperCountryCode: shipmentDetails.originCountryCode,
        consigneeAddressLine1: shipmentDetails.destinationAddress,
        consigneeCity: shipmentDetails.destinationCity,
        consigneeStateProvinceCode: shipmentDetails.destinationState,
        consigneePostalCode: shipmentDetails.destinationPostalCode,
        consigneeCountryCode: shipmentDetails.destinationCountryCode,
        senderDetails: {
          companyName: shipmentDetails.shipperName,
          city: shipmentDetails.originCity,
          state: shipmentDetails.originState,
          country: shipmentDetails.originCountryCode,
          zipCode: shipmentDetails.originPostalCode,
          address1: shipmentDetails.originAddress
        },
        receiverDetails: {
          companyName: shipmentDetails.consigneeName,
          city: shipmentDetails.destinationCity,
          state: shipmentDetails.destinationState,
          country: shipmentDetails.destinationCountryCode,
          zipCode: shipmentDetails.destinationPostalCode,
          address1: shipmentDetails.destinationAddress
        },
        originScan: this._track3ShipmentDetails ? this.findOriginScan(this._track3ShipmentDetails, 'origin') : ''
      },
      originScan: this._track3ShipmentDetails ? this.findOriginScan(this._track3ShipmentDetails, 'origin') : '',
      trackingNumber_Ext: shipmentDetails.trackingNumber,
      transportationCarrierId_Ext: shipmentDetails.carrier,
      lossDate: shipmentDetails.shipDate ? shipmentDetails.shipDate : '',
      claimSource: ''
    }

    if (this.flowType == 'customized-claims-portal') {
      claimData.claimSource = 'CCP4CP';
    }

    if (this.flowType == 'ajg') {
      claimData.claimSource = 'CCP4CP';
    }

    if (this.flowType == 'inxpress') {
      claimData.claimSource = 'CCP4CP';
    }

    let claim = _.merge(this._commonClaimsInterface, claimData);
    this.setCommonClaimsInterface(claim);
  }

  //My choice and Tupss need the emptyclaim start. Otherwise they are missing policy object, maincontact object, etc.
  //Normal flow does not need this step.
  simpleSetup() {
    this.updateCommonClaimObject(emptyClaim);
  }

  //Input is something like: "20230801"
  //Output will be something like: Tue Aug 01 2023 00:00:00 GMT-0400 (Eastern Daylight Time)
  createDateObjBasedOnString(date: string) {
    let year = date.substring(0, 4);
    let month: number;
    if (date.substring(4, 5) == "0") {
      month = parseInt(date.substring(5, 6));
      month = month - 1;
    } else {
      month = parseInt(date.substring(4, 6));
      month = month - 1;
    }
    let day = date.substring(6, 8);
    return new Date(Number(year), Number(month), Number(day));
  }

  findOriginScan(track3Data?, reason?) {
    //sometimes people want to test with hard coded data and the below "if" block is to handle such cases

    // if(ENV.hardCodedValuesForLowerEnvironments && reason == "hardcode") {
    //   let trackNo = JSON.parse(sessionStorage.getItem('persistIShipData'));
    //   let shipment = ENV.hardCodedValuesForLowerEnvironments.find(s => s['Tracking Number'] == trackNo.data.response.cdsresponse.shipmentInfo.trackNo);

    //   if(shipment) {
    //     if (shipment['Delivery Date'] && shipment['Delivery Date'] != 'N/A' && shipment['Delivery Date'].includes('/')) {
    //       this.claim.deliveryDate = new Date(shipment['Delivery Date']).toISOString().replace('.000Z', 'Z');
    //     }

    //     if (shipment['Origin Scan Date'] && shipment['Origin Scan Date'] != 'N/A' && shipment['Origin Scan Date'].includes('/')) {
    //       this.claim.originScan = new Date(shipment['Origin Scan Date']).toISOString().replace('.000Z', 'Z');
    //     }

    //     if (shipment['Expected Delivery Date'] && shipment['Expected Delivery Date'] != 'N/A' && shipment['Expected Delivery Date'].includes('/')) {
    //       this.claim.expectedDelivery = new Date(shipment['Expected Delivery Date']).toISOString().replace('.000Z', 'Z');
    //       this.claim.scheduledDeliveryDate = new Date(shipment['Expected Delivery Date']).toISOString().replace('.000Z', 'Z');
    //     }

    //     let address1 = shipment['Address 1'];
    //     let address2 = shipment['Address 2'];
    //     let city = shipment['City'];
    //     let state = shipment['State'];
    //     let zip = shipment['Zip'];
    //     let declaredValue = shipment['Declared Value'];
    //     if (address1) { this.claim.shippingDetailsDTO.receiverDetails.address1 = address1; }
    //     if (address2) { this.claim.shippingDetailsDTO.receiverDetails.address2 = address2; }
    //     if (city) { this.claim.shippingDetailsDTO.receiverDetails.city = city; }
    //     if (state) { this.claim.shippingDetailsDTO.receiverDetails.state = state; }
    //     if (zip) { this.claim.shippingDetailsDTO.receiverDetails.zipCode = zip; }
    //     if (declaredValue) {
    //       this.claim.shippingDetailsDTO.insuredValue = declaredValue;
    //       this.claim.tupssDeclaredValue = declaredValue;
    //     }

    //     console.log("Hardcoded Origin Scan: " + this.claim.originScan); //for UAT debugging purposes to make sure the hardcode worked
    //     console.log("Hardcoded Delivery Date: " + this.claim.deliveryDate);
    //     console.log("Hardcoded Expected/Scheduled Delivery Date: " + this.claim.expectedDelivery);
    //     console.log("Hardcoded Address: (see object below, address1 address2 city state zipCode)");
    //     console.log(this.claim.shippingDetailsDTO.receiverDetails);
    //     console.log("Hardcoded Declared Value: " + this.claim.tupssDeclaredValue);
    //     return;
    //   }
    // }

    if (track3Data && track3Data.shipmentPackage && track3Data.shipmentPackage.activity && reason == "origin") {
      if (track3Data.shipmentPackage.activity.length > 0) {
        let originScanActivityObject = track3Data.shipmentPackage.activity.find(x => x.status.description.toLowerCase() == 'arrived at facility');
        if (originScanActivityObject && originScanActivityObject.date) {
          return new Date(`${originScanActivityObject.date.substring(0, 4)}-${originScanActivityObject.date.substring(4, 6)}-${originScanActivityObject.date.substring(6, 8)}T00:00:00`).toISOString().replace('.000Z', 'Z');
        }
        else {
          return '';
        }
      } else { return ''; }
    } else { return ''; }
  }

  //Input will be iShip data that is stored in the claimService
  //Output will be claims model that has iship data filled in
  populateClaimInterfaceUsingIShipData() {
    this.claimService.getLocaliShipData().subscribe(
      tupssInfo => {
        console.log(tupssInfo);
        this.setIShipDetails(tupssInfo.data.response);
        sessionStorage.setItem('persistIShipData', JSON.stringify(tupssInfo));

        let updatedClaim = {
          trackingNumber_Ext: this.userService.getUserInfo().trackingNumber,
          transportationCarrierId_Ext: tupssInfo.data.response.cdsresponse.shipmentInfo.carrierId == 1 ? 'UPS' : tupssInfo.data.response.cdsresponse.shipmentInfo.carrierId == 4 ? 'USPS' : '',
          lossDate: tupssInfo.data.response.cdsresponse.shipmentInfo.shipDate,
          shippingDetailsDTO: {
            ...this._commonClaimsInterface.shippingDetailsDTO,
            shipmentIdentificationNumber: tupssInfo.data.response.cdsresponse.shipmentInfo.trackNo,
            shipperPostalCode: tupssInfo.data.response.cdsresponse.shipmentInfo.fromAddress.postal,
            consigneePostalCode: tupssInfo.data.response.cdsresponse.shipmentInfo.toAddress.postal,
            shipDate: tupssInfo.data.response.cdsresponse.shipmentInfo.shipDate,
            referenceNumber: '',
            senderDetails: {
              companyName: tupssInfo.data.response.cdsresponse.shipmentInfo.fromAddress.company,
              city: tupssInfo.data.response.cdsresponse.shipmentInfo.fromAddress.city,
              state: tupssInfo.data.response.cdsresponse.shipmentInfo.fromAddress.stateProv,
              zipCode: tupssInfo.data.response.cdsresponse.shipmentInfo.fromAddress.postal,
              country: tupssInfo.data.response.cdsresponse.shipmentInfo.fromAddress.country,
              address1: tupssInfo.data.response.cdsresponse.shipmentInfo.fromAddress.address1,
              address2: tupssInfo.data.response.cdsresponse.shipmentInfo.fromAddress.address2
            },
            receiverDetails: {
              companyName: tupssInfo.data.response.cdsresponse.shipmentInfo.toAddress.company,
              city: tupssInfo.data.response.cdsresponse.shipmentInfo.toAddress.city,
              state: tupssInfo.data.response.cdsresponse.shipmentInfo.toAddress.stateProv,
              zipCode: tupssInfo.data.response.cdsresponse.shipmentInfo.toAddress.postal,
              country: tupssInfo.data.response.cdsresponse.shipmentInfo.toAddress.country,
              address1: tupssInfo.data.response.cdsresponse.shipmentInfo.toAddress.address1,
              address2: tupssInfo.data.response.cdsresponse.shipmentInfo.toAddress.address2
            },
            insuredValue: tupssInfo.data.response.cdsresponse.shipmentInfo.insuredValue_Total
          },
          specialityProgram: this.getSpecialtyProgram(tupssInfo),
          retailShipping: tupssInfo.data.response.cdsresponse.shipmentInfo.packageInfo[0].chargeShipRetail,
          retailPackaging: tupssInfo.data.response.cdsresponse.shipmentInfo.totalPkgs == '1' ? this.calculateChargeOfOnePackage(tupssInfo) : this.calculateTotalCharge(tupssInfo) == 'NaN' ? '0' : this.calculateTotalCharge(tupssInfo),
          tupssDeclaredValue: tupssInfo.data.response.cdsresponse.shipmentInfo.insuredValue_Total,
          scheduledDeliveryDate: tupssInfo.data.response.cdsresponse.shipmentInfo.expectedDelivery,
          shipmentType: tupssInfo.data.response.cdsresponse.shipmentInfo.fromAddress.country == 'US' && tupssInfo.data.response.cdsresponse.shipmentInfo.toAddress.country == 'US' ? 'Domestic' : 'International',
          shipmentModeType: 'Outbound', //hardcoded
          // shipDate: tupssInfo.data.response.cdsresponse.shipmentInfo.shipDate,
          serviceID: tupssInfo.data.response.cdsresponse.shipmentInfo.serviceID,
          multiPieceSamePackaging: tupssInfo.data.response.cdsresponse.shipmentInfo.totalPkgs > 1 ? "true" : "false",
          transId: tupssInfo.data.response.transId,
          FMCPolicy: tupssInfo.data.response.cdsresponse.shipmentInfo.FMCPolicy,
          totalPkgs: tupssInfo.data.response.cdsresponse.shipmentInfo.totalPkgs,
          movement: tupssInfo.data.response.cdsresponse.shipmentInfo.movement,
          carrierID: tupssInfo.data.response.cdsresponse.shipmentInfo.carrierID,
          shipmentID: tupssInfo.data.response.cdsresponse.shipmentInfo.shipmentID,
          expectedDelivery: tupssInfo.data.response.cdsresponse.shipmentInfo.expectedDelivery,
          chargeShipNet_Total: tupssInfo.data.response.cdsresponse.shipmentInfo.chargeShipNet_Total,
          chargeShipRetail_Total: this.calculateChargeShip(tupssInfo),  //change
          chargeTransNet_Total: tupssInfo.data.response.cdsresponse.shipmentInfo.chargeTransNet_Total,
          chargeTransRetail_Total: tupssInfo.data.response.cdsresponse.shipmentInfo.chargeTransRetail_Total,
          chargeDVNet_Total: tupssInfo.data.response.cdsresponse.shipmentInfo.chargeDVNet_Total,
          chargeDVRetail_Total: this.calculateChargeDV(tupssInfo),  //charge
          chargeCurrency: tupssInfo.data.response.cdsresponse.shipmentInfo.chargeCurrency,
          dimUOM: tupssInfo.data.response.cdsresponse.shipmentInfo.dimUOM,
          actualWeight_Total: tupssInfo.data.response.cdsresponse.shipmentInfo.actualWeight_Total,
          billedWeight_Total: tupssInfo.data.response.cdsresponse.shipmentInfo.billedWeight_Total,
          billingType: tupssInfo.data.response.cdsresponse.shipmentInfo.billingInfo.billingType,
          storeNumber: tupssInfo.data.response.cdsresponse.shipmentInfo.siteInfo.storeNumber,
          city: tupssInfo.data.response.cdsresponse.shipmentInfo.siteInfo.city,
          stateProv: tupssInfo.data.response.cdsresponse.shipmentInfo.siteInfo.stateProv,
          postal: tupssInfo.data.response.cdsresponse.shipmentInfo.siteInfo.postal,
          country: tupssInfo.data.response.cdsresponse.shipmentInfo.siteInfo.country,
          telephone: tupssInfo.data.response.cdsresponse.shipmentInfo.siteInfo.telephone,
          email: tupssInfo.data.response.cdsresponse.shipmentInfo.siteInfo.email,
          siteAccount: tupssInfo.data.response.cdsresponse.shipmentInfo.siteInfo.siteAccount,
          packageNumber: tupssInfo.data.response.cdsresponse.shipmentInfo.packageInfo[0].number,
          trackNo: this.userService.getUserInfo().trackingNumber,
          coverageType: this.getCoverageType(tupssInfo),
          packedBy: this.getPackedBy(tupssInfo),
          dimensions: tupssInfo.data.response.cdsresponse.shipmentInfo.packageInfo.find(x => x.trackNo.toUpperCase() == this.userService.getUserInfo().trackingNumber.toUpperCase() && x.number != '0').dimensions,
          packagingNumber: '',
          packagingName: '',
          packagingDiscount: this.calculateTotalDiscount(tupssInfo) == 'NaN' ? '0' : this.calculateTotalDiscount(tupssInfo),
          totalCharge: this.calculateTotalCharge(tupssInfo) == 'NaN' ? '0' : this.calculateTotalCharge(tupssInfo),  //change
          packingServiceNumber: '',
          packingServiceName: '',
          howReported: this.tupssService.userDetails.type,
          claimSource: this.tupssService.userDetails.type == 'tfcp' ? 'TUPSS - TFCP' : 'TUPSS - TCCP',
          weightUOM: tupssInfo.data.response.cdsresponse.shipmentInfo.weightUOM.toString(),
          billingAccount: tupssInfo.data.response.cdsresponse.shipmentInfo.billingInfo.billingAccount,
          description: tupssInfo.data.response.cdsresponse.shipmentInfo.description
        }

        let claim = _.merge(this._commonClaimsInterface, updatedClaim);

        //At very end set claim interface variable.
        this.setCommonClaimsInterface(claim);
      }
    );
  }

  //Used in TUPSS flow - 
  getPackedBy(tupssdata): string {
    let packed = tupssdata?.data?.response?.cdsresponse?.shipmentInfo?.packageInfo?.find(x => x.trackNo.toUpperCase() == this.userService.getUserInfo().trackingNumber.toUpperCase() && x.packedBy != null && x.number != '0');
    if (packed) {
      //packedBy not used in service. left over from when it was used in the component

      // this.packedBy = packed.packedBy.startsWith('0') ? 'The UPS Store' : 'Customer'
      return packed && packed.packedBy ? packed.packedBy : '';
    } else {
      // this.packedBy = '';
      return packed && packed.packedBy ? packed.packedBy : '';
    }
  }

  //Used in TUPSS flow - 
  getCoverageType(tupssdata): string {
    let coverage = tupssdata?.data?.response?.cdsresponse?.shipmentInfo?.packageInfo?.find(x => (x.trackNo.toUpperCase() == this.userService.getUserInfo().trackingNumber.toUpperCase()) && (x.coverageType != null) && x.number != '0');
    if (coverage) {
      //Coverage type variable not used in the service - left over from when it was used in the component

      //this.coverageType = coverage.coverageType == 3 ? 'Flex Multi-Carrier' : coverage.coverageType == 2 ? 'Pack and Ship Guarantee' : coverage.coverageType == 1 ? 'Enhanced Declared Value' : '';
      return coverage && coverage.coverageType ? coverage.coverageType : coverage?.coverageType;
    } else {
      // this.coverageType = '';
      return coverage && coverage.coverageType ? coverage.coverageType : coverage?.coverageType;
    }
  }

  //Used in TUPSS flow - 
  getSpecialtyProgram(tupssdata): string {
    let pkg = tupssdata?.data?.response?.cdsresponse?.shipmentInfo?.packageInfo?.find(x => (x.trackNo.toUpperCase() == this.userService.getUserInfo().trackingNumber.toUpperCase()) && (x.coverageType != null) && x.number != '0');
    if (pkg) {
      if (pkg.coverageType == 0) {
        return 'UPS or USPS/Endicia';
      } else if (pkg.coverageType == 1) {
        return 'EDV';
      } else if (pkg.coverageType == 2) {
        return 'PSG';
      } else if (pkg.coverageType == 3) {
        return 'FMC';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  //Used in TUPSS flow - 
  calculateChargeOfOnePackage(iShipData): string {
    let box = iShipData.data.response.cdsresponse.receipt?.filter(x => x.name.toLowerCase().includes('box'));
    let materials = iShipData.data.response.cdsresponse.receipt?.filter(x => x.name.toLowerCase().includes('pack materials'));
    let service = iShipData.data.response.cdsresponse.receipt?.filter(x => x.name.toLowerCase().includes('pack service'));
    let total = 0;

    if (box?.length == 1) {
      total = total + Number(box[0].totalcharge);
    }
    if (materials?.length == 1) {
      total = total + Number(materials[0].totalcharge);
    }
    if (service?.length == 1) {
      total = total + Number(service[0].totalcharge);
    }
    return total.toFixed(2);
  }

  //Used in TUPSS flow - 
  calculateTotalCharge(iShipData): string {
    let packageDimensions = iShipData.data.response.cdsresponse.shipmentInfo.packageInfo.find(x => x.trackNo.toUpperCase() == this.userService.getUserInfo().trackingNumber.toUpperCase() && x.number != '0').dimensions;
    if (!packageDimensions || !iShipData.data.response.cdsresponse.receipt) {
      return '0';
    } else {
      packageDimensions = packageDimensions.split('x');
      packageDimensions.forEach((o, i, a) => {
        a[i] = (Number(a[i]) / 100).toString();
        if (a[i].length == 1) {
          a[i] = `0${a[i]}`;
        }
      });
      let convertedStringDimensions = `${packageDimensions[0]}x${packageDimensions[1]}x${packageDimensions[2]}`;
      let box = iShipData.data.response.cdsresponse.receipt.filter(x => x.name.includes(convertedStringDimensions) && x.name.toLowerCase().includes('box'));
      let materials = iShipData.data.response.cdsresponse.receipt.filter(x => x.name.includes(convertedStringDimensions) && x.name.toLowerCase().includes('pack materials'));
      let service = iShipData.data.response.cdsresponse.receipt.filter(x => x.name.includes(convertedStringDimensions) && x.name.toLowerCase().includes('pack service'));
      let total = 0;

      //Test to see if found multiple boxes of same dimensions, ex: 12x12x12 box shows up twice in receipt[]. be wary of box, pack materials, and pack service
      if (box.length > 1 || materials.length > 1 || service.length > 1) {
        return '0';
      }
      if (box.length == 1) {
        total = total + Number(box[0].totalcharge);
      }
      if (materials.length == 1) {
        total = total + Number(materials[0].totalcharge);
      }
      if (service.length == 1) {
        total = total + Number(service[0].totalcharge);
      }
      if (box.length < 1 && materials.length < 1 && service.length < 1) {
        //If same dimensions not found in receipt, but there was 1 package, then business wants to go ahead and try to use the receipt details even though the dimensions don't match.
        if (iShipData.data.response.cdsresponse.shipmentInfo.totalPkgs == 1) {
          total = total + Number(this.calculateChargeOfOnePackage(iShipData));
        }
      }
      return total.toFixed(2);
    }
  }

  calculateChargeShip(iShipData): string {
    let pkg = iShipData.data.response.cdsresponse.shipmentInfo.packageInfo.find(x => x.trackNo.toUpperCase() == this.userService.getUserInfo().trackingNumber.toUpperCase() && x.number != '0');
    return pkg && pkg.chargeShipRetail ? pkg.chargeShipRetail : '';
  }

  calculateChargeDV(iShipData): string {
    let pkg = iShipData.data.response.cdsresponse.shipmentInfo.packageInfo.find(x => x.trackNo.toUpperCase() == this.userService.getUserInfo().trackingNumber.toUpperCase() && x.number != '0');
    return pkg && pkg.chargeDVRetail ? pkg.chargeDVRetail : '';
  }

  calculateTotalDiscount(iShipData): string {
    let packageDimensions = iShipData.data.response.cdsresponse.shipmentInfo.packageInfo.find(x => x.trackNo.toUpperCase() == this.userService.getUserInfo().trackingNumber.toUpperCase() && x.number != '0').dimensions;
    if (!packageDimensions || !iShipData.data.response.cdsresponse.receipt) {
      return '0';
    } else {
      packageDimensions = packageDimensions.split('x');
      packageDimensions.forEach((o, i, a) => {
        a[i] = (Number(a[i]) / 100).toString();
        if (a[i].length == 1) {
          a[i] = `0${a[i]}`;
        }
      });
      let convertedStringDimensions = `${packageDimensions[0]}x${packageDimensions[1]}x${packageDimensions[2]}`;

      let box = iShipData.data.response.cdsresponse.receipt.find(x => x.name.includes(convertedStringDimensions) && x.name.toLowerCase().includes('box'));
      let materials = iShipData.data.response.cdsresponse.receipt.find(x => x.name.includes(convertedStringDimensions) && x.name.toLowerCase().includes('pack materials'));
      let service = iShipData.data.response.cdsresponse.receipt.find(x => x.name.includes(convertedStringDimensions) && x.name.toLowerCase().includes('pack service'));
      let total = 0;

      if (box) {
        total = total + Number(box.discount);
      }
      if (materials) {
        total = total + Number(materials.discount);
      }
      if (service) {
        total = total + Number(service.discount);
      }
      return total.toFixed(2);
    }
  }

  formatCurrencyFromTrack3(currencyFromTrack3): any { //It should return a string, but just in case Track 3 changes their values to non-string values, we let it proceed
    if (currencyFromTrack3) {
      if (currencyFromTrack3 == 1 || currencyFromTrack3 == '1') {
        return 'usd';
      } else {
        return currencyFromTrack3;  //Return whatever it is that Track 3 gave us. we only have rules to convert '1' to 'usd' at the moment.
      }
    } else {
      return '';
    }
  }

  //Cleaning up some formats so we are sure that GW won't throw validation errors
  //This should be called before any claim request.
  formatPayloadBeforeAnyAPIRequest() {
    let tempClaim = this._commonClaimsInterface;
    //Remove "currency" from the money amounts. Sometimes would send "10.00 usd" or something like that. Should be "10.00".
    tempClaim.shippingDetailsDTO.verifiedShippingCharge = tempClaim.shippingDetailsDTO.verifiedShippingCharge?.split(" ")[0];
    tempClaim.shippingDetailsDTO.merchandiseValue = tempClaim.shippingDetailsDTO.merchandiseValue?.split(" ")[0];
    tempClaim.shippingDetailsDTO.invoiceAmount = tempClaim.shippingDetailsDTO.invoiceAmount?.split(" ")[0];

    //GW Does not like the extra zero at the end of the dates.
    //Used to be lossDate:"2023-05-03T04:00:00.000Z".
    //Now should be lossDate:"2023-05-03T04:00:00Z".
    let tempLD = tempClaim.lossDate ? new Date(tempClaim.lossDate).toISOString() : '';
    tempClaim.lossDate = tempLD.replace(".000Z", "Z");
    let tempSD = tempClaim.shippingDetailsDTO.shipDate ? new Date(tempClaim.shippingDetailsDTO.shipDate).toISOString() : '';
    tempClaim.shippingDetailsDTO.shipDate = tempSD.replace(".000Z", "Z");

    //This fixes insuredAmount being something like 17.00000000001
    let tempIV = tempClaim.shippingDetailsDTO.insuredAmount;
    if (tempIV) {
      tempIV = Number(tempIV).toFixed(2).toString();
      tempClaim.shippingDetailsDTO.insuredAmount = tempIV;
    }

    //This fixes claimAmount being something like 17.00000000001
    let tempCA = tempClaim.claimAmount_Ext;
    if (tempCA) {
      tempCA = Number(tempCA).toFixed(2).toString();
      tempClaim.claimAmount_Ext = tempCA;
    }

    this.updateCommonClaimObject(tempClaim);
  }

  updateCommonClaimObject(claimData: IClaim) {
    let claim = _.merge(this._commonClaimsInterface, claimData);

    this.setCommonClaimsInterface(claim);
  }
}
