import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { ClaimService } from 'src/app/services/claim.service';
import { TupssService } from '../../../services/tupss.service';
import { IVerificationInterface, emptyVerificationInterface } from 'src/app/shared/models/verification.interface';
import { ProspectService } from 'src/app/services/prospect.service';

@Component({
  selector: 'upsc-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {
  @Input() email = "";
  @Input() claimDetailFlow: boolean = false;
  @Input() flow: any;
  @Output() canceled = new EventEmitter<any>();
  @Output() resend = new EventEmitter<string>(false);
  @Output() verified = new EventEmitter<string>(false);
  @Output() verifiedResponse = new EventEmitter<boolean>(false);
  @Output() addPolicyResponse = new EventEmitter<any>();

  @ViewChild("name1") field1: ElementRef;
  @ViewChild("name2") field2: ElementRef;
  @ViewChild("name3") field3: ElementRef;
  @ViewChild("name4") field4: ElementRef;
  @ViewChild("name5") field5: ElementRef;
  @ViewChild("name6") field6: ElementRef;

  verificationInterface = { emailID: "", VerificationCode: "" };
  confirmFormGroup: UntypedFormGroup;
  numberPattern: RegExp = /[(0-9)]/;
  letterPattern: RegExp = /[a-zA-Z]/;
  codeErrorReturned: boolean;
  loading: boolean = false;
  maskedEmail = '';
  name = '';
  policySearchError: boolean = false;


  public createVerificationInterface: IVerificationInterface = emptyVerificationInterface;
  verifyCodeInterface = { emailId: "", toVerify: "" };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private claimservice: ClaimService,
    private accountService: AccountService,
    private router: Router,
    private tupssService: TupssService,
    private prospectService: ProspectService
  ) {
    this.confirmFormGroup = this.formBuilder.group({
      firstDigit: ['', Validators.required],
      secondDigit: ['', Validators.required],
      thirdDigit: ['', Validators.required],
      fourthDigit: ['', Validators.required],
      fifthDigit: ['', Validators.required],
      sixthDigit: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    if(this.flow == 'myChoice') {
      let emailParts = this.email.split('@');
      this.maskedEmail = `${emailParts[0].charAt(0)}******${emailParts[0].charAt(emailParts[0].length - 1)}@${emailParts[1]}`;

      let verifyData = JSON.parse(sessionStorage.getItem('verifyData'));
      this.confirmFormGroup.addControl('firstName', new FormControl(verifyData?.firstName ? verifyData.firstName : '', {validators: Validators.required}));
      this.confirmFormGroup.addControl('lastName', new FormControl(verifyData?.lastName ? verifyData.lastName : '', {validators: Validators.required}));

      if(verifyData) {
        // this.field1.nativeElement.value = verifyData.code;
        this.checkVerificationCode(verifyData.code);
      }
    } else if (this.flow == 'prospect') {
      this.confirmFormGroup.addControl('recaptchaControl', new FormControl(null, Validators.required));
      let emailParts = this.email.split('@');
      this.maskedEmail = `${emailParts[0].charAt(0)}******${emailParts[0].charAt(emailParts[0].length - 1)}@${emailParts[1]}`;
    }
    else if(this.flow == 'prospectPortal') {
      let ppData = JSON.parse(sessionStorage.getItem('prospectPortalData'));
      if(ppData) {
        this.name = ppData?.customerName.split(' ')[0];
      }
    }

    //Checking for policy search errors
    this.tupssService.policySearchError$.subscribe(
      bool => {
        if (bool == true) {
          this.policySearchError = true;
        } else if (bool == false) {
          this.policySearchError = false;
        }
      }
    )
  }

  pasted() {
    if (this.field1.nativeElement.value.length > 1) {
      let digits = this.splitText();
      this.focusThis(digits)
    }
  }

  //Send verification code that user entered to api
  public checkVerificationCode(code? : any) {
    this.codeErrorReturned = false;
    this.loading = true;
    //call the check verification code api if success move ahead
    this.verifyCodeInterface.emailId = (this.claimDetailFlow || this.flow == 'deliveryConfirmation') ? JSON.parse(sessionStorage.getItem('userDetails')).emailAddress : this.email;
    if(code) {
      this.verifyCodeInterface.toVerify = code;
    }
    else {
      this.verifyCodeInterface.toVerify = this.confirmFormGroup.get('firstDigit').value + this.confirmFormGroup.get('secondDigit').value + this.confirmFormGroup.get('thirdDigit').value + this.confirmFormGroup.get('fourthDigit').value + this.confirmFormGroup.get('fifthDigit').value + this.confirmFormGroup.get('sixthDigit').value;
    }
    this.accountService.verifyTupssCode(this.verifyCodeInterface).subscribe(
      data => {
        this.loading = false;
        if ((data.message).toLowerCase() === 'verification code matched') {
          this.codeErrorReturned = false;
          //User is verified & received a token
          sessionStorage.setItem('access_token', data.accessToken);
          if (this.claimDetailFlow) {
            this.router.navigate(['/tupss/detail'], { queryParams: { claimNumber: this.tupssService.userDetails.claimNumber } });
          }
          else if (this.flow == 'deliveryConfirmation') {
            var claimNumber = this.tupssService.userDetails.claimNumber;
            //this.router.navigate(['tccp', 'confirm-package-redirect']);
            this.router.navigate(['/tupss/detail'], { queryParams: { claimNumber: claimNumber } });
          }
          else if (this.flow == 'myChoice') {
            sessionStorage.setItem('verifyData', JSON.stringify({
              code: this.verifyCodeInterface.toVerify,
              firstName: this.confirmFormGroup.controls.firstName?.value,
              lastName: this.confirmFormGroup.controls.lastName?.value,
            }));
            this.verified.emit('yes');
            // this.router.navigate(['/ups-my-choice']);
          }
          else if (this.flow == 'prospect') {
            this.prospectService.setEmailVerEvent(true);
          }
          else if (this.flow == 'prospectPortal') {
            sessionStorage.setItem('verifyData', JSON.stringify({
              code: this.verifyCodeInterface.toVerify
            }))
            this.verified.emit('yes');
          }
          else {
            this.router.navigate(['tupss', 'file']);
            this.claimservice.setStep();
          }
        } else {
          if(this.flow == 'myChoice' && sessionStorage.getItem('verifyData')) { //for my choice, clear previous code and resend
            sessionStorage.removeItem('verifyData');
            this.resend.emit(this.email);
          } else if(this.flow =='prospect') {
            this.prospectService.setEmailVerEvent(false);
          }
          else {
            this.codeErrorReturned = true;
          }
        }
      }, error => {
        this.prospectService.setEmailVerEvent(false);
        if (error.error && error.error.message && error.error.message.toLowerCase() == 'verification code did not match') {
          this.codeErrorReturned = true;
          this.loading = false;
        } else {
          this.loading = false;
          this.codeErrorReturned = false;
        }
      }
    );
    // let subscription6 = this.accountService.validateVerificationCode(this.verificationInterface).subscribe(
    //   data => {
    //     this.loading = false;
    //     if (data.message == "Valid") {
    //       this.codeErrorReturned = false;
    //       //User is verified & received a token
    //       sessionStorage.setItem('access_token', data.accessToken);
    //       if (this.claimDetailFlow) {
    //         this.router.navigate(['/tupss/detail'], { queryParams: {claimNumber: this.tupssService.userDetails.claimNumber}});
    //       } else {
    //         this.router.navigate(['tupss', 'file']);
    //         this.claimservice.setStep();
    //       }
    //     } else {
    //       this.codeErrorReturned = true;
    //     }
    //   }, error => {
    //     this.loading = false;
    //     this.codeErrorReturned = true;
    //   }
    // );

  }

  onResend(event) {
    event.preventDefault();
    this.codeErrorReturned = false;
    this.confirmFormGroup.controls.firstDigit.setValue('');
    this.confirmFormGroup.controls.firstDigit.markAsUntouched();
    this.confirmFormGroup.controls.secondDigit.setValue('');
    this.confirmFormGroup.controls.secondDigit.markAsUntouched();
    this.confirmFormGroup.controls.thirdDigit.setValue('');
    this.confirmFormGroup.controls.thirdDigit.markAsUntouched();
    this.confirmFormGroup.controls.fourthDigit.setValue('');
    this.confirmFormGroup.controls.fourthDigit.markAsUntouched();
    this.confirmFormGroup.controls.fifthDigit.setValue('');
    this.confirmFormGroup.controls.fifthDigit.markAsUntouched();
    this.confirmFormGroup.controls.sixthDigit.setValue('');
    this.confirmFormGroup.controls.sixthDigit.markAsUntouched();
    this.resend.emit(this.email);
  }

  //Determines where the next focus should be for verification code
  focusThis(nextIndex: number, event?) {
    if (nextIndex == 2) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.field1.nativeElement.focus();
      }
      else {
        this.field2.nativeElement.focus();
      }
    } else if (nextIndex == 3) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('secondDigit').setValue(' ');
        this.field1.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('secondDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('secondDigit').setValue(event.key);
        }
        this.field3.nativeElement.focus();
      }

    } else if (nextIndex == 4) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('thirdDigit').setValue(' ');
        this.field2.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('thirdDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('thirdDigit').setValue(event.key);
        }
        this.field4.nativeElement.focus();
      }
    } else if (nextIndex == 5) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('fourthDigit').setValue(' ');
        this.field3.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('fourthDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('fourthDigit').setValue(event.key);
        }
        this.field5.nativeElement.focus();
      }
    } else if (nextIndex == 6) {
      if (event && event.key == "Backspace" || event.key == "Delete") {
        this.confirmFormGroup.get('fifthDigit').setValue(' ');
        this.field4.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('fifthDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('fifthDigit').setValue(event.key);
        }
        this.field6.nativeElement.focus();
      }
    }
    else if (nextIndex >= 7) {
      if (event && event?.key == "Backspace" || event?.key == "Delete") {
        this.confirmFormGroup.get('sixthDigit').setValue(' ');
        this.field5.nativeElement.focus();
      }
      else {
        if (this.confirmFormGroup.get('sixthDigit').value == ' ' && event && /[0-9]/.test(event.key)) {
          this.confirmFormGroup.get('sixthDigit').setValue(event.key);
        }
        this.field6.nativeElement.focus();
      }
    }
  }

  //Splits up verification code if user tries to paste
  splitText() {
    var str = this.field1.nativeElement.value;
    var splitted = str.split("");
    /* this.confirmFormGroup.setValue({
      firstDigit: splitted[0],
      secondDigit: splitted[1] || '',
      thirdDigit: splitted[2] || '',
      fourthDigit: splitted[3] || '',
      fifthDigit: splitted[4] || '',
      sixthDigit: splitted[5] || ''
    }) */
    this.confirmFormGroup.controls.firstDigit.setValue(splitted.length > 0 ? (splitted[0] || '') : '');
    this.confirmFormGroup.controls.secondDigit.setValue(splitted.length > 1 ? (splitted[1] || '') : '');
    this.confirmFormGroup.controls.thirdDigit.setValue(splitted.length > 2 ? (splitted[2] || '') : '');
    this.confirmFormGroup.controls.fourthDigit.setValue(splitted.length > 3 ? (splitted[3] || '') : '');
    this.confirmFormGroup.controls.fifthDigit.setValue(splitted.length > 4 ? (splitted[4] || '') : '');
    this.confirmFormGroup.controls.sixthDigit.setValue(splitted.length > 5 ? (splitted[5] || '') : '');
    return splitted.length;
  }

  cancel() {
    this.canceled.emit('yes');
  }

  get inp1() { return this.confirmFormGroup.controls.inp1 };
  get inp2() { return this.confirmFormGroup.controls.inp2 };
  get inp3() { return this.confirmFormGroup.controls.inp3 };
  get inp4() { return this.confirmFormGroup.controls.inp4 };
  get inp5() { return this.confirmFormGroup.controls.inp5 };
  get inp6() { return this.confirmFormGroup.controls.inp6 };


  resendCode(){
    this.addPolicyResponse.emit('resendCode');
  }

  resetStep($event){
    this.addPolicyResponse.emit('resetStep');
  }

  verifyCode(){
    if(this.confirmFormGroup.valid){
      this.checkVerificationCodeAddPolicy();
    }
    else{
      return false;
    }
  }


  //Check verification code that user enters with the code that was sent
  public checkVerificationCodeAddPolicy() {
    //TODO: Concat all the digits together and then compare to the string
    let userInput = this.confirmFormGroup.get('firstDigit').value + this.confirmFormGroup.get('secondDigit').value + this.confirmFormGroup.get('thirdDigit').value + this.confirmFormGroup.get('fourthDigit').value + this.confirmFormGroup.get('fifthDigit').value + this.confirmFormGroup.get('sixthDigit').value;
    //Use the retrieveVerificationCode function here to compare userInput
    this.verifyCodeInterface.emailId = this.email;
    this.verifyCodeInterface.toVerify = userInput;

    this.accountService.verifyCode(this.verifyCodeInterface).subscribe(
      data => {
        if ((data.message).toLowerCase() === 'verification code matched') {
          this.addPolicyResponse.emit('verifyCode');
        } else {
          this.codeErrorReturned = true;
        }
      }, error => {
        //get verification code error
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.codeErrorReturned = true;
        }
        else {
          this.codeErrorReturned = false;
        }
      }
    );
  }

}
