import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from '../../shared/components/layout/layout.component';
import { ClaimsComponent } from './claims.component';
import { ClaimEditComponent } from './components/edit/claim-edit.component';
import { ClaimEditResolver } from './components/edit/claim-edit.resolver';
import { ClaimListComponent } from './components/list/claim-list.component';
import { ClaimHelpComponent } from './components/help/claim-help.component';
import { AuthGuardService } from '../../core/services/auth-guard.service';
import { PayeeComponent } from './components/payee/components/table/payee.component';
import { CreateComponent } from './components/payee/components/create/create.component';
import { BankingComponent } from './components/payee/components/banking/banking.component';
import { BankSuccessComponent } from './components/payee/components/bank-success/bank-success.component';
import { DetailComponent } from './components/detail/detail.component';
import { UnableToFulfillComponent } from 'src/app/shared/components/unable-to-fulfill/unable-to-fulfill.component';
import { DraftListComponent } from './components/draft-list/draft-list.component';
import { MessageListComponent } from './components/message-list/message-list.component';
//import { MessageClaimDetailComponent } from './components/message-claim-detail/message-claim-detail.component';
import { MessageAdjusterComponent } from './components/message-adjuster/message-adjuster.component';

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '',
      component: LayoutComponent,
      children: [
        {
          path: '',
          component: ClaimsComponent,
          canActivate: [AuthGuardService],
          children: [
            {
              path: '',
              component: ClaimListComponent
            },
            {
              path: 'new',
              component: ClaimEditComponent,
              resolve: { prefetch: ClaimEditResolver },
            },
            {
              path: 'message-list',
              component: MessageListComponent 
            },
            /*{
              path: 'message-claim-detail',
              component: MessageClaimDetailComponent               
            },*/
            {
              path: 'message-adjuster',
              component: MessageAdjusterComponent
            },
            {
              path: 'draft-list',
              component: DraftListComponent
            },
            {
              path: 'MultiClaim',
              component: ClaimEditComponent,
              resolve: { prefetch: ClaimEditResolver },
            },
            {
              path: 'help',
              component: ClaimHelpComponent,
            },
            {
              path: 'detail',
              component: DetailComponent
            },
            // {
            //   path: 'payee',
            //   children: [
            //     {
            //       path: '',
            //       component: PayeeComponent
            //     },
            //     {
            //       path: 'create',
            //       component: CreateComponent
            //     },
            //     {
            //       path: 'banking',
            //       component: BankingComponent
            //     },
            //     {
            //       path: 'unable-to-fulfill',
            //       component: UnableToFulfillComponent
            //     }
            //   ]
            // }
          ],
        }
      ],
    },
    {
      path: 'bankSuccess', component: BankSuccessComponent
    }
  ])],
  exports: [RouterModule],
})
export class ClaimsRoutingModule {
}
