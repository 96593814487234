//Angular imports
import { Component, HostBinding, OnDestroy, OnInit, ChangeDetectorRef, HostListener, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DateAdapter } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { BreakpointObserver } from '@angular/cdk/layout';
import { UsCheckboxComponent } from 'src/app/shared/components/us-checkbox/us-checkbox.component';

//rxjs imports
import { Subscription, Observable, fromEvent, empty } from 'rxjs';
import { startWith, map, debounceTime, retry } from 'rxjs/operators';
//Interfaces
import { IStep } from '../../../../shared/components/stepper/models/step.interface';
import { IClaim, emptyClaim, ICategory, IContact, IDocument, IDocumentDisplay, ISummary } from '../../../../shared/models/claim.interface';
import { ILogger } from '../../../../shared/models/logger.interface';
import { AccountInfo } from '../../../../shared/models/account-info.interface';
import { IPolicies, IPolicy, IPolicyProperties } from '../../../../shared/models/policies.interface';
import { IShippingDetailsAutofill, IShipperConsigneeAddress } from '../../../../shared/models/shippingDetails.interface';
import { emptyIEmail } from '../../../../shared/models/email.interface';

//Services
import { UtilityService } from '../../../../services/utility.service';
import { ClaimService } from '../../../../services/claim.service';
import { LoginService } from '../../../../services/login.service';
import { PayeeService } from 'src/app/services/payee.service';
import { UserService } from 'src/app/services/user.service';

//Etc
import { DialogContentComponent } from '../dialog-content/dialog-content.component';
import { States } from '../../../../../config/metadata-config';
import { ErrordialogComponent } from '../../../../shared/components/errordialog/errordialog.component';
import { ConfirmCancelComponent } from '../confirm-cancel/confirm-cancel.component';

import { countries, carriers, productCategory, limitedCarriers } from '../../../../../config/metadata-config';
import { PhoneNumberPipe } from 'src/app/shared/pipes/phone-number.pipe';
import { environment as ENV } from '../../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { DraftConfirmationComponent } from '../../dialogs/draft-confirmation/draft-confirmation.component';
import { TaggingService } from 'src/app/tags/tagging.service';
import { UserPermission } from 'src/app/shared/models/user-permission';
import { DataService } from 'src/app/services/data.service';
import { ContactInfo } from 'src/app/shared/models/contact-info';
import * as _ from 'lodash';
import { PolicyService } from 'src/app/services/policy.service';
import { BillingService } from 'src/app/services/billing.service';
import { MatDialogConfig } from '@angular/material/dialog';
import { CheckFeePopupComponent } from 'src/app/shared/components/check-fee-popup/check-fee-popup.component';

@Component({
  selector: 'upsc-claim-edit',
  templateUrl: './claim-edit.component.html',
  styleUrls: ['./claim-edit.component.scss'],
})
export class ClaimEditComponent implements OnInit, OnDestroy {
  @HostBinding('class') public hostClass = 'claim-edit';
  @HostListener('window:resize', ['$event'])
  MultiClaimDownloadForm = ENV.links.MultiClaimDownloadForm;

  @ViewChild(UsCheckboxComponent, { static: false }) usCheckboxComponent: UsCheckboxComponent;
  

  //Prevents Internet Explorer from navigating backwards when the Backspace is pressed.
  @HostListener('document:keydown', ['$event'])
  onKeyDown(evt: KeyboardEvent) {
    if (
      evt.keyCode === 8 || evt.which === 8
    ) {
      let doPrevent = true;
      const types = ['text', 'password', 'file', 'search', 'email', 'number', 'date', 'color', 'datetime', 'datetime-local', 'month', 'range', 'search', 'tel', 'time', 'url', 'week'];
      const target = (<HTMLInputElement>evt.target);

      const disabled = target.disabled || (<HTMLInputElement>event.target).readOnly;
      if (!disabled) {
        if (target.isContentEditable) {
          doPrevent = false;
        } else if (target.nodeName === 'INPUT') {
          let type = target.type;
          if (type) {
            type = type.toLowerCase();
          }
          if (types.indexOf(type) > -1) {
            doPrevent = false;
          }
        } else if (target.nodeName === 'TEXTAREA') {
          doPrevent = false;
        }
      }
      if (doPrevent) {
        evt.preventDefault();
        return false;
      }
    }
  }

  //Form Groups
  formGroup: UntypedFormGroup;
  payeeFormGroup: UntypedFormGroup;
  startFormGroup: UntypedFormGroup;
  whatHappenedFormGroup: UntypedFormGroup;
  contactInfoFormGroup: UntypedFormGroup;
  submitClaimFormGroup: UntypedFormGroup;
  contactSelectionForm: UntypedFormGroup;
  scriptError: boolean = false;
  scriptErrorFields: string = '';

  //Interfaces
  claim: IClaim = emptyClaim;
  steps: IStep[];
  logInterface: ILogger = {};
  docRequest: IDocument = {};
  userDetails: AccountInfo = {};
  mainContact: IContact = {};
  policyDetails: IPolicy = {};
  policyProperties: IPolicyProperties = {};
  policyPropertiesList: IPolicyProperties[] = [];
  fileList: IDocumentDisplay[] = []; //This list is used to display to the user the names of the documents
  track3ShipmentDetails: IShippingDetailsAutofill = {};
  consigneeAddress: IShipperConsigneeAddress[] = [];
  summary: ISummary = {};
  policy: IPolicies = {};
  contactType: string = "";
  newContactType: string = "";
  contactInfo: ContactInfo = {};

  //File Upload
  fileToUpload: File = null;
  tooManyDocs: boolean = false;
  fileSizeLimit: boolean = false;
  fileNameError = false;
  fileTypeError = false;
  systemError = false;

  //Scenarios
  loss: boolean = false;
  damage: boolean = false;
  missing: boolean = false;
  late: boolean = false;
  other: boolean = false;
  cargo: boolean = false;
  flex: boolean = false;
  showLate: boolean = false;
  istnt: boolean = false;
  //contact selections
  policyHolder: boolean = false;
  recipient: boolean = false;
  custom: boolean = false;

  //Notification booleans
  errorNotification: boolean = false;
  whereILeftButton: boolean = true;
  allClaimIds: string[];
  //Metadata / Data Lists
  countryList = [];
  newStateList = [];
  newCountryList = [];
  stateList: any[] = States; //We never pull list of states from metadata API, only comes from config file
  currencyList = {};
  carrierList: [] | {} = [];
  categoryList = [];
  ccDigits;

  //Displayed values
  senderStateDisplay = "";
  recipientStateDisplay = "";
  locationDisplay = "";
  repairableDisplay = "";
  longDamage = false; //Controls the ...More feature of damage description
  longMissing = false; //Controls the ...More feature of missing description
  dmgMore = false;
  missingMore = false;
  shortenedDamageDescription = "";
  shortenedMissingDescription = "";

  //Key values from key value pairs
  carrierKey = "";
  currencyKey = 'usd';

  showSelections: boolean = this.router.url.includes('/claims/new');
  //Helpers
  currentStep = 0;
  currentStepText: string = "";
  maxDate = new Date();
  Object = Object;
  valController = 0;
  initialClaimCreation: boolean = true;
  shipDateError: boolean = false;
  filteredCategories: Observable<ICategory[]>;
  queryCarrier: string;
  loopCounter = 0;
  privacyLink = ENV.links.privacyNotice;
  submittedClaimNumber: string = "";
  currentlyCheckingDupeTrackingNumbers = false;
  show = false;
  continuing = false; //Used with the continue button's progress
  payeeSelectionValid: boolean = false; //Let user continue from payee page. data received from payee-table component
  mobilePayeeSelectionValid: boolean = false;
  windowSize: any = window.innerWidth;


  //Digital Wallet 
  isWalletUser: boolean = false;
  dwDetails: any;
  showWalletApprovalDisclaimer: boolean = false;
  canAccessDigitalWallet: boolean;

  showClaimsPayoutNotice: boolean = false;
  walletNoTCsWithFee: boolean = false;
  walletNoTCsNoFee: boolean = false;
  noWalletWithFee: boolean = false;
  checkFeeAmount: any;
  
  //Persisted Data used throughout claims process
  persistClaimNumber: string = "tempClaimNumber";
  persistPublicId: string = "";
  persistExpirationDate: Date; //These dates handle what dates a user is allowed to select from based on their policy number
  persistEffectiveDate: Date;
  persistPayeeInfo;
  mailingInfo = {
    "insured": "",
    "streetAddress1": "",
    "streetAddress2": "",
    "city": "",
    "state": "",
    "zip": "",
    "country": ""
  }
  //Default values (Remove this? [(value)] not supported in Angular 7+ ?)
  defaultCurrency = 'usd';

  //(?)
  private saveClaimSubscription: Subscription;
  isGuestUser: boolean;
  isDDUser: boolean;
  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  hvcmFlow: boolean;
  draftClaimNumber: string = "";
  THClaimInfo: any;
  isDraftMode: boolean = false;
  stateCodeSender: string = "";
  countryCodeSender: string = "";
  stateCodeReceiver: string = "";
  countryCodeReceiver: string = "";
  myStates: any;
  myCountries: any;
  lossDescription: any;
  isPolicyHolder: boolean = false;
  isRecipient: boolean = false;
  isSelectionBackground: boolean = true;
  shipmentRole = "";
  claimReason = "";
  damageDraftDescription = "";
  damageDraftDate = "";
  packageLocation = "";
  packageLocationDetails = "";
  originalTrackNumber = "";
  repairable = "";
  quantity;
  upsmerchandise_Ext = "";
  isReturnShipment = "no";
  care = "";
  careDetails = "";
  missingDraftDescription = "";
  missingDraftDate = "";
  transportation_carrier_Ext = "";
  mainContact_firstName = "";
  mainContact_lastName = "";
  mainContact_phoneNumber = "";
  mainContact_email = "";
  isPageLoad: boolean = false;
  lossCareData = "";
  lossReshipDescription = "";
  lossRefundDescription = "";
  lateCareData = "";
  lateReshipDescription = "";
  lateRefundDescription = "";
  referenceNumber = "";
  placeholderPayee: any;
  placeholderStatus: any;
  hidePayeePanel: boolean = false;
  userPermission: UserPermission = new UserPermission();

  //Translated dropdown texts
  roles: any[] = [
    { id: 0, text: this.translateService.instant('claims.edit.start.role.roles.sender') },
    { id: 1, text: this.translateService.instant('claims.edit.start.role.roles.recipient') },
    { id: 2, text: this.translateService.instant('claims.edit.start.role.roles.third') },
  ];
  claimReasons: any[] = [
    { id: 0, text: this.translateService.instant('claims.edit.whatHappened.reason.loss') },
    { id: 1, text: this.translateService.instant('claims.edit.whatHappened.reason.damage') },
    { id: 2, text: this.translateService.instant('claims.edit.whatHappened.reason.missing') },
    { id: 3, text: this.translateService.instant('claims.edit.whatHappened.reason.late') },
  ];
  repairables: any[] = [
    { id: 0, text: this.translateService.instant('claims.edit.whatHappened.reason.yes') },
    { id: 1, text: this.translateService.instant('claims.edit.whatHappened.reason.no') },
  ];
  damagedLocations: any[] = [
    { id: 0, text: this.translateService.instant('claims.edit.whatHappened.merchandise.description.damage.where.one') },
    { id: 1, text: this.translateService.instant('claims.edit.whatHappened.merchandise.description.damage.where.two') },
    { id: 2, text: this.translateService.instant('claims.edit.whatHappened.merchandise.description.damage.where.three') }
  ];


  constructor(
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private utilityService: UtilityService,
    private claimService: ClaimService,
    private loginService: LoginService,
    private userService: UserService,
    public dialog: MatDialog,
    private router: Router,
    private titleService: Title,
    private breakpointObserver: BreakpointObserver,
    private phonePipe: PhoneNumberPipe,
    private payeeService: PayeeService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private taggingService: TaggingService,
    private dataService: DataService,
    private policyService: PolicyService,
    private billingService: BillingService
  ) {
    fromEvent(window, 'resize').pipe(
      debounceTime(500)).subscribe(
        data => {
          if (data.target['innerWidth'] >= 768 && this.windowSize < 768) {
            this.mobilePayeeSelectionValid = false;
          } else if (data.target['innerWidth'] < 768 && this.windowSize >= 768) {
            this.payeeSelectionValid = false;
          }
          this.windowSize = data.target['innerWidth'];
        }
      )
    const prefetch = this.route.snapshot.data.prefetch;
    if (prefetch) {
      this.claim = prefetch.claim;
    }
    this.userDetails = this.userService.getUserInfo();
    this.userPermission = this.userService.getUserPermissions();
    this.hvcmFlow = this.router.url.includes('MultiClaim');
    this.policy = this.loginService.getPolicyDetails();
    this.dwDetails = this.loginService.getDWDetails();
    this.checkFeeAmount = this.userService.getCheckFeeAmount();

    // Logic to display correct claims payout notice
    let walletUser = this.userService.isWalletUser();
    let acceptedTCs = this.userService.isWalletTCsAccepted();
    let checkFeeAmount = this.userService.getCheckFeeAmount();
    if (walletUser && !acceptedTCs && checkFeeAmount > 0) {
      // Show notice that if they wish to use ACH payment methods to avoid fee, they need to enroll in the wallet.
      this.showClaimsPayoutNotice = true;
      this.walletNoTCsWithFee = true;
    } else if (walletUser && !acceptedTCs && checkFeeAmount <= 0) {
      // Show notice that if they wish to use ACH payment methods, they need to enroll in the wallet.
      this.showClaimsPayoutNotice = true;
      this.walletNoTCsNoFee = true;
    } else if (!walletUser && checkFeeAmount > 0) {
      // Show notice that if they proceed with a check payee, they will be charged a fee.
      this.showClaimsPayoutNotice = true;
      this.noWalletWithFee = true;
    } else {
      this.showClaimsPayoutNotice = false;
    }

    if (this.hvcmFlow) {
      this.userService.isHVCMFlow = true;
    } else {
      this.userService.isHVCMFlow = false;
    }

    if (this.hvcmFlow) {
      this.steps = [
        { order: 1, label: this.translateService.instant('claims.edit.navi.oneHVCM'), isActive: false },
        { order: 2, label: this.translateService.instant('claims.edit.navi.twoHVCM'), isActive: false },
        { order: 3, label: this.translateService.instant('claims.edit.navi.threeHVCM'), isActive: false }
      ];
      this.currentStepText = this.translateService.instant('claims.edit.navi.oneHVCM');
    } else if (sessionStorage.getItem('claimFileStatus') == 'wallet') {
      this.steps = [
        { order: 2, label: this.translateService.instant('claims.edit.navi.two'), isActive: false },
        { order: 3, label: this.translateService.instant('claims.edit.navi.three'), isActive: false },
        { order: 4, label: this.translateService.instant('claims.edit.navi.four'), isActive: false },
        { order: 5, label: this.translateService.instant('claims.edit.navi.five'), isActive: false },
      ];

      this.currentStepText = this.translateService.instant('claims.edit.navi.one');
    } else {
      this.steps = [
        { order: 1, label: this.translateService.instant('claims.edit.navi.one'), isActive: false },
        { order: 2, label: this.translateService.instant('claims.edit.navi.two'), isActive: false },
        { order: 3, label: this.translateService.instant('claims.edit.navi.three'), isActive: false },
        { order: 4, label: this.translateService.instant('claims.edit.navi.four'), isActive: false },
        { order: 5, label: this.translateService.instant('claims.edit.navi.five'), isActive: false },
      ];

      this.currentStepText = this.translateService.instant('claims.edit.navi.one');  
    }

    this.isGuestUser = this.userService.isGuestUser();
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();
    this.isDDUser = this.userService.isDeliveryDefenseUser();
    this.canAccessDigitalWallet = this.userService.canAccessDigitalWallet();

    this.route.queryParams.subscribe(params => {
      if (params) {
        this.placeholderPayee = params.payeeID; //set params as variables
        this.placeholderStatus = params.status;
        let isHvcm = sessionStorage.getItem('isHvcm');
        let policyNum = this.policy?.policyNumber;
        let hasOpened = sessionStorage.getItem('hvcmTabOpened');

        if (isHvcm) {
          if (this.placeholderStatus == "back" || this.placeholderStatus == "cancel") {
            sessionStorage.setItem('setCurrentStep', '0');
          } else if (this.placeholderStatus == "success") {
            sessionStorage.setItem('setCurrentStep', '0');
            if (!hasOpened) {
              sessionStorage.setItem('hvcmTabOpened', JSON.stringify("true"));
              this.openHVCM(this.placeholderPayee, policyNum);
            }
          }
        } else {
          sessionStorage.removeItem('isHvcm');

          if (sessionStorage.getItem('draftClaimNumber')) { //set draft claim number if it exists
            this.draftClaimNumber = sessionStorage.getItem('draftClaimNumber');
          } else if (sessionStorage.getItem('transactionHistoryTempClaim')) { // set TH info if it exists
            this.THClaimInfo = JSON.parse(sessionStorage.getItem('transactionHistoryTempClaim'));
          }

          if (this.placeholderStatus == "success") {
            // This is to prevent an exploit where they're only allowed to use wallet as payee but they manually type in payeeID and status params into the URL to try and continue to use the DPAT payee
            if (this.dwDetails?.walletID && this.dwDetails?.tcAcceptance == '1') {
              this.router.navigate(['/claims/new']);
              sessionStorage.removeItem('claimFileStatus');
            } else {
              let request = {
                'payeeID': this.placeholderPayee,
                'policyNumber': this.userDetails.policyNumber
              }
              this.claimService.getPayeeDetails(request).subscribe(
                data => {
                  this.persistPayeeInfo = data.data;
                  this.hidePayeePanel = false;
                }, error => {
                  this.hidePayeePanel = true;
                }
              );
              if (this.draftClaimNumber) { //if it is a draft claim, initiate process
                this.initialClaimCreation = false;
                this.getDraftData(this.draftClaimNumber);
              }
              sessionStorage.setItem('setCurrentStep', '2'); //if status is success, always move them to step 2
            }
          } else if (this.placeholderStatus == "back" || this.placeholderStatus == "cancel") {
            sessionStorage.setItem('setCurrentStep', '0'); //if status is back/cancel, always keep them at step 0
            if (sessionStorage.getItem('draftClaimNumber')) { //if they came from draft list, navigate them back there
              this.router.navigate(['/claims/draft-list']);
            } else if (sessionStorage.getItem('transactionHistoryTempClaim')) { // if they came from the TH table, navigate them back there
              this.router.navigate(['/billing/transaction-history']);
            }
            sessionStorage.removeItem('draftClaimNumber');
            sessionStorage.removeItem('transactionHistoryTempClaim');
          }
        }
      }
    });

    // If they have wallet + accepted T&Cs, when they come to the claimedit component there will be a sessionStorage value
    // If it's wallet, that means we want to show the wallet flow.
    if (sessionStorage.getItem('claimFileStatus') == 'wallet') {
      this.persistPayeeInfo = {};
      this.persistPayeeInfo.payeeID = this.dwDetails?.walletID;
      this.persistPayeeInfo.paymentMethod = 'wallet';

      if (this.draftClaimNumber) { //if it is a draft claim, initiate process
        this.initialClaimCreation = false;
        this.getDraftData(this.draftClaimNumber);
      }
      sessionStorage.setItem('setCurrentStep', '2'); //if status is wallet, skip DPAT step, move to step 2
      window.scrollTo(0, 0);
    }

    if (this.isGuestUser) {
      this.currentStep = 0;
    }
    if (sessionStorage.getItem("setCurrentStep")) {
      this.currentStep = Number.parseInt(sessionStorage.getItem("setCurrentStep"));

      if (this.currentStep == 1) {
        this.currentStep = 0;
        sessionStorage.setItem('setCurrentStep', '0');
      }
    }
    sessionStorage.removeItem("setCurrentStep");

    //Form groups
    this.startFormGroup = this.formBuilder.group({
      trackingNumber: ['', Validators.compose([Validators.required, Validators.pattern(/^[a-z\-0-9]+$/i)])],
      carrier: [this.userDetails.userPreference.carrier, Validators.compose([Validators.required])],
      role: ['', Validators.compose([Validators.required])],
      sender: this.formBuilder.group({
        company: [this.userDetails.userPreference.preferenceName, Validators.compose([Validators.required])],
        address: (this.isCanadaUser || this.isUsaUser) ? "" : [this.userDetails.userPreference.address.addressLine1, (this.isCanadaUser || this.isUsaUser) ? [] : Validators.compose([Validators.required])],
        city: [this.userDetails.userPreference.address.city, (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) ? [Validators.required] : Validators.compose([Validators.required, Validators.pattern(/^[\w\-\s]+$/)])],
        state: [this.isDDUser ? '' : this.userDetails.userPreference.address.state],
        country: [this.userDetails.userPreference.address.country, Validators.compose([Validators.required])],
        zipCode: [this.userDetails.userPreference.address.zip, (this.isCanadaUser || this.isUsaUser) ? [] : Validators.compose([Validators.required])],
      }),
      recipient: this.formBuilder.group({
        company: ['', Validators.compose([Validators.required])],
        address: ['', (this.isCanadaUser || this.isUsaUser) ? [] : Validators.compose([Validators.required])],
        city: ['', (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) ? [Validators.required] : Validators.compose([Validators.required, Validators.pattern(/^[\w\-\s]+$/)])],
        state: [''],
        country: ['', Validators.compose([Validators.required])],
        zipCode: ['', (this.isCanadaUser || this.isUsaUser) ? [] : Validators.compose([Validators.required])],
      }),
      shipDate: ['', Validators.compose([Validators.required])],
      referenceNumber: ['', Validators.compose([Validators.maxLength(24)])],
      // techAgreement: ['',  [(control) => {
      //   return !control.value ? { 'required': true } : null;
      // }]]
    });

    this.whatHappenedFormGroup = this.formBuilder.group({
      reason: ['', Validators.compose([Validators.required])],
      category: [this.userDetails.userPreference.productCategory, Validators.compose([Validators.required])],
      quantity: ['', Validators.compose([Validators.required, Validators.pattern(/^[1-9]\d*$/)])],
      description: ['', Validators.compose([Validators.required, Validators.maxLength(210)])],
      currency: ['USD', Validators.compose([Validators.required])],
      merchandise: ['', Validators.compose([Validators.required])],
      shipping: ['', Validators.compose([Validators.required])],
      amount: [0, Validators.compose([Validators.required])],
      lossCare: ['',],
      lossReship: ['',],
      lossRefund: ['',],
      damageDescription: ['', Validators.maxLength(800)],
      damageDate: ['',],
      damageRepairable: ['',],
      damageLocations: ['',],
      damageOther: ['', Validators.maxLength(35)],
      missingDescription: ['', Validators.maxLength(800)],
      missingDate: ['',],
      missingLocations: ['',],
      missingOther: ['', Validators.maxLength(35)],
      lateCare: ['',],
      lateReship: ['',],
      lateRefund: ['',],
      //documents: ['', Validators.compose([Validators.required])]
      documents: ['',],
      additionalComments: ['', Validators.maxLength(250)],
      return: ['', Validators.compose([Validators.required])],
      originalTrackingNumber: ['']
    });

    function maxLengthValidator(control: UntypedFormControl): { [s: string]: boolean } {
      if (!control.value || control.value.trim().length < 1) {
        return { required: true };
      }
      else if (control.value.trim().length > 30) {
        return { maxlength: true };
      }
    }
    this.contactSelectionForm = this.formBuilder.group({
      choice: [null, Validators.required]
    });
    this.contactInfoFormGroup = this.formBuilder.group({
      contact: this.formBuilder.group({
        firstName: ['', Validators.compose([Validators.required])],
        lastName: ['', Validators.compose([Validators.required])],
        countryCode: ['', (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) ? [Validators.required, Validators.pattern(/\d{1,3}/)] : []],
        phoneNumber: ['', Validators.required],
        contactSelectionForm: this.contactSelectionForm,
        emailAddress: ['', Validators.compose([Validators.required, Validators.email])],
      })
    });

    this.submitClaimFormGroup = this.formBuilder.group({
      checkboxFormControl: [this.isDDUser? true : false, [(control) => {
        return !control.value ? { 'required': true } : null;
      }]]
    });

    this.formGroup = this.formBuilder.group({
      start: this.startFormGroup,
      whatHappened: this.whatHappenedFormGroup,
      contactInfo: this.contactInfoFormGroup,
      submitClaim: this.submitClaimFormGroup,
    });

    //Customs validations based on valuechanges
    this.whatHappenedFormGroup.controls['category'].valueChanges.subscribe(val => {
      this.whatHappenedFormGroup.controls['category'].setErrors({ 'invalid': true });
      if (typeof val === 'object') { //selected from the list
        this.whatHappenedFormGroup.controls['category'].setErrors(null);
      }
      else { //typed in
        for (let cat of this.categoryList) {
          if (cat.value.toLowerCase() === val.toLowerCase()) {
            if (cat.value !== val) {
              this.whatHappenedFormGroup.controls['category'].setValue(cat);
            }
            this.whatHappenedFormGroup.controls['category'].setErrors(null);
            break;
          }
        }
      }
    });

    //Clear the values when radio button changes
    this.whatHappenedFormGroup.get('lateCare').valueChanges.subscribe(val => {
      if (val == 0) {
        this.whatHappenedFormGroup.get('lateRefund').setValue(undefined);
        this.whatHappenedFormGroup.get('lateRefund').updateValueAndValidity();
      } else if (val == 1) {
        this.whatHappenedFormGroup.get('lateReship').setValue(undefined);
        this.whatHappenedFormGroup.get('lateReship').updateValueAndValidity();
      }
    });

    //Clear the values when radio button changes
    this.whatHappenedFormGroup.get('lossCare').valueChanges.subscribe(val => {
      if (val == 0) {
        this.whatHappenedFormGroup.get('lossRefund').setValue(undefined);
        this.whatHappenedFormGroup.get('lossRefund').updateValueAndValidity();
      } else if (val == 1) {
        this.whatHappenedFormGroup.get('lossReship').setValue(undefined);
        this.whatHappenedFormGroup.get('lossReship').updateValueAndValidity();
      } else if (val == 2 && this.isCanadaUser) { //noinfo canada only
        this.whatHappenedFormGroup.get('lossRefund').setValue(undefined);
        this.whatHappenedFormGroup.get('lossRefund').updateValueAndValidity();
        this.whatHappenedFormGroup.get('lossReship').setValue(undefined);
        this.whatHappenedFormGroup.get('lossReship').updateValueAndValidity();
      }
    });

    //Clear the values when radio button changes
    this.whatHappenedFormGroup.get('return').valueChanges.subscribe(val => {
      if (val == 'yes') {
        this.whatHappenedFormGroup.get('originalTrackingNumber').setValidators(Validators.required);
        this.whatHappenedFormGroup.get('originalTrackingNumber').updateValueAndValidity();
      } else if (val == 'no') {
        this.whatHappenedFormGroup.get('originalTrackingNumber').setValue(undefined);
        this.whatHappenedFormGroup.get('originalTrackingNumber').clearValidators();
        this.whatHappenedFormGroup.get('originalTrackingNumber').updateValueAndValidity();
      }
    });
    this.whatHappenedFormGroup.get('currency').valueChanges.subscribe(val => {
      this.currencyKey = val.toLowerCase();
    });

    //If user selects US or CA, make the state field required. Need different controls
    //Since recipient and sender don't have to be same country
    this.startFormGroup.get('sender').get('country').valueChanges.subscribe(val => {
      if (val == 'US' || val == 'CA') {
        if (this.isUsaUser || this.isCanadaUser) {
          this.startFormGroup.get('sender').get('state').setValidators([Validators.required]);
          this.startFormGroup.get('sender').get('state').updateValueAndValidity();
        }
        else {
          this.startFormGroup.get('sender').get('state').setValue(undefined);
          this.startFormGroup.get('sender').get('state').setValidators(null);
          this.startFormGroup.get('sender').get('state').updateValueAndValidity();
        }
      } else {
        this.startFormGroup.get('sender').get('state').setValue(undefined);
        this.startFormGroup.get('sender').get('state').setValidators(null);
        this.startFormGroup.get('sender').get('state').updateValueAndValidity();
      }
      if (this.startFormGroup.get('sender').get('state').value > 0) {
        if (val === 'US' || val === 'CA') {
          this.startFormGroup.get('sender').get('country').setErrors(null);
        } else {
          this.startFormGroup.get('sender').get('country').setErrors({ 'mismatch': true });
        }
      }
    });

    this.startFormGroup.get('recipient').get('country').valueChanges.subscribe(val => {
      if (val == 'US' || val == 'CA') {
        if (this.isUsaUser || this.isCanadaUser) {
          this.startFormGroup.get('recipient').get('state').setValidators([Validators.required]);
          this.startFormGroup.get('recipient').get('state').updateValueAndValidity();
        }
        else {
          this.startFormGroup.get('recipient').get('state').setValue(undefined);
          this.startFormGroup.get('recipient').get('state').setValidators(null);
          this.startFormGroup.get('recipient').get('state').updateValueAndValidity();
        }
      } else {
        this.startFormGroup.get('recipient').get('state').setValue(undefined);
        this.startFormGroup.get('recipient').get('state').setValidators(null);
        this.startFormGroup.get('recipient').get('state').updateValueAndValidity();
      }
      if (this.startFormGroup.get('recipient').get('state').value > 0) {
        if (val === 'US' || val === 'CA') {
          this.startFormGroup.get('recipient').get('country').setErrors(null);
        } else {
          this.startFormGroup.get('recipient').get('country').setErrors({ 'mismatch': true });
        }
      }
    });

    this.whatHappenedFormGroup.get('originalTrackingNumber').valueChanges.subscribe(val => {
      if (val.trim().length === 0) {
        this.whatHappenedFormGroup.get('originalTrackingNumber').setErrors({ 'whitespace': true });
      } else if (val.length == 0) {
        this.whatHappenedFormGroup.get('originalTrackingNumber').setErrors({ 'required': true });
      } else {
        this.whatHappenedFormGroup.get('originalTrackingNumber').setErrors(null);
      }
    });

    //Using these valueChanges to remove the red notifiation box, if it is active and the form is valid.
    this.startFormGroup.valueChanges.subscribe(data => {
      if (this.errorNotification) {
        if (this.startFormGroup.valid) {
          this.errorNotification = false;
        } else {
          this.errorNotification = true;
        }
      }
    });

    this.whatHappenedFormGroup.valueChanges.subscribe(data => {
      if (this.errorNotification) {
        if (this.whatHappenedFormGroup.valid) {
          this.errorNotification = false;
        } else {
          this.errorNotification = true;
        }
      }
    });

    this.contactInfoFormGroup.valueChanges.subscribe(data => {
      if (this.errorNotification) {
        if (this.contactInfoFormGroup.valid) {
          this.errorNotification = false;
        } else {
          this.errorNotification = true;
        }
      }
    });
    this.contactSelectionForm.valueChanges.subscribe(data => {
      if (this.errorNotification) {
        if (this.recipient || this.policyHolder) {
          this.errorNotification = false;
        } else if (this.custom) {
          if (this.contactInfoFormGroup.valid) {
            this.errorNotification = false;
          }
        }
        else {
          this.errorNotification = true;
        }
      }
    });

    this.submitClaimFormGroup.valueChanges.subscribe(data => {
      if (this.errorNotification) {
        if (this.submitClaimFormGroup.valid) {
          this.errorNotification = false;
        } else {
          this.errorNotification = true;
        }
      }
    });

    //When user starts typing, filteredCategories will populate.
    this.filteredCategories = this.whatHappenedFormGroup.get('category').valueChanges
      .pipe(
        startWith<string | ICategory>(''),
        map(value => typeof value === 'string' ? value : value.key),
        map(name => name ? this.filter(name) : this.categoryList.slice())
      );

    //On selecting different claims, determine which controls are required
    this.whatHappenedFormGroup.controls.reason.valueChanges.subscribe(val => {
      this.valController = val;
      if (val == 0) {
        //Loss claim selected
        //Clear ALL other flow validators
        this.whatHappenedFormGroup.controls.damageDescription.clearValidators();
        this.whatHappenedFormGroup.controls.damageDate.clearValidators();
        this.whatHappenedFormGroup.controls.damageRepairable.clearValidators();
        this.whatHappenedFormGroup.controls.damageLocations.clearValidators();
        this.whatHappenedFormGroup.controls.damageOther.clearValidators();
        this.whatHappenedFormGroup.controls.missingDate.clearValidators();
        this.whatHappenedFormGroup.controls.missingDescription.clearValidators();
        this.whatHappenedFormGroup.controls.missingLocations.clearValidators();
        this.whatHappenedFormGroup.controls.missingOther.clearValidators();
        this.whatHappenedFormGroup.controls.lateCare.clearValidators();
        this.whatHappenedFormGroup.controls.lateRefund.clearValidators();
        this.whatHappenedFormGroup.controls.lateReship.clearValidators();
        //If cargo policy & loss selected, show the care options
        if (this.cargo && (this.isUsaUser || this.isCanadaUser)) {
          this.whatHappenedFormGroup.controls.lossCare.setValidators([Validators.required]);
          this.whatHappenedFormGroup.controls.lossCare.updateValueAndValidity();
          this.whatHappenedFormGroup.controls.lossCare.valueChanges.subscribe(
            val1 => {
              if (this.whatHappenedFormGroup.controls.reason.value == 0) {
                if (val1 === '0') {
                  this.whatHappenedFormGroup.controls.lossReship.setValidators([Validators.required]);
                  this.whatHappenedFormGroup.controls.lossRefund.clearValidators();
                  this.whatHappenedFormGroup.controls.lossReship.updateValueAndValidity();
                  this.whatHappenedFormGroup.controls.lossRefund.updateValueAndValidity();
                } else if (val1 === '1') {
                  this.whatHappenedFormGroup.controls.lossRefund.setValidators([Validators.required]);
                  this.whatHappenedFormGroup.controls.lossReship.clearValidators();
                  this.whatHappenedFormGroup.controls.lossReship.updateValueAndValidity();
                  this.whatHappenedFormGroup.controls.lossRefund.updateValueAndValidity();
                } else if (val1 === '2' && this.isCanadaUser) {
                  this.whatHappenedFormGroup.controls.lossReship.clearValidators();
                  this.whatHappenedFormGroup.controls.lossRefund.clearValidators();
                  this.whatHappenedFormGroup.controls.lossReship.updateValueAndValidity();
                  this.whatHappenedFormGroup.controls.lossRefund.updateValueAndValidity();
                }
              }
            }
          );
        }
      } else if (val == 1) {
        //Damage claim selected
        //Clear all other flow validators
        this.whatHappenedFormGroup.controls.lossRefund.clearValidators();
        this.whatHappenedFormGroup.controls.lossReship.clearValidators();
        this.whatHappenedFormGroup.controls.lossCare.clearValidators();
        this.whatHappenedFormGroup.controls.missingDate.clearValidators();
        this.whatHappenedFormGroup.controls.missingDescription.clearValidators();
        this.whatHappenedFormGroup.controls.missingLocations.clearValidators();
        this.whatHappenedFormGroup.controls.missingOther.clearValidators();
        this.whatHappenedFormGroup.controls.lateCare.clearValidators();
        this.whatHappenedFormGroup.controls.lateRefund.clearValidators();
        this.whatHappenedFormGroup.controls.lateReship.clearValidators();
        this.whatHappenedFormGroup.controls.damageDescription.setValidators([Validators.required]);
        this.whatHappenedFormGroup.controls.damageDate.setValidators([Validators.required]);
        this.whatHappenedFormGroup.controls.damageRepairable.setValidators([Validators.required]);
        this.whatHappenedFormGroup.controls.damageLocations.setValidators([Validators.required]);

        this.whatHappenedFormGroup.controls.damageLocations.valueChanges.subscribe(otherVal => {
          if (otherVal == 3 && this.valController == 1) {
            //Other location has been selected
            this.whatHappenedFormGroup.controls.damageOther.setValidators([Validators.required]);
          } else {
            this.whatHappenedFormGroup.controls.damageOther.clearValidators();
          }
          this.whatHappenedFormGroup.controls.damageOther.updateValueAndValidity();
        });

      } else if (val == 2) {
        //Missing claim selected
        //Clear all other flow validators
        this.whatHappenedFormGroup.controls.lossRefund.clearValidators();
        this.whatHappenedFormGroup.controls.lossReship.clearValidators();
        this.whatHappenedFormGroup.controls.lossCare.clearValidators();
        this.whatHappenedFormGroup.controls.damageDescription.clearValidators();
        this.whatHappenedFormGroup.controls.damageDate.clearValidators();
        this.whatHappenedFormGroup.controls.damageRepairable.clearValidators();
        this.whatHappenedFormGroup.controls.damageLocations.clearValidators();
        this.whatHappenedFormGroup.controls.damageOther.clearValidators();
        this.whatHappenedFormGroup.controls.lateCare.clearValidators();
        this.whatHappenedFormGroup.controls.lateRefund.clearValidators();
        this.whatHappenedFormGroup.controls.lateReship.clearValidators();
        this.whatHappenedFormGroup.controls.missingDescription.setValidators([Validators.required]);
        this.whatHappenedFormGroup.controls.missingDate.setValidators([Validators.required]);
        this.whatHappenedFormGroup.controls.missingLocations.setValidators([Validators.required]);

        this.whatHappenedFormGroup.controls.missingLocations.valueChanges.subscribe(otherValMissing => {
          if (otherValMissing == 3 && this.valController == 2) {
            //Other location has been selected
            this.whatHappenedFormGroup.controls.missingOther.setValidators([Validators.required]);
          } else {
            this.whatHappenedFormGroup.controls.missingOther.clearValidators();
          }
          this.whatHappenedFormGroup.controls.missingOther.updateValueAndValidity();
        });

      } else if (val == 3) {
        //Late claim selected
        //clear all other flow validators
        this.whatHappenedFormGroup.controls.lossRefund.clearValidators();
        this.whatHappenedFormGroup.controls.lossReship.clearValidators();
        this.whatHappenedFormGroup.controls.lossCare.clearValidators();
        this.whatHappenedFormGroup.controls.damageDescription.clearValidators();
        this.whatHappenedFormGroup.controls.damageDate.clearValidators();
        this.whatHappenedFormGroup.controls.damageRepairable.clearValidators();
        this.whatHappenedFormGroup.controls.damageLocations.clearValidators();
        this.whatHappenedFormGroup.controls.damageOther.clearValidators();
        this.whatHappenedFormGroup.controls.missingDate.clearValidators();
        this.whatHappenedFormGroup.controls.missingDescription.clearValidators();
        this.whatHappenedFormGroup.controls.missingLocations.clearValidators();
        this.whatHappenedFormGroup.controls.missingOther.clearValidators();
        this.whatHappenedFormGroup.controls.lateCare.setValidators([Validators.required]);

        this.whatHappenedFormGroup.controls.lateCare.valueChanges.subscribe(reVal => {
          if (reVal == 0 && this.valController == 3) {
            //Reship option was selected
            this.whatHappenedFormGroup.controls.lateReship.setValidators([Validators.required]);
            this.whatHappenedFormGroup.controls.lateRefund.clearValidators();
          } else if (reVal == 1 && this.valController == 3) {
            //Refund option was selected
            this.whatHappenedFormGroup.controls.lateRefund.setValidators([Validators.required]);
            this.whatHappenedFormGroup.controls.lateReship.clearValidators();
          } else {
            this.whatHappenedFormGroup.controls.lateReship.clearValidators();
            this.whatHappenedFormGroup.controls.lateRefund.clearValidators();
          }
          this.whatHappenedFormGroup.controls.lateReship.updateValueAndValidity();
          this.whatHappenedFormGroup.controls.lateRefund.updateValueAndValidity();
        });

      } else {
      }
      //Update all controls at the end (Except for the second tier optionals, those will be updated at the end of their own subscribe)
      this.whatHappenedFormGroup.controls.lossReship.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.lossRefund.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.lossCare.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.damageDescription.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.damageDate.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.damageRepairable.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.damageLocations.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.missingDescription.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.missingDate.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.missingLocations.updateValueAndValidity();
      this.whatHappenedFormGroup.controls.lateCare.updateValueAndValidity();
    });

    this.startFormGroup.controls.shipDate.valueChanges.subscribe(val => {
      var selectedDate = new Date(val).setUTCHours(0, 0, 0, 0);
      //Check if the date entered is between the effective & expiration date of the policy.
      if ((selectedDate.valueOf() <= this.persistExpirationDate.setUTCHours(0, 0, 0, 0)).valueOf() && (selectedDate.valueOf() >= this.persistEffectiveDate.setUTCHours(0, 0, 0, 0)).valueOf()) {
        this.startFormGroup.controls['shipDate'].setErrors(null);
        this.startFormGroup.controls.shipDate.markAsTouched();
        this.shipDateError = false;
      } else if (val) {
        //If it is not between the valid dates, AND the value entered is actually a value (not undefined or null etc), then set the input to error.
        this.startFormGroup.controls['shipDate'].setErrors({ 'invalid': true });
        this.startFormGroup.controls.shipDate.markAsTouched();
        this.shipDateError = true;
      }
    });

    //Search validators for the metadata typelists
    this.startFormGroup.controls['carrier'].valueChanges.subscribe(val => {
      this.queryCarrier = val;
      if (this.startFormGroup.get('carrier').value != "UPS" && this.startFormGroup.controls.carrier.value != "FedEx") {
        if (this.late) {
          this.late = false;
          this.whatHappenedFormGroup.controls.lateCare.clearValidators();
          this.whatHappenedFormGroup.controls.lateRefund.clearValidators();
          this.whatHappenedFormGroup.controls.lateReship.clearValidators();
          this.whatHappenedFormGroup.controls.reason.setValue(null);
        }
      }
      Object.keys(this.carrierList).forEach(key => {
        if ((this.startFormGroup.get('carrier').value) && this.carrierList[key].toLowerCase() == this.startFormGroup.get('carrier').value.toLowerCase()) {
          this.carrierKey = key;
        }
      });
      for (let key in this.carrierList) {
        if ((val) && val.toLowerCase() == this.carrierList[key].toLowerCase()) {
          if (val !== this.carrierList[key]) {
            this.startFormGroup.controls['carrier'].setValue(this.carrierList[key]);
          }
          this.startFormGroup.controls['carrier'].setErrors(null);
          break;
        } else {
          this.startFormGroup.controls['carrier'].setErrors({ 'invalid': true });
        }
      }
    });

    this.contactInfoFormGroup.controls.contact.get('emailAddress').valueChanges.subscribe(val => {
      var atIndex = val.indexOf('@');
      if (atIndex == -1) {
      } else {
        //Check if there is a period after the @
        var dotIndex = val.substring(atIndex).indexOf('.');
        if (dotIndex == -1) {
          this.contactInfoFormGroup.controls.contact.get('emailAddress').setErrors({ 'invalid': true });
        }
      }
    });

    //Edge has a restriction on phone number - it cannot start with "1"
    // this.contactInfoFormGroup.controls.contact.get('phoneNumber').valueChanges.subscribe(
    //   val => {
    //     if (val.startsWith("1")) {
    //       this.contactInfoFormGroup.controls.contact.get('phoneNumber').setErrors({ 'invalid': true });
    //     }
    //   }
    // );

    this.monitorValueChanged();
    this.getClaimSummary();
  }  //End of constructor


  checkWalletStatus(){
    this.dwDetails = JSON.parse(sessionStorage.getItem('dwDetails'));
    if(this.dwDetails?.walletID && this.dwDetails?.tcAcceptance == '1'){ //user has a wallet and has accepted T&C
      this.isWalletUser = true;
    } else if (this.dwDetails?.walletID && this.dwDetails?.tcAcceptance == '0'){ //user has a wallet and has not accepted T&C
      this.isWalletUser = false; // For now, if the user has a wallet and hasn't accepted T&Cs, we let them go thru old DPAT flow. In June release, they will not have this option and must use wallet as payee
    } else if (this.dwDetails == null) { //user does not have a wallet
      this.isWalletUser = false;
    }
  }

  goToWalletTab() {
    this.router.navigate(['/billing/mywallet']);
  }

  goToWallet() {
    this.systemError = false;
    let request = {
      action: "accesswallet", //Hardcoded
      navigatingSystem: "onl", //Hardcoded
      navigatingSystemRole: "admin", //Hardcoded (assume admin)
      productSystem: "gw", //Hardcoded (not coming from cbp/dd)
      productSystemIdentifier1: this.policy?.policyNumber,
      productSystemIdentifier1Name: "policyno",
      productSystemIdentifier2: this.policy?.accountNumber,
      productSystemIdentifier2Name: "pcAccountNumber",
      productType: "iscomplete", //Hardcoded (unless we want to change later based on databricks policytype value)
      country: "us", //Harcoded (wallet is US only)
      locale: "en", //Hardcoded (wallet is US only)
      userIdentifier1Name: "createdbyuserid",
      userIdentifier1: this.userDetails?.userId?.toString(),
      displayName: this.userDetails?.contactName, //QUESTION: Use user's name or policy holder's name?
      userEmail: this.userDetails?.emailAddress, //QUESTION: Do we want to use user's info here or policy's email?
      objectUID: this.userDetails?.objectUID,
      callBackURL: "https://online.upscapital.com", //Hardcoded (we don't need this field, remove if possible)
      returnURL: "https://online.upscapital.com" //Hardcoded (we don't need this field, remove if possible)
    };
    this.billingService.DWstartSession(request).subscribe(
      data => {
        if (data?.data?.url) {
          window.open(data?.data?.url, '_blank');
        } else {
          this.systemError = true;
        }
      }, error => {
        this.systemError = true;
      }
    ); 
  }

  navigateToFAQ() {
    window.open("https://www.insureshield.com/us/en/resources/help-and-support/digital-wallet.html", '_blank');
  }

  getClaimSummary() {
    this.policy = this.loginService.getPolicyDetails();
    this.summary.claimStates = ["draft"];
    this.summary.policyNumber = this.policy.policyNumber;
    this.claimService.getAllClaimIds({ ...this.summary }).subscribe(data => {
      this.allClaimIds = data.claimIds;
      if (this.allClaimIds && this.allClaimIds.length > 0) {
        this.whereILeftButton = false;
      }
    },
    );
  }

  //Listen for state changes, if possible, autoselect country when state is chosen
  senderAutoCountry() {
    let state = this.startFormGroup.get('sender').get('state').value;
    if (state > 0) {
      if (state == 2 || state == 9 || state == 26 || state == 34 || state == 40 || state == 42 || state == 43 ||
        state == 44 || state == 49 || state == 52 || state == 54 || state == 58 || state == 68) {
        this.startFormGroup.get('sender').get('country').setValue('CA');
        this.startFormGroup.get('sender').get('country').updateValueAndValidity();
      } else {
        this.startFormGroup.get('sender').get('country').setValue('US');
        this.startFormGroup.get('sender').get('country').updateValueAndValidity();
      }
    }
  }
  recipientAutoCountry() {
    let state = this.startFormGroup.get('recipient').get('state').value;
    if (state > 0) {
      if (state == 2 || state == 9 || state == 26 || state == 34 || state == 40 || state == 42 || state == 43 ||
        state == 44 || state == 49 || state == 52 || state == 54 || state == 58 || state == 68) {
        this.startFormGroup.get('recipient').get('country').setValue('CA');
        this.startFormGroup.get('recipient').get('country').updateValueAndValidity();
      } else {
        this.startFormGroup.get('recipient').get('country').setValue('US');
        this.startFormGroup.get('recipient').get('country').updateValueAndValidity();
      }
    }
  }

  recursiveReset = (obj) => {
    Object.keys(obj).map(key => {
      // Test if it's an Object
      if (obj[key] === Object(obj[key])) {
        this.recursiveReset(obj[key])
        return
      }
      if (obj[key] instanceof Array) obj[key] = []
      else obj[key] = ''
    })
  }

  ngOnInit() {
    let policy = JSON.parse(sessionStorage.getItem('policyDetails'));
    this.taggingService.view();
    this.titleService.setTitle(this.translateService.instant('app.Settitle.ups_capital_report'));

    this.istnt = policy?.tntIndicator?.toLowerCase() == 'yes' ? true : false;

    if (!this.isUsaUser) {
      this.dateAdapter.setLocale('en-GB');
    }

    this.maxDate.setDate(this.maxDate.getDate());
    this.getMetaData();
    this.checkLists();

    // user comes from Transaction History table - billing tab with 1Z number
    let trckno = JSON.parse(localStorage.getItem('fileaclaimtrackno'));
    if(trckno) {
      this.startFormGroup.controls.trackingNumber.setValue(trckno);
      this.claim.shippingDetailsDTO.trackingNumber = trckno;
      localStorage.removeItem('fileaclaimtrackno');
    }

    this.claim = emptyClaim;
    if (this.claim.trackingNumber_Ext.length > 3 || this.claim.shippingDetailsDTO.trackingNumber.length > 3) {
      this.recursiveReset(this.claim);
    }

    this.instantiateEmptyClaimModel();
    if (this.draftClaimNumber === undefined || this.draftClaimNumber === null || this.draftClaimNumber === "" || this.draftClaimNumber.length === 0) {
      this.claim.claimNumber = "tempClaimNumber";
    }

    var reloading = sessionStorage.getItem("reloading");

    if (this.policyService.determinePolicyType(policy) == 'transactional_parcel') {
      this.flex = true;
      this.whatHappenedFormGroup.controls.return.setValidators(Validators.required);
      this.whatHappenedFormGroup.controls.return.updateValueAndValidity();
    } else if (this.policyService.determinePolicyType(policy) == 'traditional_cargo' || this.policyService.determinePolicyType(policy) == 'transactional_cargo') {
      this.cargo = true;
      this.whatHappenedFormGroup.controls.return.clearValidators();
      this.whatHappenedFormGroup.controls.return.updateValueAndValidity();
    } else {
      this.whatHappenedFormGroup.controls.return.clearValidators();
      this.whatHappenedFormGroup.controls.return.updateValueAndValidity();
    }

    //Code for Autofill Preferences
    if (this.userDetails && !this.isDDUser) {
      try {
        this.transportation_carrier_Ext = this.userDetails.userPreference.carrier;
        this.claim.shippingDetailsDTO.senderDetails.companyName = this.userDetails.userPreference.preferenceName;
        this.claim.shippingDetailsDTO.senderDetails.city = this.userDetails.userPreference.address.city;
        // this.productCategory = this.userDetails.userPreference.productCategory;
        this.whatHappenedFormGroup.controls.category.setValue(this.userDetails.userPreference.productCategory);
        this.countryCodeSender = this.userDetails.userPreference.address.country;
        //Filling state
        var stateCode = this.userDetails.userPreference.address.state;
        var stateSender = this.stateList.find(x => x.state == stateCode);
        if (stateSender) {
          this.stateCodeSender = stateSender.id;
        }
      }
      catch (e) { }
    }

    // if (this.draftClaimNumber !== undefined) {
    //   this.initialClaimCreation = false;
    //   this.getDraftData(this.draftClaimNumber);
    // }

    if (this.THClaimInfo) { //if it is a TH claim, populate info
      this.populateTHInfo();
    }


    this.setActiveStep();
    window.onpopstate = (event) => {
      if (event.state && event.state.step !== undefined) {
        this.currentStep = event.state.step;
      }
    }

    this.isPageLoad = true;

    this.checkWalletStatus();


  } //End of ngOnInit

  getStarted() {
    sessionStorage.removeItem('draftClaimNumber');
    sessionStorage.removeItem('transactionHistoryTempClaim');
    this.draftClaimNumber = '';
    if (!this.hvcmFlow) {
      this.claim = emptyClaim;
      this.recursiveReset(this.claim);
    }

    let walletUser = this.userService.isWalletUser();
    let acceptedTCs = this.userService.isWalletTCsAccepted();
    if (this.hvcmFlow) { // HVCM is still undergoing logic changes to accomodate wallet flow, for now, always use DPAT to get a payee.
      this.redirectToDPAT();
    } else if (walletUser && acceptedTCs) { // Flow #1: Wallet + TCs: No check fee pop-up, let them begin claim immediately with walletID as payeeId.
      this.startClaimWithWalletAsPayee();
    } else if (this.isUsaUser) { // Flow #2-5: Must verify latest wallet details first, at which point navigate user to DPAT or inform them of check fee logic
      this.checkDWGetSummary();
    } else { // CA/EU will NEVER be charged a check fee for the time being.
      this.redirectToDPAT();
    }
  }

  checkDWGetSummary() {
    this.systemError = false;
    let request = {
      productSourceSystem: "gw",
      sourceSystemIdentifierName: "PolicyNumber",
      sourceSystemIdentifier: this.policy?.policyNumber,
      country: "US",
      roleType: "string",
      productType: "string",
    };

    this.billingService.DWgetSummary(request).subscribe(
      data => {
        this.navigateToCorrectFlow();
      }, error => {
        // 7/20 Release
        // this.systemError = true;
        // 6/27 Release - Do not add error handling or prevent the user from continuing here, the API is failing too often
        this.navigateToCorrectFlow();
      }
    );
  }

  navigateToCorrectFlow() {
    let walletUser = this.userService.isWalletUser();
    let acceptedTCs = this.userService.isWalletTCsAccepted();
    let checkFeeAmount = this.userService.getCheckFeeAmount();
    if (walletUser && acceptedTCs) { // Flow #1: Wallet + TCs: No check fee pop-up, let them begin claim immediately with walletID as payeeId.
      this.startClaimWithWalletAsPayee();
    } else if (walletUser && !acceptedTCs) {
      // Flow #2: Wallet + no T&Cs + check fee: Display pop-up with two options: proceed to DPAT w/check and be charged a fee, or enroll in wallet for free ach options
      // Flow #3: Wallet + no T&Cs + no check fee: Display pop-up with two options: proceed to DPAT with only check payees showing (no mention of fee needed), or enroll in wallet for ach options
      this.openCheckFeePopup();
    } else if (!walletUser && checkFeeAmount > 0) {
      // Flow #4: No wallet + check fee: Display pop-up with one option: proceed to DPAT with both check and ach payees, acknowledging that if a check fee is chosen, a fee will be charged.
      this.openCheckFeePopup();
    } else {
      // Flow #5: No wallet + no check fee: No change to functionality, let them begin claim.
      this.redirectToDPAT();
    }
  }

  startClaimWithWalletAsPayee() {
    sessionStorage.setItem('claimFileStatus', 'wallet');
    this.router.navigate(['/claims/new']).then(() => {
      window.location.reload();
    });
  }

  redirectToDPAT() {
    sessionStorage.removeItem('isHvcm');
    sessionStorage.removeItem('policyNumber');
    sessionStorage.removeItem('hvcmTabOpened');
    this.systemError = false;
    let checkCountry = sessionStorage.getItem('locale').slice(3, 5);
    let paymentMode = sessionStorage.getItem('paymentMode'); // If paymentMode = 'check', DPAT will only show the user their check payees on the DPAT screens
    sessionStorage.removeItem('paymentMode');
    let request = {
      action: "Get Started",
      paymentDirection: "Claims",
      country: checkCountry ? checkCountry : "US",
      paymentMode: paymentMode ? "check" : "",
      policyIdentifier: this.policy.policyNumber ? this.policy.policyNumber : "",
      email: this.isGuestUser ? sessionStorage.getItem('guestUserEmail') : this.userDetails.emailAddress,
      callBackURL: this.hvcmFlow ? `${ENV.baseUrl.web}claims/MultiClaim` : `${ENV.baseUrl.web}claims/new`,
      scenario: "US",
      policyDetails: this.hvcmFlow ? "hvcm" : "insured",
      createdByUserId: this.userDetails.userId ? this.userDetails.userId : "",
      guestUserIndicator: this.isGuestUser ? "Y" : "N",
      locale: sessionStorage.getItem('locale'),
      tupssFlow: "",
      policyData: {
        insured: this.loginService.getPolicyDetails().insured
      },
      cudAccess: (this.userPermission.isClaimPayee || this.isGuestUser) ? true : false
    }
    if (this.hvcmFlow) {
      sessionStorage.setItem('isHvcm', JSON.stringify('true'));
      sessionStorage.setItem('policyNumber', this.userDetails.policyNumber);
    }
    this.claimService.getRedirect(request).subscribe(
      data => {
        window.location.replace(data.data.url); //dpat
      }, error => {
        this.systemError = true;
      }
    );
  }

  openCheckFeePopup() {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    // All logic to determine what content to display in the pop-up is located within the pop-up component ts file
    let dialogRef = this.dialog.open(CheckFeePopupComponent, config);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'continueToDPAT') {
          this.redirectToDPAT();
        } else if (data == 'error') {
          this.systemError = true;
        }
      }
    );
  }

  step1GoBack() {
    if (this.dwDetails?.walletID && this.dwDetails?.tcAcceptance == '1') { //if they have a wallet + accepted T&Cs and want to go back, take them directly back to the home page instead of DPAT step
      sessionStorage.removeItem('setCurrentStep');
      sessionStorage.removeItem('claimFileStatus');
      this.currentStep = 0;
      if (sessionStorage.getItem('draftClaimNumber')) { //if they came from draft list, navigate them back there
        sessionStorage.removeItem('draftClaimNumber');
        this.router.navigate(['/claims/draft-list']);
      } else if (sessionStorage.getItem('transactionHistoryTempClaim')) { // if they came from the TH table, navigate them back there
        sessionStorage.removeItem('transactionHistoryTempClaim');
        this.router.navigate(['/billing/transaction-history']);
      } else {
        this.router.navigate(['/claims/new'], { queryParams: { status: 'back' } });
      }
    } else { //Old functionality (for users that don't have wallet + accepted T&Cs)
      this.redirectToDPAT();
    }
  }

  populateTHInfo() {
    try {
      this.claim.trackingNumber_Ext = this.THClaimInfo.trackingNumber;
      this.claim.shippingDetailsDTO.trackingNumber = this.THClaimInfo.trackingNumber;
      this.startFormGroup.controls.trackingNumber.setValue(this.THClaimInfo.trackingNumber);
      this.startFormGroup.controls.trackingNumber.markAsTouched();
      var shippingDate = this.THClaimInfo.shipDate.substring(0, this.THClaimInfo.shipDate.length - 1);
      this.claim.shippingDetailsDTO.shipDate = shippingDate + "Z";
      this.dataService.updateCommonClaimObject(this.claim);
      this.checkForDuplicateTrackingNumbers(); // after this call, it will call get1ztrackingdata
    } catch (e) {} //if for some reason an error occurs, we can just let the user fill in the info manually
  }

  getDraftData(claimNumber: string) {
    this.claimService.getClaimDetails(claimNumber,
      this.userService.getUserInfo().policyNumber).subscribe(
        data => {
          try {
            data.claimContacts.forEach((element) => {
              if (element.contactRoles[0] === 'transportation_carrier_Ext') {
                this.transportation_carrier_Ext = element.contactDTO.displayName;
              }
            });
          }
          catch (e) {
            this.transportation_carrier_Ext = "";
          }

          try {

            this.lossDescription = JSON.parse(data.description);
            if (this.lossDescription) {
              this.shipmentRole = this.lossDescription.shipmentRole;
              var lossReason = this.lossDescription.reason;
              if (this.lossDescription.contactType !== undefined) {
                try {
                  this.contactType = this.lossDescription.contactType;
                  if (this.contactType === "0") {
                    this.isPolicyHolder = true;
                    this.policyHolder = true;
                    this.newContactType = "0";
                    sessionStorage.setItem("contactTypeSelected", "0");
                  }
                  if (this.contactType === "1") {
                    this.isRecipient = true;
                    this.recipient = true;
                    this.newContactType = "1";
                    sessionStorage.setItem("contactTypeSelected", "1");
                  }
                  if (this.contactType === "2") {
                    this.custom = true;
                    this.newContactType = "2";

                    try {
                      let infoContact = this.lossDescription.contactInfo;
                      if (infoContact.length > 1) {
                        let contactInfo = JSON.parse(infoContact);
                        sessionStorage.setItem("contactInfo", JSON.stringify(contactInfo));
                        sessionStorage.setItem("contactTypeSelected", contactInfo.contactType);
                      }
                    }
                    catch {

                    }
                  }
                }
                catch (e) { }
              }

              if (lossReason) {
                this.claimReason = lossReason;
                // Reason: Damage
                if (this.claimReason === "1") {
                  this.damage = true;
                  this.damageDraftDescription = this.lossDescription.damageDescription;
                  this.damageDraftDate = this.lossDescription.damageDate.substring(0, this.lossDescription.damageDate.length - 1);
                  this.damageDraftDate = this.damageDraftDate + "Z";
                  this.repairable = this.lossDescription.repairable;
                }

                //Reason: Missing Content
                if (this.claimReason === "2") {
                  this.missing = true;
                  this.missingDraftDescription = this.lossDescription.missingDescription;
                  this.missingDraftDate = this.lossDescription.missingDate.substring(0, this.lossDescription.missingDate.length - 1);
                  this.missingDraftDate = this.missingDraftDate + "Z";
                }

                //Reason: Loss Or Late Delivery
                if (this.claimReason === "0") {
                  this.loss = true;
                  this.care = this.lossDescription.care;
                  if (this.care === "0") {
                    this.lossCareData = "0";
                    this.lossReshipDescription = this.lossDescription.careDetails;
                  }
                  else {
                    this.lossCareData = "1";
                    this.lossRefundDescription = this.lossDescription.careDetails;
                  }

                }

                if (this.claimReason === "3") {
                  this.late = true;
                  this.care = this.lossDescription.care;
                  if (this.care === "0") {
                    this.lateCareData = "0";
                    this.lateReshipDescription = this.lossDescription.careDetails;
                  }
                  else {
                    this.lateCareData = "1";
                    this.lateRefundDescription = this.lossDescription.careDetails;
                  }
                }

                if (this.lossDescription.packageLocation !== undefined) {
                  this.packageLocation = this.lossDescription.packageLocation;
                  if (this.packageLocation === "3") {
                    this.other = true;
                    this.packageLocationDetails = this.lossDescription.packageLocationDetails;
                  }
                }

                if (this.lossDescription.return !== undefined && this.lossDescription.return === "yes") {
                  this.isReturnShipment = "yes";
                  if (this.lossDescription.originalTrackingNumber !== undefined) {
                    this.originalTrackNumber = this.lossDescription.originalTrackingNumber;
                  }
                }
                else {
                  this.isReturnShipment = "no";
                  this.originalTrackNumber = "";
                }
              }
            }
          }
          catch (e) {
            this.lossDescription = null;
          }

          this.claim.claimNumber = claimNumber;
          this.claim.payeeId = data.payeeID;
          sessionStorage.setItem("selectedPayee", data.payeeID);
          this.getPayee(data.payeeID);

          this.referenceNumber = data.shippingDetailsDTO.referenceNumber;

          this.claim.trackingNumber_Ext = data.shippingDetailsDTO.trackingNumber;
          this.claim.shippingDetailsDTO.trackingNumber = data.shippingDetailsDTO.trackingNumber;
          this.claim.shippingDetailsDTO.referenceNumber = data.shippingDetailsDTO.referenceNumber;

          this.claim.shippingDetailsDTO.verifiedShippingCharge = data.shippingDetailsDTO.verifiedShippingCharge;
          this.claim.shippingDetailsDTO.merchandiseValue = data.shippingDetailsDTO.merchandiseValue;
          this.claim.shippingDetailsDTO.invoiceAmount = data.shippingDetailsDTO.invoiceAmount;
          this.claim.shippingDetailsDTO.merchandiseDescription = data.shippingDetailsDTO.merchandiseDescription;

          // Sender Details
          this.claim.shippingDetailsDTO.senderDetails.companyName = data.shippingDetailsDTO.senderDetails.companyName;
          this.claim.shippingDetailsDTO.senderDetails.city = data.shippingDetailsDTO.senderDetails.city;
          this.claim.shippingDetailsDTO.senderDetails.zipCode = data.shippingDetailsDTO.senderDetails.zipCode;

          //Sender State
          var senderStateTypeCode = data.shippingDetailsDTO.senderDetails.state;
          if (senderStateTypeCode) {
            var stateSender = this.stateList.find(x => x.state == senderStateTypeCode);
            if (stateSender) {
              this.stateCodeSender = stateSender.id;
            }
          }

          //Sender Country
          var senderCountryTypeCode = data.shippingDetailsDTO.senderDetails.country;
          if (senderCountryTypeCode) {
            var countrySender = this.countryList.find(x => x.key == senderCountryTypeCode);
            if (countrySender) {
              this.countryCodeSender = countrySender.key;
            }
          }

          // Receiver Details
          this.claim.shippingDetailsDTO.receiverDetails.companyName = data.shippingDetailsDTO.receiverDetails.companyName;
          this.claim.shippingDetailsDTO.receiverDetails.city = data.shippingDetailsDTO.receiverDetails.city;
          this.claim.shippingDetailsDTO.receiverDetails.zipCode = data.shippingDetailsDTO.receiverDetails.zipCode;

          //Receiver State
          var receiverStateTypeCode = data.shippingDetailsDTO.receiverDetails.state;
          if (receiverStateTypeCode) {
            var stateSender = this.stateList.find(x => x.state == receiverStateTypeCode);
            if (stateSender) {
              this.stateCodeReceiver = stateSender.id;
            }
          }

          //Receiver Country
          var receiverCountryTypeCode = data.shippingDetailsDTO.receiverDetails.country;
          if (receiverCountryTypeCode) {
            var countryReceiver = this.countryList.find(x => x.key == receiverCountryTypeCode);
            if (countryReceiver) {
              this.countryCodeReceiver = countryReceiver.key;
            }
          }

          this.persistClaimNumber = claimNumber;
          this.persistPublicId = data.publicID;
          this.claim.lossType = data.lossType;
          this.claim.lossCause = data.lossCause;
          this.claim.publicID = data.publicID;
          this.claim.shippingDetailsDTO.shipmentConfirmation = "";
          this.claim.shippingDetailsDTO.insuredValue = "";
          this.claim.additionalNotes = data.customerNotes;

          try {
            if (data.productCategory_Ext) {
              // this.productCategory = data.productCategory_Ext.toLowerCase();
              this.whatHappenedFormGroup.controls.category.setValue(data.productCategory_Ext.toLowerCase());
            }

            if (data.shippingDetailsDTO.shipDate) {
              var shippingDate = data.shippingDetailsDTO.shipDate.substring(0, data.shippingDetailsDTO.shipDate.length - 1);
              this.claim.shippingDetailsDTO.shipDate = shippingDate + "Z";
            }

            if (data.quantity !== undefined) {
              if (data.quantity !== null) {
                this.quantity = Number.parseFloat(data.quantity);
              }
            }

            this.dataService.updateCommonClaimObject(this.claim);
            this.get1ZTrackingData(this.claim.trackingNumber_Ext);

          }
          catch (e) { }
        });
  }

  getPayee(payeeID: string) {
    let request;
    if (this.userService.isGuestUser()) {
      request = {
        "policyNumber": this.userService.getUserInfo().policyNumber,
        "emailAddress": sessionStorage.getItem('guestUserEmail') //guest email goes here
      }
    } else {
      request = {
        "policyNumber": this.userService.getUserInfo().policyNumber
      }
    }
    this.payeeService.getPayee(request).subscribe(
      data => {
        if (data.payeeInfo.length > 0) {
          var payeeInfo = data.payeeInfo.find(x => x.payeeID == payeeID);
          if (payeeInfo) {
            this.persistPayeeInfo = payeeInfo;
            this.payeeService.setClaimsPayeeInfo(this.persistPayeeInfo, true);
            this.payeeSelectionValid = true;
          }
        }
      }, error => { }
    );
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  //For some reason, categoryList and stateList would randomly return empty, OR with the correct data,
  //So, I am making a loop to keep checking until all lists are populated with the correct data, and only
  //then will I attempt to autofill the fields.
  //Will terminate itself after 10 tries.
  checkLists() {
    if (this.loopCounter > 10) {
      return;
    } else {
      if (this.categoryList.length === 0) { //Lists not yet populated
        setTimeout(() => this.checkLists(), 500);
      } else {  //Lists are populated, continue as normal
        //We need to find the OBJECT reference for the key that the API Returned
        if (this.userDetails.userPreference.productCategory != "" && this.userDetails.userPreference.productCategory != undefined && this.userDetails.userPreference.productCategory != null) {
          let cat = this.categoryList.find(x => x.key === this.userDetails.userPreference.productCategory);
          this.whatHappenedFormGroup.get('category').setValue(cat); //This value must be an object, not a string
        }
        if (this.userDetails.userPreference.address.state != "" && this.userDetails.userPreference.address.state != undefined && this.userDetails.userPreference.address.state != null && !this.isDDUser) {
          let stat = this.stateList.find(x => x.state === this.userDetails.userPreference.address.state);
          this.startFormGroup.get('sender').get('state').setValue(stat.id); //Value must be the ID of the state
        }
      }
    }
  }

  //Used to filter categories based on what user is typing.
  //Works as a "contains" instead of "startsWith" due to !== -1
  filter(name: string): ICategory[] {
    return this.categoryList.filter(option =>
      option.value.toLowerCase().indexOf(name.toLowerCase()) !== -1);
  }

  //Handles how the data gets displayed vs how the value is represented in the database.
  displayCategories(obj?: ICategory): string | undefined {
    return obj ? obj.value : undefined;
  }

  //Custom validator to require the user to select from the dropdown
  private requireMatch(control: UntypedFormControl): ValidationErrors | null {
    let selection: any = control.value;
    if (typeof selection === 'string') {
      return { requireMatch: true }
    }
    return null;
  }

  //Used in Stepper component - Use case: User selects green number to go backwards
  setCurrentStepManually(order: number) {
    // In the redesign, we show the stepper even on the success page, so this if condition is to prevent user from 'going backwards' after submitting the claim.
    if (this.currentStep != 6) {
      if (this.currentStep >= order) {
        if (order > 1) {
          this.currentStep = order;
          this.setActiveStep();
        }
        else {
          if (sessionStorage.getItem('claimFileStatus') == 'wallet') {
            // Do nothing - they shouldn't be allowed to go to DPAT if it's a wallet claim
          } else {
            this.redirectToDPAT();
          }
        }
      }
    }
  }

  setCurrentStepMobile(order: number) {

    if (order > 1 && this.currentStep <= this.steps.length) {
      this.currentStep = order;
      this.currentStepText = this.steps.find(x => x.order === order).label;
      this.setActiveStep();
    }
  }

  setStep(event, stepToSetTo: number) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.currentStep = stepToSetTo;
    this.setActiveStep();
  }

  ngOnDestroy() {

    sessionStorage.removeItem("selectedPayee");
    sessionStorage.removeItem("draftClaimNumber");
    sessionStorage.removeItem("transactionHistoryTempClaim");
    sessionStorage.removeItem("contactTypeSelected");
    sessionStorage.removeItem("contactInfo");
    sessionStorage.removeItem("setCurrentStep");
    sessionStorage.removeItem('claimFileStatus');
    this.utilityService.clearSubscriptions([
      this.saveClaimSubscription
    ]);
    if (this.payeeService.isInDesktopView()) {
      this.payeeService.setClaimsPayeeInfo(null, true);
    } else {
      this.payeeService.setClaimsPayeeInfo(null, false);
    }


    // this.refreshComponent();
    //window.location.reload();
  }

  refreshComponent() {
    this.router.navigate([this.router.url])
  }

  //If Merchandise or Shipping inputs are updated, total amount must be too
  private monitorValueChanged() {
    this.whatHappenedFormGroup.get('merchandise').valueChanges.subscribe(
      (value) => {
        if (!value) {
          return;
        }
        if (isNaN(value)) {
          this.whatHappenedFormGroup.get('amount').setValue(0);
          return;
        }
        this.whatHappenedFormGroup.get('amount').setValue((+this.claimService.formatAmount(this.whatHappenedFormGroup.get('merchandise').value, false) + +this.claimService.formatAmount(this.whatHappenedFormGroup.get('shipping').value, false)).toFixed(2));
      }
    );

    this.whatHappenedFormGroup.get('shipping').valueChanges.subscribe(
      (value) => {
        if (!value) {
          return;
        }
        if (isNaN(value)) {
          this.whatHappenedFormGroup.get('amount').setValue(0);
          return;
        }
        this.whatHappenedFormGroup.get('amount').setValue((+this.claimService.formatAmount(this.whatHappenedFormGroup.get('shipping').value, false) + +this.claimService.formatAmount(this.whatHappenedFormGroup.get('merchandise').value, false)).toFixed(2));
      }
    );
  }

  onSubmit(event, value) {
    event.preventDefault();
  }

  resetStep(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.currentStep = 0;
  }

  previousStep(event?) {
    this.setEditableContact();
    this.systemError = false;
    this.errorNotification = false;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.currentStep == 3) {
      this.clearDocumentErrors();
    }
    if (this.currentStep == 2) {
      this.currentStep = 1; //Pressing back on step 2 needs to return to step 0 now
    }
    this.currentStep--;
    this.taggingService.link({
      link_name: 'back_button'
    });
    this.setActiveStep();
  }

  nextStep(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.currentStep++;
    this.taggingService.link({
      link_name: 'continue_button'
    });
    this.tagging();
    this.setActiveStep();
  }

  private setActiveStep() {
    this.steps.forEach(
      (step) => {
        step.isActive = step.order === this.currentStep;
      },
    );
    window.scrollTo(0, 0);
    if (this.currentStep == 0) {
      window.history.replaceState({ step: this.currentStep }, '');
    }
    else {
      window.history.pushState({ step: this.currentStep }, '');
    }

    if (this.currentStep > 0) {
      var step = this.steps.find(x => x.order == this.currentStep && x.isActive == true);
      if (step !== undefined) {
        this.currentStepText = step.label;
      }
    }
  }

  openHVCM(payeeID?: any, policyNumber?: any) {
    this.userService.openHVCM(
      {
        policyNo: policyNumber,
        payeeID: payeeID,
        username: this.userService.userInfo.firstName,
        firstName: this.userService.userInfo.firstName,
        lastName: this.userService.userInfo.lastName,
        emailAddress: this.userService.userInfo.emailAddress,
        contactPhone: this.userService.userInfo.contactPhone
      }
    )
  }

  clearDocumentErrors() {
    this.fileSizeLimit = false;
    this.tooManyDocs = false;
    this.fileNameError = false;
    this.fileTypeError = false;
    this.systemError = false;
  }

  uploadDocument(files?: FileList, event?) {
    let documentModel: IDocumentDisplay = { file: files.item(0) }

    if (files.item(0)) {
      this.clearDocumentErrors();

      this.fileToUpload = files.item(0);

      if (this.fileToUpload.name.length > 80) {
        this.fileNameError = true;
        return;
      }
      /* let fileNameParts = this.fileToUpload.name.split('.');
      if (fileNameParts.length <= 1 || ALLOWED_DOC_TYPES.indexOf(fileNameParts[fileNameParts.length - 1].toLowerCase()) < 0) {
        this.fileTypeError = true;
        return;
      } */
      if (this.fileToUpload.size > 7340032) {
        this.fileSizeLimit = true;
      } else {
        this.fileSizeLimit = false;

        if (this.fileList.length == 10) {
          this.tooManyDocs = true;
        } else {
          this.tooManyDocs = false;

          //All validations have passed
          this.claimService.generateUploadToken().subscribe(
            data => {
              this.docRequest.sessionID = data;
            }, error => {
              this.systemError = true;
            }, () => {
              this.docRequest.claimNumber = this.persistClaimNumber;
              this.docRequest.name = this.fileToUpload.name;
              this.docRequest.docUID = "001";
              this.docRequest.documentType = "letter_received";
              this.docRequest.securityType = "unrestricted";
              this.docRequest.status = "approved";
              this.docRequest.postFnolDoc = "false";

              if (this.fileToUpload.name.endsWith('.msg')) {
                this.docRequest.mimeType = "application/vnd.ms-outlook"
              } else {
                this.docRequest.mimeType = this.fileToUpload.type;
              }

              let formData: FormData = new FormData();
              formData.append('documentUploadRequest', JSON.stringify(this.docRequest));
              formData.append('file', this.fileToUpload, this.fileToUpload.name);

              //Ready to upload document
              this.claimService.uploadDocument(formData).subscribe(
                data => {
                  if (data.publicID) {
                    documentModel.publicID = data.publicID;
                    this.fileList.push(documentModel);
                    if (event) {
                      event.target.value = "";
                    }
                  }
                  else {
                    this.systemError = true;
                  }
                }, error => {
                  this.systemError = true;
                }
              );
            }
          );
        }
      }
    }
  }

  //When a user tries to remove a document, we show a popup
  openDialog(file: IDocumentDisplay): void {
    let dialogRef = this.dialog.open(DialogContentComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result == "confirm") {
        this.removeDocument(file);
      }
    });
  }

  //If a user tries to cancel the claim, ask them if they are sure
  openCancelDialog(event?): void {
    this.taggingService.link({
      link_name: 'cancel_button'
    });
    let dialogRef = this.dialog.open(ConfirmCancelComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result == "confirm") {
        sessionStorage.removeItem('draftClaimNumber');
        sessionStorage.removeItem('transactionHistoryTempClaim');
        sessionStorage.removeItem('selectedPayee');
        sessionStorage.removeItem('setCurrentStep');
        sessionStorage.removeItem('claimFileStatus');
        this.router.navigate(['/claims/new'], { queryParams: { status: 'cancel' } });
        this.currentStep = 0;
      }
    });
  }

  removeDocument(file: IDocumentDisplay) {
    this.claimService.removeDocument(file).subscribe(
      data => {
        this.fileList.splice(this.fileList.indexOf(file), 1);
      }, error => { }
    );
  }

  //Switch through the different flows
  toggleFlow(type: string) {
    if (type == 'loss' || type == 'damage' || type == 'missing' || type == 'late') {
      this.loss = false;
      this.damage = false;
      this.missing = false;
      this.late = false;
    }

    if (this.newContactType === "0") {
      this.policyHolder = true;
    }
    else
      this.policyHolder = false;

    if (this.newContactType === "1") {
      this.recipient = true;
    }
    else
      this.recipient = false;

    if (this.newContactType === "2") {
      this.custom = true;
    }
    else
      this.custom = false;

    if (type == "loss") {
      this.loss = true;
      this.setContactLossDescription();
    } else if (type == "damage") {
      this.damage = true;
      this.setContactLossDescription();
    } else if (type == "missing") {
      this.missing = true;
      this.setContactLossDescription();
    } else if (type == "late") {
      this.late = true;
      this.setContactLossDescription();
    } else if (type == "policyHolder") {
      this.policyHolder = true;
      this.recipient = false;
      this.custom = false;
      this.contactType = "0";
      this.newContactType = "0";
      this.setContactLossDescription();
    } else if (type == "recipient") {
      this.policyHolder = false;
      this.recipient = true;
      this.custom = false;
      this.contactType = "1";
      this.newContactType = "1";
      this.setContactLossDescription();
    } else if (type == "custom") {
      this.policyHolder = false;
      this.recipient = false;
      this.custom = true;
      this.contactType = "2";
      this.newContactType = "2";
      this.setContactLossDescription();
    }
  }

  setContactLossDescription() {

    var lossDescription = JSON.parse(this.claim.lossDescription);
    lossDescription = {
      ...lossDescription,
      contactType: this.newContactType
    }
    this.claim.lossDescription = JSON.stringify(lossDescription);
  }

  //Handles the "Other" text field in damage and missing flows
  toggleOther(type: boolean) {
    this.other = false;
    if (type) {
      this.other = true;
    }
  }

  //Check for available tracking number information
  checkForDuplicateTrackingNumbers() {
    this.dataService.updateCommonClaimObject(this.claim)

    //Don't call any API's if the field is not valid
    if (!this.startFormGroup.get('trackingNumber').valid) {
      return;
    }
    let trackingNumber = this.trackingNumber.value;
    this.continuing = true;
    this.currentlyCheckingDupeTrackingNumbers = true;

    //Check if the tracking number has been used to file a claim previously
    this.claimService.getShipmentDetails(trackingNumber).subscribe(
      data => {
        this.continuing = false;
        this.get1ZTrackingData();

        this.claim.shippingDetailsDTO.shipmentConfirmation = data.shipmentConfirmation ? data.shipmentConfirmation : "";
        this.claim.shippingDetailsDTO.insuredValue = data.InsuredValue ? data.InsuredValue.split(" ")[0] : "";

        this.currentlyCheckingDupeTrackingNumbers = false;
        if (data.TrackingNumberDetails.IsDuplicateTrackingNumber) {
          this.startFormGroup.get('trackingNumber').setErrors({ 'inuse': true });
        } else {
          if (this.startFormGroup.get('trackingNumber').hasError('pattern')) {
            this.startFormGroup.get('trackingNumber').setErrors(null);
            this.startFormGroup.get('trackingNumber').setErrors({ 'pattern': true });
          } else if (this.startFormGroup.get('trackingNumber').hasError('required')) {
            this.startFormGroup.get('trackingNumber').setErrors(null);
            this.startFormGroup.get('trackingNumber').setErrors({ 'required': true });
          } else {
            this.startFormGroup.get('trackingNumber').setErrors(null);
          }
        }
      }, error => {
        this.continuing = false;
        this.get1ZTrackingData();
        this.currentlyCheckingDupeTrackingNumbers = false;
      }
    );
  }

  get1ZTrackingData(trackingNumber?) {
    this.continuing = true;
    this.dataService.gatherTrack3ShipmentDetails(trackingNumber ? trackingNumber : this.trackingNumber.value);
    this.dataService.getTrack3ShipmentDetails().subscribe(
      data => {
        if (data == null || (data.shipmentPackage?.shipmentIdentificationNumber == "" && data.shipDate == "" && data.shipmentType?.code == "")) {
          this.continuing = false;
          return;
        } else if (data.noInfoReturned) {
          this.continuing = false;
          return;
        } else {
          this.continuing = false;
          this.track3ShipmentDetails = data;
          this.populateStartFormGroupWithTrack3();
          this.dataService.populateClaimInterfaceUsingTrack3Details(this.track3ShipmentDetails);
          this.dataService.formatPayloadBeforeAnyAPIRequest();

        }
      }, error => { }
    );
  }

  //Sorts an array alphabetically
  //If needing case insensitive, add .toUpperCase()
  //To use: this.someArray.sort(this.compare);
  compare(a, b) {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  }

  //Loads all MetaData for claims flow
  getMetaData() {
    this.claimService.getMetaData().subscribe(
      data => {
        try {

          this.newStateList = data.metaData.states;
          this.newCountryList = data.metaData.country;

          this.countryList = Object.entries(countries).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.country.${key}`) } });
          this.countryList.sort(this.compare);
          if (this.isCanadaUser) {
            this.currencyList = { 'cad': 'CAD' }
          }
          if (this.isUsaUser) {
            this.currencyList = { 'usd': 'USD' }
          }
          else if (this.isUkUser) {
            this.currencyList = { 'gbp': 'GBP', 'usd': 'USD', 'eur': 'EUR' }
          }
          else if (this.isGermanyUser || this.isFranceUser || this.isItalyUser) {
            this.currencyList = { 'eur': 'EUR', 'gbp': 'GBP', 'usd': 'USD', }
          }
          this.categoryList = Object.entries(productCategory).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.productCategory.${key}`) } }); //translated categories
          if (Object.keys(data.metaData.transportationid_Ext).find(x => x === 'UPS Freight')) { //UPS Freight needs to be removed from the carrier list
            delete data.metaData.transportationid_Ext['UPS Freight'];
          }
          //Load certain carriers based on if the policy is Cargo or Flex
          if (this.flex) {
            this.carrierList = Object.fromEntries(Object.entries(limitedCarriers).map(([key, value]) => [key, this.translateService.instant(`metadata.transportationid_Ext.${key}`)])); //translated carriers
          } else {
            this.carrierList = Object.fromEntries(Object.entries(carriers).map(([key, value]) => [key, this.translateService.instant(`metadata.transportationid_Ext.${key}`)])); //translated carriers
          }
        } catch (e) {
          //Load local metadata
          this.loadLocalMetaData();
        }
      }, error => {
        // Load local metadata
        this.loadLocalMetaData();
      }
    );
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  loadLocalMetaData() {
    this.countryList = Object.entries(countries).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.country.${key}`) } });//translated countries
    this.countryList.sort(this.compare);
    this.currencyList = { 'usd': 'USD', 'cad': 'CAD', 'eur': 'EUR', 'gbp': 'GBP' }
    this.categoryList = Object.entries(productCategory).map(([key, value]) => ({ key, value })).map(({ key, value }) => { return { key, value: this.translateService.instant(`metadata.productCategory.${key}`) } }); //translated categories
    //Load certain carriers based on if the policy is Cargo or Flex
    if (this.flex) {
      this.carrierList = Object.fromEntries(Object.entries(limitedCarriers).map(([key, value]) => [key, this.translateService.instant(`metadata.transportationid_Ext.${key}`)])); //translated carriers
    } else {
      this.carrierList = Object.fromEntries(Object.entries(carriers).map(([key, value]) => [key, this.translateService.instant(`metadata.transportationid_Ext.${key}`)])); //translated carriers
    }
  }


  //Used to manually check all fields & show errors
  validateAllFormFields(formGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  instantiateEmptyClaimModel() {
    //Hold policy information in policy variable to use in future
    this.policy = this.loginService.getPolicyDetails();
    this.claim.policy.policyNumber = this.policy.policyNumber;
    this.claim.policy.effectiveDate = this.policy.effectiveDate;
    this.claim.policy.expirationDate = this.policy.expirationDate;
    this.claim.policy.insured = this.policy.insured;
    this.claim.policy.policyType = this.policy.policyType.key;
    this.claim.claimSource = 'ONL - Online';
    this.persistEffectiveDate = new Date(this.loginService.getPolicyDetails().effectiveDate);
    this.persistExpirationDate = new Date(this.loginService.getPolicyDetails().expirationDate);
    if (this.userDetails.userRoleID == '1') {
      this.claim.userRole = 'ONL-Admin';  //Must have no spaces
    } else if (this.userDetails.userRoleID == '3') {
      this.claim.userRole = 'ONL-Guest';
    } else if (this.userDetails.userRoleID == '4') {
      this.claim.userRole = 'ONL-COI';
    } else if (this.userDetails.userRoleID == '7') {
      this.claim.userRole = 'ONL-Guest';
      this.claim.claimSource = 'DD Score Protection';
    }
  }

  gatherFormValues() {
    this.claim = this.dataService._commonClaimsInterface;
    //Payee Data
    this.claim.payeeId = this.persistPayeeInfo?.payeeID ? this.persistPayeeInfo?.payeeID : this.placeholderPayee ? this.placeholderPayee : "";

    //StartFormGroup
    if (this.startFormGroup.valid) {
      this.claim.trackingNumber_Ext = this.startFormGroup.value.trackingNumber ? this.startFormGroup.value.trackingNumber : "";
      this.claim.shippingDetailsDTO.shipmentIdentificationNumber = this.startFormGroup.value.trackingNumber ? this.startFormGroup.value.trackingNumber : "";
      this.claim.shippingDetailsDTO.trackingNumber = this.startFormGroup.value.trackingNumber ? this.startFormGroup.value.trackingNumber : "";
      this.claim.transportationCarrierId_Ext = this.startFormGroup.value.carrier ? this.startFormGroup.value.carrier : "";
      this.claim.shippingDetailsDTO.senderDetails.companyName = this.startFormGroup.value.sender.company ? this.startFormGroup.value.sender.company : "";
      this.claim.shippingDetailsDTO.senderDetails.address1 = this.startFormGroup.value.sender.address ? this.startFormGroup.value.sender.address : "";
      this.claim.shippingDetailsDTO.senderDetails.city = this.startFormGroup.value.sender.city ? this.startFormGroup.value.sender.city : "";
      this.claim.shippingDetailsDTO.senderDetails.state = this.startFormGroup.value.sender.state ? this.stateList[this.startFormGroup.value.sender.state - 1].state : "NA";
      this.claim.shippingDetailsDTO.senderDetails.zipCode = this.startFormGroup.value.sender.zipCode ? this.startFormGroup.value.sender.zipCode : "";
      this.claim.shippingDetailsDTO.shipperPostalCode = this.startFormGroup.value.sender.zipCode ? this.startFormGroup.value.sender.zipCode : "";
      this.claim.shippingDetailsDTO.senderDetails.country = this.startFormGroup.value.sender.country ? this.startFormGroup.value.sender.country : "";
      this.claim.shippingDetailsDTO.receiverDetails.companyName = this.startFormGroup.value.recipient.company ? this.startFormGroup.value.recipient.company : "";
      this.claim.shippingDetailsDTO.receiverDetails.address1 = this.startFormGroup.value.recipient.address ? this.startFormGroup.value.recipient.address : "";
      this.claim.shippingDetailsDTO.receiverDetails.city = this.startFormGroup.value.recipient.city ? this.startFormGroup.value.recipient.city : "";
      this.claim.shippingDetailsDTO.receiverDetails.state = this.startFormGroup.value.recipient.state ? this.stateList[this.startFormGroup.value.recipient.state - 1].state : "NA";
      this.claim.shippingDetailsDTO.receiverDetails.zipCode = this.startFormGroup.value.recipient.zipCode ? this.startFormGroup.value.recipient.zipCode : "";
      this.claim.shippingDetailsDTO.consigneePostalCode = (this.isCanadaUser || this.isUsaUser) ? "" : this.startFormGroup.value.recipient.zipCode ? this.startFormGroup.value.recipient.zipCode : "";
      this.claim.shippingDetailsDTO.receiverDetails.country = this.startFormGroup.value.recipient.country ? this.startFormGroup.value.recipient.country : "";
      this.claim.shippingDetailsDTO.shipDate = this.startFormGroup.value.shipDate ? this.startFormGroup.value.shipDate : "";
      this.claim.lossDate = this.startFormGroup.value.shipDate ? this.startFormGroup.value.shipDate : "";
      this.claim.shippingDetailsDTO.referenceNumber = this.startFormGroup.value.referenceNumber ? this.startFormGroup.value.referenceNumber : "";
      this.senderStateDisplay = this.startFormGroup.value.sender.state ? this.stateList[this.startFormGroup.get('sender').get('state').value - 1].state : "";
      this.recipientStateDisplay = this.startFormGroup.value.recipient.state ? this.stateList[this.startFormGroup.get('recipient').get('state').value - 1].state : "";
      this.claim.lossDescription = this.formLossDescription();
    }
    //WhatHappenedFormGroup
    if (this.whatHappenedFormGroup.valid) {
      if (this.whatHappenedFormGroup != null) {
        if (this.whatHappenedFormGroup.get('reason').value == 0) {
          this.claim.lossCause = "loss_Ext";
        } else if (this.whatHappenedFormGroup.get('reason').value == 1) {
          this.claim.lossCause = "damage_Ext";
        } else if (this.whatHappenedFormGroup.get('reason').value == 2) {
          this.claim.lossCause = "fallout_Ext";
        } else if (this.whatHappenedFormGroup.get('reason').value == 3) {
          this.claim.lossCause = "delay";
        }
      }
      this.claim.productCategory_Ext = this.whatHappenedFormGroup.value.category.key ? this.whatHappenedFormGroup.value.category.key : "";
      this.claim.shippingDetailsDTO.merchandiseDescription = this.whatHappenedFormGroup.value.description;
      this.claim.upsmerchandise_Ext = "Description of merchandise in detail = " + this.whatHappenedFormGroup.value.description;
      this.claim.shippingDetailsDTO.insuredValueCurrency = this.currencyKey;
      this.claim.shippingDetailsDTO.merchandiseValue = parseFloat(this.claimService.formatAmount(this.whatHappenedFormGroup.value.merchandise, false)).toFixed(2);
      this.claim.shippingDetailsDTO.verifiedShippingCharge = parseFloat(this.claimService.formatAmount(this.whatHappenedFormGroup.value.shipping, false)).toFixed(2);
      this.claim.shippingDetailsDTO.insuredAmount = parseFloat(this.whatHappenedFormGroup.value.amount).toFixed(2);
      this.claim.claimAmount_Ext = parseFloat(this.whatHappenedFormGroup.value.amount).toFixed(2);
      this.claim.additionalNotes = this.whatHappenedFormGroup.value.additionalComments;
      this.claim.quantity = this.whatHappenedFormGroup.value.quantity;
      if (this.loss) {
        this.claim.lossDescription = this.formLossDescription();
        this.claim.reshipTrackingNumber = this.whatHappenedFormGroup.value.lossReship;
        this.claim.creditMemoRefundNo = this.whatHappenedFormGroup.value.lossRefund;
        this.claim.shipmentStatus = ""; //Shipment Status only used in Damage and Missing
      } else if (this.damage) {
        this.claim.creditMemoRefundNo = ""; //CreditMemoRefundNo only used in Loss and Late
        this.claim.reshipTrackingNumber = ""; //ReshipTrackingNumber only used in Loss and Late
        this.claim.lossDescription = this.formLossDescription();
        this.repairableDisplay = this.repairables[this.whatHappenedFormGroup.value.damageRepairable].text;
        if (this.whatHappenedFormGroup.value.damageLocations < 3) {
          if (this.whatHappenedFormGroup.value.damageLocations != '') {
            this.locationDisplay = this.damagedLocations[this.whatHappenedFormGroup.value.damageLocations].text;
            this.claim.shipmentStatus = this.damagedLocations[this.whatHappenedFormGroup.value.damageLocations].text;
          }
        } else if (this.whatHappenedFormGroup.value.damageLocations == 3) {
          this.claim.shipmentStatus = this.whatHappenedFormGroup.value.damageOther;
        }
      } else if (this.missing) {
        this.claim.creditMemoRefundNo = ""; //CreditMemoRefundNo only used in Loss and Late
        this.claim.reshipTrackingNumber = ""; //ReshipTrackingNumber only used in Loss and Late
        this.claim.lossDescription = this.formLossDescription();
        if (this.whatHappenedFormGroup.value.missingLocations < 3) {
          if (this.whatHappenedFormGroup.value.missingLocations != '') {
            this.locationDisplay = this.damagedLocations[this.whatHappenedFormGroup.value.missingLocations].text;
            this.claim.shipmentStatus = this.damagedLocations[this.whatHappenedFormGroup.value.missingLocations].text;
          }
        } else if (this.whatHappenedFormGroup.value.missingLocations == 3) {
          this.claim.shipmentStatus = this.whatHappenedFormGroup.value.missingOther;
        }
      } else if (this.late) {
        this.claim.lossDescription = this.formLossDescription();
        this.claim.reshipTrackingNumber = this.whatHappenedFormGroup.value.lateReship;
        this.claim.creditMemoRefundNo = this.whatHappenedFormGroup.value.lateRefund;
        this.claim.shipmentStatus = ""; //Shipment Status only used in Damage and Missing
      }
    }

    //Contact Info Form Group
    let contactInfo = JSON.parse(sessionStorage.getItem("contactInfo"));

    if (contactInfo!==null && contactInfo!==undefined
      && (contactInfo.contactType === '0' || contactInfo.contactType === '1' || contactInfo.contactType === '2')) {

      this.claim.mainContact.firstName = contactInfo.firstName;
      this.claim.mainContact.lastName = contactInfo.lastName;
      //this.claim.mainContact.workNumber = contactInfo.workNumber;
      this.claim.mainContact.emailAddress1 = contactInfo.emailAddress1;
      this.mailingInfo.insured = contactInfo.insured;
      this.mailingInfo.streetAddress1 = contactInfo.streetAddress1;
      this.mailingInfo.streetAddress2 = contactInfo.streetAddress2;
      this.mailingInfo.city = contactInfo.city;
      this.mailingInfo.state = contactInfo.state;
      this.mailingInfo.zip = contactInfo.zip;
      this.mailingInfo.country = contactInfo.country;
      this.claim.mainContact.workNumber = contactInfo.workNumber;
      this.claim.lossDescription = this.formLossDescription();
    }

    //Shipper + Group numbers
    this.claim.shipperNumber = this.userDetails.shipperNumber;
    this.claim.shipperName = this.userDetails.shipperName;
    this.claim.groupID = this.userDetails.groupID;

    //Everytime we gather form details we want to update the data service with the new claim model
    this.dataService.updateCommonClaimObject(this.claim);
  }

  //Only called once, after user moves to second page of claims flow for the first time
  createClaim(event) {
    if (this.startFormGroup.valid) {  //Only submit form if valid
      this.setCurrenciesForInternational();
      this.continuing = true;
      this.systemError = false;
      this.errorNotification = false;
      event.preventDefault();
      if (this.initialClaimCreation) {  //Check if this is the first time calling it
        this.gatherFormValues();
        if (this.carrier.value == 'UPS' || this.carrier.value == 'FedEx') {  //this determines future routes for the user
          this.showLate = true;
        } else {
          this.showLate = false;
        }

        this.dataService.formatPayloadBeforeAnyAPIRequest();
        this.claimService.createClaim(this.dataService._commonClaimsInterface).subscribe(
          data => {
            if (data?.claimNumber) {
              this.setStep(event, 3);
              this.persistClaimNumber = data.claimNumber;
              this.persistPublicId = data.publicId;
              this.initialClaimCreation = false;
              this.scriptError = false;
            } else {
              this.continuing = false;
              this.systemError = true;
            }
          }, error => {
            if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
              this.continuing = false;
              this.scriptError = true;
            }
            this.continuing = false;
            this.systemError = true;
            this.logInterface.routeName = this.router.url;
            this.logInterface.exceptionMessage = error.toString();
            this.utilityService.uiLog(this.logInterface).subscribe(
              data => { },
              error => { }
            );
          }, () => {
            this.continuing = false;
          }
        );
      } else {
        //User has been to page 2 and pressed back
        //So, instead of create claim, we save claim
        this.saveClaim(event);
      }
    } else {
      //Check validations for first form group.
      this.validateAllFormFields(this.startFormGroup);
      this.errorNotification = true;
      window.scrollTo(0, 0);
    }
  }

  //Everytime user clicks Continue button, saveClaim is called
  saveClaim(event) {
    if (this.startFormGroup.valid) {
      this.continuing = true;
      this.errorNotification = false;
      event.preventDefault();
      //Need these 4 values prepopulated for the save claim API.
      this.claim = this.dataService._commonClaimsInterface;
      this.claim.claimNumber = this.persistClaimNumber;
      this.claim.publicID = this.persistPublicId;
      this.claim.lossType = "PR";
      this.claim.lossCause = "loss_Ext";
      this.dataService.updateCommonClaimObject(this.claim);
      if (this.carrier.value == 'UPS' || this.carrier.value == 'FedEx') {
        this.showLate = true;
      } else {
        this.showLate = false;
      }
      this.gatherFormValues();
      if (this.draftClaimNumber) {
        this.saveDraftWithDupeTNCheck();
      } else {
        this.dataService.formatPayloadBeforeAnyAPIRequest();
        this.claimService.saveClaim(this.dataService._commonClaimsInterface).subscribe(
          data => {
            this.setStep(event, 3);
            this.scriptError = false;
          }, error => {
            if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
              this.continuing = false;
              this.scriptError = true;
            }
            this.continuing = false;
            this.logInterface.routeName = this.router.url;
            this.logInterface.exceptionMessage = error.toString();
            this.utilityService.uiLog(this.logInterface).subscribe(
              data => { },
              error => { }
            );
          }, () => {
            this.continuing = false;
          }
        );
      }
    } else {
      this.validateAllFormFields(this.startFormGroup);
      this.errorNotification = true;
      window.scrollTo(0, 0);
    }
  }

  //Everytime user clicks Continue button, saveClaim is called
  saveClaim2(event) {

    this.clearDocumentErrors();
    if (this.whatHappenedFormGroup.valid) {
      this.continuing = true;
      this.errorNotification = false;
      event.preventDefault();
      //Need these 4 values prepopulated for the save claim API.
      this.claim.claimNumber = this.persistClaimNumber;
      this.claim.publicID = this.persistPublicId;
      this.claim.lossType = "PR";
      this.claim.lossCause = "loss_Ext";
      this.gatherFormValues();
      //mark todo - shouldn't need this getTrack3Data... but check it with draft claims and create -> go back -> save claim?
      // this.getTrack3Data(this.claim);
      this.dataService.formatPayloadBeforeAnyAPIRequest();
      this.claimService.saveClaim(this.dataService._commonClaimsInterface).subscribe(
        data => {
          this.setStep(event, 4);
          this.scriptError = false;
        }, error => {
            if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
              this.continuing = false;
              this.scriptError = true;
            }
          this.continuing = false;
          this.logInterface.routeName = this.router.url;
          this.logInterface.exceptionMessage = error.toString();
          this.utilityService.uiLog(this.logInterface).subscribe(
            data => { },
            error => { }
          );
        }, () => {
          this.continuing = false;
        }
      );
    } else {
      this.validateAllFormFields(this.whatHappenedFormGroup);
      this.errorNotification = true;
      window.scrollTo(0, 0);
    }
  }

  //Everytime user clicks Continue button, saveClaim is called
  saveClaim3(event) {

    if (this.contactSelectionForm.valid && (this.policyHolder || this.recipient)) {

      this.continuing = true;
      this.errorNotification = false;
      event.preventDefault();
      this.gatherFormValues();
      this.dataService.formatPayloadBeforeAnyAPIRequest();
      this.claimService.saveClaim(this.dataService._commonClaimsInterface).subscribe(
        data => {
          this.setStep(event, 5);
          this.scriptError = false;
        }, error => {
          this.continuing = false;
          this.logInterface.routeName = this.router.url;
          this.logInterface.exceptionMessage = error.toString();
          this.utilityService.uiLog(this.logInterface).subscribe(
            data => { },
            error => {
              if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
                this.continuing = false;
                this.scriptError = true;
              }
            }
          );
        }, () => {
          this.continuing = false;
        }
      );
    }
    else if (this.contactSelectionForm.valid && this.custom) {

      if (this.contactInfoFormGroup.valid) {
        this.continuing = true;
        this.errorNotification = false;
        event.preventDefault();
        this.gatherFormValues();
        this.dataService.formatPayloadBeforeAnyAPIRequest();
        this.claimService.saveClaim(this.dataService._commonClaimsInterface).subscribe(
          data => {
            this.setStep(event, 5);
            this.scriptError = false;
          }, error => {
            if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
              this.continuing = false;
              this.scriptError = true;
            }
            this.continuing = false;
            this.logInterface.routeName = this.router.url;
            this.logInterface.exceptionMessage = error.toString();
            this.utilityService.uiLog(this.logInterface).subscribe(
              data => { },
              error => { }
            );
          }, () => {
            this.continuing = false;
          }
        );
      } else {
        this.validateAllFormFields(this.contactInfoFormGroup);
        this.errorNotification = true;
        window.scrollTo(0, 0);
      }
    }
    else {

      this.validateAllFormFields(this.contactSelectionForm);
      this.errorNotification = true;
      window.scrollTo(0, 0);
    }
  }

  saveContact(contactInfo: ContactInfo) {
    this.contactInfo = contactInfo;
    this.contactType = contactInfo.contactType;
    this.newContactType = contactInfo.contactType;
    this.saveClaim4(contactInfo);
  }

  saveDraft(contactInfo: ContactInfo){
    this.contactInfo = contactInfo;
    this.contactType = contactInfo.contactType;
    this.newContactType = contactInfo.contactType;
    this.openDraftConfirmation();
  }

  saveClaim4(contactInfo: ContactInfo) {

    if (contactInfo.contactType === '0' || contactInfo.contactType === '1') {

      this.continuing = true;
      this.errorNotification = false;
      event.preventDefault();
      this.gatherFormValues();
      this.dataService.formatPayloadBeforeAnyAPIRequest();
      this.claimService.saveClaim(this.dataService._commonClaimsInterface).subscribe(
        data => {
          this.setStep(event, 5);
          this.scriptError = false;
          this.scriptErrorFields = '';
        }, error => {
          this.continuing = false;
          this.logInterface.routeName = this.router.url;
          this.logInterface.exceptionMessage = error.toString();
          this.utilityService.uiLog(this.logInterface).subscribe(
            data => { },
            error => {
              if (error.error != null && error.error.errorMessage !== undefined && error.error.errorMessage === 'InvalidInput for field/s.') {
                this.continuing = false;
                this.scriptError = true;
                this.scriptErrorFields = error.error.fields;
              }
            }
          );
        }, () => {
          this.continuing = false;
        }
      );
    }
    else if (contactInfo.contactType === '2') {

     // if (this.contactInfoFormGroup.valid) {
        this.continuing = true;
        this.errorNotification = false;
        event.preventDefault();
        this.gatherFormValues();
        this.dataService.formatPayloadBeforeAnyAPIRequest();
        this.claimService.saveClaim(this.dataService._commonClaimsInterface).subscribe(
          data => {
            this.setStep(event, 5);
            this.scriptError = false;
            this.scriptErrorFields = '';
          }, error => {
            if (error.error != null && error.error.errorMessage !== undefined && error.error.errorMessage === 'InvalidInput for field/s.') {
              this.continuing = false;
              this.scriptError = true;
              this.scriptErrorFields = error.error.fields;
            }
            this.continuing = false;
            this.logInterface.routeName = this.router.url;
            this.logInterface.exceptionMessage = error.toString();
            this.utilityService.uiLog(this.logInterface).subscribe(
              data => { },
              error => { }
            );
          }, () => {
            this.continuing = false;
          }
        );
    }
  }


  showCheckedHandler(cbEvent: boolean) {
    this.submitClaimFormGroup.get("checkboxFormControl").setValue(cbEvent);
  }

  //Final submission of claim
  submitClaim(event) {

    if (this.submitClaimFormGroup.valid) {

      if (this.isUsaUser && !this.isDDUser) {
        this.usCheckboxComponent.submitForm();
      }

      this.continuing = true;
      this.errorNotification = false;
      this.systemError = false;
      event.preventDefault();
      this.dataService.formatPayloadBeforeAnyAPIRequest();
      this.claimService.submitClaim(this.dataService._commonClaimsInterface).subscribe(
        data => {
          this.continuing = false;
          if (data.claimNumber) {
            this.submittedClaimNumber = data.claimNumber;
            let emailTemplate = emptyIEmail;
            emailTemplate.emailFor = 'ClaimDetails';
            emailTemplate.data = {
              email: this.claim.mainContact.emailAddress1,
              fName: this.claim.mainContact.firstName,
              lName: this.claim.mainContact.lastName,
              claimNumber: data.claimNumber,
              claimDetailLink: ENV.baseUrl.web + btoa(`${sessionStorage.getItem('userid')}/${data.claimNumber}`)
            }
            if (this.isGuestUser) {
              emailTemplate.data = { ...emailTemplate.data, claimDetailLink: ENV.baseUrl.web + btoa(`guest/detail?policy=${this.claim.policy.policyNumber}&claimNumber=${data.claimNumber}&email=${this.claim.mainContact.emailAddress1}`)};
              this.utilityService.sendGuestFilingEmail(emailTemplate).subscribe();
            }
            this.setStep(event, 6);
            if (sessionStorage.getItem('claimFileStatus') == 'wallet') {
              this.showWalletApprovalDisclaimer = true;
            }
            sessionStorage.removeItem('claimFileStatus');
          } else {
            this.systemError = true;
          }

        }, error => {
          this.continuing = false;
          this.logInterface.routeName = this.router.url;
          this.logInterface.policyNumber = this.claim.policy.policyNumber;
          this.logInterface.exceptionMessage = error.message;
          this.logInterface.errorCode = error.status;
          this.utilityService.uiLog(this.logInterface).subscribe(
            data => { },
            error => { }
          );

        }, () => {
          this.continuing = false;
        }
      );
    } else {
      this.validateAllFormFields(this.submitClaimFormGroup);
      this.errorNotification = true;
      window.scrollTo(0, 0);
    }
  }

  setCurrentStep() {
    this.currentStep = 1;
  }

  reloadPage(event) {
    sessionStorage.setItem("reloading", "true");
    sessionStorage.removeItem('claimFileStatus');
    if (this.draftClaimNumber) {
      this.draftClaimNumber = null;
      window.location.replace(`${ENV.baseUrl.web}claims/new`);
      setTimeout(() => location.reload(), 1000);
    } else if (this.THClaimInfo) {
      this.THClaimInfo = null;
      window.location.replace(`${ENV.baseUrl.web}claims/new`);
      setTimeout(() => location.reload(), 1000);
    } else {
      window.location.replace(`${ENV.baseUrl.web}claims/new`);
    }
  }

  backToClaims(event) {
    this.router.navigate(['/claims']);
  }

  onClick(event: any) {
    if (navigator.appVersion.indexOf('iPhone') > -1) {
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          let overlay = document.getElementById(`cdk-overlay-${i}`);
          if (overlay && overlay.style.top) {
            let offset = Number(overlay.style.top.split('px')[0]) - event.clientY;
            // console.log('offset - ', offset);
            if (offset > 25) {
              overlay.style.top = (event.clientY + 25) + 'px';
            }
            break;
          }
        }
      }, 200);
    }
  }

  toggleDescription(type: string) {
    switch (type) {
      case 'damage':
        this.dmgMore = !this.dmgMore;
        break;
      case 'missing':
        this.missingMore = !this.missingMore;
        break;
    }
  }

  exitToClaimDetails() {
    this.router.navigate(['/claims/detail'], { queryParams: { claimNumber: this.submittedClaimNumber } });
  }

  edit(page: string) {
    switch (page) {
      case 'shipment':
        this.currentStep = 2;
        this.setActiveStep();
        window.scrollTo(0, 0);
        this.setEditableContact();
        break;
      case 'claim':
        this.currentStep = 3;
        this.setActiveStep();
        window.scrollTo(0, 0);
        this.setEditableContact();
        break;
      case 'contact':
        this.currentStep = 4;
        this.setActiveStep();
        window.scrollTo(0, 0);
        this.setEditableContact();
    }
  }

  setEditableContact() {
    if (this.newContactType === "0") {
      this.isPolicyHolder = true;
      this.policyHolder = true;
      this.isRecipient = false;
      this.recipient = false;
      this.custom = false;
      this.mainContact_firstName = "";
      this.mainContact_lastName = "";
      this.mainContact_phoneNumber = "";
      this.mainContact_email = "";
    }
    if (this.newContactType === "1") {
      this.isRecipient = true;
      this.recipient = true;
      this.isPolicyHolder = false;
      this.policyHolder = false;
      this.custom = false;
      this.mainContact_firstName = "";
      this.mainContact_lastName = "";
      this.mainContact_phoneNumber = "";
      this.mainContact_email = "";
    }
    if (this.newContactType === "2") {
      this.custom = true;
      this.isRecipient = false;
      this.recipient = false;
      this.isPolicyHolder = false;
      this.policyHolder = false;
    }
  }

  //Ask user if they want to cancel filing a claim
  errorDialog() {
    let dialogRef = this.dialog.open(ErrordialogComponent);
    dialogRef.afterClosed().subscribe();
  }

  payeeChanged(payeeInfo) {

    if (payeeInfo == false) {
      this.payeeSelectionValid = false;
      this.mobilePayeeSelectionValid = false;
    }
    else {
      if (payeeInfo.mobilePayee) {
        this.persistPayeeInfo = payeeInfo.mobilePayee;
        this.payeeService.setClaimsPayeeInfo(this.persistPayeeInfo, false);
        this.mobilePayeeSelectionValid = true;
      } else if (payeeInfo.payee) {
        this.persistPayeeInfo = payeeInfo.payee;
        this.payeeService.setClaimsPayeeInfo(this.persistPayeeInfo, true);
        this.payeeSelectionValid = true;
      }
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && charCode != 43 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  testingNewGatherFormValues() {
    //Need these 4 values prepopulated for the save claim API.
    this.claim.claimNumber = this.persistClaimNumber;
    this.claim.publicID = this.persistPublicId;
    this.claim.lossType = "PR";
    this.claim.lossCause = "loss_Ext";
    //payee form
    this.claim.payeeId = this.persistPayeeInfo?.payeeID ? this.persistPayeeInfo?.payeeID : '';
    //start form
    this.claim.trackingNumber_Ext = this.startFormGroup.value.trackingNumber ? this.startFormGroup.value.trackingNumber : "";
    this.claim.shippingDetailsDTO.shipmentIdentificationNumber = this.startFormGroup.value.trackingNumber ? this.startFormGroup.value.trackingNumber : "";
    this.claim.transportationCarrierId_Ext = this.startFormGroup.value.carrier ? this.startFormGroup.value.carrier : "";
    this.claim.shippingDetailsDTO.senderDetails.companyName = this.startFormGroup.value.sender.company ? this.startFormGroup.value.sender.company : "";
    this.claim.shippingDetailsDTO.senderDetails.address1 = (this.isCanadaUser || this.isUsaUser) ? "" : this.startFormGroup.value.sender.address ? this.startFormGroup.value.sender.address : "";
    this.claim.shippingDetailsDTO.senderDetails.city = this.startFormGroup.value.sender.city ? this.startFormGroup.value.sender.city : "";
    this.claim.shippingDetailsDTO.senderDetails.state = this.startFormGroup.value.sender.state ? this.stateList[this.startFormGroup.value.sender.state - 1].state : "NA";
    this.claim.shippingDetailsDTO.senderDetails.zipCode = (this.isCanadaUser || this.isUsaUser) ? "" : this.startFormGroup.value.sender.zipCode ? this.startFormGroup.value.sender.zipCode : "";
    this.claim.shippingDetailsDTO.shipperPostalCode = this.startFormGroup.value.sender.zipCode ? this.startFormGroup.value.sender.zipCode : "";
    this.claim.shippingDetailsDTO.senderDetails.country = this.startFormGroup.value.sender.country ? this.startFormGroup.value.sender.country : "";
    this.claim.shippingDetailsDTO.receiverDetails.companyName = this.startFormGroup.value.recipient.company ? this.startFormGroup.value.recipient.company : "";
    this.claim.shippingDetailsDTO.receiverDetails.address1 = (this.isCanadaUser || this.isUsaUser) ? "" : this.startFormGroup.value.recipient.address ? this.startFormGroup.value.recipient.address : "";
    this.claim.shippingDetailsDTO.receiverDetails.city = this.startFormGroup.value.recipient.city ? this.startFormGroup.value.recipient.city : "";
    this.claim.shippingDetailsDTO.receiverDetails.state = this.startFormGroup.value.recipient.state ? this.stateList[this.startFormGroup.value.recipient.state - 1].state : "NA";
    this.claim.shippingDetailsDTO.receiverDetails.zipCode = (this.isCanadaUser || this.isUsaUser) ? "" : this.startFormGroup.value.recipient.zipCode ? this.startFormGroup.value.recipient.zipCode : "";
    this.claim.shippingDetailsDTO.consigneePostalCode = (this.isCanadaUser || this.isUsaUser) ? "" : this.startFormGroup.value.recipient.zipCode ? this.startFormGroup.value.recipient.zipCode : "";
    this.claim.shippingDetailsDTO.receiverDetails.country = this.startFormGroup.value.recipient.country ? this.startFormGroup.value.recipient.country : "";
    this.claim.shippingDetailsDTO.shipDate = this.startFormGroup.value.shipDate ? this.startFormGroup.value.shipDate : "";
    this.claim.lossDate = this.startFormGroup.value.shipDate ? this.startFormGroup.value.shipDate : "";
    this.claim.shippingDetailsDTO.referenceNumber = this.startFormGroup.value.referenceNumber ? this.startFormGroup.value.referenceNumber : "";
    this.senderStateDisplay = this.startFormGroup.value.sender.state ? this.stateList[this.startFormGroup.get('sender').get('state').value - 1].state : "";
    this.recipientStateDisplay = this.startFormGroup.value.recipient.state ? this.stateList[this.startFormGroup.get('recipient').get('state').value - 1].state : "";
    //what happened form

    this.claim.quantity = this.whatHappenedFormGroup.value.quantity;

    if (this.whatHappenedFormGroup != null) {
      this.claim.lossCause = this.whatHappenedFormGroup.controls.reason.value == 0 ? 'loss_Ext' : this.whatHappenedFormGroup.controls.reason.value == 1 ? 'damage_Ext' : this.whatHappenedFormGroup.controls.reason.value == 2 ? 'fallout_Ext' : this.whatHappenedFormGroup.controls.reason.value == 3 ? 'delay' : '';
    }
    this.claim.productCategory_Ext = this.whatHappenedFormGroup.value.category?.key ? this.whatHappenedFormGroup.value.category.key : "";
    this.claim.shippingDetailsDTO.merchandiseDescription = this.whatHappenedFormGroup.value.description ? this.whatHappenedFormGroup.value.description : '';
    this.claim.upsmerchandise_Ext = "Description of merchandise in detail = " + this.whatHappenedFormGroup.value.description;
    this.claim.shippingDetailsDTO.insuredValueCurrency = this.currencyKey;
    this.claim.shippingDetailsDTO.merchandiseValue = this.whatHappenedFormGroup.controls.merchandise.value ? parseFloat(this.claimService.formatAmount(this.whatHappenedFormGroup.value.merchandise, false)).toFixed(2) : '';
    this.claim.shippingDetailsDTO.verifiedShippingCharge = this.whatHappenedFormGroup.controls.shipping.value ? parseFloat(this.claimService.formatAmount(this.whatHappenedFormGroup.value.shipping, false)).toFixed(2) : '';
    this.claim.shippingDetailsDTO.insuredAmount = this.whatHappenedFormGroup.controls.amount.value ? parseFloat(this.whatHappenedFormGroup.value.amount).toFixed(2) : '';
    this.claim.claimAmount_Ext = this.whatHappenedFormGroup.controls.amount.value ? parseFloat(this.whatHappenedFormGroup.value.amount).toFixed(2) : '';
    this.claim.additionalNotes = this.whatHappenedFormGroup.value.additionalComments ? this.whatHappenedFormGroup.value.additionalComments : '';
    if (this.loss) {
      this.claim.lossDescription = this.formLossDescription();
      this.claim.reshipTrackingNumber = this.whatHappenedFormGroup.value.lossReship ? this.whatHappenedFormGroup.value.lossReship : '';
      this.claim.creditMemoRefundNo = this.whatHappenedFormGroup.value.lossRefund ? this.whatHappenedFormGroup.value.lossRefund : '';
      this.claim.shipmentStatus = ""; //Shipment Status only used in Damage and Missing
    } else if (this.damage) {
      this.claim.creditMemoRefundNo = ""; //CreditMemoRefundNo only used in Loss and Late
      this.claim.reshipTrackingNumber = ""; //ReshipTrackingNumber only used in Loss and Late
      this.claim.lossDescription = this.formLossDescription();
      this.repairableDisplay = this.whatHappenedFormGroup.value.damageRepairable ? this.repairables[this.whatHappenedFormGroup.value.damageRepairable].text : '';
      if (this.whatHappenedFormGroup.value.damageLocations < 3) {
        if (this.whatHappenedFormGroup.value.damageLocations != '') {
          this.locationDisplay = this.whatHappenedFormGroup.value.damageLocations ? this.damagedLocations[this.whatHappenedFormGroup.value.damageLocations].text : '';
          this.claim.shipmentStatus = this.whatHappenedFormGroup.value.damageLocations ? this.damagedLocations[this.whatHappenedFormGroup.value.damageLocations].text : '';
        }
      } else if (this.whatHappenedFormGroup.value.damageLocations == 3) {
        this.claim.shipmentStatus = this.whatHappenedFormGroup.value.damageOther ? this.whatHappenedFormGroup.value.damageOther : '';
      }
    } else if (this.missing) {
      this.claim.creditMemoRefundNo = ""; //CreditMemoRefundNo only used in Loss and Late
      this.claim.reshipTrackingNumber = ""; //ReshipTrackingNumber only used in Loss and Late
      this.claim.lossDescription = this.formLossDescription();
      if (this.whatHappenedFormGroup.value.missingLocations < 3) {
        if (this.whatHappenedFormGroup.value.missingLocations != '') {
          this.locationDisplay = this.whatHappenedFormGroup.value.missingLocations ? this.damagedLocations[this.whatHappenedFormGroup.value.missingLocations].text : '';
          this.claim.shipmentStatus = this.whatHappenedFormGroup.value.missingLocations ? this.damagedLocations[this.whatHappenedFormGroup.value.missingLocations].text : '';
        }
      } else if (this.whatHappenedFormGroup.value.missingLocations == 3) {
        this.claim.shipmentStatus = this.whatHappenedFormGroup.value.missingOther ? this.whatHappenedFormGroup.value.missingOther : '';
      }
    } else if (this.late) {
      this.claim.lossDescription = this.formLossDescription();
      this.claim.reshipTrackingNumber = this.whatHappenedFormGroup.value.lateReship ? this.whatHappenedFormGroup.value.lateReship : '';
      this.claim.creditMemoRefundNo = this.whatHappenedFormGroup.value.lateRefund ? this.whatHappenedFormGroup.value.lateRefund : '';
      this.claim.shipmentStatus = ""; //Shipment Status only used in Damage and Missing
    }

    //contactinfo
    let contactInfo = JSON.parse(sessionStorage.getItem("contactInfo"));
    if (contactInfo!==null && contactInfo!==undefined
      && (contactInfo.contactType === '0' || contactInfo.contactType === '1' || contactInfo.contactType === '2')) {
      this.claim.mainContact.firstName = contactInfo.firstName;
        this.claim.mainContact.lastName = contactInfo.lastName;
        //this.claim.mainContact.workNumber = contactInfo.workNumber;
        this.claim.mainContact.emailAddress1 = contactInfo.emailAddress1;
        this.mailingInfo.insured = contactInfo.insured;
        this.mailingInfo.streetAddress1 = contactInfo.streetAddress1;
        this.mailingInfo.streetAddress2 = contactInfo.streetAddress2;
        this.mailingInfo.city = contactInfo.city;
        this.mailingInfo.state = contactInfo.state;
        this.mailingInfo.zip = contactInfo.zip;
        this.mailingInfo.country = contactInfo.country;
        if (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) {
          this.claim.mainContact.workNumber = "+" + contactInfo.countryCode + " " + contactInfo.workNumber;
        }
        else{
          this.claim.mainContact.workNumber = contactInfo.workNumber;
        }
        this.claim.lossDescription = this.formLossDescription();
      }

    /*if (this.recipient) {
      let payeefullName = this.persistPayeeInfo?.payeeName ? this.persistPayeeInfo.payeeName.split(' ') : '';
      this.claim.mainContact.firstName = payeefullName[0] ? payeefullName[0] : '';
      this.claim.mainContact.lastName = payeefullName[1] ? payeefullName[1] : '';
      this.claim.mainContact.workNumber = this.persistPayeeInfo.phoneNumber ? this.persistPayeeInfo.phoneNumber.replace(/\./g, '') : '';
      this.claim.mainContact.emailAddress1 = this.persistPayeeInfo.email ? this.persistPayeeInfo.email : '';
      this.mailingInfo.insured = this.persistPayeeInfo.payeeName ? this.persistPayeeInfo.payeeName : '';
      this.mailingInfo.streetAddress1 = this.persistPayeeInfo.addressLine1 ? this.persistPayeeInfo.addressLine1 : '';
      this.mailingInfo.streetAddress2 = this.persistPayeeInfo.addressLine2 ? this.persistPayeeInfo.addressLine2 : '';
      this.mailingInfo.city = this.persistPayeeInfo.city ? this.persistPayeeInfo.city : '';
      this.mailingInfo.state = this.persistPayeeInfo.state ? this.persistPayeeInfo.state : '';
      this.mailingInfo.zip = this.persistPayeeInfo.zip ? this.persistPayeeInfo.zip : '';
      this.mailingInfo.country = this.persistPayeeInfo.country ? this.persistPayeeInfo.country : '';
    } else if (this.policyHolder) {
      let payeefullName = this.policy?.insured ? this.policy.insured.split(' ') : '';
      this.claim.mainContact.firstName = payeefullName[0] ? payeefullName[0] : '';
      this.claim.mainContact.lastName = payeefullName[1] ? payeefullName[1] : '';
      this.claim.mainContact.workNumber = this.userDetails.contactPhone ? this.userDetails.contactPhone : '';
      this.claim.mainContact.emailAddress1 = this.userDetails.emailAddress ? this.userDetails.emailAddress : '';
      this.mailingInfo.insured = this.policy.insured ? this.policy.insured : '';
      this.mailingInfo.streetAddress1 = this.policy.addressLine1_Ext ? this.policy.addressLine1_Ext : '';
      this.mailingInfo.streetAddress2 = this.policy.addressLine2_Ext ? this.policy.addressLine2_Ext : '';
      this.mailingInfo.city = this.policy.city ? this.policy.city : '';
      this.mailingInfo.state = this.policy.state ? this.policy.state : '';
      this.mailingInfo.zip = this.policy.zip ? this.policy.zip : '';
    } else if (this.custom) {
      this.claim.mainContact.firstName = this.contactInfoFormGroup.value.contact.firstName ? this.contactInfoFormGroup.value.contact.firstName.trim() : '';
      this.claim.mainContact.lastName = this.contactInfoFormGroup.value.contact.lastName ? this.contactInfoFormGroup.value.contact.lastName.trim() : '';
      let workNum;
      if (this.isUkUser || this.isItalyUser || this.isGermanyUser || this.isFranceUser) {
        workNum = "+" + this.contactInfoFormGroup.value.contact.countryCode ? this.contactInfoFormGroup.value.contact.countryCode : '' + this.contactInfoFormGroup.value.contact.phoneNumber ? this.contactInfoFormGroup.value.contact.phoneNumber.replace(/\./g, '') : '';
      } else {
        workNum = this.contactInfoFormGroup.value.contact.phoneNumber ? this.contactInfoFormGroup.value.contact.phoneNumber.replace(/\./g, '') : '';
      }
      this.claim.mainContact.workNumber = workNum;
      this.claim.mainContact.emailAddress1 = this.contactInfoFormGroup.value.contact.emailAddress ? this.contactInfoFormGroup.value.contact.emailAddress : '';
      this.mailingInfo.insured = this.policy.insured ? this.policy.insured : '';
      this.mailingInfo.streetAddress1 = this.policy.addressLine1_Ext ? this.policy.addressLine1_Ext : '';
      this.mailingInfo.streetAddress2 = this.policy.addressLine2_Ext ? this.policy.addressLine2_Ext : '';
      this.mailingInfo.city = this.policy.city ? this.policy.city : '';
      this.mailingInfo.state = this.policy.state ? this.policy.state : '';
      this.mailingInfo.zip = this.policy.zip ? this.policy.zip : '';
    }*/
  }

  openBreadCrumbsSaveConfirmation(currentStep: any) {
    if ((currentStep > 2 || this.persistClaimNumber != 'tempClaimNumber')
      && this.isUsaUser && !this.isGuestUser) {
      this.openDraftConfirmation();
    }

  }

  openDraftConfirmation() {
    //Gather form values, put into the claim object, send to component

    this.testingNewGatherFormValues();
    let dialogRef = this.dialog.open(DraftConfirmationComponent, {
      minWidth: '300px',
      maxWidth: '550px',
      width: '50%',
      data: { claimReq: this.claim, step: this.currentStep }
    });
  }

  formLossDescription() {

    let lossDescription = null;
    lossDescription = {
      shipmentRole: this.role.value,
      reason: this.reason.value,
      contactType: this.newContactType
    }
    //Fill up dynamic data
    if (this.reason.value == 0) {

      //loss
      var detailsCare = "";
      if (this.lossCareData && this.lossCareData === "0") {
        if (this.lossReshipDescription) {
          detailsCare = this.lossReshipDescription;
        }
      }
      else if (this.lossCareData && this.lossCareData === "1") {
        if (this.lossRefundDescription) {
          detailsCare = this.lossRefundDescription;
        }
      }

      lossDescription = {
        ...lossDescription,
        care: this.lossCare.value,
        //careDetails: this.lossCare.value == '0' ? this.lossReship.value : this.lossCare.value == '1' ? this.lossRefund.value : '',
        careDetails: detailsCare,
        return: this.return.value,
        originalTrackingNumber: this.originalTrackingNumber.value
      }
    } else if (this.reason.value == 1) {
      //damage
      lossDescription = {
        ...lossDescription,
        damageDescription: this.damageDescription.value,
        damageDate: this.damageDate.value,
        repairable: this.damageRepairable.value,
        packageLocation: this.damageLocations.value,
        packageLocationDetails: this.damageOther.value,
        return: this.return.value,
        originalTrackingNumber: this.originalTrackingNumber.value
      }
    } else if (this.reason.value == 2) {
      //missing
      lossDescription = {
        ...lossDescription,
        missingDescription: this.missingDescription.value,
        missingDate: this.missingDate.value,
        packageLocation: this.missingLocations.value,
        packageLocationDetails: this.missingOther.value,
        return: this.return.value,
        originalTrackingNumber: this.originalTrackingNumber.value
      }
    } else if (this.reason.value == 3) {

      //late
      var detailsCare = "";
      if (this.lateCareData && this.lateCareData === "0") {
        if (this.lateReshipDescription) {
          detailsCare = this.lateReshipDescription;
        }
      }
      else if (this.lateCareData && this.lateCareData === "1") {
        if (this.lateRefundDescription) {
          detailsCare = this.lateRefundDescription;
        }
      }

      lossDescription = {
        ...lossDescription,
        care: this.lateCare.value,
        //careDetails: this.lateCare.value == '0' ? this.lateReship.value : this.lateCare.value == '1' ? this.lateRefund.value : '',
        careDetails: detailsCare,
        return: this.return.value,
        originalTrackingNumber: this.originalTrackingNumber.value
      }
    }

    try{
      let contactInfo = JSON.parse(sessionStorage.getItem("contactInfo"));
      if (contactInfo!==undefined && contactInfo!==null && contactInfo.contactType === '2') {
        let infoContact = JSON.stringify(contactInfo);
        lossDescription = {
          ...lossDescription,
          contactInfo: infoContact,
        }
      }
    }
    catch{

    }

    return JSON.stringify(lossDescription);
  }

  //This is what actually autofills the startFormGroup form.
  //Uses track3 details to .setValue() on the form.
  //In TUPSS and MyChoice this form is not actually shown to the user (StartForm).
  //But to keep it the same as regular claim flow, this way still works for populating the claim interface.
  //Because later on gatherFormValues() will be called.
  populateStartFormGroupWithTrack3() {
    let senderInfo = this.track3ShipmentDetails.shipperConsigneeAddress.find(x => x.description?.toLowerCase() == 'shipper address');
    let recipInfo = this.track3ShipmentDetails.shipperConsigneeAddress.find(x => x.description?.toLowerCase() == 'shipto address');
    if (this.countryList.find(x => x.key == senderInfo?.countryCode)) {
      this.startFormGroup.controls.sender.get('country').setValue(senderInfo.countryCode);
    }
    if (senderInfo?.city) {
      this.startFormGroup.controls.sender.get('city').setValue(senderInfo.city);
      this.startFormGroup.controls.sender.get('city').markAsTouched();
    }
    if (senderInfo?.companyName) {
      this.startFormGroup.controls.sender.get('company').setValue(senderInfo.companyName);
      this.startFormGroup.controls.sender.get('company').markAsTouched();
    }
    if (senderInfo?.postalCode) {
      this.startFormGroup.controls.sender.get('zipCode').setValue(senderInfo.postalCode);
      this.startFormGroup.controls.sender.get('zipCode').markAsTouched();
    }
    if (senderInfo?.stateProvinceCode) {
      if (this.stateList.find(x => x.state == senderInfo.stateProvinceCode)) {
        this.startFormGroup.controls.sender.get('state').setValue(this.stateList.find(x => x.state == senderInfo.stateProvinceCode).id);
      }
    }
    if (senderInfo?.addressLine) {
      this.startFormGroup.controls.sender.get('address').setValue(senderInfo.addressLine);
    }
    if (recipInfo?.countryCode) {
      if (this.countryList.find(x => x.key == recipInfo?.countryCode)) {
        this.startFormGroup.controls.recipient.get('country').setValue(recipInfo.countryCode);
      }
    }
    if (recipInfo?.city) {
      this.startFormGroup.controls.recipient.get('city').setValue(recipInfo.city);
      this.startFormGroup.controls.recipient.get('city').markAsTouched();
    }
    if (recipInfo?.companyName) {
      this.startFormGroup.controls.recipient.get('company').setValue(recipInfo.companyName);
      this.startFormGroup.controls.recipient.get('company').markAsTouched();
    }
    if (recipInfo?.postalCode) {
      this.startFormGroup.controls.recipient.get('zipCode').setValue(recipInfo.postalCode);
      this.startFormGroup.controls.recipient.get('zipCode').markAsTouched();
    }
    if (recipInfo?.stateProvinceCode) {
      if (this.stateList.find(x => x.state == recipInfo.stateProvinceCode)) {
        this.startFormGroup.controls.recipient.get('state').setValue(this.stateList.find(x => x.state == recipInfo.stateProvinceCode).id);
      }
    }
    if (recipInfo?.addressLine) {
      this.startFormGroup.controls.recipient.get('address').setValue(recipInfo.addressLine);
    }
    if (this.track3ShipmentDetails.shipDate) {
      this.startFormGroup.controls.shipDate.setValue(this.track3ShipmentDetails.shipDate);
    }
  }

  saveDraftWithDupeTNCheck() {
    //Don't call any API's if the field is not valid
    if (!this.startFormGroup.get('trackingNumber').valid) {
      return;
    }
    let trackingNumber = this.startFormGroup.get('trackingNumber').value.toUpperCase();
    this.continuing = true;
    this.currentlyCheckingDupeTrackingNumbers = true;
    //Check if the tracking number has been used to file a claim previously
    this.claimService.getShipmentDetails(trackingNumber).subscribe(
      data => {
        this.claim.shippingDetailsDTO.shipmentConfirmation = data.shipmentConfirmation ? data.shipmentConfirmation : "";
        this.claim.shippingDetailsDTO.insuredValue = data.InsuredValue ? data.InsuredValue.split(" ")[0] : "";
        this.currentlyCheckingDupeTrackingNumbers = false;
        if (data.TrackingNumberDetails.IsDuplicateTrackingNumber) {
          this.startFormGroup.get('trackingNumber').setErrors({ 'inuse': true });
          this.startFormGroup.controls.trackingNumber.markAsTouched();
          window.scrollTo(0, 0);
        } else {
          if (this.startFormGroup.get('trackingNumber').hasError('pattern')) {
            this.startFormGroup.get('trackingNumber').setErrors(null);
            this.startFormGroup.get('trackingNumber').setErrors({ 'pattern': true });
          } else if (this.startFormGroup.get('trackingNumber').hasError('required')) {
            this.startFormGroup.get('trackingNumber').setErrors(null);
            this.startFormGroup.get('trackingNumber').setErrors({ 'required': true });
          } else {
            this.startFormGroup.get('trackingNumber').setErrors(null);
          }
          //Save the claim and continue if no dupe found.
          this.dataService.formatPayloadBeforeAnyAPIRequest();
          this.claimService.saveClaim(this.claim).subscribe(
            data => {
              this.setStep(event, 3);
              this.scriptError = false;
            }, error => {
              if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
                this.continuing = false;
                this.scriptError = true;
              }
              this.continuing = false;
              this.logInterface.routeName = this.router.url;
              this.logInterface.exceptionMessage = error.toString();
              this.utilityService.uiLog(this.logInterface).subscribe(
                data => { },
                error => { }
              );
            }, () => {
              this.continuing = false;
            }
          );
        }
      }, error => {
        this.continuing = false;
        this.currentlyCheckingDupeTrackingNumbers = false;
        //Save the claim and continue if the duplicate tracking number API failed.
        this.dataService.formatPayloadBeforeAnyAPIRequest();
        this.claimService.saveClaim(this.claim).subscribe(
          data => {
            this.setStep(event, 3);
            this.scriptError = false;
          }, error => {
            if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
              this.continuing = false;
              this.scriptError = true;
            }
            this.continuing = false;
            this.logInterface.routeName = this.router.url;
            this.logInterface.exceptionMessage = error.toString();
            this.utilityService.uiLog(this.logInterface).subscribe(
              data => { },
              error => { }
            );
          }, () => {
            this.continuing = false;
          }
        );
      }
    );
  }

  tagging(type?) {
    if (type && type == 'start') {
      this.taggingService.link({ link_name: 'get_started_file_a_claim' });
    }
    if (this.currentStep == 1) {
      this.taggingService.view({
        step: 'add_claim_payment_recipient',
        journey_step_number: '1',
        journey: 'File a Claim'
      });
    } else if (this.currentStep == 2) {
      this.taggingService.view({
        step: 'enter_shipment_detail',
        journey_step_number: '2',
        journey: 'File a Claim'
      });
    } else if (this.currentStep == 3) {
      this.taggingService.view({
        step: 'describe_what_happened',
        journey_step_number: '3',
        journey: 'File a Claim'
      });
    } else if (this.currentStep == 4) {
      this.taggingService.view({
        step: 'review_contact_information',
        journey_step_number: '4',
        journey: 'File a Claim'
      });
    } else if (this.currentStep == 5) {
      this.taggingService.view({
        step: 'submit_claim',
        journey_step_number: '5',
        journey: 'File a Claim'
      });
    }
  }

  setCurrenciesForInternational() {
    if (this.isCanadaUser) {
      this.whatHappenedFormGroup.controls['currency'].setValue('CAD');
      this.currencyKey = 'cad';
    }
    else if (this.isUkUser) {
      this.whatHappenedFormGroup.controls['currency'].setValue('GBP');
      this.currencyKey = 'gbp';
    }
    else if (this.isGermanyUser || this.isFranceUser || this.isItalyUser) {
      this.whatHappenedFormGroup.controls['currency'].setValue('EUR');
      this.currencyKey = 'eur';
    }
    else {
      this.whatHappenedFormGroup.controls['currency'].setValue('USD');
      this.currencyKey = 'usd';
    }
  }

  downloadTemplate() {
    window.location.href = this.MultiClaimDownloadForm;
  }

  get role() { return this.startFormGroup.controls.role };
  get reason() { return this.whatHappenedFormGroup.controls.reason };
  get lossCare() { return this.whatHappenedFormGroup.controls.lossCare };
  get lossReship() { return this.whatHappenedFormGroup.controls.lossReship };
  get lossRefund() { return this.whatHappenedFormGroup.controls.lossRefund };
  get damageDescription() { return this.whatHappenedFormGroup.controls.damageDescription };
  get damageDate() { return this.whatHappenedFormGroup.controls.damageDate };
  get damageRepairable() { return this.whatHappenedFormGroup.controls.damageRepairable };
  get damageLocations() { return this.whatHappenedFormGroup.controls.damageLocations };
  get damageOther() { return this.whatHappenedFormGroup.controls.damageOther };
  get missingDescription() { return this.whatHappenedFormGroup.controls.missingDescription };
  get missingDate() { return this.whatHappenedFormGroup.controls.missingDate };
  get missingLocations() { return this.whatHappenedFormGroup.controls.missingLocations };
  get missingOther() { return this.whatHappenedFormGroup.controls.missingOther };
  get lateCare() { return this.whatHappenedFormGroup.controls.lateCare };
  get lateReship() { return this.whatHappenedFormGroup.controls.lateRership };
  get lateRefund() { return this.whatHappenedFormGroup.controls.lateRefund };
  get return() { return this.whatHappenedFormGroup.controls.return };
  get originalTrackingNumber() { return this.whatHappenedFormGroup.controls.originalTrackingNumber };
  get phoneNumber() { return this.contactInfoFormGroup.controls.contact.get('phoneNumber') };
  get countryCode() { return this.contactInfoFormGroup.controls.contact.get('countryCode') };
  get contactEmail() { return this.contactInfoFormGroup.controls.contact.get('emailAddress') };
  get trackingNumber() { return this.startFormGroup.controls.trackingNumber };
  get carrier() { return this.startFormGroup.controls.carrier };

}
