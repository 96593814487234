<div class="red mb-3" *ngIf="showSystemError">{{ 'common.systemError' | translate }}</div>
<div class="spinner d-flex justify-content-center my-5" *ngIf="showSpinner">
  <div class="spinner-border">
    <span class="sr-only">{{ 'guestVerification.form.loading' | translate }}</span>
  </div>
</div>
<div class="confirm-shipment-details-wrapper" *ngIf="!showSpinner">
  <div class="title">{{ 'claims.edit.navi.tupssOne' | translate }}</div>
  <hr>
  <div class="subtitle">{{ 'claims.edit.tupssShipmentDetails.subtitle' | translate }}</div>
  <div class="group d-flex flex-wrap justify-content-between">
    <div class="cluster">
      <div class="label">{{ 'claims.edit.tupssShipmentDetails.trackNo' | translate }}</div>
      <input readonly value="{{ easyDataDisplayObject?.trackingNumber }}">
    </div>
    <div class="cluster">
      <div class="label">{{ 'claimSummaryExport.header.rName' | translate }}</div>
      <input readonly value="{{ easyDataDisplayObject?.name }}">
    </div>
    <div class="cluster">
      <div class="label">{{ 'claimSummaryExport.header.rCity' | translate }}</div>
      <input readonly value="{{ easyDataDisplayObject?.city }}">
    </div>
    <div class="cluster">
      <div class="label">{{ 'claimSummaryExport.header.rState' | translate }}</div>
      <input readonly value="{{ easyDataDisplayObject?.state }}">
    </div>
    <div class="cluster">
      <div class="label">{{ 'claims.edit.review.reviewAndSubmit.reviewList.tupssShipDate' | translate }}</div>
      <input readonly value="{{ easyDataDisplayObject?.date | date: 'MM/dd/yyyy':'UTC' }}">
    </div>
  </div>
  <div class="description" *ngIf="userDetails?.type == 'tfcp'">
    <div class="label">{{ 'claims.edit.tupssShipmentDetails.description' | translate }}</div>
    <textarea readonly value="{{ easyDataDisplayObject?.description }}"></textarea>
  </div>
</div>