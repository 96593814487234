import { Component, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { AccountService } from 'src/app/services/account.service';
import { IVerificationInterface, emptyVerificationInterface } from 'src/app/shared/models/verification.interface';
import { PolicyService } from 'src/app/services/policy.service';
import { Router } from '@angular/router';
import { PolicyEventService } from 'src/app/services/policy-event.service';
import { Title } from '@angular/platform-browser';
import { ALPHA_NUMERIC_AND_SPACES_PATTERN } from 'src/app/shared/regex-patterns';

@Component({
  selector: 'upsc-add-policy',
  templateUrl: './add-policy.component.html',
  styleUrls: ['./add-policy.component.scss']
})
export class AddPolicyComponent {

  public currentStep = 1;
  public policyFormGroup: UntypedFormGroup;

  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;

  userDetails: any;
  scriptError: boolean;
  errorReturned: boolean;
  apiInProgress: boolean;
  systemError: boolean;

  app_Role_ID: string = '';
  claim_Notification: boolean = false;
  groupId: number = 0;
  shipperNumber: string = '00000';
  parentPolicy: boolean = false;
  invalidPolicy: boolean = false;
  emailAddress: string = '';

  public createVerificationInterface: IVerificationInterface = emptyVerificationInterface;

  constructor(private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private accountService: AccountService,
    private policyService: PolicyService,
    private policyEventService: PolicyEventService,
    private titleService: Title
  ) {
    this.titleService.setTitle('Add New Policy');
    this.setUserInfo();
    this.setAppRoleIdNClaimsNotification();
    this.setpolicyFormGroupInfo();
  }

  setUserInfo() {
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();
    this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
  }

  setpolicyFormGroupInfo() {
    //this.userDetails.emailAddress : '15914024-P0004050' '11201'
    this.policyFormGroup = this.formBuilder.group({
      policyNumberFormControl: ['', [Validators.required, Validators.minLength(6)]],
      emailFormControl: [{ value: this.userDetails.emailAddress, disabled: true }, Validators.compose([Validators.required, Validators.email])],
      zipFormControl: ['', [Validators.required, Validators.pattern(ALPHA_NUMERIC_AND_SPACES_PATTERN)]],
    });
  }

  setAppRoleIdNClaimsNotification() {
    var userInfo = this.userService.getUserInfo();
    this.app_Role_ID = userInfo.userRoleID === null ? 0 : userInfo.userRoleID;
    this.groupId = userInfo.groupID === null ? 0 : userInfo.groupID;
    this.shipperNumber = userInfo.shipperNumber === null ? '00000' : userInfo.shipperNumber;
    this.claim_Notification = false;
  }

  public onSubmit() {
    var policyNumber = this.policyFormGroup.get('policyNumberFormControl').value.trim();
    var zipCode = this.policyFormGroup.get('zipFormControl').value.trim();

    if (this.policyFormGroup.valid) {
      this.accountService.policyCheck(policyNumber, zipCode).subscribe(
        data => {
          if (data) {
            this.saveVerificationCode();
            this.emailAddress = this.policyFormGroup.get('emailFormControl').value;
            this.scriptError = false;
            this.invalidPolicy = false;
            this.systemError = false;
          } else {
            this.errorReturned = true;
            this.apiInProgress = false;
            this.invalidPolicy = true;
            this.systemError = false;
          }
        }, error => {
          if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
            this.scriptError = true;
          }
          else {
            this.systemError = true;
            this.apiInProgress = false;
          }
        }
      );
    }
    return false;
  }

  public saveVerificationCode() {
    this.createVerificationInterface.data.emailAddress = this.policyFormGroup.get('emailFormControl').value;
    this.accountService.createVerificationCode(this.createVerificationInterface).subscribe(
      data => {
        if ((data.responseMessage).toLowerCase() === 'new verification code created' ||
          (data.responseMessage).toLowerCase() === 'code not yet expired') {
          this.currentStep = 2;
          this.apiInProgress = false;
        }
      }, error => {
        if ((error.error != null && error.error.errorMessage !== undefined) && ((error.error.errorMessage === 'InvalidInput for field/s.') || (error.error.errorMessage === 'Invalid Input'))) {
          this.scriptError = true;
          this.systemError = false;
          this.apiInProgress = false;
        }
        else {
          this.scriptError = false;
          this.systemError = true;
          this.apiInProgress = false;
        }
      }
    );
  }

  cancelFromVerification() {
    this.currentStep = 1;
  }

  resendCode() {
    this.saveVerificationCode();
  }


  policyError: boolean = false;
  addPolicy() {
    let policyCount = 0;
    if (sessionStorage.getItem("PolicyCount") !== null) {
      policyCount = Number(sessionStorage.getItem("PolicyCount"));
    }

    var childPolicies = this.policyEventService.getChildPolicies();
    if (childPolicies !== undefined && childPolicies) {
      let policy = childPolicies.find(x => x == this.policyFormGroup.get('policyNumberFormControl').value.trim());
      if (policy !== undefined && policy.length > 1) {
        this.policyError = true;
      }
    }

    if (policyCount <= 3 && !this.policyError) {
      let request = {
        docId: 0,
        userID: Number(this.userDetails.userId),
        policyNumber: this.policyFormGroup.get('policyNumberFormControl').value.trim(),
        modifiedBy: 0,
        policyZip: this.policyFormGroup.get('zipFormControl').value.trim(),
        approve: 1,
        active: 0,
        groupId: this.groupId === null ? 0 : this.groupId,
        nickName: this.userDetails.contactName,
        shipperNumber: this.shipperNumber,
        appRoleId: Number(this.app_Role_ID),
        claimNotifications: this.claim_Notification === true ? 1 : 0,
        PolicyHolderName: '',
        ShipperName: ''
      };

      this.policyService.addNewPolicy(request).subscribe(
        data => {
          if (data) {
            sessionStorage.setItem("UserPolicyDetails", "0");
            this.policyEventService.subjectAdded('Added new policy:' + request.policyNumber);
            this.currentStep = 3;
          }
        },
        error => {
          this.systemError = true;
        }
      );     

    }
  }

  addPolicyResponse($event) {
    if ($event === 'resetStep') {
      this.currentStep = 1;
    }
    else if ($event === 'verifyCode') {
      this.addPolicy();
    }
    else {
      this.resendCode();
    }
  }
}
