import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../../shared/components/layout/layout.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { StepperModule } from '../../shared/components/stepper/stepper.module';
import { DashboardComponent } from './dashboard.component';
import {DashboardRoutingModule} from './dashboard.routing.module';
import { ClaimsModule } from '../claims/claims.module';
import { AuthService } from '../../core/services/auth.service';
import { AuthGuardService } from '../../core/services/auth-guard.service';
import { NotificationBoxModule } from 'src/app/shared/components/notification-box/notification-box.module';

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    MatStepperModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatDividerModule,
    SharedModule,
    StepperModule,
    DashboardRoutingModule,
    ClaimsModule,
    NotificationBoxModule,
  ],
  declarations: [ DashboardComponent ],
  providers: [AuthGuardService, AuthService
  ],

})
export class DashboardModule { }
