export const environment = {
  production: false,
  hmr: false,
  baseUrl: {
    web: 'https://online-qa-app.azurewebsites.net/',
    api: 'https://online-qa-api.azurewebsites.net/',
    urlType: 'qa'
  },
  webjob: {
    url: 'https://webjobqamailservice.scm.azurewebsites.net',
    username: '$WebjobQAMailService',
    env: 'QA'
  },
  links: {
    techAgreement: 'https://upscapital.com/technology-agreement/',
    privacyNotice: 'https://upscapital.com/privacy-notice/',
    canadaprivacyNotice: 'https://upscapital.com/ca-en/privacy-notice/',
    canadaProductDisclosures: 'https://vulture.upscapital.com/ca-en/product-disclosures/',
    principlesofConduct: "https://urldefense.com/v3/__https:/5u84u.r.a.d.sendibm1.com/mk/cl/f/1FKr8TkIPnc74pCZgHHnr6I2YDkCcOIrT8bL8CT6AWZXgRjxR7B1tOlmV6_rnhfFxpB-1eAircs44q_x7T2zhPg6YTF1G_LR0A_dFVQj71tOkAvG2XC9T6My3HBKxqFY86MvNsPjV5A_v15CGVAK4B3GX0U2eMkvMu2Vzy0V4OFZuxYGrbI3eDg46B3sXZGiqHewGEgiFHAOpP6K2rkD3_9hKWLqVeMFalPmQ_Q__;!!AQegZw!PaelcAEQR10sdbj38MDu7tWNEDXLM4zhV1VhNifMw97PHJCunT12SFdRgA$",
    factSheet: "https://urldefense.com/v3/__https:/5u84u.r.a.d.sendibm1.com/mk/cl/f/aH9p8Y81SCMKF1zf5DNQeB5-ZBhPWqrxhVZXZ-H8xrqRWAeeI4oOMj6CuqzB-oOnZGU6wz_GrKScNP33tLQRtUdV4NKQ2YfU0TOHfGNvUjH2FzCoOE5zfiscZXWwHhdHk2FiEQcdMWkkj9kAEZJR5BV5tE4DuFoR4ms4NNm2LAyUKyvpZw8Z77NNW12aytr3N9uVaAbdxivjgXoTqfcSjps3NXOro3rP3g__;!!AQegZw!PaelcAEQR10sdbj38MDu7tWNEDXLM4zhV1VhNifMw97PHJCunT2Q2vkiGA$",
    deTechAgreementLink: 'https://www.insureshield.com/de/en/legal/technology-agreement.html',
    deDeTechAgreementLink: 'https://www.insureshield.com/de/de/legal/technology-agreement.html',
    itTechAgreementLink: 'https://www.insureshield.com/it/en/legal/technology-agreement.html',
    itItTechAgreementLink: 'https://www.insureshield.com/it/it/legal/technology-agreement.html',
    frTechAgreementLink: 'https://www.insureshield.com/fr/en/legal/technology-agreement.html',
    frFrTechAgreementLink: 'https://www.insureshield.com/fr/fr/legal/technology-agreement.html',
    ukComplints: 'https://vulture.upscapital.com/gb-en/complaints/',
    ukprivacyNotice: 'https://upscapital.com/gb-en/privacy-notice/',
    ukproductDisclosures: 'https://vulture.upscapital.com/gb-en/product-disclosures/',
    deComplints: 'https://www.insureshield.com/de/en/legal/complaints.html',
    deprivacyNotice: 'https://www.insureshield.com/de/en/legal/privacy-notice.html',
    deproductDisclosures: 'https://www.insureshield.com/de/en/legal/product-disclosure.html',
    deImpressum: 'https://www.insureshield.com/de/en/legal/impressum.html',
    itComplints: 'https://www.insureshield.com/it/en/legal/complaints.html',
    itprivacyNotice: 'https://www.insureshield.com/it/en/legal/privacy-notice.html',
    itproductDisclosures: 'https://www.insureshield.com/it/en/legal/product-disclosure.html',
    frComplints: 'https://www.insureshield.com/fr/en/legal/complaints.html',
    frprivacyNotice: 'https://www.insureshield.com/fr/en/legal/privacy-notice.html',
    frproductDisclosures: 'https://www.insureshield.com/fr/en/legal/product-disclosure.html',
    deDeComplints: 'https://www.insureshield.com/de/de/legal/complaints.html',
    deDeprivacyNotice: 'https://www.insureshield.com/de/de/legal/privacy-notice.html',
    deDeproductDisclosures: 'https://www.insureshield.com/de/de/legal/product-disclosure.html',
    deDeImpressum: 'https://vulture.upscapital.com/de-de/impressum/',
    determsOfuse: 'https://www.ups.com/de/en/support/shipping-support/legal-terms-conditions.page?WT.svl=Footer',
    deDetermsOfuse: 'https://www.ups.com/de/de/support/shipping-support/legal-terms-conditions.page',
    itItComplints: 'https://www.insureshield.com/it/it/legal/complaints.html',
    itItprivacyNotice: 'https://www.insureshield.com/it/it/legal/privacy-notice.html',
    itItproductDisclosures: 'https://www.insureshield.com/it/it/legal/product-disclosure.html',
    ittermsOfuse: 'https://www.ups.com/it/en/support/shipping-support/legal-terms-conditions.page?WT.svl=Footer',
    itIttermsOfuse: 'https://www.ups.com/it/it/support/shipping-support/legal-terms-conditions.page?WT.svl=Footer',
    frFrComplints: 'https://www.insureshield.com/fr/fr/legal/complaints.html',
    frFrprivacyNotice: 'https://www.insureshield.com/fr/fr/legal/privacy-notice.html',
    frFrproductDisclosures: 'https://www.insureshield.com/fr/fr/legal/product-disclosure.html',
    frtermsOfuse: 'https://www.ups.com/fr/en/support/shipping-support/legal-terms-conditions.page?WT.svl=Footer',
    frFrtermsOfuse: 'https://www.ups.com/fr/fr/support/shipping-support/legal-terms-conditions.page?WT.svl=Footer',
    MultiClaimDownloadForm: 'https://hvcm-qa-app.azurewebsites.net/Content/HVCM%20Template.xlsx',
  },
  tealiumEnv: 'dev',
  hpp: {
    paymentServiceUrl: 'https://www.chasepaymentechhostedpay-var.com/securepayments/a1/cc_collection.php',
    contentTemplateUrl: 'https://online-qa-app.azurewebsites.net/assets/hpp/template-qa.html',
    cssUrl: 'https://online-qa-app.azurewebsites.net/assets/hpp/styles.css',
    returnUrl: 'https://online-qa-app.azurewebsites.net/billing/payment-method/return',
    cancelUrl: 'https://online-qa-app.azurewebsites.net/billing/payment-method/add',
    policyNumbers: [
      { policyNumber: '15914024-P6000201',
        policyTermNumber: '1',
        pcAccountNumber: '4687948200'
      },
      { policyNumber: '15914024-P6000186',
        policyTermNumber: '1',
        pcAccountNumber: '1874609624'
      },
      { policyNumber: '15914024-P6000105',
        policyTermNumber: '1',
        pcAccountNumber: '2340083460'
      },
      { policyNumber: '15914024-P6000215',
        policyTermNumber: '1',
        pcAccountNumber: '5730090066'
      },
      { policyNumber: '15914024-P6000226',
        policyTermNumber: '1',
        pcAccountNumber: '3147153040'
      }
    ]
  },
  hpGuestUser: 'aHBHdWVzdFVzZXI=',
  smartUpload: {
    url: 'https://smrtupload-qa-app.azurewebsites.net/?'
  },
  hvcm: {
    url: 'https://hvcm-qa-app.azurewebsites.net/',
    formLink: 'https://hvcm-qa-app.azurewebsites.net/Content/HVCM%20Template.xlsx'
  },
  tupss: {
    // signin: 'https://login.microsoftonline.com/9d0c5465-d03b-48e5-85f5-11c82497e95b/oauth2/authorize?client_id=7685222b-413d-4b20-8bd4-3b3957af12fb&response_type=id_token&redirect_uri=https://online-qa-app.azurewebsites.net/tfcp&scope=openid&state=12345&nonce=12345&dc=ESTS-PUB-WUS2-AZ1-FD000-TEST1&debugmodeflight=true',
    signin: 'https://login.microsoftonline.com/9d0c5465-d03b-48e5-85f5-11c82497e95b/oauth2/authorize?client_id=7685222b-413d-4b20-8bd4-3b3957af12fb&response_type=id_token&redirect_uri=https://online-qa-app.azurewebsites.net/tfcp&scope=openid&state=12345&nonce=12345',
    terms: 'https://uat-flexapp.upscapital.com/ClaimProcessTermsandConditionsofService.pdf'
  },
  recaptcha: {
    // siteKey: '6LfnkAUiAAAAAJRQO9k8GwoCNZnzCebsNJE1VNTT', //This is our normal QA siteKey. Switch back to this if don't need below functionality.
    siteKey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI', //This is a custom siteKey provided by Google in order to run automated scripts for testing purposes. Only using it for QA so QA can get past the recaptcha using their scripts. https://developers.google.com/recaptcha/docs/faq
    siteKeyV3: '6LeVOuYpAAAAAOnuNUJhIqnwKVHCO7J51dAfQkds',
    secretV3: '6LeVOuYpAAAAALVb8M-4S38ugBhEqzQgtZSUCmY-'
  },
  hardCodedValuesForLowerEnvironments: null,
  roleLevelSecurity: {
    signin: 'https://login.microsoftonline.com/e7520e4d-d5a0-488d-9e9f-949faae7dce8/oauth2/authorize?client_id=a15e8fb6-db27-4949-81b8-5ba393f0e90b&response_type=id_token&redirect_uri=https://online-qa-app.azurewebsites.net/internal&scope=openid&state=isop&nonce=170220'
  },
  createRequest: {
    applicationName: 'OnlinePortal',
    emailFor: "AuthorizeNewUser",
    emailForNewAdmin: "NewAdminRegistration",
    environment: "QA"
  },
  rolename: {
    approleName: 'Administrator'
  },
  deliveryDefense: {
    url: 'https://eagle.deliverydefense.com/address-search-tool',
    timeoutUrl: 'https://eagle.deliverydefense.com/'
  },
  myChoice: {
    oAuthUrl: 'https://onlinetools.ups.com/security/v1/oauth/authorize',
    clientId: 'VFo1jfacYmex4C5ptBC5vH3uPgOLXVK35HjglGdarI6136EJ',
    redirectUri: 'ups-my-choice'
  },
  visibility: {
    contaxEnvironment: 'eu10'
  },
  prospect: {
    email: 'lmogos@ups.com'
  }
};
