import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

declare var pendo;

/*
  This file is basically for managing when scripts are downloaded and used.
  Scripts in this sense meaning mainly third party scripts, such as Tealium or Pendo
*/


@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  script_src: string = '';
  reCAPTCHA_site_key = environment.recaptcha.siteKeyV3;

  constructor() {
    //Next 4 lines are for Tealium Tags
    (<any>window).utag_cfg_ovrd = { noview: true };   // Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
    (<any>window).utag_data = {};
    this.setConfig({ account: 'ups', profile: 'ups-capital', environment: environment.tealiumEnv });
    this.getScript(this.script_src, function () { });
  }

  //Call "pendo.validateInstall()" from the browser console if you want to test if pendo has been initialized. It should show the ID's specified below.
  //Right now we only initialize pendo if the user is logged in
  pendoInit() {
    //Only init Pendo in the UAT environment.
    if (environment.baseUrl.urlType != 'uat' && environment.baseUrl.urlType != 'prod') {
      return;
    }

    this.downloadPendo();

    let userDetails = JSON.parse(sessionStorage.getItem('userDetails'))

    //This part actually initializes the Pendo script and starts sending data.
    pendo.initialize({
      visitor: {
        id: userDetails?.objectUID ? userDetails.objectUID : 'uid-not-found,possibly-internal-user' // Originally they wanted it to be IP Address - but that requires API layer to return to the frontend the IP. Instead we use ObjectUID.
        // email:        // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },
      account: {
        id: 'temp-test' // Highly recommended, required if using Pendo Feedback   //further differention between users?
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      }
    });
  }

  //Just downloads the pendo script from the CDN.
  downloadPendo() {
    let p = window;
    let e = document;
    let n = 'script';
    let d = 'pendo';
    let o;

    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = o._q || [];

    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (w = 0, x = v.length; w < x; ++w)(function (m) {
      o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
    })(v[w]);

    y = e.createElement(n);
    y.async = !0;
    y.src = 'https://cdn.pendo.io/agent/static/9cce70a4-43ee-4b6c-76ce-02939428a461/pendo.js';
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  }

  // Generic script loader with callback - For Tealium Scripts
  getScript(src: string, callback: Function) {
    let d = document;
    let o = { callback: callback || function () { } };
    let s, t;

    if (typeof src == "undefined") {
      return;
    }

    s = d.createElement("script"); s.language = "javascript"; s.type = "text/javascript"; s.async = 1; s.charset = "utf-8"; s.src = src;
    if (typeof o.callback == "function") {
      if (d.addEventListener) {
        s.addEventListener("load", function () { o.callback() }, false);
      } else {
        // old IE support
        s.onreadystatechange = function () { if (this.readyState == "complete" || this.readyState == "loaded") { this.onreadystatechange = null; o.callback() } };
      }
    }
    t = d.getElementsByTagName("script")[0];
    t.parentNode.insertBefore(s, t);
  }

  // Config settings used to build the path to the utag.js file - Tealium
  setConfig(config: { account: string, profile: string, environment: string }) {
    if (config.account !== undefined && config.profile !== undefined && config.environment !== undefined) {
      this.script_src = 'https://tags.tiqcdn.com/utag/' + config.account + '/' + config.profile + '/' + config.environment + '/utag.js';
    }
  }

  // Data layer is optional set of key/value pairs - Tealium
  track(tealium_event: string, data?: any) {
    if (this.script_src === '') {
      console.log("Tealium config not set.");
      return;
    }
    if ((<any>window).utag === undefined) {
      this.getScript(this.script_src, function () {
        (<any>window).utag.track(tealium_event, data);
      });
    } else {
      (<any>window).utag.track(tealium_event, data);
    }
  }

  //Tealium
  view(data?: any) {
    this.track("view", data);
  }

  //Tealium
  link(data?: any) {
    this.track("link", data);
  }

  //reCAPTCHA
  downloadCaptcha() {
    let script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${this.reCAPTCHA_site_key}`;
    document.head.appendChild(script);
  }

  loadGlia() {
    // let userInfo = JSON.parse(sessionStorage.getItem('userDetails'));
    this.unloadGlia();
    //if(document.querySelectorAll('script[data-script-identifier="glia"]')?.length == 0) {
      var gliaScript = document.createElement('script');
      /* if (!userInfo) {
        script.src = 'https://api.glia.com/salemove_integration.js';
        script.async = true;
      }
      else { */

      // }
      gliaScript.setAttribute('data-script-identifier', 'glia');
      gliaScript.text = this.generateGliaScript();
      document.head.appendChild(gliaScript);
    /* }
    else {
      gliaScript.text = this.generateGliaScript();
    } */

    /* else {
      if(userInfo) {
        this.unloadGlia();
        this.loadGlia();
      }
    } */
  }

  generateGliaScript() {
    return `var installGlia = function(a) {
            !function(a, b, c, d) {
              var e = a.createElement(b),
              f = a.getElementsByTagName(b)[0];
              e.async = 1;
              e.src = c;
              e.type = "text/javascript";
              e.setAttribute("data-script-identifier","glia");
              d && e.addEventListener("load", d);
              f.parentNode.insertBefore(e, f);
            } (document, "script", "https://api.glia.com/salemove_integration.js", a)
          };
          installGlia(function() {
            let userInfo = JSON.parse(sessionStorage.getItem('userDetails'));
            let businessName = userInfo?.insured ? userInfo.insured : userInfo?.policyHoldersName;
            sm.getApi({version: 'v1'}).then(function(glia) {
              glia.updateInformation({
                phone: userInfo?.contactPhone,
                email: userInfo?.emailAddress,
                name: userInfo?.contactName,
                externalId: userInfo?.policyNumber,
                note: "",
                customAttributes: {
                  zipCode: userInfo?.zipCode,
                  business: businessName
                }
              }).then(function() {
                //Callback fired after the Visitor Information has
                //been successfully updated
              }).catch(function(error) {
                //Callback fired when the request for updating the
                //Visitor Information fails
              });
            });
          });`
  }

  unloadGlia() {
    document.querySelectorAll('script[data-script-identifier="glia"]')?.forEach(
      el => el.remove()
    );
  }
}
