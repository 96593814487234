<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<div class="footer" *ngIf="showONLFooter">
  <upsc-footer></upsc-footer>
</div>
<div class="footer" *ngIf="showInsureshieldFooter">
  <upsc-insureshield-footer></upsc-insureshield-footer>
</div>