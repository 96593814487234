import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TupssService } from 'src/app/pages/claims-custom/tupss/services/tupss.service';
import { ClaimService } from 'src/app/services/claim.service';
import { columnSequences } from '../../models/claim.interface';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'upsc-column-settings',
  templateUrl: './column-settings.component.html',
  styleUrls: ['./column-settings.component.scss']
})



export class ColumnSettingsComponent {
  constructor(
    public dialogRef: MatDialogRef<ColumnSettingsComponent>,
    public claimService: ClaimService,
    private tupssService: TupssService,
    private translateService: TranslateService,
    private userService: UserService,
    ) { 
      this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
      
    }

  @Output() filterResult = new EventEmitter<any>();
  userDetails;
  layoutOptionsControl: UntypedFormControl = new UntypedFormControl({ value: '', disabled: false }, Validators.required,);
  nameControl: UntypedFormControl = new UntypedFormControl({ value: '', disabled: false }, Validators.required,);

  options = [{ item1: 'Default', item2: 'Default' }];
  maxLayoutsReached: boolean = false;
  layoutColumns: columnSequences[] = [];
  parentIndicater: boolean = false;
  EAffiliateUser: boolean = false;
  totalColumnCount = 11;
  filters;
  filtersSelected = 0;
  currentStep = 1;

  showSuccessfulEditMessage: boolean = false;
  editFilterFlow: boolean = false;
  editFilterId;
  editFilterName;

  deleteLayoutError: boolean = false;
  tooManyLayoutsError: boolean = false;
  systemError: boolean = false;

  ngOnInit(): void {
    this.getUserType();
    this.resetFilters();
    this.getUserLayouts();
  }

  getUserType()
  {
    this.EAffiliateUser = this.userService.isEAUserorNonEA(); 
    if(this.EAffiliateUser) {
      this.totalColumnCount = 14;
      this.parentIndicater = this.userService.isParentPolicy();
    }
  }

  public close(){
    this.dialogRef.close();
  }

  // helper functions
  defaultFilters() {
    this.filters = [
      { value: 17, label: this.translateService.instant('columnSettings.columns.policyNumber'), selected: this.parentIndicater == true ? true : false, disabled: true, display: (this.parentIndicater == true) && (this.EAffiliateUser == true) ? true : false },
      { value: 18, label: this.translateService.instant('columnSettings.columns.shipperName'), selected: this.parentIndicater == true ? true : false, disabled: true, display: (this.EAffiliateUser == true) ? true : false },
      { value: 19, label: this.translateService.instant('columnSettings.columns.shipperNumber'), selected: this.parentIndicater == true ? true : false, disabled: true, display: (this.EAffiliateUser == true) ? true : false },
      { value: 3, label: this.translateService.instant('columnSettings.columns.receiver'), selected: true, disabled: false, display: (this.EAffiliateUser == true) ? true : false  }, //this will be displayed for EA Users
      { value: 1, label: this.translateService.instant('columnSettings.columns.claimNumber'), selected: true, disabled: true },
      { value: 2, label: this.translateService.instant('columnSettings.columns.claimStatus'), selected: true, disabled: true },
      { value: 16, label: this.translateService.instant('columnSettings.columns.fileDate'), selected: true, disabled: true },

      { value: 3, label: this.translateService.instant('columnSettings.columns.receiver2'), selected: true, disabled: false, display: (this.EAffiliateUser == false) ? true : false  }, //this will be displayed for Non EA Users

      { value: 4, label: this.translateService.instant('columnSettings.columns.paymentRecipient'), selected: false, disabled: false },
      { value: 5, label: this.translateService.instant('columnSettings.columns.paidAmount'), selected: true, disabled: false },
      { value: 6, label: this.translateService.instant('columnSettings.columns.trackingNumber'), selected: true, disabled: false },
      { value: 7, label: this.translateService.instant('columnSettings.columns.adjuster'), selected: false, disabled: false },
      { value: 8, label: this.translateService.instant('columnSettings.columns.achNumber'), selected: true, disabled: false },
      { value: 9, label: this.translateService.instant('columnSettings.columns.achDate'), selected: true, disabled: false},
      { value: 10, label: this.translateService.instant('columnSettings.columns.loss'), selected: false, disabled: false },
      { value: 11, label: this.translateService.instant('columnSettings.columns.filedAmount'), selected: true, disabled: false },
      { value: 12, label: this.translateService.instant('columnSettings.columns.accountNumber'), selected: true, disabled: false },
      { value: 13, label: this.translateService.instant('columnSettings.columns.declared'), selected: false, disabled: false },
      { value: 14, label: this.translateService.instant('columnSettings.columns.reference'), selected: true, disabled: false },
      { value: 15, label: this.translateService.instant('columnSettings.columns.submission'), selected: false, disabled: false }
    ];
    this.countSelectedFilters();
  }

  resetFilters() {
    this.filters = [
      { value: 17, label: this.translateService.instant('columnSettings.columns.policyNumber'), selected: this.parentIndicater == true ? true : false, disabled: true, display: (this.parentIndicater == true) && (this.EAffiliateUser == true) ? true : false },
      { value: 18, label: this.translateService.instant('columnSettings.columns.shipperName'), selected: this.EAffiliateUser == true ? true : false, disabled: true, display: (this.EAffiliateUser == true) ? true : false },
      { value: 19, label: this.translateService.instant('columnSettings.columns.shipperNumber'), selected: this.EAffiliateUser == true ? true : false, disabled: true, display: (this.EAffiliateUser == true) ? true : false },
      { value: 3, label: this.translateService.instant('columnSettings.columns.receiver'), selected: false, disabled: false, display: (this.EAffiliateUser == true) ? true : false  }, //this will be displayed for EA Users
      { value: 1, label: this.translateService.instant('columnSettings.columns.claimNumber'), selected: true, disabled: true },
      { value: 2, label: this.translateService.instant('columnSettings.columns.claimStatus'), selected: true, disabled: true },
      { value: 16, label: this.translateService.instant('columnSettings.columns.fileDate'), selected: true, disabled: true },
      { value: 3, label: this.translateService.instant('columnSettings.columns.receiver2'), selected: false, disabled: false, display: (this.EAffiliateUser == false) ? true : false  }, //this will be displayed for Non EA Users
      { value: 4, label: this.translateService.instant('columnSettings.columns.paymentRecipient'), selected: false, disabled: false },
      { value: 5, label: this.translateService.instant('columnSettings.columns.paidAmount'), selected: false, disabled: false },
      { value: 6, label: this.translateService.instant('columnSettings.columns.trackingNumber'), selected: false, disabled: false },
      { value: 7, label: this.translateService.instant('columnSettings.columns.adjuster'), selected: false, disabled: false },
      { value: 8, label: this.translateService.instant('columnSettings.columns.achNumber'), selected: false, disabled: false },
      { value: 9, label: this.translateService.instant('columnSettings.columns.achDate'), selected: false, disabled: false},
      { value: 10, label: this.translateService.instant('columnSettings.columns.loss'), selected: false, disabled: false },
      { value: 11, label: this.translateService.instant('columnSettings.columns.filedAmount'), selected: false, disabled: false },
      { value: 12, label: this.translateService.instant('columnSettings.columns.accountNumber'), selected: false, disabled: false },
      { value: 13, label: this.translateService.instant('columnSettings.columns.declared'), selected: false, disabled: false },
      { value: 14, label: this.translateService.instant('columnSettings.columns.reference'), selected: false, disabled: false },
      { value: 15, label: this.translateService.instant('columnSettings.columns.submission'), selected: false, disabled: false }
    ];
    this.countSelectedFilters();
  }

  countSelectedFilters() {
    this.filtersSelected = 0;
    for (let i = 0; i < this.filters.length; i++) {
      if (this.filters[i].selected) {
        this.filtersSelected += 1;
      }
    }

    // if the count reaches 14, disable selection for all other values
    if (this.filtersSelected >= this.totalColumnCount) {
      for (let i = 0; i < this.filters.length; i++) {
        if (!this.filters[i].selected) {
          this.filters[i].disabled = true;
        }
      }
    } else {
      for (let i = 0; i < this.filters.length; i++) {
        if (i > 2) { // first 3 filters cannot be unselected by the user
          this.filters[i].disabled = false;
        }
      }
    }
  }

  // Retrieve all of the user's current saved layouts
  getUserLayouts() {
    let request = {
      "email": this.userDetails.emailAddress ? this.userDetails.emailAddress : this.tupssService.userData && this.tupssService.userData.userPrincipalName ? this.tupssService.userData.userPrincipalName : sessionStorage.getItem('guestUserEmail') ? sessionStorage.getItem('guestUserEmail') : '',
      "userId": this.userDetails.userId ? this.userDetails.userId : '',
    }
    this.claimService.getUserLayout(request).subscribe(
      data => {
        this.maxLayoutsReached = false;
        try {
          if (data.data.userLayouts) {
            this.options = [{ item1: 'Default', item2: 'Default' }];

            if (data.data.userLayouts.length >= 3) {
              this.maxLayoutsReached = true;
            }
  
            for (let i = 0; i < data.data.userLayouts.length; i++) {
              this.options.push(data.data.userLayouts[i]);
            }
          }
        } catch (e) {    
          this.options = [{ item1: 'Default', item2: 'Default' }]; 
        }
      }, error => {
        this.options = [{ item1: 'Default', item2: 'Default' }]; 
      }
    );
  }

  // initial landing page functions (currentStep == 1)
  editLayout(option: any) {
    this.showSuccessfulEditMessage = false;
    this.systemError = false;
    this.editFilterId = option.item1;
    this.editFilterName = option.item2;
    this.editFilterFlow = true;
    // Get saved layout details so we can pre-populate them on the next page for the user to edit
    let request = {
      "email": this.userDetails.emailAddress ? this.userDetails.emailAddress : this.tupssService.userData && this.tupssService.userData.userPrincipalName ? this.tupssService.userData.userPrincipalName : sessionStorage.getItem('guestUserEmail') ? sessionStorage.getItem('guestUserEmail') : '',
      "userId": this.userDetails.userId ? this.userDetails.userId : '',
      "layoutId": this.editFilterId
    }
    this.claimService.getUserLayoutDetails(request).subscribe(
      data => {
        this.applyPreExistingFilters(data.data.columns);
        this.currentStep = 2;
      }, error => {
        this.systemError = true;
      }
    );
  }

  deleteLayout(option) {
    this.deleteLayoutError = false;
    this.systemError = false;
    let request = {
      "layoutId": option.item1,
      "userId": this.userDetails.userId ? this.userDetails.userId : '',
    }
    this.claimService.removeUserLayout(request).subscribe(
      data => {
        if (data.code == 200 && data.message == "LayoutDeleted") {
          this.deleteLayoutError = false;
          this.getUserLayouts();
        } else {
          this.deleteLayoutError = true;
        }
      }, error => {
        this.deleteLayoutError = true;
      }
    ); 
  }

  applyExistingLayout() {
    if (this.layoutOptionsControl.value == 'Default') {
      this.defaultFilters();
      this.filterResult.emit(this.filters); 
      this.dialogRef.close();
    } else {
      this.systemError = false;
      let request = {
        "email": this.userDetails.emailAddress ? this.userDetails.emailAddress : this.tupssService.userData && this.tupssService.userData.userPrincipalName ? this.tupssService.userData.userPrincipalName : sessionStorage.getItem('guestUserEmail') ? sessionStorage.getItem('guestUserEmail') : '',
        "userId": this.userDetails.userId ? this.userDetails.userId : '',
        "layoutId": this.layoutOptionsControl.value
      }
      this.claimService.getUserLayoutDetails(request).subscribe(
        data => {
          if (data) {
            this.systemError = false;
            this.applyPreExistingFilters(data.data.columns);
            this.filterResult.emit(this.filters); 
            this.dialogRef.close();
          }
        }, error => {
          this.systemError = true;
        }
      );
    }
  }

  applyPreExistingFilters(columns) {
    for (let i = 0; i < this.filters.length; i++) {
      if (columns.some((x) => x == this.filters[i].value)) {
        this.filters[i].selected = true;
      } else {
        this.filters[i].selected = false;
      }
    }
    this.countSelectedFilters();
  }

  createNewLayout() {
    if (this.maxLayoutsReached) {
      this.tooManyLayoutsError = true;
    } else {
      this.tooManyLayoutsError = false;
      this.deleteLayoutError = false;
      this.systemError = false;
      this.editFilterFlow = false;
      this.editFilterName = '';
      this.editFilterId = undefined;
      this.showSuccessfulEditMessage = false;
      this.resetFilters();
      this.currentStep = 2;
    }
  }

  resetCurrentLayout() {
    this.defaultFilters();
    this.filterResult.emit(this.filters); 
    this.dialogRef.close();
  }

  // select filters/layout page functions (currentStep == 2)
  select(filter) {
    filter.selected = !filter.selected;
    this.countSelectedFilters();
  }

  applyLayoutWithoutSaving() {
    this.filterResult.emit(this.filters); 
    this.dialogRef.close();
  }

  nameLayout() {
    if (this.editFilterFlow) {
      this.nameControl.setValue(this.editFilterName);
    }
    this.systemError = false;
    this.currentStep = 3;
  }

  goBackToLandingPage() {
    this.resetFilters();
    this.systemError = false;
    this.currentStep = 1;
  }

  // name and save layout page functions (currentStep == 3)
  goBack() {
    this.systemError = false;
    this.currentStep = 2;
  }

  getColumnDetails() {
    this.layoutColumns = [];
    for (let i = 0; i < this.filters.length; i++) {
      var test: columnSequences = {};
      if (this.filters[i].selected) {
        test.columnId = this.filters[i].value;
        test.sequenceNumber = i;
        this.layoutColumns.push(test);
      }
    }
  }

  saveCreatedLayout() {
    if (this.editFilterFlow) {
      this.saveLayout(this.nameControl.value, this.editFilterId);
    } else {
      this.saveLayout(this.nameControl.value);
    }
  }

  saveLayout(layoutName?: any, layoutId?: any) {
    this.showSuccessfulEditMessage = false;
    this.systemError = false;
    this.getColumnDetails();
     let request = {
        "userId": this.userDetails.userId ? this.userDetails.userId : '',
        "layoutId": layoutId ? layoutId : '',
        "layoutName": layoutName,
        "email": "ddpolicy@ups.com",
        "columnSequences": this.layoutColumns
      };
      this.claimService.submitUserLayoutDetails(request).subscribe(
        data => {
          if (data && data.data.layoutId) {
            if (this.editFilterFlow) {
              this.showSuccessfulEditMessage = true;
              this.getUserLayouts();
              this.currentStep = 1;
            } else {
              this.filterResult.emit(this.filters); 
              this.dialogRef.close();
            }
          }
        }, error => {
          this.systemError = true;
        }
      );
  }
}
