import { Component, HostBinding, OnInit, ViewChild, Input, ElementRef, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { LoginService } from '../../../../services/login.service';
import { IPolicies } from '../../../../shared/models/policies.interface';
import { ClaimService } from '../../../../services/claim.service';
import { retry } from 'rxjs/operators';
import { IClaimsDetails, ClaimSummary, ClaimSummaryFormatted, listOfClaims, ISummary } from '../../../../shared/models/claim.interface';
import { PagerService } from '../../../../services/pager.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DecimalPipe, formatDate } from '@angular/common';
import { ExportComponent } from '../../dialogs/export/export.component';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatLegacyRadioGroup as MatRadioGroup } from '@angular/material/legacy-radio';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { DeleteDraft } from 'src/app/shared/models/delete-draft.interface';
import { DraftDeleteConfirmationComponent } from '../../dialogs/draft-delete-confirmation/draft-delete-confirmation.component';
import { AccountInfo } from 'src/app/shared/models/account-info.interface';
import { environment as ENV } from 'src/environments/environment';
import { UserPermission } from 'src/app/shared/models/user-permission';
import { PolicyService } from 'src/app/services/policy.service';
import { BillingService } from 'src/app/services/billing.service';
import { CheckFeePopupComponent } from 'src/app/shared/components/check-fee-popup/check-fee-popup.component';

@Component({
  selector: 'upsc-draft-list',
  templateUrl: './draft-list.component.html',
  styleUrls: ['./draft-list.component.scss']
})
export class DraftListComponent implements OnInit {


  //For testing
  private expandState = new Map<string, boolean>();
  public claimDetails: IClaimsDetails = {}
  public isCollapsed = false;
  public summary: ISummary = {};
  public policy: IPolicies;
  claimSummary: ClaimSummary[] = [];
  claimModelCollection: listOfClaims[];
  claim: ClaimSummary;
  claimLowerCase: ClaimSummaryFormatted;
  public PolicyNo: string;
  public policyType: string;
  public searchByValue: any;
  public searchQuery: any = "";
  public searchReceiver: any = "";
  public searchupsAccountNo: any = "";
  public lowerCaseClaimList: any[];
  public calendarBool: boolean = false;
  public amountBool: boolean = false;
  public isEnabled: boolean = false;
  public applysearchReceiver: boolean = false;
  public isValidReceiver: boolean = false;
  public applysearchUpsAccNo: boolean = false;
  public dataInObj: Object;
  public claimSummaryJson: any[] = [];
  public userDetails: AccountInfo = {};
  isInsureShield: boolean = false;
  loading: boolean = true;
  dwDetails;
  policyDetails;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatRadioGroup) category: MatRadioGroup;
  @ViewChild('searchButton') searchButton: ElementRef;
  @HostBinding('class') public hostClass = 'claim-list';
  @Input() isPagination: boolean = true;
  @Input() isParentSearc: boolean = false;

  @Output() onDelete = new EventEmitter<boolean>(false);
  @Output() onDraftLoad: EventEmitter<any> = new EventEmitter<any>()

  public userPermission: UserPermission = new UserPermission();
  manualPage: number;
  pager: any = {};
  allClaimIds: string[];
  public currentClaim;
  pagedItems: any[];
  isDataPresent: boolean =  false;

  deleteDraftRequest: DeleteDraft = {};

  searchTypeControl: UntypedFormControl = new UntypedFormControl({ value: '', disabled: false }, Validators.required,);
  searchReceiverControl: UntypedFormControl = new UntypedFormControl('', Validators.minLength(3));
  searchUpsAcControl: UntypedFormControl = new UntypedFormControl('', Validators.required);

  searchByValueControl: UntypedFormControl = new UntypedFormControl({ value: 'Receiver', disabled: true }, Validators.compose([Validators.required]));
  categorySelected: boolean;
  queryEntered: boolean;
  emptySearchResult: boolean = false;
  emptyClaimResult: boolean = false; //Shows "No claim found" on List. Only happens when account has no claims on it.
  defaultSearch: boolean = true;
  advanceSearch: boolean = false;
  isClaimSearch: boolean = false;
  isReceiverSearch: boolean = false;
  isTrackingSearch: boolean = false;
  valueErrors: boolean = true;
  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  isTupssUser: boolean;
  isGuestUser: boolean;
  tupssSiteId: string;
  hvcmFlow: boolean;
  systemError: boolean = false;
  panelIsOpen: boolean = false;
  searchBarPlaceholder: string = "Receiver";
  pageSize: number  = 10;

  isClaimFiler:boolean;
  searchBy = [
    { value: 'claimNumber', label: 'Claim #' },
    { value: 'fileDate', label: 'File Date' },
    { value: 'totalPaidAmount', label: 'Total Paid Amount' },
    { value: 'trackingNumber', label: 'Tracking #/BOL' },
    { value: 'referenceNumber', label: 'Reference #' },
    { value: 'receiver', label: 'Receiver' }
  ];

  searchFilters = [
    { value: '0', label: 'Receiver' },
    { value: '1', label: 'Tracking Number' }
  ];


  columns = [
    { columnDef: 'fileDate', cell: (element: ClaimSummary) => `${element.fileDate}` },
    { columnDef: 'tracking', cell: (element: ClaimSummary) => `${element.tracking}` },
    { columnDef: 'receiver', cell: (element: ClaimSummary) => `${element.receiver}` },
    { columnDef: 'claimStatus', cell: (element: ClaimSummary) => `${element.claimStatus}` },
    { columnDef: 'reference', cell: (element: ClaimSummary) => `${element.reference}` }
  ];

  displayedColumns = this.columns.map(c => c.columnDef);

  toggleRow(claimId: string) {
    this.expandState.set(claimId, !this.expandState.get(claimId));
  }

  getExpandStatus(claimId: string): boolean {
    let result: boolean;
    if (this.expandState.has(claimId)) {
      result = this.expandState.get(claimId);
    } else {
      result = false;
      this.expandState.set(claimId, result);
    }
    return result;
  }

  handleOpenChange() {
    this.panelIsOpen = !this.panelIsOpen;
  }

  setPager(event) {
    var currentPage = Number(event.toString());
    this.setPage(currentPage);
  }

  loadAssetPerPage(event) {
    this.pageSize = Number(event.toString());
    this.setPage(1);
  }

  amountSearchQuery;
  claimStatusQuery = [];
  claimClosedOutcome = [];
  dateSearchQuery;
  amountSearch(event) {
    this.amountSearchQuery = {};
    this.amountSearchQuery = event;
  }
  claimStatus(event) {
    if (event == undefined) {
      this.claimStatusQuery = [];
      this.claimClosedOutcome = [];
    }
    else {
      this.claimStatusQuery = event.claimSubStatus;
      this.claimClosedOutcome = event.claimClosedOutcome;
    }
  }
  dateSearch(event) {
    this.dateSearchQuery = {};
    this.dateSearchQuery = event
  }
  toggledefaultSearch() {
    this.defaultSearch = false;
    this.advanceSearch = true;
    this.searchTypeControl.reset();
    this.searchByValueControl.reset();
    this.isEnabled = false;
    this.searchByValueControl.disable();
    sessionStorage.setItem('isadvanced', 'true');
    sessionStorage.removeItem('isdefault');

  }
  toggleadvanceSearch() {
    this.defaultSearch = true;
    this.advanceSearch = false;
    this.searchTypeControl.enable()
    sessionStorage.removeItem('isadvanced');
    sessionStorage.setItem('isdefault', 'true');
    sessionStorage.removeItem('pageId');
    this.claimservice.clearFilters();
    this.resetReceiver();
  }

  constructor(
    private pagerService: PagerService,
    public dialog: MatDialog,
    public loginservice: LoginService,
    public claimservice: ClaimService,
    public userService: UserService,
    private ref: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private number: DecimalPipe,
    private router: Router,
    private translateService: TranslateService,
    private policyService: PolicyService,
    private billingService: BillingService
  ) {
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();
    if (this.isTupssUser = this.userService.isTupssUser()) {
      this.tupssSiteId = this.userService.getUserInfo().siteId;
    }
    this.isGuestUser = this.userService.isGuestUser();
    this.userDetails = this.userService.getUserInfo();
    this.hvcmFlow = this.router.url.endsWith('MultiClaim');
    this.userPermission = this.userService.getUserPermissions();
    this.isClaimFiler = this.userService.getUserPermissions()?.isFileClaim ? true : false;
    this.dwDetails = this.loginservice.getDWDetails();
    this.policyDetails = this.loginservice.getPolicyDetails();
  }

  reset() {
    this.claimservice.clearFilters();
    this.applysearchReceiver = false;
    this.isValidReceiver = false;
    this.applysearchUpsAccNo = false;
    this.searchReceiver = "";
    this.searchupsAccountNo = "";
    this.searchReceiverControl.reset();
    this.searchUpsAcControl.reset();
    this.refreshList();
  }

  getUtcDate(stringDate: string) {
    var date = new Date(stringDate);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  }

  setPage(page: number) {
    this.isDataPresent = false;
    this.pager = this.pagerService.getPager(this.allClaimIds.length, page, this.pageSize);
    let pageIds = [];
    
    if (this.isPagination) {
      pageIds = this.allClaimIds.slice(this.pager.startIndex, this.pager.endIndex + 1);
    }
    else {
      pageIds = this.allClaimIds.slice(this.pager.startIndex, (this.pager.endIndex > 4 ? 4 : this.pager.endIndex) + 1);
    }

    this.claimservice.getClaimByIds(this.policy.policyNumber, pageIds).subscribe(
      datac => {
        this.loading = false;
        let unSortedClaims = datac.items.map(claim => {
          var trackingNumber = "";
          var receiverDetails = "";
          var claimStatus = "";
          var fileDate = "";
          var claimNumber = "";
          var savedTime = "";

          try {
            claimNumber = claim.claimNumber;
            fileDate = claim.fileDate;
            claimStatus = this.getUtcDate(claim.fileDate);
            trackingNumber = claim.shippingDetails.trackingNumber;
            receiverDetails = claim.shippingDetails.receiverDetails == null ? "" : claim.shippingDetails.receiverDetails.companyName;    
            savedTime = claim.savedTime;    
          }
          catch (e) { }

          return {
            claimNumber: claimNumber,
            fileDate: fileDate,
            claimStatus: claimStatus,
            tracking: trackingNumber,
            receiver: receiverDetails,
            reference: "Saved ",
            savedTime: savedTime
          }

        });
        let sortedClaims = [];
        pageIds.forEach(id => {
          let matchedClaim = unSortedClaims.find(c => c.claimNumber == id);
          if (matchedClaim) {
            sortedClaims.push(matchedClaim);
          }
        });
        if(sortedClaims.length > 0)
        {
          this.pagedItems = sortedClaims;
          this.isDataPresent = true;
        }
        
        this.onDraftLoad.emit(true);
      },
      error => 
      {
        this.loading = false;
        this.pagedItems = [];
        this.isDataPresent = false;
        this.onDraftLoad.emit(false);
      }
    );
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  get isScreenMedium() {
    return this.breakpointObserver.isMatched('(max-width: 992px)');
  }  

  ngOnInit() {

    if (!this.isUsaUser) {
      this.router.navigate(['claims']);
    }

    if (sessionStorage.getItem('retainState') && sessionStorage.getItem('isPostBack')) {
      if (sessionStorage.getItem('isadvanced') && sessionStorage.getItem('isSearchMade')) {
        this.advanceSearch = true;
        this.defaultSearch = false;
        let receiver = sessionStorage.getItem('receiverInfo');
        let upsaccount = sessionStorage.getItem('accountInfo');
        if (receiver) {
          this.applysearchReceiver = true;
          this.searchReceiver = receiver.replace(/['"]+/g, '');
        }
        else { this.searchReceiver = ''; }
        if (upsaccount) {
          this.applysearchUpsAccNo = true;
          this.searchupsAccountNo = upsaccount.replace(/['"]+/g, '');
        }
        else { this.applysearchUpsAccNo = false; }
        let data = JSON.parse(localStorage.getItem('paramsDrafts'));
        if (data != null) {
          this.getClaimSummary(data);          
        }
      }
      else if (sessionStorage.getItem('isdefault') && sessionStorage.getItem('isSearchMade')) {
        this.advanceSearch = false;
        this.defaultSearch = true;
        let defaultSearchInfo = JSON.parse(sessionStorage.getItem('defaultType'));
        if (defaultSearchInfo) {
          this.isEnabled = true
          this.searchByValueControl.enable();
          this.searchTypeControl.setValue(defaultSearchInfo.type);
          this.searchByValueControl.setValue(defaultSearchInfo.value);
          let data = JSON.parse(localStorage.getItem('paramsDrafts'));
          if (data != null) {
            this.getClaimSummary(data);
          }
        }
      }
      else if (sessionStorage.getItem('isadvanced')) {
        this.advanceSearch = true;
        this.defaultSearch = false;
        let data = JSON.parse(localStorage.getItem('paramsDrafts'));
        if (data != null) {
          this.getClaimSummary(data);
        }
        else {
          this.getClaimSummary();
        }
      }
      else {
        this.getClaimSummary();
        this.advanceSearch = false;
        this.defaultSearch = true;
        this.claimservice.clearStorage();
      }
    }
    else {
      this.getClaimSummary();
      this.claimservice.clearStorage();
      sessionStorage.removeItem('pageId');
    }
    sessionStorage.removeItem('isPostBack');
    this.policy = this.loginservice.getPolicyDetails();
    this.PolicyNo = this.policy.policyNumber;
    this.policyType = this.policyService.determinePolicyType(this.policy);
    if (this.policyType == 'transactional_parcel' || this.policyType == 'transactional_cargo' || this.policyType == 'traditional_cargo') {
      this.policyType = "InsureShield";
      this.isInsureShield = true;
    } else {
      this.policyType = `${this.policy.productName} ${this.policy.subPolicyType}`;
      this.isInsureShield = false;
    }
  }
  ngAfterViewInit() {
    
    this.searchReceiverControl.valueChanges.subscribe(
      val => {
        if (val && val.length > 0) {
          this.queryEntered = true;
        }
        else {
          this.queryEntered = false;
        }
        this.ref.detectChanges();
      }
    );
    this.searchTypeControl.valueChanges.subscribe(
      val => {
        this.searchByValueControl.reset();
        this.searchByValue = val;
        this.isClaimSearch = false;
        this.isTrackingSearch = false;
        this.valueErrors = false;
        this.searchByValueControl.markAsUntouched({ onlySelf: true });
        this.searchBarPlaceholder = "";
        if (val && val.length > 0) {
          this.searchByValueControl.enable();
          if (val == '0') {
            this.valueErrors = true;
            this.isReceiverSearch = true;
            this.searchBarPlaceholder = "Receiver";
            //this.searchQuery = "receiver";
            this.isTrackingSearch = false;
          } else if (val == '1') {
            this.valueErrors = true;
            this.isTrackingSearch = true;
            this.searchBarPlaceholder = "Tracking Number";
            //this.searchQuery = "trackingNumber";
            this.isReceiverSearch = false;
          }
          this.queryEntered = true;
          this.isEnabled = true;
        } else {
          this.queryEntered = false;
          this.searchByValueControl.disable();
          this.isEnabled = false;
        }
        this.ref.detectChanges();
      }
    );
  }

  resetSearch() {
    this.emptySearchResult = false;
    this.isReceiverSearch = false;
    this.isTrackingSearch = false;
  }

  //After user is done searching, refresh the list back to normal
  public refreshList() {
    this.searchQuery = "";
    this.searchByValueControl.reset();
    localStorage.removeItem('paramsDrafts');
    sessionStorage.removeItem('pageId');
    if (sessionStorage.getItem('isSearchMade')) {
      this.pagedItems = [];
      this.searchTypeControl.reset();
      this.searchByValue = "";
      this.getClaimSummary();
      this.claimservice.clearStorage();
      this.resetSearch();
    }
    this.ref.markForCheck();
  }

  markedAsTouched() {
    this.searchByValueControl.markAsTouched();
  }

  getClaimSummary(filter = {}, search?: boolean): void {

    if(!this.isParentSearc){
      this.policy = this.loginservice.getPolicyDetails();
      this.summary.claimStates = ["draft"];
      this.summary.policyNumber = this.policy.policyNumber;
  
      localStorage.removeItem('paramsDrafts');
      localStorage.setItem('paramsDrafts', JSON.stringify({ ...this.summary, ...filter }));
      this.claimservice.getAllClaimIds({ ...this.summary, ...filter }).subscribe(data => {
        this.allClaimIds = data.claimIds;
        if (this.allClaimIds && this.allClaimIds.length > 0) {
          this.emptySearchResult = false;
          let setPage = JSON.parse(sessionStorage.getItem('pageId'));
          if (setPage) {
            this.setPage(setPage);
          }
          else {
            this.setPage(1);
          }
        }
        else {
          this.loading = false;
          this.handlNoResults(search);
        }
        this.ref.markForCheck();
      },
        error => {
          this.handlNoResults(search);
          this.loading = false;
        }
      );
    }   
  }

  public ngOnDestroy() { }

  dialogRef: any;
  delete(claimNumber) {

    let config = new MatDialogConfig();
    config.autoFocus = false;
    config.closeOnNavigation = true;
    config.width = '515px';
    config.data = "";
    this.dialogRef = this.dialog
      .open(DraftDeleteConfirmationComponent, config)
      .afterClosed().subscribe(result => {
        if (result === "Delete") {
          this.deleteConfirm(claimNumber);
        }
      });
  }

  deleteConfirm(claimNumber) {
    this.deleteDraftRequest.claimNumber = claimNumber;
    this.deleteDraftRequest.policyNumber = this.policy.policyNumber;
    this.claimservice.deleteDraft(this.deleteDraftRequest)
      .subscribe(
        deleteData => {
          this.getClaimSummary();
          this.onDelete.emit(true);
        }, errorDelete => { }
      );
  }

  startDraftClaim(claimNumber) {
    let walletUser = this.userService.isWalletUser();
    let acceptedTCs = this.userService.isWalletTCsAccepted();
    if (walletUser && acceptedTCs) { // Use wallet flow for claims
      sessionStorage.setItem('draftClaimNumber', claimNumber);
      this.startClaimWithWalletAsPayee();
    } else if (this.isUsaUser) { // Retrieve latest wallet summary details to see if they've accepted T&Cs to the wallet or not
      this.checkDWGetSummary(claimNumber);
    } else { // No wallet flow (use DPAT as payee)
      this.redirectToDPAT(claimNumber);
    }
  }

  checkDWGetSummary(claimNumber) {
    this.systemError = false;
    let request = {
      productSourceSystem: "gw",
      sourceSystemIdentifierName: "PolicyNumber",
      sourceSystemIdentifier: this.policyDetails?.policyNumber,
      country: "US",
      roleType: "string",
      productType: "string",
    };

    this.billingService.DWgetSummary(request).subscribe(
      data => {
        this.navigateToCorrectFlow(claimNumber);
      }, error => {
        // 7/20 Release
        // this.systemError = true;
        // 6/27 Release - Do not add error handling or prevent the user from continuing here, the API is failing too often
        this.navigateToCorrectFlow(claimNumber);
      }
    );
  }

  navigateToCorrectFlow(claimNumber) {
    let walletUser = this.userService.isWalletUser();
    let acceptedTCs = this.userService.isWalletTCsAccepted();
    let checkFeeAmount = this.userService.getCheckFeeAmount();
    if (walletUser && acceptedTCs) { // Flow #1: Wallet + TCs: No check fee pop-up, let them begin claim immediately with walletID as payeeId.
      sessionStorage.setItem('draftClaimNumber', claimNumber);
      this.startClaimWithWalletAsPayee();
    } else if (walletUser && !acceptedTCs) {
      // Flow #2: Wallet + no T&Cs + check fee: Display pop-up with two options: proceed to DPAT w/check and be charged a fee, or enroll in wallet for free ach options
      // Flow #3: Wallet + no T&Cs + no check fee: Display pop-up with two options: proceed to DPAT with only check payees showing (no mention of fee needed), or enroll in wallet for ach options
      this.openCheckFeePopup(claimNumber);
    } else if (!walletUser && checkFeeAmount > 0) {
      // Flow #4: No wallet + check fee: Display pop-up with one option: proceed to DPAT with both check and ach payees, acknowledging that if a check fee is chosen, a fee will be charged.
      this.openCheckFeePopup(claimNumber);
    } else {
      // Flow #5: No wallet + no check fee: No change to functionality, let them begin claim.
      this.redirectToDPAT(claimNumber);
    }
  }

  // User starts claim. In claim edit, we will check this sessionStorage value to put them on the wallet flow
  startClaimWithWalletAsPayee() {
    sessionStorage.setItem('claimFileStatus', 'wallet');
    this.router.navigate(['/claims/new'])
    .then(() => {
      window.location.reload();
    });
  }
 
  //Now used to navigate directly to dpat
  redirectToDPAT(claimNumber) {
    this.systemError = false;
    sessionStorage.setItem('draftClaimNumber', claimNumber);
    let checkCountry = sessionStorage.getItem('locale').slice(3, 5);
    let paymentMode = sessionStorage.getItem('paymentMode'); // If paymentMode = 'check', DPAT will only show the user their check payees on the DPAT screens
    sessionStorage.removeItem('paymentMode');
    let request = {
      action: "Get Started",
      paymentDirection: "Claims",
      country: checkCountry ? checkCountry : "US",
      paymentMode: paymentMode ? "check" : "",
      policyIdentifier: this.policy.policyNumber ? this.policy.policyNumber : "",
      email: this.isGuestUser ? sessionStorage.getItem('guestUserEmail') : this.userDetails.emailAddress,
      callBackURL: `${ENV.baseUrl.web}claims/new`,
      scenario: "US",
      policyDetails: this.hvcmFlow ? "hvcm" : "insured",
      createdByUserId: this.userDetails.userId ? this.userDetails.userId : "",
      guestUserIndicator: this.isGuestUser ? "Y" : "N",
      locale: sessionStorage.getItem('locale'),
      tupssFlow: "",
      policyData: {
        insured: this.loginservice.getPolicyDetails().insured
      },
      cudAccess: (this.userService.getUserPermissions()?.isClaimPayee || this.isGuestUser) ? true : false
    }
    this.claimservice.getRedirect(request).subscribe(
      data => {
        window.location.replace(data.data.url);
      }, error => {
        this.systemError = true;
      }
    );
  }

  openCheckFeePopup(claimNumber) {
    let config = new MatDialogConfig();
    config.autoFocus = false;
    // All logic to determine what content to display in the pop-up is located within the pop-up component ts file
    let dialogRef = this.dialog.open(CheckFeePopupComponent, config);
    dialogRef.afterClosed().subscribe(
      data => {
        if (data == 'continueToDPAT') {
          this.redirectToDPAT(claimNumber);
        } else if (data == 'error') {
          this.systemError = true;
        }
      }
    );
  }

  public formatAmount(event, backspace: boolean) {
    let plainNumber = event.replace(/[^-+0-9]/g, '');
    plainNumber = this.number.transform(plainNumber / 100, '1.2-2').replace(/,/g, '');
    return plainNumber;
  }

  trim(event) {
    if (this.searchByValueControl.value) {
      this.searchByValueControl.setValue(this.searchByValueControl.value.trim());
    }
  }

  searchByQuery() {
    if ((this.searchByValueControl.value).length > 0) {
      this.searchQuery = this.searchByValueControl.value
    }
    else {
      this.searchQuery = "";
    }
  }

  checkReceiver() {
    //Check if user can search now
    if ((this.searchReceiverControl.value).length >= 3) {
      this.isValidReceiver = false;
      this.applysearchReceiver = true;
      this.searchReceiver = this.searchReceiverControl.value;
    }
    else {
      this.isValidReceiver = true;
      this.searchReceiver = "";
    }
    sessionStorage.setItem('receiverInfo', JSON.stringify(this.searchReceiver))
  }


  resetReceiver() {
    this.applysearchReceiver = false;
    this.isValidReceiver = false;
    this.searchReceiverControl.reset();
    this.searchReceiver = "";
    sessionStorage.removeItem('receiverInfo');
  }
  
  //Handles claim search
  public search(cat?: string, query?: string) {
    sessionStorage.setItem('isSearchMade', 'true');
    sessionStorage.removeItem('pageId');
    this.isDataPresent = false;
    if (this.defaultSearch) {
      if (cat && query) {
        this.searchByValue = cat;
        this.searchQuery = query;
      }
      
      if (this.searchByValue == '0') {
        if (this.searchQuery.length === 0) {
          this.valueErrors = true;
          this.isReceiverSearch = true;
        }
        else {
          this.getClaimSummary({ receiverCompanyName: this.searchQuery }, true);
        }
      }
      else if (this.searchByValue == '1') {
        if (this.searchQuery.length === 0) {
          this.isTrackingSearch = true;
        }
        else {
          this.getClaimSummary({ trackingNumber: this.searchQuery }, true);
        }
      }

      const defaultSearchInfo = {
        type: this.searchByValue,
        value: this.searchQuery
      }
      sessionStorage.setItem('defaultType', JSON.stringify(defaultSearchInfo));
      sessionStorage.setItem('isdefault', 'true');
    }
  }

  handlNoResults(search?: boolean) {
    this.emptySearchResult = true;
    if (search) {
      this.emptyClaimResult = false;
    } else {
      this.emptyClaimResult = true;
    }
    if (this.isPagination) {
      this.pagedItems = new Array(10);
    }
    else {
      this.pagedItems = new Array(5);
    }
    this.pagedItems.fill({});
  }
}



