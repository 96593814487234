export const PagesThatDontNeedIdleTracking = [
  '/login',
  '/create-account',
  '/login-help',
  '/login-help/forgotPassword',
  '/email-sent',
  '/reminder-sent',
  '/mypolicy',
  '/guestVerify',
  '/unauthorized',
  '/404',
  '/500',
  '/technology-agreement',
  '/maintenance',
  '/tfcp',
  '/tccp',
  '/tupss',
  '/prospect'
]

export const ALLOWED_DOC_TYPES = ['csv', 'doc', 'pdf', 'ps', 'rtf', 'xls', 'ppt', 'pps', 'pptx', 'xlsx', 'docx', 'xls', 'wav', 'wma', 'bmp', 'gif', 'jpg', 'jpg', 'tiff', 'tif', 'mdi', 'png', 'png', 'html', 'htm', 'txt', 'rtx', 'xml', 'mpg', 'mpeg', 'mov', 'avi', 'msg'];