import { UserService } from './../../services/user.service';
import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { TupssService } from '../claims-custom/tupss/services/tupss.service';
import { DynamicFlowService } from 'src/app/services/dynamic-flow.service';

@Component({
  selector: 'upsc-url-handler',
  templateUrl: './url-handler.component.html',
  styleUrls: ['./url-handler.component.scss']
})
export class UrlHandlerComponent {
  constructor(
    private router: Router,
    private userService: UserService,
    private tupssService: TupssService,
    private dynamicFlowService: DynamicFlowService
  ) {
    let returnUrl = 'login';
    let claimNumber = '';
    let url = decodeURIComponent(this.router.url.substring(1)); //Need to call decodeURIComponent because the special characters were being converted to things like %3D, etc in the URL. Decode changes them back into the special character that we expect.
    try {

      // condition to check if flow is not for tupss|tccp|tfcp|unishippers|ajg|inxpress|guest|ups-my-choice
      // redirecting to ssp portal
      if (url[0] &&
        (!url[0].toLowerCase().includes('tupss') || !url[0].toLowerCase().toLowerCase().includes('tccp') || !url[0].toLowerCase().includes('tfcp')
          || !url[0].includes('unishippers') || !url[0].includes('ajg') || !url[0].includes('inxpress')
          || !url[0].includes('guest') || !url[0].includes('ups-my-choice'))
      ) {
        this.router.navigate([`/ssp${this.router.url}`]);
        return;
      } else {
        url = atob(url);
        if (url.includes('ups-my-choice/claim-portal')) {
          this.myChoiceClaimsLanding(url);
          return; //Return here so that it doesn't continue to attempt guest access flow below this.
        } 
        else if (url.indexOf('/') > 0) {
          let urls = url.split('/');
          if (urls[0] && urls[0].includes('ups-my-choice')) { //My Choice Claim Detail
            this.myChoiceClaimDetail(urls);
            return;
          } else if ((urls[0] && this.dynamicFlowService.checkUrlForCcpPortals(urls) === true) || (urls[0] && urls[0] == 'guest')) {
            this.customizedClaimsPortalClaimDetail(urls);
            return;
          } else if (urls[0] && urls[0].includes('-')) {  //TUPSS Claim Detail
            //urls0 = policy number, urls[1] = claim number, urls[2] = type of original claim submitter, urls[3] = email, urls[4] = trackingNumber
            //type should always be tccp actually. TFCP users will never come through this flow
            sessionStorage.setItem('userDetails', JSON.stringify({ policyNumber: urls[0], type: 'tccp', emailAddress: urls[3], trackingNumber: urls[4] }));
            sessionStorage.setItem('cdLink', btoa(url));
            this.tupssService.userDetails = { policyNumber: urls[0], claimNumber: urls[1], type: 'tccp', emailAddress: urls[3], trackingNumber: urls[4] };
            this.router.navigate(['/tupss/detail-verification']);
            return;
          } else {
            url = urls[0];
            claimNumber = urls[1];
            sessionStorage.setItem("GuestclaimNumber", claimNumber);
          }
        }
        this.userService.isValidGuestUser(btoa(url)).subscribe(
          data => {
            if (data.isValid == 'T') {
              this.router.navigate(['/guestVerify']);
            }
            else {
              this.router.navigateByUrl(returnUrl);
            }
          },
          error => this.router.navigateByUrl(returnUrl)
        );
      }

    }
    catch (e) {
      this.router.navigateByUrl(returnUrl);
    }
  }

  myChoiceClaimsLanding(url: string) {
    try {
      let queryParams = url.split('?')[1].split('&');
      this.router.navigate(['/ups-my-choice/claim-portal'], {
        queryParams: {
          policy: queryParams[0].split('=')[1],
          tracking: queryParams[1].split('=')[1],
          email: queryParams[2].split('=')[1]
        }
      });
    } catch (e) {
      this.router.navigate(['/login']);
    }
  }

  myChoiceClaimDetail(urls) {
    if (urls[1]) {
      let params = urls[1].split('?')[1].split('&');
      let policy = params[0].split('=')[1];
      let claimNumber = params[1].split('=')[1];
      let email = params[2].split('=')[1]; //Not really used at the moment
      sessionStorage.setItem('userDetails', JSON.stringify({ policyNumber: policy, claimNumber: claimNumber, type: 'my-choice' }));
      this.router.navigate(['/ups-my-choice/detail']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  customizedClaimsPortalClaimDetail(urls) {
    if (urls[1]) {
      let params = urls[1].split('?')[1].split('&');
      let policy = params[0].split('=')[1];
      let claimNumber = params[1].split('=')[1];
      let email = params[2].split('=')[1]; //Not really used at the moment
      sessionStorage.setItem('userDetails', JSON.stringify({ policyNumber: policy, claimNumber: claimNumber, type: 'customized-claims-portal' }));
      this.router.navigate([`/${urls[0]}/detail`]);
    } else {
      this.router.navigate(['/login']);
    }
  }
}
