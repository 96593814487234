import { Component, OnInit, Input } from '@angular/core';
import { BillingService } from 'src/app/services/billing.service';
import { UserService } from 'src/app/services/user.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ExportComponent } from 'src/app/pages/billing/dialog/export/export.component';
import { defaultBillingTable, Ibilling_Table } from '../../models/billing-list.interface';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'upsc-accordion-and-table',
  templateUrl: './accordion-and-table.component.html',
  styleUrls: ['./accordion-and-table.component.scss']
})
export class AccordionAndTableComponent implements OnInit {
  @Input() dashboardRecentInvoices = false;
  @Input() upcomingInvoices = false;
  @Input() paymentHistory = false;
  @Input() invoices = [];

  billing;
  invoiceListNoPlanned = new Array(); //Also excludes CLOSED status... unless we use it in payment history :)
  invoiceListPlannedOnly = new Array();
  isCanadaUser: boolean;
  isUkUser: boolean;
  isUsaUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  options = [];
  selected = [];
  tableObj: Ibilling_Table = defaultBillingTable;
  download: boolean = true;
  userRoleID: string = "";
  loading: boolean = false;
  userDetails;
  policyDetails;
  canAccessDigitalWallet: boolean;
  walletError: boolean = false;

  constructor(
    private billingService: BillingService,
    public dialog: MatDialog,
    private userService: UserService,
    private loginService: LoginService
  ) {
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();
    this.userRoleID = this.userService.getUserInfo().userRoleID;
    this.userDetails = this.userService.getUserInfo();
    this.policyDetails = this.loginService.getPolicyDetails();
    this.canAccessDigitalWallet = this.userService.canAccessDigitalWallet();
  }

  ngOnInit() {
    this.getBillingInfo();
  }

  // check if the item are selected
  checked(item) {
    if (this.selected.indexOf(item) != -1) {
      return true;
    }
  }

  checkedAll() {
    let allSelected = true;
    for (let i=0; i<this.invoiceListNoPlanned.length; i++) {
      if (this.invoiceListNoPlanned[i].upsBillingType != "DDCustomer" && !this.selected.includes(this.invoiceListNoPlanned[i])) {
        // we found a select-able invoice that wasn't selected so far
        allSelected = false;
      }
    }
    // if none were found in above for loop, that means all select-able invoices were selected. check the select all button
    return allSelected;
  }

  // when checkbox change, add/remove the item from the array
  onChange(checked, item) {
    if (checked) {
      this.download = false;
      this.selected.push(item);
      const jsonDatas = JSON.stringify(this.selected)
      localStorage.setItem('selectedpaymenthistory', jsonDatas)
    } else {
      this.selected.splice(this.selected.indexOf(item), 1)
      if (this.selected.length == 0) {
        this.download = true;
      }
    }
  }

  // when select all checkbox changes, add/remove all items from the array
  onChangeAll(checked) {
    if (checked) {
      // select all invoices except DDCustomer ones
      this.selected = [];
      for (let i=0; i<this.invoiceListNoPlanned.length; i++) {
        if (this.invoiceListNoPlanned[i].upsBillingType != "DDCustomer") {
          this.selected.push(this.invoiceListNoPlanned[i]);
        }
      }
      const jsonDatas = JSON.stringify(this.selected)
      localStorage.setItem('selectedpaymenthistory', jsonDatas)
    } else {
      this.selected = [];
    }
  }

  goToWallet() {
    this.walletError = false;
    let request = {
      action: "accesswallet", //Hardcoded
      navigatingSystem: "onl", //Hardcoded
      navigatingSystemRole: "admin", //Hardcoded (assume admin)
      productSystem: "gw", //Hardcoded (not coming from cbp/dd)
      productSystemIdentifier1: this.policyDetails?.policyNumber,
      productSystemIdentifier1Name: "policyno",
      productSystemIdentifier2: this.policyDetails?.accountNumber,
      productSystemIdentifier2Name: "pcAccountNumber",
      productType: "iscomplete", //Hardcoded (unless we want to change later based on databricks policytype value)
      country: "us", //Harcoded (wallet is US only)
      locale: "en", //Hardcoded (wallet is US only)
      userIdentifier1Name: "createdbyuserid",
      userIdentifier1: this.userDetails?.userId?.toString(),
      displayName: this.userDetails?.contactName, //QUESTION: Use user's name or policy holder's name?
      userEmail: this.userDetails?.emailAddress, //QUESTION: Do we want to use user's info here or policy's email?
      objectUID: this.userDetails?.objectUID,
      callBackURL: "https://online.upscapital.com", //Hardcoded (we don't need this field, remove if possible)
      returnURL: "https://online.upscapital.com" //Hardcoded (we don't need this field, remove if possible)
    };
    this.billingService.DWstartSession(request).subscribe(
      data => {
        if (data?.data?.url) {
          window.open(data?.data?.url, '_blank');
        } else {
          this.walletError = true;
        }
      }, error => {
        this.walletError = true;
      }
    );
  }

  getBillingInfo() {
    if (this.paymentHistory) {
      this.billing = this.invoices;
      for (let i = 0; i < this.billing.payments.length; i++) {
        if (this.billing.payments[i].status == 'Reversed') {
          if (this.billing.payments.find(x => x.publicId == this.billing.payments[i].publicId && (x.status == 'paid'))) {
            //Found a payment for this reversed transaction. Only add the final payment to history - so do nothing here.
          } else {
            this.invoiceListNoPlanned.push(this.billing.payments[i]);
          }
        } else if (this.billing.payments[i].paymentMethod == 'Experience Refund') {
          this.billing.payments[i].status = '';
          this.invoiceListNoPlanned.push(this.billing.payments[i]);
        } else {
          this.invoiceListNoPlanned.push(this.billing.payments[i]);
        }
      }
      for (let i = 0; i < this.billing.disbursements.length; i++) {
        this.invoiceListNoPlanned.push(this.billing.disbursements[i]);
      }
      for (let i = 0; i < this.billing.paymentRequests.length; i++) {
        if (this.billing.paymentRequests[i].status.includes('Closed') || this.billing.paymentRequests[i].status === 'Drafted-Error') {
          if (this.invoiceListNoPlanned.find(x => x.invoiceNumber == this.billing.paymentRequests[i].invoiceNumber)) {
            //don't add it. we already added it from the payments array.
          } else if (this.invoiceListNoPlanned.find(x => x.publicId == this.billing.paymentRequests[i].relatedPayment && (this.billing.paymentRequests[i].relatedPayment != null))) {
            //don't add it. we already added it from the payments array.
          } else {
            this.invoiceListNoPlanned.push(this.billing.paymentRequests[i]);
          }
        }
      }
      this.invoiceListNoPlanned.sort((a, b) => {
        if (b.date && a.date) {
          return <any>new Date(b.date) - <any>new Date(a.date);
        } else if (b.date && a.draftDate) {
          return <any>new Date(b.date) - <any>new Date(a.draftDate);
        } else if (b.draftDate && a.draftDate) {
          return <any>new Date(b.draftDate) - <any>new Date(a.draftDate);
        } else if (b.loseDate && a.date) {
          return <any>new Date(b.draftDate) - <any>new Date(a.date);
        }
      });
    }
    else {
      let source = this.userRoleID === '7' ? 'cbp' : 'gw';
      let policyNumber = this.userService.getUserInfo().policyNumber;
      this.loading = true;

      this.billingService.billingCenterSearch(policyNumber, source).subscribe(
        data => {
          this.billing = data;
          //Different arrays based on which screen is using the accordion + table.
          try {
            if (this.billing) {
              if (this.dashboardRecentInvoices) {
                for (let i = 0; i < this.billing.paymentRequests.length; i++) {
                  if (!this.billing.paymentRequests[i].status.includes('Closed') && !this.billing.paymentRequests[i].status.includes('Drafted-Error') && !this.billing.paymentRequests[i].paymentTechStatus.includes('Payment Reversed')) {
                    if (this.billing.payments.find(x => x.invoiceNumber == this.billing.paymentRequests[i].invoiceNumber)) {
                      //Invoice was found - don't do anything.
                    } else {
                      this.invoiceListNoPlanned.push(this.billing.paymentRequests[i]);
                    }
                  }
                }
                for (let i = 0; i < this.billing.invoices.length; i++) {
                  if (this.billing.invoices[i].status != 'Planned') {
                    if (this.billing.paymentRequests.find(x => x.invoiceNumber == this.billing.invoices[i].invoiceNumber)) {
                      if (this.billing.paymentRequests.find(x => x.invoiceNumber == this.billing.invoices[i].invoiceNumber && (x.status === 'Closed-Declined' || x.status === 'Drafted-Declined' || x.status === 'Closed-Error' || x.status === 'Drafted-Error'))) {
                        if (this.billing.payments.find(x => x.invoiceNumber == this.billing.invoices[i].invoiceNumber)) {
                          if (parseFloat(this.billing.invoices[i].dueAmount) > 0.00) {
                            this.invoiceListNoPlanned.push(this.billing.invoices[i]);
                          }
                        } else {
                          //We know this invoice # has a closed-declined. We know it does NOT have a payment.
                          //If we see a payment drafted, do not add back the 'billed' status invoice
                          if (this.billing.paymentRequests.find(x => x.invoiceNumber == this.billing.invoices[i].invoiceNumber && x.paymentTechStatus == 'Payment Drafted')) {
                            //FEB 28 2022 UPDATE:
                            //Needed to comment out this code, because it caused both the "Due" and "Payment Drafted" to show for the same invoice in account summary.
                            //Commenting out this code makes it so that only the latest status, "Payment Drafted", will show to the user. 
                            //Don't add the billing invoice here, unless due amount is greater than 0
                            // if (parseFloat(this.billing.invoices[i].dueAmount) > 0.00) {
                            //   this.invoiceListNoPlanned.push(this.billing.invoices[i]);
                            // }
                          } else {
                            //Invoice does not have a payment yet, and the paymentrequest was closed-declined. Add this item back to the invoices list. Also check for payment reversals.
                            if (this.billing.paymentRequests.find(x => x.invoiceNumber == this.billing.invoices[i].invoiceNumber && (x.paymentTechStatus == 'Payment Reversed'))) {
                              if (this.billing.payments.find(x => x.invoiceNumber == this.billing.invoices[i].invoiceNumber)) {
                                if (parseFloat(this.billing.invoices[i].dueAmount) > 0.00) {
                                  this.invoiceListNoPlanned.push(this.billing.invoices[i]);
                                }
                              } else {
                                if(this.isCanadaUser) {
                                  this.billing.invoices[i].paymentTechStatus = "Payment Reversed";
                                  this.billing.invoices[i].status = "";
                                }
                                this.invoiceListNoPlanned.push(this.billing.invoices[i]);
                              }
                            } else {
                              this.invoiceListNoPlanned.push(this.billing.invoices[i]);
                            }
                          }
                        }
                      } else if (this.billing.paymentRequests.find(x => x.invoiceNumber == this.billing.invoices[i].invoiceNumber && (x.paymentTechStatus == 'Payment Reversed' || x.status == 'Closed-Approved'))) {
                        let pr = this.billing.paymentRequests.find(x => x.invoiceNumber == this.billing.invoices[i].invoiceNumber && (x.paymentTechStatus == 'Payment Reversed' || x.status == 'Closed-Approved'));
                        if (this.billing.payments.find(x => x.publicId == pr.relatedPayment && (x.status === 'Reversed'))) {
                          if (this.billing.payments.find(x => x.invoiceNumber == this.billing.invoices[i].invoiceNumber)) {
                            if (parseFloat(this.billing.invoices[i].dueAmount) > 0.00) {
                              this.invoiceListNoPlanned.push(this.billing.invoices[i]);
                            }
                          } else {
                            this.invoiceListNoPlanned.push(this.billing.invoices[i]);
                          }
                        }
                      }
                    } else if (this.billing.payments.find(x => x.invoiceNumber == this.billing.invoices[i].invoiceNumber)) {
                      if (parseFloat(this.billing.invoices[i].dueAmount) > 0.00) {
                        this.invoiceListNoPlanned.push(this.billing.invoices[i]);
                      }
                    } else {
                      this.invoiceListNoPlanned.push(this.billing.invoices[i]);
                    }
                  }
                }
                this.invoiceListNoPlanned.sort((a, b) => {
                  return <any>new Date(a.invoiceBillDate) - <any>new Date(b.invoiceNumber);
                });
              } else if (this.upcomingInvoices) {
                for (let i = 0; i < this.billing.invoices.length; i++) {
                  if (this.billing.invoices[i].status != 'Planned') {
                    this.invoiceListNoPlanned.push(this.billing.invoices[i]);
                  } else {
                    this.invoiceListPlannedOnly.push(this.billing.invoices[i]);
                  }
                }
                this.invoiceListPlannedOnly.sort((a, b) => {
                  return <any>new Date(a.invoiceBillDate) - <any>new Date(b.invoiceBillDate);
                });
              }
            }
            this.loading = false;
          } catch (e) {
            //i saw catch execute when disbursements array was not present in the response. try-catch needed.
            this.loading = false;
          }
        },
        error => {
          this.loading = false;
        }
      );
    }
  }

  public openExport() {
    if (this.selected?.length > 0 ) {
      let config = new MatDialogConfig();
      config.autoFocus = false;
      config.width = '515px';
      config.data = { tableObj: this.tableObj, documentList: this.billing.documents }
      let dialogRef = this.dialog.open(ExportComponent, config);
      dialogRef.afterClosed().subscribe(
        closed => {
          localStorage.removeItem('selectedRecentInvoices');
          localStorage.removeItem('selectedpaymenthistory');
        }
      );
      this.selected = [];
    } else {
      this.selected = [];
    }
  }
}
