import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NavigationStart, Router, RouterEvent, NavigationEnd, Scroll } from '@angular/router';
import { CssThemesService } from 'src/app/services/css-themes.service';
import { CustomContactUsComponent } from 'src/app/shared/dialogs/Tupss-contact-us/tupss-contact-us.component';
import { filter, take } from 'rxjs/operators';
import { TupssService } from './tupss/services/tupss.service';
import { Title } from '@angular/platform-browser';
import { TranslatePipe } from '@ngx-translate/core';
import { TaggingService } from 'src/app/tags/tagging.service';
import { DataService } from '../../services/data.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ThemeInfo } from 'src/app/shared/models/theme-info';
import { DynamicFlowService } from 'src/app/services/dynamic-flow.service';
import { TabData } from 'src/app/shared/models/tab-data';

@Component({
  selector: 'upsc-claims-custom',
  templateUrl: './claims-custom.component.html',
  styleUrls: ['./claims-custom.component.scss']
})
export class ClaimsCustomComponent implements OnInit {
  
  showSidebar: boolean = false;
  filingClaim = true;
  tfcp = false;
  tccp = false;
  subNav = false;
  userDetails: any;
  siteIds: string[];
  showPolicy: boolean = false;
  currentUrl: string;
  flowType = this.dataService.determineWhichClaimFlow();  //'my-choice', 'tupss', or 'customized-claims-portal'
  formGroup: UntypedFormGroup;
  isClaimTabActive: boolean = true;
  isFaqTabActive: boolean = false;
  channelPartner: string = '';
  currentTheme: ThemeInfo = {};
  tabData: TabData = {};
  isGuestUser: boolean = false;
  companyLogo: string = '';  

  constructor(
    private router: Router,
    private tupssService: TupssService,
    public contactUsDialog: MatDialog,
    private themeService: CssThemesService,
    private titleService: Title,
    private translateService: TranslatePipe,
    private taggingService: TaggingService,
    private dataService: DataService,
    private formBuilder: UntypedFormBuilder,
    private breakpointObserver: BreakpointObserver,
    private dynamicFlowService: DynamicFlowService,
    @Inject(DOCUMENT) private document: Document
  ) {
    //Form groups
    this.formGroup = this.formBuilder.group({
      policyList: ['']
    });
    sessionStorage.setItem("flowType", this.flowType);
  }


  setHighlghter(tabName: string) {
    if (tabName === 'faq') {
      this.tabData.isClaimTabActive = false;
      this.tabData.isFaqTabActive = true;
    } else {
      this.tabData.isClaimTabActive = true;
      this.tabData.isFaqTabActive = false;
    }
  }


  ngOnInit(): void {
    this.userDetails = this.tupssService.userDetails;
    this.currentUrl = this.router.url;
    this.isGuestUser = this.router.url.includes('guest');

    // If the flow is for channel partner portals
    if (this.dynamicFlowService.checkCcpUrl() === true) {
      //set current channel partner.
      this.channelPartner = this.dynamicFlowService.setChannelPartner();
      //get current theme data based on channel partner.
      this.currentTheme = this.dynamicFlowService.loadThemeData(this.channelPartner);
      //set top menu tab data
      this.tabData = this.dynamicFlowService.setTabData();
    }   
    
    // const countryDefault = this.stateList.find(c => c.id == '1');
    // this.formGroup.get('policyList').setValue(countryDefault.id);
    this.tupssService.userData$.pipe(
      filter(data => !!data)
    ).subscribe(
      data => {
        if (data) {
          this.siteIds = this.tupssService.getSiteIds();
          if (this.siteIds.length == 1 && data.contractData) {
            let flexContract = data.contractData.find(cd => cd && cd.programType == 'FLEX');
            if (flexContract) {
              this.tupssService.setSiteId(flexContract.siteID);
              this.tupssService.setContractId(flexContract.contractID);
              this.showPolicy = true
            }
            else {
              this.tupssService.setSiteId(data.contractData[0].siteID);
              this.tupssService.setContractId(data.contractData[0].contractID);
              this.showPolicy = false;
            }
          }
          else {
            this.showPolicy = true;
          }
        }
      }
    );

    if (this.currentUrl.startsWith('/tfcp') || this.userDetails?.type == 'tfcp') {
      this.tfcp = true;
      this.titleService.setTitle(this.translateService.transform('franchiseclaimportal.pageTitle'));
    }
    else if (this.currentUrl.startsWith('/tccp') || this.userDetails?.type == 'tccp') {
      this.tccp = true;
      this.titleService.setTitle(this.translateService.transform('customerclaimsportal.pageTitle'));
    }

    this.setActiveMenus();

    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          this.currentUrl = event.url;
          this.setActiveMenus();
        }
      }
    );
  }  

  setActiveMenus() {
    if (this.currentUrl.startsWith('/tupss') || this.currentUrl.startsWith('/tccp')
      || !this.currentUrl.endsWith('claims')) {
      this.filingClaim = true;
    }
    else {
      this.filingClaim = false;
    }

    if (!!this.currentUrl.match(/(policy|store|faqs|detail|delivery-status|confirm-package-status)/)) {
      this.subNav = false
    }
    else {
      this.subNav = true;
    }
  }

  fileClaimClick(event) {
    event.preventDefault();
    this.taggingService.link({ link_name: 'file_a_claim' });
    if (this.tfcp) {
      this.router.navigate(['tfcp']);
    }
    else {
      this.router.navigate(['tccp']);
    }
  }

  claimsClick(event) {
    event.preventDefault();
    this.taggingService.link({ link_name: 'all_claims' });

    if (this.skipSiteSelection()) {
      this.tupssService.userData$.pipe(
        filter(data => !!data)
      ).subscribe(
        data => {
          if (data && data.contractData && data.contractData.length > 1) {
            //This store is returning with more than 1 policy. We should only show the user the one that has programType UPSSTORES
            let edvPol = data.contractData.find(cd => cd && cd.programType == 'UPSSTORES');
            if (edvPol) {
              this.tupssService.setSiteId(edvPol.siteID);
              this.tupssService.setContractId(edvPol.contractID);
            }
          }
        }
      );
      //then navigate
      this.router.navigate(['tfcp', 'claims']); //go straight to claims for single site
    }
    else {
      this.router.navigate(['tfcp', 'store'], { fragment: 'claims' }); //go to site selection for multi sites
    }
  }

  policyClick(event) {
    event.preventDefault();

    if (this.skipSiteSelection()) {
      this.tupssService.userData$.pipe(
        filter(data => !!data)
      ).subscribe(
        data => {
          if (data && data.contractData && data.contractData.length > 1) {
            //This store is returning with more than 1 policy. We should only show the user the one that has programType FLEX
            let flexPol = data.contractData.find(cd => cd && cd.programType == 'FLEX');
            if (flexPol) {
              this.tupssService.setSiteId(flexPol.siteID);
              this.tupssService.setContractId(flexPol.contractID);
            }
          }
        }
      );
      //then navigate
      this.router.navigate(['tfcp', 'policy']); //go straight to policy for single site
    }
    else {
      this.router.navigate(['tfcp', 'store'], { fragment: 'policy' }); //go to site selection for multi sites
    }
  }

  skipSiteSelection() {
    if (this.siteIds?.length == 1) {
      return true;
    }
    return false;
  }

  public openContactUsDialog(): void {
    const dialogRef = this.contactUsDialog.open(CustomContactUsComponent);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openFAQs(event) {
    event.preventDefault();
    this.tupssService.openFaqs();
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }
}
