import { Component, EventEmitter, OnInit, Output, Input, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { PagerService } from 'src/app/services/pager.service';
import { PayeeService } from 'src/app/services/payee.service';
import { UserService } from 'src/app/services/user.service';
import { payeeInfo, sessionInfo } from '../../models/payee.interface';
import { environment as ENV } from '../../../../environments/environment';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PayeeBankPopupComponent } from '../payee-bank-popup/payee-bank-popup.component';
import { RemovePayeePopupComponent } from '../remove-payee-popup/remove-payee-popup.component';
import { RouteObserverService } from 'src/app/services/route-observer.service';
import { TaggingService } from 'src/app/tags/tagging.service';
import { ClaimService } from 'src/app/services/claim.service';
@Component({
  selector: 'upsc-payee-table',
  templateUrl: './payee-table.component.html',
  styleUrls: ['./payee-table.component.scss']
})
export class PayeeTableComponent implements OnInit {
  // @Input() selected: boolean; //mainly used for radio button
  @Output() selectionChange = new EventEmitter<any>();
  @Input() payeeIdSelected: string = "";
  isPagination: boolean = false;
  pager: any = {};
  searchPayees: payeeInfo[] = [];
  pagedPayeeList: any[];
  showSpinner = false;
  showError = false;
  previousUrl = null;
  sortedData: payeeInfo[];
  nameSort: boolean = false;
  payeeForm: UntypedFormGroup;
  selectedPayee: string;
  windowSize: any = window.innerWidth;
  showSelections: boolean = this.router.url.includes('/claims/new') || this.router.url == '/claims/MultiClaim';
  public searchByValue: any = 'recipientName';
  public searchQuery: any = "";
  public searchQueryLength: boolean = false;
  queryEntered: boolean;
  isHidesearch: boolean = false;
  allSortedData: payeeInfo[];
  emptySearchResult: boolean = false;
  isCanadaUser: boolean;
  isUsaUser: boolean;
  isUkUser: boolean;
  isGermanyUser: boolean;
  isFranceUser: boolean;
  isItalyUser: boolean;
  isGuestUser: boolean;
  hvcmFlow: boolean;
  searchTermControl: UntypedFormControl = new UntypedFormControl('', Validators.pattern('.*?\\S{1,}.*'));
  //sessionInfo used for initiate transfer session
  sessionInfo: sessionInfo = {
    "payeeId": "",
    "returnSessionId": "",  //This will be the same as payeeId. Used to update ACH indicator after adding bank info
    "returnUrl": `${ENV.baseUrl.web}claims/bankSuccess`
  };

  constructor(
    private payeeService: PayeeService,
    private userService: UserService,
    private pagerService: PagerService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private routeObserverService: RouteObserverService,
    private ref: ChangeDetectorRef,
    private taggingService: TaggingService,
    private claimService: ClaimService
  ) {
    this.payeeForm = this.fb.group({
      payee: ['', Validators.required],
      mobilePayee: ['', Validators.required]
    });

    // this.getPayees();

    fromEvent(window, 'resize').pipe(
      debounceTime(500)).subscribe(
        data => {
          if (data.target['innerWidth'] >= 768 && this.windowSize < 768) {
            this.payeeForm.controls.mobilePayee.setValue(null)
            this.payeeForm.updateValueAndValidity();
          } else if (data.target['innerWidth'] < 768 && this.windowSize >= 768) {
            this.payeeForm.controls.payee.setValue(null)
            this.payeeForm.updateValueAndValidity();
          }
          this.windowSize = data.target['innerWidth'];
        }
      )
    this.isCanadaUser = this.userService.isCanadaUser();
    this.isUsaUser = this.userService.isUsaUser();
    this.isUkUser = this.userService.isUkUser();
    this.isGermanyUser = this.userService.isGermanyUser();
    this.isFranceUser = this.userService.isFranceUser();
    this.isItalyUser = this.userService.isItalyUser();
    this.hvcmFlow = this.userService.isHVCMFlow;
  }

  ngOnChanges() {
    if(this.payeeIdSelected){         
      this.getPayees(); 
    }
  }

  ngOnInit(): void {
    this.isGuestUser = this.userService.isGuestUser();

    this.payeeService.activePayeeMonitor.pipe(
      filter(payeeID => payeeID == 'null'),
      distinctUntilChanged(),
      tap(payeeID => console.log("distinct payee id", payeeID))
    ).subscribe(
      data => this.getPayees(),
      err => this.getPayees()
    )

    this.payeeForm.valueChanges.subscribe(payeeFormControl => {
      this.selectionChange.emit(payeeFormControl);
    });

    this.routeObserverService.previousUrl$.subscribe(
      data => this.previousUrl = data
    );

   
  }
  ngAfterViewInit() {
    this.searchTermControl.valueChanges.subscribe(
      val => {
        if (val && val.length > 0) {
          val = val.trim();
          if ((val && val.length < 2)) {
            this.searchQueryLength = true;
            this.queryEntered = false;
          }
          else {
            this.searchQueryLength = false;
            this.queryEntered = true;
          }
        }
        else {
          this.searchPayees = [];
          this.queryEntered = false;
          this.emptySearchResult = false;
          this.sortData('date');
        }
        this.ref.detectChanges();
      }
    );

  }

  getPayees() {
    this.showSpinner = true;
    let request;
    if (this.userService.isGuestUser()) {
      request = {
        "policyNumber": this.userService.getUserInfo().policyNumber,
        "emailAddress": sessionStorage.getItem('guestUserEmail') //guest email goes here
      }
    } else {
      request = {
        "policyNumber": this.userService.getUserInfo().policyNumber
      }
    }
    this.payeeService.getPayee(request).subscribe(
      data => {

        this.showSpinner = false;
        this.showError = false;
        try {
          if (data && data.payeeInfo) {
            //Filter out the inactive payees before doing anything
            this.sortedData = data.payeeInfo.filter(payee => payee.recordActiveStatusIndicator === 'T');
            //this.sortedData = data.payeeInfo;
            this.allSortedData = this.sortedData;
            if (this.sortedData.length > 10) {
              this.isHidesearch = true;
              this.isPagination = true;
            }
            this.sortData('date');
            this.setPage(1);
            if (sessionStorage.getItem("paymentRecipientCurrentPage")) {  //Only used if on the payment recipient details tab
              this.setPage(Number(sessionStorage.getItem("paymentRecipientCurrentPage")));
              sessionStorage.removeItem("paymentRecipientCurrentPage");
            }
            if (this.payeeService.getClaimsPayeeInfo()) { //User has already selected a payee. reselect it.
              if (this.payeeService.isInDesktopView()) {
                let pageIndex = this.sortedData.findIndex(x => x.payeeID == this.payeeService.getClaimsPayeeInfo().payeeID);  //Find the page the user has selected from
                this.setPage(Math.trunc(pageIndex / 10) + 1);
                this.payeeForm.controls.payee.setValue(this.sortedData.find(x => x.payeeID == this.payeeService.getClaimsPayeeInfo().payeeID));
              } else {
                let pageIndex = this.sortedData.findIndex(x => x.payeeID == this.payeeService.getClaimsPayeeInfo().payeeID);
                this.setPage(Math.trunc(pageIndex / 10) + 1);
                this.payeeForm.controls.mobilePayee.setValue(this.sortedData.find(x => x.payeeID == this.payeeService.getClaimsPayeeInfo().payeeID));
              }
              this.payeeForm.updateValueAndValidity();
            } else if (this.sortedData.length == 1) {
              //If only 1 payee is available, automatically select it.
              if (this.payeeService.isInDesktopView()) {
                this.payeeForm.controls.payee.setValue(this.sortedData[0]);
              } else {
                this.payeeForm.controls.mobilePayee.setValue(this.sortedData[0]);
              }
              this.payeeForm.updateValueAndValidity();
            } else {
              if (this.sortedData.length > 1 || this.previousUrl == '/claims/payee/banking') {
                //If user just created a payee (determined by url), then automatically select that payee (will always be the newest one anyways) and If they update the Bank info then also it will be auto selected. 
                let selectedPayee = JSON.parse(sessionStorage.getItem("selectedPayee"));
                if (selectedPayee && selectedPayee.currentPage) {  //User is coming from "Check via mail" or "Add Bank Information" or even just hitting back button during file claim flow.
                  if (this.payeeService.isInDesktopView()) {
                    this.payeeForm.controls.payee.setValue(this.sortedData.find(i => i.payeeID == selectedPayee.payeeID));
                  } else {
                    this.payeeForm.controls.mobilePayee.setValue(this.sortedData.find(i => i.payeeID == selectedPayee.payeeID));
                  }
                  this.setPage(selectedPayee.currentPage);
                } else if (selectedPayee) { //User is coming from just creating a payee
                  if (this.payeeService.isInDesktopView()) {
                    this.payeeForm.controls.payee.setValue(this.sortedData.find(i => i.payeeID == selectedPayee));
                  } else {
                    this.payeeForm.controls.mobilePayee.setValue(this.sortedData.find(i => i.payeeID == selectedPayee));
                  }
                }
                this.payeeForm.updateValueAndValidity();
                sessionStorage.removeItem("selectedPayee");
              }
            }
          }
        } catch (e) { }
      },
      error => {
        this.showSpinner = false;
        this.showError = true;
      }
    )
  }

  //Sorts the payee list. sortBy(name) returns sorted list by payee names
  sortData(by) {
    let data = null;
    this.isPagination = false;
    try {
      if (this.emptySearchResult) {
        this.pagedPayeeList = null;
      }
      else {
        if (this.searchPayees && this.searchPayees.length > 0) {
          data = this.searchPayees.slice();
        }
        else {
          data = this.allSortedData.slice();
        }
        this.nameSort = !this.nameSort;
        let isAsc = this.nameSort;
        this.sortedData = data.sort((a, b) => {
          switch (by) {
            case 'name': return this.compare(a.payeeName.toLowerCase(), b.payeeName.toLowerCase(), isAsc); //Using Lower Case because otherwise uppercase preceeds lowercase, leading to not a true alphabetically sorted dataset
            case 'date': return this.compare(a.createdDate, b.createdDate, false);
            default: return 0;
          }
        });
        this.pagedPayeeList = this.sortedData;
        if (this.sortedData.length > 10) {
          this.isPagination = true;
          this.setPage(1);
        }
      }
    } catch (e) { }
  }

  //Set the page of the pagination. setPage(1) is the beginning
  setPage(page: number) {
    this.pager = this.pagerService.getPager(this.sortedData.length, page);
    this.pagedPayeeList = this.sortedData.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  //Simple comparison, is able to reverse order based on isAsc boolean
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  dpatGuest() {
    //I believe the payee table page is now depreciated by implemented this just in case.
    let request = {
      action: "Get Started",
      paymentDirection: "Claims",
      country: "US",
      paymentMode: "",
      policyIdentifier: this.userService.getUserInfo().policyNumber,
      email: sessionStorage.getItem('guestUserEmail'),
      callBackURL: `${ENV.baseUrl.web}claims/payee`, 
      scenario: "US",
      createdByUserId: "333",
      guestUserIndicator: this.isGuestUser ? "Y" : "N",
      tupssFlow: "",
      locale: sessionStorage.getItem('locale'),
    }
    this.claimService.getRedirect(request).subscribe(
      data => {
        window.location.replace(data.data.url);
      }, error => { }
    );

  }
  addBankInfo(payee: payeeInfo) {
    //Set the proper data for the session transfer request
    this.sessionInfo.payeeId = payee.payeeID;
    this.sessionInfo.returnSessionId = payee.payeeID;
    this.payeeService.displayPreexistingPayee = payee;  //Used for displaying preexisting payee info on bank page
    this.payeeService.recipientAddFlow.payeeFlow = "2";
    sessionStorage.setItem("paymentRecipientCurrentPage", this.pager.currentPage);
    this.payeeService.initiatePublicSessionTransfer(this.sessionInfo).subscribe(
      data => {
        //Check if result.code == S031 for success
        if (data.result.code == 'S031') {
          this.payeeService.achURL = data.sessionTransferUrl;
        } else {
          //Send error notification to service, display error message instead of iframe
          this.payeeService.initiateSessionFailed = true;
        }
        //Always reroute
        this.router.navigate(['/claims/payee/banking']);
      }
    );
  }

  removePayee(payee: payeeInfo) {
    this.dialog.open(RemovePayeePopupComponent,
      {
        data: {
          payee: payee
        }
      }
    );
  }

  openBankDetails(payee, checkViaMail: boolean) {
    let dialogRef = this.dialog.open(PayeeBankPopupComponent, {
      data: {
        payee: payee,
        checkViaMail: checkViaMail,
        currentPage: this.pager.currentPage,
        policyNum: this.userService.getUserInfo().policyNumber
      }
    });
  }

  get payee() { return this.payeeForm.controls.payee; }

  public changeCategory() {
    this.searchQuery = "";
    this.searchQueryLength = false;
  }
  resetSearch() {
    this.searchPayees = [];
    this.emptySearchResult = false;
    if (this.payeeService.isInDesktopView()) {
      this.payeeService.setClaimsPayeeInfo(null, true);
      this.payeeForm.controls.payee.reset();
    } else {
      this.payeeService.setClaimsPayeeInfo(null, false);
      this.payeeForm.controls.mobilePayee.reset();
    }
    this.selectionChange.emit(false);
  }

  public search() {
    this.resetSearch();
    var text = this.searchQuery.trim().toLowerCase();
    if (this.searchByValue === "recipientName") {
      for (let paye of this.sortedData) {
        if (paye['payeeName'].toLowerCase().includes(text)) {
          this.searchPayees.push(paye);
        }
      }
    }
    if (this.searchByValue === "recipientEmail") {
      for (let paye of this.sortedData) {
        if (paye['payeeEmail'].toLowerCase().includes(text)) {
          this.searchPayees.push(paye);
        }
      }
    }

    this.pagedPayeeList = this.searchPayees;
    if (this.searchPayees && this.searchPayees.length > 0) {
      this.sortData('date');
    }
    else {
      this.emptySearchResult = true;
      this.isPagination = false;
    }
  }

  tagging() {
    this.taggingService.link({ link_name: 'add_claim_payment_recipient' });
  }
}
