<div *ngIf="pageError" class="page-Error">
    {{ 'claims.edit.shippingStatus.errors.somethingWentWrong' | translate }}
</div>
<div class="header">
    <div class="subtitle" style="margin-left: 3px;">{{'app.armor.progress' | translate}}</div>
    <div class="progress-bar">
        <div class="progress-bar-filled" style="width: 30%;"></div>
    </div>
    <div class="title">{{'app.armor.title' | translate}}</div>
    <div class="bluebar"></div>
    <div class="subtext d-flex flex-row justify-content-between" style="margin-top: 26px;">{{'app.armor.search' |
        translate}}{{'app.armor.search2' |
        translate}}<div class="count">({{selectedCarriers?.length}}/3)</div>
    </div>

</div>
<form>
    <div class="form-fields">
        <mat-form-field appearance="fill">
            <mat-label>Search for carriers</mat-label>
            <input type="text" matInput [formControl]="searchControl" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectCarrier($event)">
                <mat-option *ngFor="let carrier of filteredCarriers" [value]="carrier">
                    {{ carrier}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <div class="selected-carrier-bubbles">
            <ul class="bubble">
                <li class="d-inline-flex justify-content-start align-items-center" *ngFor="let carrier of selectedCarriers">
                    <div class="ml-1">{{ carrier }}</div>
                    <mat-icon class="delete-icon ml-1" (click)="clearSelectedCarrier(carrier)">clear</mat-icon>
                </li>
            </ul>
        </div>
        <div class="buttons d-flex flex-row justify-content-between">
            <button class="upsc-secondary-button icon-left" routerLink="/prospect-portal/start">
                <mat-icon class="back-icon"> chevron_left</mat-icon>
                {{'app.armor.back'| translate}}
            </button>
            <div class="buttons d-flex flex-row">
                <button class="upsc-primary-button icon-right" [disabled]="selectedCarriers?.length === 0" (click)="doneAdding()">
                    {{'app.armor.addShippers' | translate}}
                    <mat-icon class="back-icon">chevron_right</mat-icon>
                </button>
            </div>
        </div>
        <div class="text" *ngIf="upsSelected">
            <div class="title" style="font-size: 20px;">{{'app.armor.shipmentUPS' | translate}}</div>
            <div class="subtext">{{'app.armor.shipmentText' | translate}}</div>
            <img src="../../../../assets/images/icon_info1.png" style="cursor: pointer; float: right;" (click)="toggleImage()">
            <div *ngIf="isImageVisible" class="image">
                <img src="../../../../assets/images/shipperNumberPicture.png">
            </div>
        </div>
        <div *ngIf="upsSelected">
            <form [formGroup]="shipperForm">
                <div class="d-flex flex-column">
                    <div *ngFor="let formControlName of allShipperFormControls; last as last">
                    <div class="d-flex flex-row">
                        <mat-form-field class="width-100">
                        <input matInput formControlName="{{formControlName}}" placeholder="UPS Shipper Number">
                        <mat-error *ngIf="shipperForm.get([formControlName])?.errors">
                            <div *ngIf="shipperForm.get([formControlName])?.errors['pattern']">Please enter a valid UPS shipper
                            number.</div>
                            <div *ngIf="shipperForm.get([formControlName])?.errors['notValid']">Please enter a valid UPS shipper number.</div>
                        </mat-error>
                        </mat-form-field>
                        <div class="add-shipper" *ngIf="!last">
                        <img class="mr-1" src="../../../assets/images/minus_circle.png"
                            (click)="removeShipperFormControl(formControlName)">
                        </div>
                        <div class="add-shipper" *ngIf="last" (click)="addShipperFormControl()">
                            <img class="mr-1" src="../../../assets/images/minus_circle.png"
                            (click)="removeShipperFormControl(formControlName)" *ngIf="allShipperFormControls?.length > 1">
                        <img class="mr-1" src="../../../assets/images/plus_circle.png">
                        <a class="upsc-link">Add Shipper Number</a>
                        </div>
                    </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="text" *ngIf="otherSelected">
            <div class="title">{{'app.armor.shipmentHeader' | translate}}</div>
            <div class="subtext">{{'app.armor.shipmentText' | translate}}</div>
        </div>
        <form [formGroup]="shipmentForm" (submit)="onSubmit(shipmentForm.value, $event)">
            <mat-form-field *ngIf="otherSelected" appearance="fill" class="input">
                <mat-label>Enter Number of Shipments</mat-label>
                <input matInput formControlName="numberOfShipments" type="number">
                <mat-icon matSuffix>create</mat-icon>
            </mat-form-field>
            <div *ngIf="upsSelected || otherSelected" style="font-size: 20px;">{{'app.armor.shipmentValueHeader'|
                translate}}</div>
            <div *ngIf="upsSelected || otherSelected">{{'app.armor.shipmentValueText' | translate}}</div>
            <mat-form-field *ngIf="upsSelected || otherSelected" appearance="fill" class="input">
                <mat-label>Enter Value of Shipments</mat-label>
                <input matInput formControlName="valueOfShipments" type="number">
                <mat-icon matSuffix>create</mat-icon>
            </mat-form-field>
            <div class="buttons d-flex flex-row" *ngIf="upsSelected || otherSelected">
                <button class="upsc-secondary-button icon-left" routerLink="/prospect-portal/start">
                    <mat-icon class="back-icon">chevron_left</mat-icon>
                    {{'app.armor.back' | translate}}
                </button>
                <button class="upsc-primary-button icon-right" [disabled]="!isFormValid()" (click)="onContinue()" *ngIf="!validateLoading">
                    {{'app.armor.continue' | translate}}
                    <mat-icon>chevron_right</mat-icon>
                </button>
                <button class="upsc-primary-button icon-right" disabled *ngIf="validateLoading">
                    {{'app.armor.continue' | translate}}
                    <span class="spinner-border"></span>
                </button>
            </div>
            <div class="error" *ngIf="validateError">{{ 'common.systemError' | translate }}</div>
            <div class="error" *ngIf="atLeastOneError">Please enter at least one UPS shipper number.</div>
        </form>
    </div>
</form>