import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../core/services/auth.service";
import { PolicyService } from "./policy.service";
import { UserService } from "./user.service";
import * as ENV from '../../environments/environment';

@Injectable()
export class RouteGuardService  {

  isDdUser: boolean = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private policyService: PolicyService,
    private authService: AuthService
  ) {
    if (this.userService.getUserInfo()?.userRoleID === '7') {
      this.isDdUser = true;
    }

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (state.url.startsWith('/adjusterDashboard')) {
      if (this.userService.isUserAdjuster() && !this.isDdUser) {
        return true;
      }
      else {
        this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
        return false;
      }
    }
    else if (state.url == '/claims/new') {
      if (this.userService.getUserPermissions()?.isFileClaim && (!this.isDdUser || this.userService.isDDClaimsFlow())) {
        return true;
      }
      else {
        this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
        return false;
      }
    }
    else if (state.url == '/claims/MultiClaim') {
      if (!this.userService.isGuestUser() && this.userService.getUserPermissions()?.isHVCM && this.userService.hasHVCM() && !this.isDdUser) {
        return true;
      }
      else {
        this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
        return false;
      }
    }
    else if (state.url == '/claims/message-list') {
      if (!this.userService.isGuestUser() && this.userService.getUserPermissions()?.isFileClaim && this.userService.isUsaUser() && !this.isDdUser) {
        return true;
      }
      else {
        this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
        return false;
      }
    }
    else if (state.url == '/claims/help') {
      if ((this.userService.getUserPermissions()?.isClaimView || this.userService.getUserPermissions()?.isFileClaim || this.userService.isGuestClaim() || this.userService.isShopifyUser())  && !this.isDdUser) {
        return true;
      }
      else {
        this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
        return false;
      }
    }
    else if (state.url == '/claims/draft-list') {
      if (!this.userService.isGuestUser() && this.userService.getUserPermissions()?.isFileClaim && this.userService.isUsaUser() && (!this.isDdUser || this.userService.isDDClaimsFlow())) {
        return true;
      }
      else {
        this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
        return false;
      }
    }
    else if (state.url.startsWith('/claims')) {
      var stng = state.url.slice(1, 20);
      if (stng.length == 19 && (stng == 'claims/new?payeeID=')) {
        if (this.userService.getUserPermissions()?.isFileClaim && (!this.isDdUser || this.userService.isDDClaimsFlow()) || this.userService.isGuestClaim() || this.userService.isShopifyUser()) {
          return true;
        }
        else {
          this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
          return false;
        }
      }
      else if (state.url == '/claims') {
        if ((!this.userService.isGuestUser())  && (!this.isDdUser || this.userService.isDDClaimsFlow()) && (this.userService.getUserPermissions()?.isClaimView || this.userService.getUserPermissions()?.isFileClaim || this.userService.isGuestClaim() || this.userService.isShopifyUser())) {
          return true;
        }
        else {
          this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
          return false;
        }
      }
    }
    else if (state.url.startsWith('/billing')) {
      if (ENV.environment.baseUrl.urlType == 'dev' || ENV.environment.baseUrl.urlType == 'qa') {  //In dev & qa environments, and for certain policies, allow them to use the billing tab.
        if (this.userService.getUserInfo()?.policyNumber == '15914024-P0000743' || this.userService.getUserInfo()?.policyNumber == '15914024-90063') {  //Transactional history policies
          return true;
        }
      }
      if ((this.userService.getUserPermissions()?.isBillingView || this.userService.isShopifyUser()) && (this.policyService.isGWPolicy() || (this.isDdUser && this.userService.isDDBillingFlow()))) {
        return true;
      }
      else {
        this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
        return false;
      }
    }
    else if (state.url.startsWith('/dashboard')) {
      if ((!this.userService.isGuestUser())  && !this.isDdUser && (this.userService.isHPGuestUser() || this.userService.isShopifyUser() || (this.userService.getUserPermissions()?.isBillingView) || (this.userService.getUserPermissions()?.isClaimView) || (this.userService.getUserPermissions()?.isFileClaim))) {
        return true;
      }
      else {
        this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
        return false;
      }
    }
    else if (state.url.startsWith('/self-service')) {
      let channeluniquePartner = JSON.parse(sessionStorage.getItem('ccpUniquePartnerName'));
      if (channeluniquePartner && channeluniquePartner.ccp_selfService_Check == true) {
        return true;
      }
      else {
        this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
        return false;
      }
    }
    else if (state.url.startsWith('/profile')) {
      if (!this.userService.isGuestUser()  && !this.isDdUser && !this.userService.isHPGuestUser() && !this.userService.isShopifyUser() && !this.userService.isTupssUser() && !this.userService.isUserAdjuster()) {
        return true;
      }
      else {
        this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
        return false;
      }
    }
    else if (state.url.startsWith('/policies')) {
      if ((!this.userService.isGuestUser() && !this.isDdUser) && (this.userService.getUserPermissions()?.isPolicyView || this.userService.isHPGuestUser() || this.userService.isShopifyUser())) {
        return true;
      }
      else {
        this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
        return false;
      }
    }
    else if (state.url.startsWith('/visibility')) {
      // New Visibility page is only for admin roles under is connect policies
      // if ((!this.isDdUser) && (!this.userService.isGuestClaim()) && this.userService.isAdminRole() && this.policyService.isConnectPolicy()) {
      // if ((!this.isDdUser) && (!this.userService.isGuestClaim())) {
      // Everything commented out above was for new DD visibility item dev work
      if ((!this.isDdUser) && (!this.userService.isGuestClaim()) && this.userService.isAdminRole() && (this.policyService.hasInsights() || this.policyService.hasShipVision() || this.policyService.isConnectPolicy())) {
        return true;
      }
      else {
        this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
        return false;
      }
    }
    else if (state.url.startsWith('/multicarrier')) {
      if (this.userService.isIS4UAcquisitionUser()) {
        return true;
      } else {
        this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
        return false;
      }
    }
    else if (state.url.startsWith('/guestVerify')) {
      if (this.userService.isGuestUser() && !this.isDdUser) {
        return true;
      }
      else if ((this.userService.isHPGuestUser() || this.userService.isShopifyUser) && this.authService.getAccessToken() && !this.isDdUser) {
        this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
        return false
      }
      else {
        this.router.navigate(['/login'], { skipLocationChange: true, replaceUrl: true });
        return false;
      }
    }
    else if (state.url.startsWith('/manage-users')) {
      if (this.userService.getUserPermissions()?.isUserManagement && this.userService.isUsaUser() && !this.isDdUser) {
        if (state.url.startsWith('/manage-users/edit-user')) {  //Additionally check the edit user page inside of manage-users
          if (route.queryParams.userId) {
            return true;
          } else {
            this.router.navigate(['/manage-users']);
            return false;
          }
        } else if (state.url.startsWith('/manage-users/sub-policies') || state.url.startsWith('/manage-users/shipper-accounts') || state.url.startsWith('/manage-users/add-shipper') || state.url.startsWith('/manage-users/add-sub-policy')){
          if (this.userService.isEAUserorNonEA()) {
            return true;
          } else {
            this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
            return false;
          }
        } else {  //User just trying to visit /manage-user, and they do have the right permissions. Let them in.
          return true;
        }
      } else {  //User did not have right permissions.
        this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
        return false;
      }
    } else {
      if (this.userService.isHPGuestUser() && !this.isDdUser) {
        return true;
      }
      else {
        this.router.navigate(['/unauthorized'], { skipLocationChange: true, replaceUrl: true });
        return false;
      }
    }
  }
}
