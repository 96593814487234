import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SiteConfig } from '../config/site-config';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginService } from './services/login.service';
import { AuthInterceptor } from './core/services/auth.interceptor';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ErrordialogComponent } from './shared/components/errordialog/errordialog.component';
import { PreLoadService } from './preLoad.service';
import { CoreModule } from './core/core.module';
import { WarningDialogComponent } from './dialog/warning-dialog/warning-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { LayoutModule } from '@angular/cdk/layout';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginModule } from './pages/login/login.module';
import { ClaimsModule } from './pages/claims/claims.module';
import { ResetpasswordModule } from './pages/resetpassword/resetpassword.module';
import { UpdateSuccessModule } from './pages/profile/updatesuccess/updatesuccess.module';
import { ProfileModule } from './pages/profile/profile.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { CollapsiblePanelModule } from './shared/components/collapsible-panel/collapsible-panel.module';
import { CollapsiblePanel2Module } from './shared/components/collapsible-panel2/collapsible-panel2.module';
import { CreateAccountModule } from './pages/create-account/create-account.module';
import { AdjusterDashboardModule } from './pages/adjuster-dashboard/adjuster-dashboard.module';
import { NotificationBoxModule } from './shared/components/notification-box/notification-box.module';
import { ClaimService } from './services/claim.service';
import { AccountService } from './services/account.service';
import { TechnologyAgreementComponent } from './pages/technology-agreement/technology-agreement.component';
import { UrlHandlerComponent } from './pages/url-handler/url-handler.component';
import { RouteGuardService } from './services/routeGuard.service';
import { UnauthorizedComponent } from './pages/errors/unauthorized/unauthorized.component';
import { HpCoiModule } from './pages/hp-coi/hp-coi.module';
import { GuestVerificationModule } from './pages/guest-verification/guest-verification.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { InsightsContainerModule} from './pages/insights-container/insights-container.module';
import { AuthComponent } from './pages/auth/auth.component'
import { SmartUploadModule } from './pages/smart-upload/smart-upload.module';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { StepperModule } from './shared/components/stepper/stepper.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { RemovePayeePopupComponent } from './shared/components/remove-payee-popup/remove-payee-popup.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ZipcodeDirective } from './shared/directives/zipcode.directive';
import { TuppsStepperModule } from './shared/components/tupps-stepper/stepper.module';
import { JpmcErrorDialogComponent } from './dialog/jpmc-error-dialog/jpmc-error-dialog.component';
import { FooterModule } from './shared/components/footer/footer.module';
import { CustomClaimsModule } from './pages/claims-custom/claims-custom.module'
import { SharedModule } from './shared/shared.module';
import { ExportComponent } from './pages/billing/dialog/export/export.component';
import { ManageUsersComponent } from './pages/manage-users/manage-users.component';
import { InternalUserModule } from './pages/internal-user/internal-user.module';
import { NewNotificationBoxModule } from './shared/components/new-notification-box/new-notification-box.module';
import { ProspectComponent } from './pages/prospect/prospect.component';
import { NewpolicyModule } from './pages/newpolicy/newpolicy.module';
import { EmailChangeConfirmationComponent } from './pages/profile/dialog/address-change-confirmation/email-change-confirmation/email-change-confirmation.component';
import { ProspectPortalModule } from './pages/prospect-portal/prospect-portal.module';
const globalRippleConfig: RippleGlobalOptions = {
  disabled: true
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function tokenGetter() {
  return localStorage.getItem(SiteConfig.storageKey.accessToken);
}

export function preLoadServiceFactory (provider: PreLoadService) {
  return provider.load();
}

@NgModule({
    imports: [
        CoreModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        MatDialogModule,
        MatProgressBarModule,
        MatRadioModule,
        StepperModule,
        TuppsStepperModule,
        LayoutModule,
        ReactiveFormsModule,
        LoginModule,
        ClaimsModule,
        MatExpansionModule,
        MatCheckboxModule,
        ResetpasswordModule,
        GuestVerificationModule,
        UpdateSuccessModule,
        ProfileModule,
        DashboardModule,
        CollapsiblePanelModule,
        CollapsiblePanel2Module,
        NotificationBoxModule,
        NewNotificationBoxModule,
        CreateAccountModule,
        AdjusterDashboardModule,
        HpCoiModule,
        MatRadioModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
        MatInputModule,
        MatIconModule,
        MatSelectModule,
        MatAutocompleteModule,
        NgbModule,
        FooterModule,
        InsightsContainerModule,
        SmartUploadModule,
        SharedModule,
        CustomClaimsModule,
        NewpolicyModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                // whitelistedDomains: getWhitelistedDomains(),
                allowedDomains: [
                    'localhost:3000',
                ],
                // blacklistedRoutes: getBlacklistedDomains(),
                disallowedRoutes: [
                    'localhost:3000/auth/'
                ],
                skipWhenExpired: true,
            }
        }),
        NgProgressModule.withConfig({
            color: '#00857d',
            spinner: false,
        }),
        NgProgressHttpModule,
        NgProgressRouterModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        InternalUserModule,
        ProspectPortalModule
    ],
    declarations: [
        AppComponent,
        ErrordialogComponent,
        WarningDialogComponent,
        TechnologyAgreementComponent,
        UrlHandlerComponent,
        UnauthorizedComponent,
        AuthComponent,
        RemovePayeePopupComponent,
        ZipcodeDirective,
        JpmcErrorDialogComponent,
        ExportComponent,
        ManageUsersComponent,
        ProspectComponent,
        EmailChangeConfirmationComponent
    ],
    providers: [
        LoginService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
        PreLoadService,
        DatePipe,
        TitleCasePipe,
        ClaimService,
        AccountService,
        RouteGuardService
        /* ,
        { provide: APP_INITIALIZER, useFactory: preLoadServiceFactory, deps: [PreLoadService], multi: true } */
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
