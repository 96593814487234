import * as i18 from '../../../assets/i18n/en.json';

let translations = i18;

export interface ProspectPortal {
  opportunityID: string;
  accountOwner?: string;
  accountOwnerEmail?: string;
  customerNumber?: string;
  customerName: string;
  customerEmail: string
  customerCompany: string;
  customerPhone: string;
  billingStreet: string;
  billingStreet2?: string;
  billingCity: string;
  billingStateCode: string;
  billingPostalCode: string;
  taxID?: string;
  webSite?: string;
  currentPage: string;
  reviewStatus?: string;
  dpsFound?: any;
  products?: string[];
  selectedCarriers?: string[];
  numberOfShipments?: any;
  valueOfShipments?: any;
  shipperNumbers?: any
}

export const COMMODITY_LISTS = [
  { key: '006', value: 'Apparel'},
  { key: '161', value: 'Textiles'},
  { key: '011', value: 'Artwork and Collectibles'},
  { key: '019', value: 'Automotive Parts and Accessories'},
  { key: '043', value: 'Cell Phones'},
  { key: '051', value: 'Commercial Electronics'},
  { key: '059', value: 'Computers & Equipment'},
  { key: '062', value: 'Consumer Electronics'},
  { key: '070', value: 'Electronic Components'},
  { key: '078', value: 'Furniture and Décor'},
  { key: '060', value: 'Construction Equipment'},
  { key: '091', value: 'Hardware / Tools - Retail Consumer Products'},
  { key: '018', value: 'Machinery Parts'},
  { key: '107', value: 'Metal - Aluminum, Copper, Steel'},
  { key: '133', value: 'Pipes'},
  { key: '096', value: 'Jewelry & Watches'},
  { key: '116', value: 'Office Supplies & Stationary'},
  { key: '104', value: 'Instruments, scientific, optical, surgical'},
  { key: '105', value: 'Medical Equipment / Supplies'},
  { key: '165', value: 'Sporting Goods and Outdoor Equipment'},
  { key: '118', value: 'Oil and Gas Field Machinery and Equipment'},
  { key: '002', value: 'Aircraft Wiring'},
]
