import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'upsc-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  prospectPortalData;
  pageLoading = true;

  constructor(
    private router: Router
  ) {
    this.gatherSessionData();
  }

  ngOnInit() { }

  ngOnDestroy() {
    sessionStorage.removeItem('prospectPortalData');
  }

  gatherSessionData() {
    this.prospectPortalData = JSON.parse(sessionStorage.getItem('prospectPortalData'));
    this.pageLoading = false;
  }

  navigate() {
    this.router.navigate(['/login']);
  }


}
