<div class="add-policy">
    <div class="policy-container">

        <div *ngIf="currentStep === 1">
            <ng-container *ngTemplateOutlet="detailsTepmlate"></ng-container>
        </div>
        <div *ngIf="currentStep === 2">
            <ng-container *ngTemplateOutlet="verificationTemplate"></ng-container>
        </div>
        <div *ngIf="currentStep === 3">
            <ng-container *ngTemplateOutlet="successTemplate"></ng-container>
        </div>
    </div>
    <ng-template #detailsTepmlate>
        <form [formGroup]="policyFormGroup" class="policy-details" (ngSubmit)="onSubmit()">
            <div class="title">
                <div class="login-title">{{'policies.policy.addNewPolicy' | translate}}</div>
            </div>
            <div class="bar">
                <div class="login-bar"></div>
            </div>
            <div class="title-sub">
                <div class="title">{{'policies.policy.subTitle' | translate}}</div>
            </div>
            <div class="input-container">
                <mat-form-field>
                    <input matInput required placeholder="{{ 'createAccount.account.form.email' | translate }}"
                        formControlName="emailFormControl" class="custom-width">
                    <mat-error *ngIf="policyFormGroup.controls['emailFormControl'].errors?.required">
                        {{ 'createAccount.account.form.emailRequired' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="(policyFormGroup.controls['emailFormControl'].errors?.invalid || policyFormGroup.controls['emailFormControl'].errors?.email) && !policyFormGroup.controls['emailFormControl'].errors?.required">
                        {{ 'createAccount.account.form.validEmail' | translate }}
                    </mat-error>
                    <mat-error *ngIf="policyFormGroup.controls['emailFormControl'].errors?.inuse">
                        {{ 'createAccount.account.form.emailInUse' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="input-container">
                <mat-form-field>
                    <input autocomplete="off" matInput required
                        placeholder="{{ 'createAccount.account.form.policy' | translate }}"
                        formControlName="policyNumberFormControl">
                    <mat-error *ngIf="policyFormGroup.controls['policyNumberFormControl'].errors?.required">
                        {{ 'createAccount.account.form.policyRequired' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="policyFormGroup.controls['policyNumberFormControl'].errors && !policyFormGroup.controls['policyNumberFormControl'].errors?.required">
                        {{ 'createAccount.account.form.validPolicy' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="input-container">
                <mat-form-field>
                    <input matInput required placeholder="{{ 'createAccount.account.form.zip' | translate }}"
                        formControlName="zipFormControl">
                    <mat-error *ngIf="policyFormGroup.controls['zipFormControl'].errors?.required">
                        {{ 'createAccount.account.form.zipRequired' | translate }}
                    </mat-error>
                    <mat-error *ngIf="policyFormGroup.controls['zipFormControl'].errors?.pattern">
                        {{ 'common.validZip' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="requiredMark">
                <div class="star">*</div>
                <div class="reqText">{{'policies.policy.requiredField' | translate}}</div>
            </div>
            <div class="btnContainers">
                <button type="button" class="upsc-white-button" routerLink='/dashboard'><mat-icon
                        class="icon-key">keyboard_arrow_left</mat-icon>{{ 'common.cancel' | translate }}</button>
                <button type="submit" class="upsc-green-popup-button">Next<mat-icon
                        class="icon-key2">keyboard_arrow_right</mat-icon></button>
            </div>
            <div *ngIf="invalidPolicy" class="policy-error">
                Invalid Policy
            </div> 
            <div *ngIf="systemError" class="policy-error">
                Something went wrong when attempting to add policy. Please try again later.
            </div>
        </form>
    </ng-template>

    <ng-template #verificationTemplate>
       <upsc-email-verification flow="add-policy" [email]="emailAddress" (addPolicyResponse)="addPolicyResponse($event)"></upsc-email-verification> 
       <div class="policy-error" *ngIf="policyError">Policy Count Exceeds or Duplicate Policy</div>
    </ng-template>

   

    <ng-template #successTemplate>
        <div class="verifyFormGroup">
            <div class="center-verify">
                <div class="title-verify-success">
                    <div class="login-title">{{'policies.policy.completeNewPolicy' | translate}}</div>
                </div>
                <div class="bar-verify">
                    <div class="login-bar"></div>
                </div>
                <div class="add-success">
                    <img class="custom-width" src="../../../assets/images/add-policy-sucess.svg">
                </div>
                <div class="title-verify-success">
                    <div class="login-title">{{'policies.policy.success' | translate}}</div>
                </div>
                <div class="title-verify-success">
                    <div class="login-title">{{'policies.policy.newPolicyAdded' | translate}}</div>
                </div>
                <div class="btnHome">
                    <button type="button" routerLink='/dashboard' class="upsc-green-popup-button">{{'policies.policy.goBackHome' | translate}}<mat-icon class="icon-key2">keyboard_arrow_right</mat-icon></button>
                </div>
            </div>
        </div>
    </ng-template>
</div>